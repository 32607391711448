import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { AddressBlock, PhoneNumberInput } from 'common/components';

import inputLabels from 'common/messages/inputLabels';

const ContactSection = (): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="button" color="textSecondary" gutterBottom>
          <FormattedMessage {...inputLabels.address} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AddressBlock />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name="phone"
          render={({ field }) => (
            <PhoneNumberInput onChange={field.onChange} onBlur={field.onBlur} value={field.value} />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(ContactSection);
