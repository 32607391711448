// libraries
import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { Checkbox, FormControlLabel, FormHelperText, Grid, SvgIcon } from '@material-ui/core';
import { Button, NumberTextField, PaymentField } from 'common/components/index';
// messages
import messages from 'common/messages/messages';
import inputLabels from 'common/components/../messages/inputLabels';
import posMessages from 'modules/pos-kiosk/messages/messages';
// images
import { ReactComponent as PosIcon } from 'img/icons/sidebar/cash-register.svg';
// constants
import { defaultPriceNumberProps } from 'common/components/NumberController/NumberController';
import { getStepStyles } from '../styleConstants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
// utils
import { calculateTenderedPropositions, formatPrice, getPriceFromNumber } from 'common/utils';
import { cacheValidationSchema } from './cashValidationSchema';
// state
import { selectCurrentInvoice, selectUpdatedInvoiceLoading } from 'common/state/invoice/selectors';
import { IInvoicePaymentSplit, PaymentsType } from 'common/interfaces/invoices';
import { resetUpdateInvoiceWithSync, updateInvoiceWithSync } from 'common/state/invoice/actions';
import { PeakModules } from 'common/constants/peakModules';
import { StepContext } from 'common/createContext/stepContext';
import { useUpdatePaymentData } from '../useUpdatePaymentData';
import DeviceActionButton from 'common/components/DeviceActionButton/DeviceActionButton';
import { DeviceType } from 'common/constants/scanner';
import { ICustomDeviceActionButtonProps } from 'common/interfaces/webHid';
import useInitial from 'common/hooks/useInitial';

interface ICashPaymentStepProps {
  leftToPay: number;
  memberId?: string;
  clubId: string;

  module: PeakModules;
  isPaymentStep: boolean;
  paymentTypeId?: string;

  onClose: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  ...getStepStyles(theme),
  onAccount: {
    margin: 'auto',
  },
  suggestionBlock: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: theme.spacing(0.25),
  },
  suggestionButton: {
    padding: theme.spacing(0),
    backgroundColor: theme.palette.secondary.light,
    width: `calc(50% - ${theme.spacing(0.25)}px)`,
    minWidth: '40%',
    fontWeight: 400,
  },
}));

interface ICashPaymentForm {
  amount: number;
  tendered: number;
  change: number;
  applyToAccount: boolean;
}

const recalculateChange = (tenderedAmount: number, totalAmount: number): number => {
  const changeAmount = getPriceFromNumber(tenderedAmount - totalAmount);

  return changeAmount >= 0 ? changeAmount : 0;
};

const CustomButton = (props: ICustomDeviceActionButtonProps): JSX.Element => {
  return (
    <Button
      fullWidth
      color="primary"
      variant="outlined"
      startIcon={<SvgIcon component={PosIcon} />}
      {...props}
    >
      <FormattedMessage {...posMessages.openDrawer} />
    </Button>
  );
};

const CashPaymentStep: React.FC<ICashPaymentStepProps> = (
  props: ICashPaymentStepProps,
): JSX.Element => {
  const { leftToPay, module, isPaymentStep, memberId, paymentTypeId, onClose, clubId } = props;
  const classes = useStyles(props);
  const renderIntlMessage = useRenderIntlMessage();

  // form

  const defaultValues = useInitial(
    (): ICashPaymentForm => {
      return {
        amount: leftToPay,
        tendered: leftToPay,
        change: 0,
        applyToAccount: false,
      };
    },
  );

  const { control, watch, setValue, handleSubmit, reset, formState } = useForm<ICashPaymentForm>({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(cacheValidationSchema) as any, // TODO - PRM-1810 need resolver type
  });

  const { errors } = formState;
  const amount = watch('amount');
  const tendered = watch('tendered');

  const tenderedSuggestions: number[] = useMemo(() => calculateTenderedPropositions(+amount), [
    amount,
  ]);

  // global storage

  const dispatch = useDispatch();
  const currentInvoice = useSelector(selectCurrentInvoice);
  const isUpdatingInvoiceLoading = useSelector(selectUpdatedInvoiceLoading);

  const { helperData } = useContext(StepContext);
  const { profileId } = helperData || {};

  // handlers

  const handleProceed = (data: ICashPaymentForm): void => {
    const invoiceData = currentInvoice.toJS();
    const invoicePaymentSplit: IInvoicePaymentSplit = {
      paymentMethodId: paymentTypeId,
      type: PaymentsType.CASH,
      paymentAmount: data.amount,
      changeAmount: data.change || 0,
      tenderedAmount: data.tendered,
      applyToAccount: data.applyToAccount,
    };

    dispatch(
      updateInvoiceWithSync(
        module,
        clubId,
        invoiceData,
        invoicePaymentSplit,
        profileId,
        isPaymentStep,
      ),
    );
  };

  const handleTenderedSuggestionClick = (suggestion: number): void => {
    setValue('tendered', suggestion);
  };

  const handleChangeTendered = (newTendered: number, onChange: (...event: any) => void): void => {
    onChange(newTendered);
    setValue('change', recalculateChange(newTendered, amount));
  };

  // effects

  useUpdatePaymentData(module, profileId, onClose);

  useEffect(() => {
    return () => {
      reset(defaultValues);
      dispatch(resetUpdateInvoiceWithSync());
    };
  }, [dispatch, reset, defaultValues]);

  // renders

  return (
    <>
      <Grid container spacing={2} className={classes.body}>
        <Grid item sm={6} xs={12}>
          <Controller control={control} name="amount" render={() => <></>} />

          <Controller
            control={control}
            name="tendered"
            render={({ field }) => {
              return (
                <PaymentField
                  fullWidth
                  variant="outlined"
                  name={field.name}
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={val => handleChangeTendered(+val, field.onChange)}
                  defaultValue={leftToPay}
                  label={<FormattedMessage {...inputLabels.amountTendered} />}
                  error={!!errors.tendered}
                  helperText={renderIntlMessage(errors.tendered?.message)}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} className={classes.suggestionBlock}>
          {tenderedSuggestions.map(suggestion => (
            <Button
              key={suggestion}
              fullWidth
              className={classes.suggestionButton}
              onClick={() => handleTenderedSuggestionClick(suggestion)}
            >
              {formatPrice(suggestion)}
            </Button>
          ))}
        </Grid>

        {amount !== tendered && (
          <Grid item sm={6} xs={12}>
            <Controller
              control={control}
              name="change"
              render={({ field }) => (
                <NumberTextField
                  fullWidth
                  variant="outlined"
                  name={field.name}
                  value={field.value}
                  label={<FormattedMessage {...inputLabels.changeAmount} />}
                  numberFormatProps={defaultPriceNumberProps}
                  disabled
                />
              )}
            />
          </Grid>
        )}

        {memberId && amount !== tendered && (
          <Grid item sm={6} className={classes.onAccount}>
            <Controller
              name="applyToAccount"
              control={control}
              render={({ field }) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onBlur={field.onBlur}
                        onChange={e => field.onChange(e.target.checked)}
                      />
                    }
                    label={<FormattedMessage {...messages.applyChangeAmountToAccountLabel} />}
                  />
                  {errors.applyToAccount && (
                    <FormHelperText error>
                      {renderIntlMessage(errors.applyToAccount?.message)}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2} className={classes.footerActions}>
        <Grid item xs={6}>
          <DeviceActionButton deviceType={DeviceType.CashDrawer} CustomButton={CustomButton} />
        </Grid>

        <Grid item xs={6}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            disabled={!formState.isValid || isUpdatingInvoiceLoading}
            onClick={handleSubmit(handleProceed)}
          >
            <FormattedMessage
              {...(amount !== leftToPay ? messages.proceedBtn : messages.checkOut)}
            />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CashPaymentStep;
