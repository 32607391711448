import axios from 'axios';
import ApiService from './ApiService';
import { IPaginatedData } from 'common/interfaces/pagination';
import { ITableParams } from 'common/interfaces/table';
import { IEndReasonItem } from 'modules/dictionaries/interfaces/interfaces';

export default class EndReasonsService extends ApiService {
  public getEndReasons = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedData<IEndReasonItem>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/end-reason', { params: searchParams });
  };

  public createEndReason = (data: IEndReasonItem): Promise<IEndReasonItem> => {
    return axios.post('/api/v1/end-reason', data);
  };

  public updateEndReason = (data: IEndReasonItem): Promise<IEndReasonItem> => {
    return axios.put(`/api/v1/end-reason/${data.id}`, data);
  };

  public updateStatusEndReason = (id: string, isActive: boolean): Promise<IEndReasonItem> => {
    return axios.patch(`/api/v1/end-reason/${id}/change-status`, { active: isActive });
  };
}
