/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';

import { personInitialState } from './reducers';
import { ActionResult } from 'common/constants';
import {
  IFallthroughCaptureImt,
  ILeadCallHistoryDtoImt,
  IPaymentAccountImt,
  IPersonDocumentsImt,
  IReminderDtoImt,
  ISMSImt,
  ReminderHistoryDtoImt,
} from 'common/components/PersonProfile/interfaces';
import { ILeadEmailHistoryDtoImt } from 'modules/crm/interfaces/leads';
import { IStoredFileSimpleDtoImt } from 'common/interfaces/uploadFile';
import { AppStoreTypeImt } from 'store';
import { IPageMetaImt } from 'common/interfaces/pagination';

const selectPersonPanelDomain = (state, { personId }) => {
  return state.getIn(['persons', 'profiles', Number(personId)]);
};

export const createPersonSelectorFactory = (selectors: string | string[]) => () =>
  createSelector([selectPersonPanelDomain], state => {
    const st = state || fromJS(personInitialState);
    if (Array.isArray(selectors)) {
      return st.getIn(selectors);
    }
    return st.get(selectors);
  });

export const personsListPath = ['persons', 'profiles'];

export const selectPersonProfile = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'profile']);

export const selectPersonAdditionalFields = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'additionalFields']);

export const selectPersonProfileLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'profile', 'profileLoading']);

export const selectPersonUpdateProfileActionLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'updateProfileAction', 'isLoading']);
export const selectPersonUpdateProfileActionResult = (personId: string) => (state): ActionResult =>
  state.getIn([...personsListPath, personId, 'updateProfileAction', 'result']);

export const selectRemindLaterActionResult = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'remindLaterActionResult']);

// TODO delete after class components will be removed
export const makeSelectPersonProfile = createPersonSelectorFactory('profile');
export const makeSelectPersonAdditionalFields = createPersonSelectorFactory('additionalFields');
export const makeSelectPersonProfileLoading = createPersonSelectorFactory('profileLoading');

const selectPersonsProfileDomain = (state, { customerId }) => {
  return state.getIn([...personsListPath, customerId]);
};

export const makeSelectPersonNotes = () =>
  createSelector(selectPersonsProfileDomain, profile => (profile ? profile.get('notes') : []));
export const makeSelectPersonNotesLoading = () =>
  createSelector(selectPersonsProfileDomain, profile => Boolean(profile?.get('notesLoading')));

export const makeSelectCreatedNote = () =>
  createSelector(selectPersonsProfileDomain, profile =>
    profile ? profile.get('createdNote') : {},
  );
export const selectCreatedNote = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'createdNote']);
export const makeSelectCreateNoteLoading = () =>
  createSelector(selectPersonsProfileDomain, profile => Boolean(profile?.get('addNoteLoading')));
export const selectCreateNoteLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'addNoteLoading']);

export const makeSelectUpdatedNote = () =>
  createSelector(selectPersonsProfileDomain, profile =>
    profile ? profile.get('updatedNote') : {},
  );
export const makeSelectUpdatedNoteLoading = () =>
  createSelector(selectPersonsProfileDomain, profile =>
    Boolean(profile?.get('updatingNoteLoading')),
  );

export const makeSelectDeletedNote = () =>
  createSelector(selectPersonsProfileDomain, profile =>
    profile ? profile.get('deletedNote') : {},
  );
export const makeSelectDeletedNoteLoading = () =>
  createSelector(selectPersonsProfileDomain, profile =>
    Boolean(profile?.get('deletingNoteLoading')),
  );

// Payment methods
export const selectStoredCreditCards = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'paymentMethods', 'cards']);
export const selectPaymentMethodsModalDataLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'paymentMethods', 'paymentMethodsModalDataLoading']);
export const selectPersonSubscriptions = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'paymentMethods', 'subscriptions']);
export const selectUpdatePersonSubscriptionsActionLoading = (personId: string) => state =>
  state.getIn([
    ...personsListPath,
    personId,
    'paymentMethods',
    'updateSubscriptionsAction',
    'isLoading',
  ]);
export const selectUpdatePersonSubscriptionsActionResult = (personId: string) => state =>
  state.getIn([
    ...personsListPath,
    personId,
    'paymentMethods',
    'updateSubscriptionsAction',
    'result',
  ]);
export const selectPersonPaymentMethods = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'paymentMethods', 'list']);
export const selectAddCreditCardResult = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'paymentMethods', 'addCardResult']);
export const selectAddCreditCardResultLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'paymentMethods', 'addCardResultLoading']);
export const selectDeleteCardResult = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'paymentMethods', 'deleteCardResult']);
export const selectDeleteCardResultLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'paymentMethods', 'deleteCardResultLoading']);

// Checking
export const selectCheckingSavingsData = (personId: string) => (state): List<IPaymentAccountImt> =>
  state.getIn([...personsListPath, personId, 'checkingSavings']);
export const selectFetchCheckingSavingsDataLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'fetchCheckingSavingsLoading']);
export const selectAddCheckingSavingsDataLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'addCheckingSavingsLoading']);
export const selectAddCheckingSavingsDataActionResult = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'addCheckingSavingsActionResult']);
export const selectDeleteCheckingSavingsItemLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'deleteCheckingSavingsLoading']);
export const selectDeleteCheckingSavingsItemActionResult = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'deleteCheckingSavingsActionResult']);

// note codes
export const makeSelectNoteCodes = createSelector(selectPersonsProfileDomain, profile =>
  profile ? profile.get('noteCodes') : {},
);
export const selectNoteCodes = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'noteCodes']);
export const makeSelectNoteCodesIsLoading = createSelector(selectPersonsProfileDomain, profile =>
  Boolean(profile?.get('noteCodesIsLoading')),
);
export const selectNoteCodesLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'noteCodesIsLoading']);

// fallthrough capture
export const selectPersonFallthroughCapture = (personId: string) => (
  state,
): IFallthroughCaptureImt | null => {
  return state.getIn([...personsListPath, personId, 'fallthroughCapture']);
};

export const selectPersonFallthroughCaptureForClass = () =>
  createSelector(selectPersonPanelDomain, state => {
    const st = state || fromJS(personInitialState);

    return st.get('fallthroughCapture');
  });

export const selectPersonFallthroughCaptureLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'fallthroughCaptureLoading']);

export const selectPersonFallthroughCaptureActionResult = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'fallthroughCaptureActionResult']);

export const selectPersonFallthroughCaptureActionLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'fallthroughCaptureActionLoading']);

export const selectPersonCampaigns = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'campaigns']);

export const selectPersonCampaignsLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'campaignsLoading']);

export const selectPersonCampaignsHistoryMeta = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'campaignsHistoryMeta']);
export const selectPersonCampaignsHistory = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'campaignsHistory']);
export const selectPersonCampaignsHistoryLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'campaignsHistoryLoading']);

export const selectPersonEmail = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'email']);

export const selectPersonEmailLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'emailLoading']);

export const selectPersonSuggestedProducts = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'suggestedProducts']);
export const selectPersonSuggestedProductsLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'suggestedProductsLoading']);

export const selectPayments = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'payments']);
export const selectPaymentsLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'paymentsLoading']);
export const selectPaymentsMeta = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'paymentsMeta']);
export const selectRecentPayments = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'recentPayments']);
export const selectRecentPaymentsLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'recentPaymentsLoading']);
export const selectPaymentDetails = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'paymentDetails']);
export const selectPaymentDetailsLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'paymentDetailsLoading']);

export const selectVoidInvoiceActionResult = personId => {
  return (state): ActionResult | null => {
    return state.getIn([...personsListPath, personId, 'voidInvoice', 'result']);
  };
};
export const selectVoidInvoiceLoading = personId => {
  return (state): boolean => {
    return state.getIn([...personsListPath, personId, 'voidInvoice', 'isLoading']);
  };
};

export const makeSelectPersonRecentCalls = createPersonSelectorFactory('recentCalls');
export const makeSelectPersonRecentCallsLoading = createPersonSelectorFactory('recentCallsLoading');

export const selectPersonCalls = personId => {
  return (state): List<ILeadCallHistoryDtoImt> => {
    return state.getIn([...personsListPath, personId, 'calls']);
  };
};
export const selectPersonCallsMeta = personId => {
  return (state): IPageMetaImt => {
    return state.getIn([...personsListPath, personId, 'callsMeta']);
  };
};
export const selectPersonCallsLoading = personId => {
  return (state): boolean => {
    return state.getIn([...personsListPath, personId, 'callsLoading']);
  };
};

export const selectPersonCallDetailsLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'isCallLoading']);

export const selectPersonCallDetails = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'call']);
export const makeSelectPersonUpdateCallAction = createPersonSelectorFactory('createCallAction');

export const selectPersonCreateEmailActionResult = (personId: string) => {
  return state => state.getIn([...personsListPath, personId, 'createEmailActionResult']);
};

export const makeSelectPersonCreateSmsActionResult = createPersonSelectorFactory(
  'createSmsActionResult',
);

export const makeSelectPersonRecentEmails = createPersonSelectorFactory('recentEmails');
export const makeSelectPersonRecentEmailsLoading = createPersonSelectorFactory(
  'recentEmailsLoading',
);

export const selectPersonEmailsMeta = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'emailsMeta']);
export const selectPersonEmails = (personId: string) => (state): List<ILeadEmailHistoryDtoImt> =>
  state.getIn([...personsListPath, personId, 'emails']);
export const selectPersonEmailsLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'emailsLoading']);

export const makeSelectPersonSMS = createPersonSelectorFactory('sms');
export const makeSelectPersonSMSLoading = createPersonSelectorFactory('smsLoading');

export const selectPersonSMSDetails = (personId: string) => (state: AppStoreTypeImt): ISMSImt =>
  state.getIn([...personsListPath, personId, 'smsDetails']);
export const selectPersonSMSDetailsLoading = (personId: string) => (
  state: AppStoreTypeImt,
): boolean => state.getIn([...personsListPath, personId, 'smsDetailsLoading']);

export const selectPersonSMSHistoryMeta = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'smsHistoryMeta']);
export const selectPersonSMSHistory = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'smsHistory']);
export const selectPersonSMSHistoryLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'smsHistoryLoading']);

export const selectPersonReminders = (personId: string) => (
  state: AppStoreTypeImt,
): List<ReminderHistoryDtoImt> => {
  return state.getIn([...personsListPath, personId, 'reminders']);
};
export const selectPersonRemindersMeta = (personId: string) => (
  state: AppStoreTypeImt,
): IPageMetaImt => {
  return state.getIn([...personsListPath, personId, 'remindersMeta']);
};
export const selectPersonRemindersLoading = (personId: string) => (
  state: AppStoreTypeImt,
): boolean => {
  return state.getIn([...personsListPath, personId, 'remindersLoading']);
};

export const selectPersonReminder = (personId: string) => (
  state: AppStoreTypeImt,
): IReminderDtoImt => {
  return state.getIn([...personsListPath, personId, 'reminder']);
};
export const selectPersonReminderLoading = (personId: string) => (
  state: AppStoreTypeImt,
): boolean => {
  return state.getIn([...personsListPath, personId, 'reminderLoading']);
};

export const makeSelectPersonRecentReminders = createPersonSelectorFactory('recentReminders');
export const makeSelectPersonRecentRemindersLoading = createPersonSelectorFactory(
  'remindersLoading',
);

export const makeSelectAddPersonReminderActionResult = createPersonSelectorFactory(
  'addReminderAction',
);

// services section

export const selectMembershipServices = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'services', 'membershipInfo', 'services']);
export const selectMembershipInventories = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'services', 'membershipInfo', 'inventories']);

export const selectExternalServices = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'services', 'posInfo', 'services']);
export const selectExternalInventories = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'services', 'posInfo', 'inventories']);

export const selectFamilyMembersServices = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'services', 'familyMembershipServices']);

export const selectServicesSectionLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'servicesLoading']);

// export const makeSelectPersonServicesLoading = createPersonSelectorFactory('servicesLoading');

export const makeSelectPersonServiceItem = createPersonSelectorFactory('serviceItem');
export const makeSelectPersonServiceItemLoading = createPersonSelectorFactory('serviceItemLoading');

// Modification history

export const selectModificationHistory = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'modificationHistory']);
export const selectModificationHistoryLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'modificationHistoryLoading']);
export const selectModificationHistoryMeta = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'modificationHistoryMeta']);
export const selectRecentModificationHistory = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'recentModificationHistory']);
export const selectRecentModificationHistoryLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'recentModificationHistoryLoading']);
export const selectModificationHistoryItem = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'modificationHistoryItem']);
export const selectModificationHistoryItemLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'modificationHistoryItemLoading']);

// CheckIns history

export const selectRecentCheckInsHistoryList = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'recentCheckInsHistory']);
export const selectRecentCheckInsHistoryLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'recentCheckInsHistoryLoading']);
export const selectDocumentsHistoryList = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'documentsHistory']);
export const selectDocumentsHistoryLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'isDocumentsHistoryLoading']);
export const selectDocumentsHistoryMeta = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'documentsHistoryMeta']);
export const selectCheckInsHistoryList = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'checkInsHistory']);
export const selectCheckInsHistoryMeta = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'checkInsHistoryMeta']);
export const selectCheckInsHistoryLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'checkInsHistoryLoading']);

export const selectUpcomingBillings = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'upcomingBillings']);
export const selectUpcomingBillingsLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'upcomingBillingsLoading']);
export const selectUpdateBillingScheduleAmountActionLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'updateBillingScheduleAmountAction', 'isLoading']);
export const selectUpdateBillingScheduleAmountActionResult = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'updateBillingScheduleAmountAction', 'result']);

export const selectPastDueBillings = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'pastDueBillings']);

export const selectPastDueBillingsLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'pastDueBillingsLoading']);

const selectSignWaiverDomain = state => {
  return state.getIn(['persons', 'signWaiver']);
};

export const selectSignWaiverLoading = () =>
  createSelector(selectSignWaiverDomain, state => state.get('isLoading'));

export const selectSignWaiverResult = () =>
  createSelector(selectSignWaiverDomain, state => state.get('result'));

const selectProfilesDomain = state => {
  return state.getIn(['persons', 'profiles']);
};

export const selectUpdateImage = (id: string) => {
  return createSelector(
    selectProfilesDomain,
    (state): IStoredFileSimpleDtoImt => state.getIn([id, 'profile', 'image']),
  );
};
export const selectUpdateImageLoading = (id: string) => {
  return createSelector(selectProfilesDomain, state =>
    state.getIn([id, 'profile', 'imageLoading']),
  );
};

// sales info
export const selectPersonSalesInfo = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'salesInfo']);

export const selectPersonSalesInfoLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'salesInfoLoading']);

export const selectPersonSalesInfoUpdateLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'salesInfoActionStatus', 'isLoading']);

export const selectPersonSalesInfoUpdateResult = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'salesInfoActionStatus', 'result']);

// Documents

export const selectPersonDocuments = (personId: string) => (state): IPersonDocumentsImt | null =>
  state.getIn([...personsListPath, personId, 'documents']);

export const selectPersonDocumentsLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'isDocumentsLoading']);

export const selectPersonDocumentHTMLLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'isDocumentHTMLLoading']);

export const selectPersonDocumentActionResult = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'documentActionStatus', 'result']);

export const selectPersonDocumentActionLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'documentActionStatus', 'isLoading']);

// Alerts

export const selectPersonAlertsLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'alertsLoading']);

export const selectPersonAlerts = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'alerts']);

export const selectIsUpdateRewardsLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'isUpdateRewardsLoading']);

// Referrals

export const selectReferralData = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'referrals', 'referrals']);
export const selectReferredBy = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'referrals', 'referredBy']);

export const selectPersonBalanceHistory = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'balanceHistory']);
export const selectPersonBalanceHistoryLoading = (personId: string) => state =>
  state.getIn([...personsListPath, personId, 'balanceHistoryLoading']);
