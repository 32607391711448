import React, { FC } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { Box, createStyles, makeStyles, TextField, Theme, Typography } from '@material-ui/core';

import {
  Button,
  ButtonWithCircularProgress,
  DialogComponent,
  PasswordField,
  Alert,
} from 'common/components/index';
import { AlertTypes } from 'common/interfaces/alerts';

import { unlockKioskModeActionError } from 'common/state/kioskMode/actions';
import { loginToConfirmValidationSchema } from './loginToConfirmValidationSchema';
import modalTitles from 'common/messages/modalTitles';
import inputLabels from 'common/messages/inputLabels';
import messages from 'modules/authentication/messages';
import commonMessages from 'common/messages/messages';
import errorMessages from 'common/errors/messages';
import { IUnlockCredentials } from 'modules/authentication/interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiDialog-paper': {
        padding: theme.spacing(3),
      },
    },
    signInBtn: {
      marginBottom: theme.spacing(1),
    },
  }),
);

interface IUnlockKioskModeModal {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (data: IUnlockCredentials) => void;
  hasError?: boolean;
}

const initialValues = {
  email: '',
  password: '',
};

const UnlockKioskModeModal: FC<IUnlockKioskModeModal> = ({
  isOpen,
  isLoading,
  onClose,
  onSubmit,
  hasError = false,
}) => {
  const dispatch = useDispatch();

  const formMethods = useForm<any>({
    defaultValues: initialValues,
    mode: 'onSubmit',
    resolver: yupResolver(loginToConfirmValidationSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = formMethods;

  const classes = useStyles();
  const renderIntlMessage = useRenderIntlMessage();
  const resetServerError = () => hasError && dispatch(unlockKioskModeActionError(null));

  const renderHeader = (): JSX.Element => (
    <Box display="flex" justifyContent="center" mb={3.5}>
      <Typography variant="h3">
        <FormattedMessage {...modalTitles.signInToConfirmTitle} />
      </Typography>
    </Box>
  );

  return (
    <DialogComponent
      isOpen={isOpen}
      loading={isLoading}
      onClose={onClose}
      header={renderHeader()}
      hasCustomFooter
      onSubmit={handleSubmit(onSubmit)}
      size="xs"
      disableBodyPadding
      supressBottomShadow
      hasShadowsOnScroll={false}
      formId="unlock-kiosk-mode-form"
      className={classes.root}
    >
      <FormProvider {...formMethods}>
        <form id="unlock-kiosk-mode-form" onSubmit={handleSubmit(onSubmit)}>
          <Box overflow="hidden">
            <Box mb={1}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    value={field.value}
                    onChange={v => {
                      resetServerError();
                      field.onChange(v);
                    }}
                    onBlur={field.onBlur}
                    label={<FormattedMessage {...inputLabels.username} />}
                    fullWidth
                    variant="outlined"
                    error={!hasError ? !!errors.email : false}
                    helperText={!hasError ? renderIntlMessage(errors?.email?.message) : ''}
                  />
                )}
              />
            </Box>

            <Box>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <PasswordField
                    fullWidth
                    variant="outlined"
                    value={field.value}
                    onChange={v => {
                      resetServerError();
                      field.onChange(v);
                    }}
                    onBlur={field.onBlur}
                    label={<FormattedMessage {...inputLabels.password} />}
                    error={!hasError ? !!errors.password : false}
                    helperText={!hasError ? renderIntlMessage(errors?.password?.message) : ''}
                  />
                )}
              />
            </Box>
            <Box mt={1}>
              {hasError && !isLoading && (
                <Alert
                  title={<FormattedMessage {...errorMessages.wrongUsernameOrPasswordError} />}
                  severity={AlertTypes.Danger}
                  resolveLabel={<FormattedMessage {...commonMessages.configureBtn} />}
                />
              )}
            </Box>
          </Box>

          <Box mt={2.25}>
            <ButtonWithCircularProgress
              type="submit"
              variant="contained"
              fullWidth
              isSubmitting={false}
              className={classes.signInBtn}
            >
              <FormattedMessage {...messages.signIn} />
            </ButtonWithCircularProgress>

            <Button color="primary" fullWidth onClick={onClose}>
              <FormattedMessage {...commonMessages.cancelBtn} />
            </Button>
          </Box>
        </form>
      </FormProvider>
    </DialogComponent>
  );
};

export default UnlockKioskModeModal;
