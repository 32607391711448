import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Grid, TextField, Typography, MenuItem } from '@material-ui/core';

import { resetLeadProfileActionResult } from 'modules/crm/state/leads/actions';

import { selectCurrentUserAvailableClubs } from 'modules/authentication/state/selectors';
import { selectLeadActionResult, selectLeadActivities } from 'modules/crm/state/leads/selectors';

import { INamedEntityImt } from 'common/interfaces/common';
import {
  IDictionaryCommonItem,
  IDictionaryCommonItemImt,
} from 'modules/dictionaries/interfaces/interfaces';

import { CustomAutocomplete, Select } from 'common/components';

import messages from 'modules/crm/messages/leads';
import inputLabels from 'common/messages/inputLabels';
import { getString } from 'common/utils/typeUtils';

interface IProps {
  onActivityCreate: (activity: IDictionaryCommonItem) => void;
}

const InterestsSection = ({ onActivityCreate }: IProps): JSX.Element => {
  const dispatch = useDispatch();

  const clubs: ImmutableList<INamedEntityImt> = useSelector(selectCurrentUserAvailableClubs);
  const activities: ImmutableList<IDictionaryCommonItemImt> = useSelector(selectLeadActivities);
  const createActivityResult: IDictionaryCommonItemImt = useSelector(selectLeadActionResult);

  const { control, formState, watch, setValue } = useFormContext();
  const { errors } = formState;
  const activitiesIds = watch('activitiesIds');

  useEffect(() => {
    if (createActivityResult?.get('id')) {
      const newActivities = activitiesIds.filter(
        activityItem => activityItem.title !== createActivityResult.get('title'),
      );

      setValue('activitiesIds', [...newActivities, createActivityResult.toJS()]);

      dispatch(resetLeadProfileActionResult());
    }
  }, [activitiesIds, createActivityResult, dispatch, setValue]);

  const getCreatedActivity = (activitiesOptions: IDictionaryCommonItem[]) =>
    activitiesOptions.find(
      activity =>
        !activities.find(activityItem => activityItem.get('title') === activity.title) &&
        !activitiesIds.find(activeItem => activeItem.title === activity.title),
    );

  const filteredActivities = activities?.filter(
    activity => !activitiesIds.find(activeActivity => activeActivity.id === activity.get('id')),
  );

  const handleChangeActivities = (e: React.ChangeEvent, options: IDictionaryCommonItem[]) => {
    const newActivity = getCreatedActivity(options);

    if (newActivity) {
      onActivityCreate(newActivity);
    }

    setValue('activitiesIds', options);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="button" color="textSecondary">
          <FormattedMessage {...messages.newLeadPrimaryInfoStepInterests} />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="potentialClubId"
              render={({ field: { value, onChange, onBlur } }) => (
                <Select
                  fullWidth
                  label={<FormattedMessage {...inputLabels.club} />}
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                >
                  {clubs?.map(club => (
                    <MenuItem key={club.get('id')} value={club.get('id')}>
                      {club.get('title')}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="activitiesIds"
              render={({ field }) => (
                <CustomAutocomplete
                  multiple
                  options={filteredActivities.toJS() || []}
                  value={field.value}
                  optionLabelProp="title"
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={<FormattedMessage {...inputLabels.activities} />}
                      variant="outlined"
                    />
                  )}
                  onChange={handleChangeActivities}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="limitations"
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  label={<FormattedMessage {...inputLabels.limitations} />}
                  variant="outlined"
                  multiline
                  rows={7}
                  rowsMax={7}
                  fullWidth
                  error={!!errors.limitations && !!errors.limitations.message}
                  helperText={getString(errors.limitations?.message)}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(InterestsSection);
