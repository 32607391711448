import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Grid,
  Table as MuiTable,
  TableContainer,
  TablePagination,
} from '@material-ui/core';
import cx from 'classnames';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import {
  IFooterOptions,
  IHeadCell,
  IMultipleSelectAction,
  ITableParams,
  ITableRow,
  Order,
} from 'common/interfaces/table';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';
import { ChangedFilterName, IFilter, IFilterSettings } from 'common/interfaces/filter';
import { IMultipleSelectTableControlProps } from 'common/hooks/useMultipleSelectTableControl';
import { DEFAULT_TABLE_PARAMS, perPageOptions, TableOrderByParams } from 'common/constants/table';

import useScreenSizes from 'common/hooks/useScreenSizes';
import useMultipleSelect from 'common/hooks/useMultipleSelect';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';

import {
  AllowedTo,
  FiltersContainer,
  LoadingBackdrop,
  ScrollBox,
  TableProgress,
  ToolbarSearchInput,
} from 'common/components';
import TableHeader from './TableHeader/TableHeader';
import TableBody from './TableBody/TableBody';
import TableFooter from './TableFooter/TableFooter';
import TablePaginationActions from './TablePaginationActions/TablePaginationActions';
import TablePageTitle from './TablePageTitle/TablePageTitle';
// messages
import commonMessages from 'common/messages/messages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import inputLabels from 'common/messages/inputLabels';
import { IObject } from 'common/interfaces/common';

/*
 * For the tableHeader and scrollable classes, leave min padding (don't use theme.spacing)
 * to display the shadow of the table body
 * */
const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: '100%',
    position: 'static',
    boxShadow: theme.shadows[3],
  },
  customBody: {
    position: 'relative',
    height: '100%',
  },
  table: {
    minWidth: 250,
    background: 'white',
  },
  toolbarContainer: {
    [theme.breakpoints.up('laptop')]: {
      flexWrap: 'nowrap',
    },
  },
  pagination: {
    overflow: 'initial',
    '& .MuiToolbar-root': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      overflow: 'inherit',
    },
    '& .MuiTablePagination-selectRoot': {
      borderRadius: 3,
    },
    '& .MuiTablePagination-select': {
      borderRadius: 3,
      boxShadow: theme.shadows[3],
    },
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    paddingBottom: theme.spacing(2),
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  tableHeader: {
    padding: '1px',
  },
  mobilePadding: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
    },
  },
  scrollable: {
    flex: '1 !important',
    minHeight: 0,
    padding: '3px',
  },
  filtersGridItem: {
    flex: 'auto',
    flexShrink: 0,
    overflow: 'hidden',
  },
  paginationGridItem: {
    order: 2,
    flexShrink: 0,
    marginLeft: 'auto',
  },
  noBodyPadding: {
    paddingBottom: theme.spacing(0),
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface IProps {
  defaultToolbar?: boolean;
  title?: string | JSX.Element;
  activeSelect?: boolean;
  showHeaderSelect?: boolean;
  headerOptions?: IHeadCell[];
  footerOptions?: IFooterOptions;
  rows: ITableRow[];
  isLoading?: boolean;
  totalRows?: number;
  page?: number;
  defaultOrderBy?: TableOrderByParams;
  defaultOrder?: Order;
  multipleSelectActions?: IMultipleSelectAction[];
  filters?: IFilterSettings[];
  size?: 'small' | 'medium';
  filterRowRightComponent?: React.ReactNode;
  addButton?: React.ReactNode;
  addButtonRedirect?: string;
  backRedirectLink?: string;
  disabledSelectionsTotal?: number;
  hidePagination?: boolean;
  hideToolbar?: boolean;
  hideSearchInput?: boolean;
  suppressSmartBehavior?: boolean;
  suppressFiltersMobilePadding?: boolean;
  children?: React.ReactNode;
  successAction?: IObject;
  renderHeader?: () => React.ReactNode;
  renderBody?: () => React.ReactNode;
  renderMiniBody?: () => React.ReactNode;
  renderHeaderRight?: () => React.ReactNode;
  onChangeParams?: (params: ITableParams, changedFilterName?: ChangedFilterName) => void;
  initialSelected?: string[];
  onSelect?: (selectedKeys: IObject) => void;
  hidePadding?: boolean;
  refMultipleSelectParams?: MutableRefObject<IMultipleSelectTableControlProps>;
  tableParams?: ITableParams;
  noDataMsg?: string | JSX.Element;
  placeholderToSearch?: MessageDescriptor;
  showPerPageSelect?: boolean;
  scrollBoxClassName?: string;
  hideSelectedNumber?: boolean;
  isRadioButtonMode?: boolean;
  inputType?: string;
  className?: string;
  showAddButton?: boolean;
}

interface IToolbarFiltersState {
  filters: IFilter[];
  changedFilterName?: ChangedFilterName;
}

const Table = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const {
    title,
    backRedirectLink,
    filterRowRightComponent,
    addButton,
    addButtonRedirect,
    activeSelect,
    showHeaderSelect = true,
    headerOptions,
    footerOptions,
    page,
    defaultOrderBy,
    defaultOrder,
    filters,
    multipleSelectActions,
    totalRows,
    size,
    hidePagination,
    hideToolbar,
    hideSearchInput,
    suppressSmartBehavior,
    suppressFiltersMobilePadding,
    scrollBoxClassName,
    successAction,
    onChangeParams,
    renderBody,
    renderHeaderRight,
    initialSelected,
    onSelect,
    hidePadding,
    refMultipleSelectParams,
    tableParams,
    noDataMsg,
    showPerPageSelect,
    rows = [],
    disabledSelectionsTotal = 0,
    isRadioButtonMode = false,
    hideSelectedNumber = false,
    isLoading = false,
    placeholderToSearch = inputLabels.search,
    inputType = 'text',
    className = '',
    showAddButton = true,
  } = props;

  const tableFilters = tableParams?.filters;

  const [searchStr, setSearchStr] = useState<string>(tableParams ? tableParams.searchStr : '');
  const [order, setOrder] = useState<Order>(
    tableParams ? tableParams.order : defaultOrder || 'asc',
  );
  const [orderBy, setOrderBy] = useState<keyof any>(
    tableParams ? tableParams.orderBy : defaultOrderBy || TableOrderByParams.NAME,
  );
  const [internalPage, setInternalPage] = useState<number>(
    tableParams ? tableParams.page : DEFAULT_TABLE_PARAMS.page,
  );
  const [perPage, setPerPage] = useState<number>(
    tableParams ? tableParams.perPage : DEFAULT_TABLE_PARAMS.perPage,
  );
  const [toolbarFilters, setToolbarFilters] = useState<IToolbarFiltersState>({
    filters: tableFilters ?? [],
    changedFilterName: null,
  });

  const { isSmallScreen, isMiddleScreen } = useScreenSizes();

  const renderIntlMessage = useRenderIntlMessage();

  const {
    selected,
    setSelected,
    setNumSelected,
    isExcluded,
    numSelected,
    isAllSelected,
    handleSelectClick,
    setAllSelected,
    resetSelected,
    isSelected,
    excluded,
  } = useMultipleSelect({
    isRadioButtonMode,
    initialSelected,
    onSelect,
    perPage,
    page: internalPage,
    sortBy: String(orderBy),
    sortDirection: order.toUpperCase(),
    search: searchStr,
    filters: toolbarFilters.filters,
    disabledSelectionsTotal,
  });

  useEffect(() => {
    if (refMultipleSelectParams) {
      refMultipleSelectParams.current = {
        ...refMultipleSelectParams.current,
        selected,
        setSelected,
        isExcluded,
        numSelected,
        isAllSelected,
        handleSelectClick,
        setAllSelected,
        resetSelected,
        isSelected,
        excluded,
        setNumSelected,
        tableParams: {
          searchStr,
          perPage,
          page,
          sortDirection: order.toUpperCase(),
          sortBy: String(orderBy),
          tableFilters: toolbarFilters.filters,
        },
      };

      const { onChangeTableParamsSubscriber, ...params } = refMultipleSelectParams.current;

      if (onChangeTableParamsSubscriber) {
        onChangeTableParamsSubscriber(params);
      }
    }
  }, [
    excluded,
    refMultipleSelectParams,
    handleSelectClick,
    isAllSelected,
    isExcluded,
    isSelected,
    numSelected,
    resetSelected,
    searchStr,
    selected,
    setSelected,
    setNumSelected,
    setAllSelected,
    toolbarFilters,
    perPage,
    page,
    order,
    orderBy,
  ]);

  useEffect(() => {
    if (successAction && successAction.success) {
      resetSelected();
      setAllSelected(0);
    }
  }, [successAction, resetSelected, setAllSelected]);

  useEffect(() => {
    if (tableParams && tableParams.page >= 0) {
      setInternalPage(tableParams.page);
      setOrder(tableParams.order);
      setOrderBy(tableParams.orderBy);
      setSearchStr(tableParams.searchStr);
    } else if (page >= 0) {
      setInternalPage(page);
    }
  }, [page, tableParams]);

  useEffect(() => {
    if (tableFilters) {
      setToolbarFilters(prevState => ({ ...prevState, filters: tableFilters }));
    }
  }, [setToolbarFilters, tableFilters]);

  useEffect(() => {
    if (totalRows === 0) {
      setInternalPage(0);
    }
  }, [totalRows]);

  const handleTableParamsChange = useCallback(() => {
    if (onChangeParams) {
      onChangeParams(
        {
          page: internalPage,
          perPage,
          order,
          filters: toolbarFilters.filters,
          searchStr,
          orderBy,
        },
        toolbarFilters.changedFilterName,
      );
    }
  }, [
    searchStr,
    order,
    orderBy,
    internalPage,
    perPage,
    toolbarFilters.filters,
    toolbarFilters.changedFilterName,
    onChangeParams,
  ]);

  const handleSearchChange = useCallback(
    newValue => {
      if (isAllSelected || selected.length || excluded.length) {
        setAllSelected(0);
        resetSelected();
      }

      setSearchStr((newValue || '').trim());
      setInternalPage(0);
    },
    [isAllSelected, selected.length, excluded.length, setAllSelected, resetSelected],
  );

  const handleFiltersChange = useCallback(
    (newToolbarFilters: IFilter[], changedFilterName?: ChangedFilterName) => {
      setAllSelected(0);
      resetSelected();
      setToolbarFilters({ filters: newToolbarFilters, changedFilterName });
      setInternalPage(0);
    },
    [setToolbarFilters, setInternalPage, resetSelected, setAllSelected],
  );

  const handleSortClick = useCallback(
    (newOrder, newOrderBy) => {
      setOrder(newOrder);
      setOrderBy(newOrderBy);
    },
    [setOrder, setOrderBy],
  );

  const handleChangePage = useCallback(
    (e, newPage) => {
      setInternalPage(newPage);
    },
    [setInternalPage],
  );

  const handleChangePerPage = useCallback(e => {
    setPerPage(e.target.value);
    setInternalPage(DEFAULT_TABLE_PARAMS.page);
  }, []);

  useComponentDidUpdate(handleTableParamsChange, [handleTableParamsChange]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && !numSelected) {
      setAllSelected(totalRows);
      resetSelected();
    } else {
      setAllSelected(0);
      resetSelected();
    }
  };

  const renderAddButton = () => {
    if (addButton) {
      return (
        <Grid item className={classes.buttonContainer}>
          {addButton}
        </Grid>
      );
    }
    return (
      addButtonRedirect &&
      showAddButton && (
        <Grid item>
          <Link to={addButtonRedirect}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon width={16} height={16} />}
            >
              <FormattedMessage {...commonMessages.newBtn} />
            </Button>
          </Link>
        </Grid>
      )
    );
  };

  const renderTableToolbar = () => {
    return (
      <Box className={classes.item}>
        <Grid
          container
          spacing={isMiddleScreen ? 2 : 3}
          justifyContent="space-between"
          className={classes.toolbarContainer}
        >
          {(!isMiddleScreen || !title) && renderAddButton()}
          {!hideSearchInput && (
            <Grid item xs="auto" style={{ flexShrink: 5 }}>
              <ToolbarSearchInput
                placeholder={renderIntlMessage(placeholderToSearch)}
                onChange={handleSearchChange}
                value={searchStr}
                inputType={inputType}
              />
            </Grid>
          )}

          {/* eslint-disable-next-line react/prop-types */}
          {filters.length ? (
            <Grid item xs={12} sm="auto" className={classes.filtersGridItem}>
              <FiltersContainer
                hidePagination={hidePagination}
                settings={filters}
                onFiltersChange={handleFiltersChange}
                toolbarFilters={toolbarFilters.filters}
              />
            </Grid>
          ) : null}

          {!hidePagination && (
            <Grid item xs={12} sm="auto" className={classes.paginationGridItem}>
              {/* // TODO MATERIAL change props after MUI V5 upgrade */}
              {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore */}
              <TablePagination
                backIconButtonProps={{ disabled: isLoading }}
                nextIconButtonProps={{ disabled: isLoading }}
                SelectProps={{ disabled: isLoading }}
                className={classes.pagination}
                page={internalPage}
                rowsPerPage={perPage}
                rowsPerPageOptions={
                  showPerPageSelect ? perPageOptions : [DEFAULT_TABLE_PARAMS.perPage]
                }
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangePerPage}
                labelRowsPerPage={null}
                component="div"
                count={totalRows || 0}
                ActionsComponent={TablePaginationActions}
              />
            </Grid>
          )}
          {!!filterRowRightComponent && (
            <Grid item xs={12} sm="auto" className={classes.paginationGridItem}>
              {filterRowRightComponent}
            </Grid>
          )}
        </Grid>
      </Box>
    );
  };

  return (
    <Box className={cx(classes.wrapper, { [className]: !!className })}>
      <Box
        className={cx(classes.tableHeader, {
          [classes.mobilePadding]: !suppressFiltersMobilePadding,
        })}
      >
        <AllowedTo perform={!!title}>
          <Box className={classes.item}>
            <TablePageTitle
              title={title}
              backRedirectLink={backRedirectLink}
              showAddButton={isMiddleScreen}
              customAddButton={renderAddButton()}
              renderHeaderRight={renderHeaderRight}
            />
          </Box>
        </AllowedTo>

        {/* Render toolbar based on filters settings */}
        <AllowedTo perform={!hideToolbar || !hideSearchInput || !hidePagination}>
          {renderTableToolbar()}
        </AllowedTo>
      </Box>

      <Box className={classes.scrollable}>
        <Box position="relative" component="span">
          <TableContainer className={`${classes.container} scrollbar-border-radius`}>
            <ScrollBox className={scrollBoxClassName}>
              {renderBody ? (
                renderBody()
              ) : (
                <>
                  <MuiTable className={classes.table} size={size}>
                    {headerOptions && !!headerOptions.length && (
                      <TableHeader
                        isEmptyRows={!rows.length}
                        isRadioButtonMode={isRadioButtonMode}
                        columns={headerOptions}
                        smartView={isSmallScreen && !suppressSmartBehavior}
                        activeSelect={activeSelect}
                        showHeaderSelect={showHeaderSelect}
                        selectedRows={selected}
                        isAllSelected={isAllSelected}
                        multipleSelectActions={multipleSelectActions}
                        numSelected={numSelected}
                        totalRows={totalRows}
                        disabledSelectionsTotal={disabledSelectionsTotal}
                        onSelectAllClick={handleSelectAllClick}
                        onSortClick={handleSortClick}
                        hidePadding={hidePadding}
                        resetSelected={resetSelected}
                        excludedIds={excluded}
                        hideSelectedNumber={hideSelectedNumber}
                      />
                    )}

                    <TableBody
                      rows={rows}
                      smartView={isSmallScreen && !suppressSmartBehavior}
                      activeSelect={activeSelect}
                      isAllSelected={isAllSelected}
                      onSelectClick={handleSelectClick}
                      isSelected={isSelected}
                      isExcluded={isExcluded}
                      hidePadding={hidePadding}
                    />
                    {footerOptions && (
                      <TableFooter hidePadding={hidePadding} footerOptions={footerOptions} />
                    )}
                  </MuiTable>
                  <TableProgress isLoading={isLoading} rows={rows} notFoundMessage={noDataMsg} />
                </>
              )}
            </ScrollBox>
            <LoadingBackdrop isLoading={isLoading} />
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

Table.defaultProps = {
  activeSelect: false,
  defaultToolbar: false,
  headerOptions: [],
  filters: [],
  size: 'medium',
};

export default Table;
