import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import { List as ImmutableList } from 'immutable';
import { Box, FormHelperText, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { fetchDictionaryItem, resetDictionaryItemAction } from 'common/state/dictionary/actions';
import {
  selectDictionaryItem,
  selectDictionaryItemLoading,
} from 'common/state/dictionary/selectors';

import { NewEmailValidationSchema } from './NewEmailValidationSchema';
import { DictionaryItem } from 'common/constants/dictionaryConstants';

import { INamedEntityImt, IObject } from 'common/interfaces/common';
import { ICrmCampaignDtoImt } from 'common/interfaces/dictionary';
import { IEmailImt, ISendEmailRequestPayload } from 'common/components/PersonProfile/interfaces';

import { DialogComponent, LoadingBackdrop } from 'common/components';
import { EmailEditor } from 'modules/crm/components';
import NewEmailForm from './NewEmailForm';
// state
import { selectPersonEmail, selectPersonEmailLoading } from '../../../state/selectors';
import { fetchPersonEmail, resetPersonEmail } from '../../../state/actions';
// hooks
import { useAppDispatch } from 'store/hooks';
// messages
import commonMessages from 'common/messages/messages';
import messages from 'common/components/PersonProfile/messages';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { EmailStatus } from 'common/components/PersonProfile/constants';
import Markdown from 'react-markdown';

// TODO find the way to work with hookForm in portal  *migrate to hook form
// const Portal = ({ children, node }) => {
//   if (node) {
//     const portalContainer = document.createElement('div');
//     node.insertBefore(portalContainer, node.childNodes[0]);

//     return ReactDOM.createPortal(children, portalContainer);
//   }
//   return null;
// };

const useStyles = makeStyles((theme: Theme) => ({
  editor: {
    '& .gjs-editor': {
      '& .gjs-pn-panels': {
        marginRight: theme.spacing(3),
      },
      '& .gjs-cv-canvas': {
        flex: 2,
        height: 'auto',
      },
      '& .gjs-block': {
        flexBasis: '25%',
        maxHeight: '80px',
        minHeight: 'auto',
      },
      '& .gjs-comp-selected': {
        outline: '2px solid red !important',
      },
    },
  },
}));

interface IProps {
  emailId?: string;
  campaigns?: ImmutableList<INamedEntityImt>;
  personEmail?: string;
  isOpen: boolean;
  isDetailsModalOpen: boolean;
  personId: string;
  onClose: () => void;
  onSubmit: (email: ISendEmailRequestPayload) => void;
  module: PeakModuleForNewPersonType;
}

const initValues = {
  campaign: '',
  emailSenderName: '',
  subject: '',
};

const NewEmailModal = ({
  emailId,
  personEmail,
  isOpen,
  isDetailsModalOpen,
  campaigns,
  onClose,
  onSubmit,
  personId,
  module,
}: IProps): JSX.Element => {
  const classes = useStyles();

  // local state

  const [grapesEditor, setGrapesEditor] = useState<IObject | null>(null);
  const [editorError, setEditorError] = useState<string>('');
  // TODO find the way to work with hookForm in portal  *migrate to hook form
  // const [formContainer, setFormContainer] = useState(null);

  // global state

  const dispatch = useAppDispatch();
  const campaign: ICrmCampaignDtoImt = useSelector(
    selectDictionaryItem(DictionaryItem.CAMPAIGN_EMAIL),
  );
  const isCampaignLoading: boolean = useSelector(
    selectDictionaryItemLoading(DictionaryItem.CAMPAIGN_EMAIL),
  );

  const emailData: IEmailImt = useSelector(selectPersonEmail(personId));
  const isLoadingEmailData: boolean = useSelector(selectPersonEmailLoading(personId));

  // form

  const formMethods = useForm<any>({
    defaultValues: initValues,
    resolver: yupResolver(NewEmailValidationSchema),
    mode: 'onBlur',
  });

  const { handleSubmit, reset, getValues, watch } = formMethods;

  const campaignId = watch('campaign');

  // effects

  useEffect(() => {
    // load replying email details
    if (emailId) {
      dispatch(fetchPersonEmail(personId, emailId, module));
    }

    return () => {
      if (!isDetailsModalOpen) {
        dispatch(resetPersonEmail(null, personId));
      }
    };
  }, [dispatch, personId, emailId, module, isDetailsModalOpen]);

  useEffect(() => {
    // set replying data into the form
    if (emailData?.size) {
      reset({
        ...initValues,
        campaign: emailData.getIn(['campaign', 'id']) || '',
        emailSenderName: emailData.getIn(['documentTemplate', 'emailSenderName']),
        subject: `RE: ${emailData.get('subject')}`,
      });
    }
  }, [emailData, reset]);

  // add replying content to the component
  useEffect(() => {
    if (emailData?.size && grapesEditor) {
      grapesEditor.addComponents([
        {
          type: 'table',
          attributes: {
            height: '150px',
            width: '100%',
          },
        },
        {
          type: 'reply-content',
          attributes: {
            content:
              emailData.get('status') === EmailStatus.Received
                ? ReactDOMServer.renderToStaticMarkup(
                    <Markdown>{emailData.get('emailBody')}</Markdown>,
                  )
                : emailData.get('emailBody'),
          },
        },
      ]);
    }
  }, [emailData, grapesEditor, classes]);

  useEffect(() => {
    return () => {
      dispatch(resetDictionaryItemAction({ dictionary: DictionaryItem.CAMPAIGN_EMAIL }));

      if (personEmail) {
        dispatch(resetPersonEmail(null, personEmail));
      }
    };
  }, [dispatch, isOpen, personEmail]);

  useEffect(() => {
    if (campaign?.size && grapesEditor) {
      const documentTemplate = campaign.get('documentTemplate');

      grapesEditor.setComponents(documentTemplate.get('content'));

      reset({
        ...getValues(),
        subject: documentTemplate.get('emailSubject'),
        emailSenderName: documentTemplate.get('emailSenderName'),
      });
    }
  }, [campaign, campaign.size, getValues, grapesEditor, reset]);

  useEffect(() => {
    if (!campaignId) {
      grapesEditor?.setComponents('<body></body>');
    }
  }, [campaignId, grapesEditor]);

  // handlers

  const onEditorInit = editorInstance => setGrapesEditor(editorInstance);

  const onEditorUpdate = () => setEditorError('');

  const formSubmit = values => {
    const currentCampaign =
      campaigns?.find(campaignItem => campaignItem.get('id') === values.campaign)?.toJS() || null;

    if (grapesEditor) {
      onSubmit({
        ...values,
        campaign: currentCampaign || null,
        emailBody: `<div><style>${grapesEditor.getCss()} pre {white-space: pre-wrap}</style>${grapesEditor.getHtml()}</div>`,
      });
    }
  };

  const fetchCampaignEmail = (id: string) => {
    dispatch(fetchDictionaryItem(DictionaryItem.CAMPAIGN_EMAIL, { personId, campaignId: id }));
  };

  const getEmailContent = () => {
    return grapesEditor ? grapesEditor.getHtml() : '';
  };

  // render

  return (
    <DialogComponent
      title={<FormattedMessage {...(emailId ? messages.replyTitle : messages.newMessageTitle)} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.sendBtn} />}
      size="lg"
      loading={isCampaignLoading || isLoadingEmailData}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(formSubmit)}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {/* TODO find the way to work with hookForm in portal  *migrate to hook form */}
          {/* <Portal node={formContainer}> */}
          <form id="email-form">
            <FormProvider {...formMethods}>
              <NewEmailForm
                getEmailContent={getEmailContent}
                fetchCampaignEmail={fetchCampaignEmail}
                personEmail={personEmail || ''}
                campaigns={campaigns}
              />
            </FormProvider>
          </form>
          {/* </Portal> */}
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.editor}>
            <EmailEditor
              id="gjs"
              width="100%"
              onInit={onEditorInit}
              onEditorUpdate={onEditorUpdate}
            />
            <FormHelperText color="error">{editorError}</FormHelperText>
          </Box>
        </Grid>
      </Grid>

      <LoadingBackdrop isLoading={isCampaignLoading} />
    </DialogComponent>
  );
};

export default NewEmailModal;
