import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Menu, MenuItem } from '@material-ui/core';
import classnames from 'classnames';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: 'min-content',
  },
  groupContainer: {
    display: 'flex',
    gap: 2,
    padding: 2,
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: theme.shadows[3],
  },
  btn: {
    boxShadow: 'unset',
    padding: '6px 12px',
    borderRadius: 4,
    whiteSpace: (props: IButtonGroupProps) => (props.noWrap ? 'nowrap' : 'normal'),
    textAlign: 'center',
    color: theme.palette.text.primary,
    '&:hover': {
      boxShadow: 'unset',
      backgroundColor: '#f7f8f8',
    },
    '&:disabled': {
      opacity: '0.5',
      backgroundColor: 'inherit',
    },
  },
  btnMobileWrapper: {
    [theme.breakpoints.down('xs')]: {
      marginRight: 'auto',
    },
  },
  btnActive: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white',
    cursor: 'default',
  },
  navBtn: {
    minWidth: '32px',
    width: '32px',
    height: '32px',
  },
}));

interface IButtonSettings {
  title: string;
  label: string | JSX.Element;
  isActive: boolean;
  onClick: () => void;
  disabled?: boolean;
}

interface IButtonGroupProps {
  title: React.ReactNode;
  settings: IButtonSettings[];
  isSmallScreen?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  noWrap?: boolean;
  className?: string;
}

const ButtonGroup: React.FunctionComponent<IButtonGroupProps> = (
  props: IButtonGroupProps,
): JSX.Element => {
  const classes = useStyles(props);

  const { title, settings, isSmallScreen, className } = props;

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const handleClick = (actionFn: () => void): void => {
    actionFn();
    onCloseMenu();
  };

  const activeButtonSetting = settings.find(setting => setting.isActive);

  return !isSmallScreen ? (
    <Grid item container wrap="nowrap" className={classes.wrapper}>
      <div className={classes.groupContainer}>
        {settings.map(setting => (
          <Button
            key={setting.title}
            className={classnames(
              classes.btn,
              {
                [classes.btnActive]: setting.isActive,
              },
              className,
            )}
            variant="contained"
            onClick={setting.onClick}
            disabled={!!setting.disabled && !setting.isActive}
          >
            {setting.label}
          </Button>
        ))}
      </div>
    </Grid>
  ) : (
    <Grid item className={classes.btnMobileWrapper}>
      <Button
        className={classes.btn}
        variant="contained"
        endIcon={<ArrowDropDownIcon />}
        onClick={onMenuClick}
      >
        {activeButtonSetting ? activeButtonSetting.label : title}
      </Button>
      <Menu id="menu-list-grow" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onCloseMenu}>
        {settings.map(setting => (
          <MenuItem key={setting.title} onClick={() => handleClick(setting.onClick)}>
            {setting.label}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export default React.memo(ButtonGroup);
