import { defineMessages } from 'react-intl';

export default defineMessages({
  itemDictionaryValueBody: {
    id: 'app.modules.pos-settings.Dictionary.itemDictionaryValueBody.body',
    defaultMessage: 'Are you sure you want to {action} {name}?',
  },
  deleteDictionaryValueTitle: {
    id: 'app.modules.pos-settings.Dictionary.deleteDictionary',
    defaultMessage: 'Delete the dictionary value',
  },
  changeStatusDictionaryValueTitle: {
    id: 'app.modules.pos-settings.Dictionary.changeStatusDictionary',
    defaultMessage: 'Change status the dictionary value',
  },
  newDictionaryValueTitle: {
    id: 'app.modules.pos-settings.Dictionary.newDictionary',
    defaultMessage: 'New {dictionaryName}',
  },
  editDictionaryValueTitle: {
    id: 'app.modules.pos-settings.Dictionary.editDictionary.title',
    defaultMessage: 'Edit {dictionaryName}',
  },
  // DictionaryName
  alertSoundUpload: {
    id: 'app.modules.pos-settings.Dictionary.AlertSoundUpload.alertSoundUploadItem.name',
    defaultMessage: 'Alert Sound Upload',
  },
  clubSetup: {
    id: 'app.modules.pos-settings.Dictionary.sidebar.clubSetup',
    defaultMessage: 'Club Setup',
  },
  cameFromIndication: {
    id: 'app.modules.pos-settings.Dictionary.sidebar.cameFromIndication',
    defaultMessage: 'Came From Indication',
  },
  additionalGuestFields: {
    id: 'app.modules.pos-settings.Dictionary.sidebar.additionalGuestFields',
    defaultMessage: 'Additional Guest Fields',
  },
  noteCode: {
    id: 'app.modules.pos-settings.Dictionary.sidebar.noteCode',
    defaultMessage: 'Note Code',
  },
  fallthroughReason: {
    id: 'app.modules.pos-settings.Dictionary.sidebar.fallthroughReason',
    defaultMessage: 'Fallthrough Reason',
  },
  departments: {
    id: 'app.modules.pos-settings.Dictionary.sidebar.departments',
    defaultMessage: 'Departments',
  },
  clubGroups: {
    id: 'app.modules.pos-settings.Dictionary.sidebar.clubGroups',
    defaultMessage: 'Club Groups',
  },
  insuranceCompanies: {
    id: 'app.modules.pos-settings.Dictionary.sidebar.insuranceCompanies',
    defaultMessage: 'Insurance Companies',
  },
  accessHours: {
    id: 'app.modules.pos-settings.Dictionary.sidebar.accessHours',
    defaultMessage: 'Access Hours',
  },
  eventsTags: {
    id: 'app.modules.pos-settings.Dictionary.sidebar.eventTags',
    defaultMessage: 'Events Tags',
  },
  resourcesTags: {
    id: 'app.modules.pos-settings.Dictionary.sidebar.resourcesTags',
    defaultMessage: 'Resources Tags',
  },
  helpInformation: {
    id: 'app.modules.pos-settings.Dictionary.sidebar.helpInformation',
    defaultMessage: 'Help Information',
  },
  endReason: {
    id: 'app.modules.pos-settings.Dictionary.sidebar.endReason',
    defaultMessage: 'End Reason',
  },
  // Modals
  modalNewSound: {
    id: 'app.modules.pos-settings.Dictionary.AlertSoundUpload.alertSoundUploadItem.modals.newSound',
    defaultMessage: `New sound:`,
  },
  cameFromIndicator: {
    id: 'app.modules.pos-settings.Dictionary.modals.cameFromIndicator',
    defaultMessage: 'Came From Indicator',
  },
  clubGroup: {
    id: 'app.modules.pos-settings.Dictionary.modals.clubGroup',
    defaultMessage: 'Club Group',
  },
  insuranceCompany: {
    id: 'app.modules.pos-settings.Dictionary.modals.insuranceCompany',
    defaultMessage: 'Insurance Company',
  },
  additionalGuestFieldsitem: {
    id: 'app.modules.pos-settings.Dictionary.modals.additionalGuestFieldsitem',
    defaultMessage: 'Additional Guest Fields item',
  },
  eventTagsItem: {
    id: 'app.modules.pos-settings.Dictionary.modals.eventTagsItem',
    defaultMessage: 'Event Tags item',
  },
  fallthroughReasonItem: {
    id: 'app.modules.pos-settings.Dictionary.modals.fallthroughReasonItem',
    defaultMessage: 'Fallthrough Reason item',
  },
  endReasonItem: {
    id: 'app.modules.pos-settings.Dictionary.modals.endReasonItem',
    defaultMessage: 'End Reason item',
  },
  noteCodeItem: {
    id: 'app.modules.pos-settings.Dictionary.modals.noteCodeItem',
    defaultMessage: 'Note Code item',
  },
  resourceTagItem: {
    id: 'app.modules.pos-settings.Dictionary.modals.resourceTagItem',
    defaultMessage: 'Resource Tag item',
  },
  departmentsItem: {
    id: 'app.modules.pos-settings.Dictionary.modals.departmentsItem',
    defaultMessage: 'Department item',
  },
  dictionaryCreatedMessage: {
    id: 'app.modules.dictionaries.dictionaryCreatedMessage',
    defaultMessage: 'New dictionary has been created',
  },
  // came from modal
  singleInputOption: {
    id: 'app.modules.dictionaries.singleInputOption',
    defaultMessage: 'Single Input',
  },
  dropdownSelectOption: {
    id: 'app.modules.dictionaries.dropdownSelectOption',
    defaultMessage: 'Dropdown Select',
  },
  noInputOption: {
    id: 'app.modules.dictionaries.noInputOption',
    defaultMessage: 'No Input',
  },
  referralListOption: {
    id: 'app.modules.dictionaries.referralListOption',
    defaultMessage: 'Referral List',
  },
  addOptionBtn: {
    id: 'app.modules.dictionaries.addOptionBtn',
    defaultMessage: 'Option',
  },
  // Content type options
  videoOption: {
    id: 'app.modules.dictionaries.option.videoOption',
    defaultMessage: 'Video',
  },
  documentOption: {
    id: 'app.modules.dictionaries.option.documentOption',
    defaultMessage: 'Document',
  },
  htmlPageOption: {
    id: 'app.modules.dictionaries.option.htmlPageOption',
    defaultMessage: 'HTML Page',
  },
  // labels
  optionFieldsLabel: {
    id: 'app.modules.dictionaries.optionFieldsLabel',
    defaultMessage: 'Option {index}',
  },
  inputPlaceholderLabel: {
    id: 'app.modules.dictionaries.inputPlaceholderLabel',
    defaultMessage: 'Input placeholder',
  },
  optionalFieldLabel: {
    id: 'app.modules.dictionaries.optionalFieldLabel',
    defaultMessage: 'Optional Field',
  },
  dictionaryLabel: {
    id: 'app.modules.dictionaries.dictionaryLabel',
    defaultMessage: 'Dictionary',
  },
  typeUsedByMembersCount: {
    id: 'app.modules.pos-settings.Dictionary.sidebar.typeUsedByMembersCount',
    defaultMessage: 'This type is used by {count} members',
  },
  zipCodeOption: {
    id: 'app.modules.dictionaries.endReason.zipCodeOption',
    defaultMessage: 'Zip code',
  },
  mdLicenseOption: {
    id: 'app.modules.dictionaries.endReason.mdLicense',
    defaultMessage: ' MD license',
  },
  noteCodeOption: {
    id: 'app.modules.dictionaries.endReason.noteCodeOption',
    defaultMessage: 'Note code',
  },
});
