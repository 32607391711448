import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { Box, debounce, TextField } from '@material-ui/core';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { INamedEntity, INamedEntityImt } from 'common/interfaces/common';

import { ListboxComponent, TooltipTypography } from 'common/components/index';

import commonMessages from 'common/messages/messages';

interface IAppointmentsAutocomplete {
  personId: string;
  isLoading: boolean;
  services: List<INamedEntityImt>;
  searchServices: (search: string, personId: string) => void;
  clearSearchResults: () => void;
}

const AppointmentServicesAutocomplete = ({
  personId,
  searchServices,
  services,
  isLoading,
  clearSearchResults,
}: IAppointmentsAutocomplete): JSX.Element => {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const renderIntlMessage = useRenderIntlMessage();

  const renderServiceOption = ({ title }: INamedEntity) => (
    <Box display="flex" alignItems="center" width="100%">
      <TooltipTypography ellipsized>{title}</TooltipTypography>
    </Box>
  );

  const convertedServices = useMemo(() => services?.toJS() || [], [services]);

  const onChangeSearch = useMemo(() => {
    return debounce((value: string) => {
      searchServices(value, personId);
    }, 500);
  }, [searchServices, personId]);

  const ListboxProps = {
    itemSize: 40,
    limitCount: 8,
  };

  return (
    <Controller
      control={control}
      name="service"
      render={({ field: { value, onChange } }) => {
        return (
          <MuiAutocomplete
            open={isOpenDropdown}
            onOpen={() => setIsOpenDropdown(true)}
            onClose={() => setIsOpenDropdown(false)}
            onChange={(e: React.BaseSyntheticEvent, val) => {
              onChange(val);
            }}
            onInputChange={(e: React.BaseSyntheticEvent) => {
              onChangeSearch(e?.target.value || '');
            }}
            value={value}
            getOptionLabel={option => option.title || ''}
            renderOption={renderServiceOption}
            onFocus={(e: React.BaseSyntheticEvent) => {
              onChangeSearch(e?.target.value || '');
            }}
            onBlur={clearSearchResults}
            ListboxComponent={ListboxComponent}
            ListboxProps={ListboxProps}
            options={convertedServices}
            loading={isLoading}
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  label={<FormattedMessage {...commonMessages.serviceLabel} />}
                  variant="outlined"
                  fullWidth
                  error={!!errors.serviceId}
                  helperText={renderIntlMessage(errors.serviceId?.message)}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off',
                  }}
                />
              );
            }}
          />
        );
      }}
    />
  );
};

export default AppointmentServicesAutocomplete;
