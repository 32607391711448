import React, { useState } from 'react';
import { List as ImmutableList } from 'immutable';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Grid, MenuItem, TextField, Typography } from '@material-ui/core';

import { INamedEntityImt } from 'common/interfaces/common';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { Select } from 'common/components';
import { QuestionnaireModal } from 'common/modals';

import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';

interface IProps {
  campaigns?: ImmutableList<INamedEntityImt>;
  personEmail: string;
  getEmailContent: () => string;
  fetchCampaignEmail: (campaignId: string) => void;
}

const NewEmailForm = (props: IProps): JSX.Element => {
  const { campaigns, personEmail, fetchCampaignEmail, getEmailContent } = props;

  const [{ isOpenConfirmationModal, campaignId }, setIsOpenConfirmationModal] = useState({
    isOpenConfirmationModal: false,
    campaignId: '',
  });

  const { control, formState, setValue } = useFormContext();
  const { errors } = formState;
  const renderIntlMessage = useRenderIntlMessage();

  const handleOverrideEnteredData = () => {
    if (campaignId) {
      fetchCampaignEmail(campaignId);
    }

    setValue('campaign', campaignId);
    setIsOpenConfirmationModal({ isOpenConfirmationModal: false, campaignId: '' });
  };

  const onChangeCampaign = (v: string, onChange: (v: string) => void) => {
    const emailContent = getEmailContent();

    const contentWithoutAttributes = emailContent.replace(/<\s*([a-z][a-z0-9]*)\s.*?>/gi, '<$1>');

    const isEmptyEmailContent = contentWithoutAttributes === '<body></body>';

    if (!isEmptyEmailContent) {
      setIsOpenConfirmationModal({ isOpenConfirmationModal: true, campaignId: v });
    } else if (v !== '') {
      fetchCampaignEmail(v);
      onChange(v);
    } else {
      onChange(v);
    }
  };

  const onCloseQuestionnaireModal = () => {
    setIsOpenConfirmationModal({ isOpenConfirmationModal: false, campaignId: '' });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Controller
          name="subject"
          control={control}
          render={({ field }) => (
            <TextField
              label={<FormattedMessage {...inputLabels.subject} />}
              variant="outlined"
              value={field.value}
              onChange={field.onChange}
              error={!!errors.subject}
              helperText={renderIntlMessage(errors.subject?.message)}
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="campaign"
          control={control}
          render={({ field }) => (
            <Select
              label={<FormattedMessage {...inputLabels.campaign} />}
              variant="outlined"
              error={!!errors.campaign}
              helperText={renderIntlMessage(errors.campaign?.message) || undefined}
              fullWidth
              value={field.value}
              onChange={v => onChangeCampaign(v, field.onChange)}
            >
              <MenuItem key="empty-option" value="">
                <Typography variant="body1">
                  <FormattedMessage {...messages.noCampaign} />
                </Typography>
              </MenuItem>
              {Boolean(campaigns?.size) &&
                campaigns?.map(c => (
                  <MenuItem key={c.get('id')} value={c.get('id')}>
                    {c.get('title')}
                  </MenuItem>
                ))}
            </Select>
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="emailSenderName"
          control={control}
          render={({ field }) => (
            <TextField
              label={<FormattedMessage {...inputLabels.fromName} />}
              variant="outlined"
              value={field.value}
              onChange={field.onChange}
              error={!!errors.emailSenderName}
              helperText={renderIntlMessage(errors.emailSenderName?.message)}
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label={<FormattedMessage {...inputLabels.to} />}
          variant="outlined"
          value={personEmail}
          disabled
          fullWidth
        />
      </Grid>

      {isOpenConfirmationModal && (
        <QuestionnaireModal
          isOpen={isOpenConfirmationModal}
          onClose={onCloseQuestionnaireModal}
          onSubmit={handleOverrideEnteredData}
          body={
            <FormattedMessage
              {...messages.confirmOverrideTextMessage}
              values={{ value: 'email' }}
            />
          }
          submitBtnTitle={<FormattedMessage {...commonMessages.confirmBtn} />}
        />
      )}
    </Grid>
  );
};

export default NewEmailForm;
