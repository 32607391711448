import { useState, useEffect, useLayoutEffect, useCallback, MutableRefObject } from 'react';

const useBoxSize = (
  targetRef: MutableRefObject<HTMLDivElement | null>,
): { width: number; height: number } => {
  const getDimensions = useCallback(() => {
    return {
      width: targetRef.current ? targetRef.current.offsetWidth : 0,
      height: targetRef.current ? targetRef.current.offsetHeight : 0,
    };
  }, [targetRef]);
  const [dimensions, setDimensions] = useState(getDimensions);

  const handleResize = useCallback(() => {
    setDimensions(getDimensions());
  }, [getDimensions]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useLayoutEffect(() => {
    handleResize();
  }, [handleResize]);
  return dimensions;
};

export default useBoxSize;
