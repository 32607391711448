import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import { Grid, MenuItem, Divider } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

// components
import { Select, MultipleSelect, DateTimePicker, TextArea } from 'common/components/index';
import ReminderSection from 'modules/booking/components/EventForm/ReminderSection/ReminderSection';
import NotifyVariant from 'modules/booking/components/Modals/NotifyVariant';
// interfaces
import { IEmployeeListItemImt } from 'common/interfaces/dictionary';
import { ITourStepImt } from 'modules/front-desk/interfaces';
import { ITagImt } from 'modules/booking/interfaces';
// common
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
// constants
import { times } from 'common/constants/times';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
// messages
import inputLabels from 'common/messages/inputLabels';
import { selectSenderAvailability } from 'modules/booking/state/senderAvailability/selectors';

const useStyles = makeStyles((theme: Theme) => ({
  durationMenu: {
    maxHeight: '300px',
    margin: theme.spacing(2, 0),
  },
  reminderCheckbox: {
    margin: '6px 10px 0 0',
  },
  reminderInput: {
    '& .MuiSelect-select, .MuiOutlinedInput-input': {
      padding: '8px 32px 8px 12px',
    },
  },
  reminderNumberInput: {
    maxWidth: '60px',
  },
  reminderSelect: {
    minWidth: '100px',
  },
}));

interface IProps {
  event?: ITourStepImt;
  employees: ImmutableList<IEmployeeListItemImt>;
  eventTags?: ImmutableList<ITagImt>;
}

const CallEventSection = ({ employees, eventTags }: IProps): JSX.Element => {
  const senderAvailability = useSelector(selectSenderAvailability(SenderAvailabilityTypeList.TOUR));
  const classes = useStyles();
  const renderErrorMessage = useRenderIntlMessage();
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <MultipleSelect
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  fullWidth
                  multiple
                  label={<FormattedMessage {...inputLabels.eventTagsOptional} />}
                  options={eventTags.toJS()}
                  error={!!errors.tags}
                  helperText={renderErrorMessage(errors.tags?.message)}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="salesperson"
              control={control}
              render={({ field }) => (
                <MultipleSelect
                  fullWidth
                  label={<FormattedMessage {...inputLabels.salesperson} />}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!errors.salesperson}
                  helperText={renderErrorMessage(errors?.salesperson?.message)}
                  options={
                    !!employees && !!employees.size
                      ? employees
                          .map(e => ({
                            id: e.get('id'),
                            title: e.get('title'),
                          }))
                          .toJS()
                      : []
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <DateTimePicker disablePast />
              </Grid>

              <Grid item xs={6} sm={6}>
                <Controller
                  control={control}
                  name="durationInMinutes"
                  render={({ field }) => (
                    <Select
                      label={<FormattedMessage {...inputLabels.duration} />}
                      isDuration
                      variant="outlined"
                      value={field.value}
                      onChange={field.onChange}
                      renderValue={(v: string) => `${+v.split(':')[0]}h ${v.split(':')[1]}m`}
                      MenuProps={{ classes: { paper: classes.durationMenu } }}
                      fullWidth
                      error={!!errors.durationInMinutes}
                      helperText={renderErrorMessage(errors.durationInMinutes?.message)}
                    >
                      {times.map(timeItem => (
                        <MenuItem key={timeItem} value={timeItem}>
                          {timeItem}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <ReminderSection />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <NotifyVariant senderAvailability={senderAvailability} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <TextArea
              value={field.value}
              label={<FormattedMessage {...inputLabels.descriptionOptional} />}
              variant="outlined"
              autoComplete="none"
              fullWidth
              maxSymbols={1000}
              rows={6}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!errors.description}
              helperText={renderErrorMessage(errors?.description?.message)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default CallEventSection;
