import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, TextField } from '@material-ui/core';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { getRequiredMessage } from 'common/constants/globalConstants';

import { LinkIcon } from 'img/editor-icons';
import DialogComponent from '../DialogComponent/DialogComponent';

import inputLabels from 'common/messages/inputLabels';
import inputErrors from 'common/messages/inputErrors';
import modalTitles from 'common/messages/modalTitles';

const initialValues = {
  linkUrl: '',
  linkTitle: '',
  isNewWindow: false,
};

const validationSchema = yup.object().shape({
  linkUrl: yup
    .string()
    .url(() => inputErrors.websiteMatchError)
    .required(getRequiredMessage),
  linkTitle: yup
    .string()
    .max(200, () => inputErrors.linkMaxLengthError)
    .required(getRequiredMessage),
});

interface IProps {
  currentState: any;
  onChange: (row, title, url, target) => void;
}

const LinkTool = ({ onChange }: IProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const renderIntlMessage = useRenderIntlMessage();

  const onClose = () => {
    setIsOpen(false);
  };

  const formMethods = useForm<any>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const formSubmit = ({ linkTitle, linkUrl }) => {
    onChange('link', linkTitle, linkUrl, '_blank');
    onClose();
  };

  return (
    <Box aria-label="rdw-color-picker">
      <Box className="rdw-option-wrapper" onClick={() => setIsOpen(true)}>
        <img src={LinkIcon} alt="linkIcon" />
      </Box>

      <DialogComponent
        isOpen={isOpen}
        onSubmit={handleSubmit(formSubmit)}
        onClose={onClose}
        title={<FormattedMessage {...modalTitles.addLinkTitle} />}
        size="xs"
        formId="link-tool"
      >
        <form id="link-tool" onSubmit={handleSubmit(formSubmit)}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Controller
                name="linkUrl"
                control={control}
                // eslint-disable-next-line
                render={({ field }) => (
                  <TextField
                    fullWidth
                    name={field.name}
                    variant="outlined"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    label={<FormattedMessage {...inputLabels.url} />}
                    error={!!errors.linkUrl}
                    helperText={renderIntlMessage(errors.linkUrl?.message)}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="linkTitle"
                control={control}
                // eslint-disable-next-line
                render={({ field }) => (
                  <TextField
                    fullWidth
                    name={field.name}
                    variant="outlined"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    label={<FormattedMessage {...inputLabels.title} />}
                    error={!!errors.linkTitle}
                    helperText={renderIntlMessage(errors.linkTitle?.message)}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogComponent>
    </Box>
  );
};

export default LinkTool;
