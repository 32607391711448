// libraries
import React, { useCallback, useEffect } from 'react';
import { batch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Box, Menu, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
// state
import {
  resetRightPanelPersonAction,
  resetSelectedEntranceIdAction,
  setSelectedEntranceId,
} from 'modules/front-desk/state/actions';
import { resetSelectedCheckInAction } from 'modules/front-desk/state/checkins/actions';
// services
import AppService from 'services/application/AppService';
// hooks
import useMenuState from 'common/hooks/useMenuState';
import { useAppDispatch } from 'store/hooks';
import useEntranceWorkingHours, { EntranceStatus } from './EntranceItem/useEntranceWorkingHours';
// interfaces
import { IEntranceItemImt } from 'modules/clubs/interfaces';
import { ICheckIn } from '../../interfaces';
// components
import { LoadingBackdrop } from 'common/components';
import EntranceItem from './EntranceItem/EntranceItem';
// messages
import messages from 'modules/front-desk/messages';

interface IProps {
  entrancesList: ImmutableList<IEntranceItemImt>;
  isEntrancesListLoading: boolean;
}

const EntranceSelector = ({ entrancesList, isEntrancesListLoading }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const state: ICheckIn | undefined = location.state;
  const checkInEntranceId = state && state.entranceId ? state.entranceId : null;
  const { menuAnchorEl, handleShowMenu, handleCloseMenu } = useMenuState();
  const selectedEntranceId = AppService.getAppSelectedEntrance();

  const selectedEntrance = entrancesList.find(
    entrance => entrance.get('id') === selectedEntranceId,
  );
  const selectedEntranceTitle =
    selectedEntranceId !== null && entrancesList.size ? (
      selectedEntrance?.get('title')
    ) : (
      <FormattedMessage {...messages.selectEntranceText} />
    );

  const [entranceStatus] = useEntranceWorkingHours(selectedEntrance);

  useEffect(() => {
    if (
      entranceStatus === EntranceStatus.CLOSED ||
      entranceStatus === EntranceStatus.DO_NOT_WORKING_HOURS ||
      entranceStatus === EntranceStatus.INACTIVE
    ) {
      dispatch(resetSelectedEntranceIdAction());

      AppService.clearAppSelectedEntrance();
    }
  }, [dispatch, entranceStatus]);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedCheckInAction());
    };
  }, [dispatch]);

  const onEntranceSelect = useCallback(
    (entranceId: string) => {
      batch(() => {
        dispatch(setSelectedEntranceId(entranceId));
        dispatch(resetRightPanelPersonAction());
        dispatch(resetSelectedCheckInAction());
      });

      handleCloseMenu();
    },
    [dispatch, handleCloseMenu],
  );

  useEffect(() => {
    if (checkInEntranceId) {
      dispatch(setSelectedEntranceId(checkInEntranceId));
    }
  }, [dispatch, checkInEntranceId]);

  return (
    <>
      <Box
        position="relative"
        onClick={handleShowMenu}
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ cursor: 'pointer' }}
      >
        <LoadingBackdrop isLoading={isEntrancesListLoading} />
        <Typography variant="h2" component="h1" color="textPrimary">
          {selectedEntranceTitle}
        </Typography>
        {menuAnchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
      </Box>

      <Menu
        id="entrance-select-menu"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {entrancesList.map(entrance => (
          <EntranceItem
            selectedEntranceId={selectedEntranceId}
            onEntranceSelect={onEntranceSelect}
            entrance={entrance}
            key={entrance.get('id')}
          />
        ))}
      </Menu>
    </>
  );
};

export default React.memo(EntranceSelector);
