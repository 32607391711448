import React, { useMemo, useState } from 'react';
import { Controller, FieldArray, useFieldArray, UseFormReturn } from 'react-hook-form';
import { Box, Button, CardContent, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
// constants
// interfaces
import { IBillingOption, IFormStepStyle } from 'modules/services/interfaces/packages';
// components
import { DurationType } from 'modules/services/constants/packages';
import { Alert, MultipleSelect } from 'common/components';
import { FormattedMessage } from 'react-intl';
import messages from 'modules/services/messages/messages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { List as ImmutableList } from 'immutable';
import {
  IPackageRCodeListItem,
  IPackageRCodeListItemImt,
} from 'modules/pos-settings/interfaces/revenueCodes';
import { useSelector } from 'react-redux';
import * as packageSelectors from 'modules/services/state/packages/selectors';
import { Add as AddIcon } from '@material-ui/icons';
import commonMessages from 'common/messages/messages';
import { getString } from 'common/utils/typeUtils';
import BillingOptionView from 'modules/services/components/EditPackageForm/BillingStep/BillingOptionView/BillingOptionView';
import EditBillingOptionModal from 'modules/services/components/EditPackageForm/BillingStep/EditBillingOptionModal/EditBillingOptionModal';
import { AlertTypes } from 'common/interfaces/alerts';
import OutOfTurmOptionView from './OutOfTurmOptionView/OutOfTurmOptionView';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    flex: 1,
    minHeight: 0,
    overflow: 'hidden',
  },
  hidden: {
    display: 'none',
  },
  cardContent: {
    padding: ({ smallPaddingContent }: IFormStepStyle) =>
      smallPaddingContent ? theme.spacing(1, 0) : theme.spacing(1, 2),
    '&:last-child': {
      padding: ({ smallPaddingContent }: IFormStepStyle) =>
        smallPaddingContent ? theme.spacing(1, 0) : theme.spacing(1, 2),
    },
  },
  durationEditableWrap: {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      padding: 0,
    },
    '& >div .MuiOutlinedInput-root': {
      width: 'calc(100% + 1px)',
    },
    '& >div:last-child .MuiOutlinedInput-root': {
      width: '100%',
    },
  },
  sectionTitle: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    opacity: '0.3',
  },
}));

type TIsOpenEditModal = {
  isOpen: boolean;
  isOutOfTerm: boolean;
};

interface IBillingStepProps extends IFormStepStyle {
  formMethods: UseFormReturn<any>;
  durationType: DurationType | string;
  durationEditableNumber: string | number;
  startDate: string;
  endDate: string;
}

const BillingStepForm = ({
  formMethods,
  smallPaddingContent,
  durationType,
  durationEditableNumber,
  startDate,
  endDate,
}: IBillingStepProps): JSX.Element => {
  const classes = useStyles({ smallPaddingContent });
  const renderIntlMessage = useRenderIntlMessage();

  const [selectedBillingOption, setSelectedBillingOption] = useState<FieldArray<
    { billingOptions: IBillingOption[] },
    'billingOptions'
  > | null>(null);

  const [selectedBillingOptionIndex, setSelectedBillingOptionIndex] = useState<number>(null);
  const [isOpenEditBillingModal, setIsOpenEditBillingModal] = useState<TIsOpenEditModal>({
    isOpen: false,
    isOutOfTerm: false,
  });

  const packageRCodesList: ImmutableList<IPackageRCodeListItemImt> = useSelector(
    packageSelectors.selectPackageRcodesList,
  );

  const packageRCodes: IPackageRCodeListItem[] = useMemo(() => packageRCodesList?.toJS() || [], [
    packageRCodesList,
  ]);

  const {
    control,
    reset,
    getValues,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = formMethods;

  const { fields, append, remove } = useFieldArray<
    { billingOptions: IBillingOption[] },
    'billingOptions'
  >({
    control,
    name: 'billingOptions',
  });

  const revenueCode = watch('revenueCode');
  const outOfTurmOption = watch('outOfTermBillingOption');

  const durationOption = {
    type: durationType as DurationType,
    amount: durationEditableNumber as number,
    fromDate: startDate,
    toDate: endDate,
  };

  const closeModal = () => {
    setSelectedBillingOption(null);
    setSelectedBillingOptionIndex(null);
    setIsOpenEditBillingModal({ isOpen: false, isOutOfTerm: false });
  };

  const submitModalResult = (data: IBillingOption) => {
    if (data.isOutOfTerm) {
      setValue('outOfTermBillingOption', data);
      void trigger('outOfTermBillingOption');
      closeModal();

      return;
    }

    if (selectedBillingOptionIndex != null) {
      const values = getValues().billingOptions;

      values[selectedBillingOptionIndex] = { ...values[selectedBillingOptionIndex], ...data };

      // need to set default billing option
      if (data.useAsDefault) {
        const updateValues = values.map((billingOption: IBillingOption, index) => ({
          ...billingOption,
          useAsDefault: index === selectedBillingOptionIndex,
        }));
        reset({ ...getValues(), billingOptions: updateValues });
      } else {
        reset({ ...getValues(), billingOptions: values });
      }

      // TODO
      // setValue(`billingOptions.${selectedBillingOptionIndex}`, data);
    } else {
      if (data.useAsDefault) {
        const updatedFields = getValues().billingOptions.map(option => ({
          ...option,
          useAsDefault: false,
        }));
        reset({ ...getValues(), billingOptions: updatedFields });
      }
      append(data);
    }
    closeModal();
  };

  return (
    <Box className={classes.contentWrapper}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {/* Revenue code block */}

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="revenueCode"
                  defaultValue=""
                  render={({ field: { value, onChange, onBlur } }) => (
                    <MultipleSelect
                      label={<FormattedMessage {...messages.revenueCodeLabel} />}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      fullWidth
                      options={packageRCodes}
                      error={!!errors.revenueCode}
                      helperText={renderIntlMessage(errors.revenueCode?.message)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  variant="outlined"
                  label={<FormattedMessage {...messages.reVenueCodeTaxLabel} />}
                  value={revenueCode ? `${revenueCode.totalTax}%` : ''}
                  InputLabelProps={{ shrink: !!revenueCode }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    <Typography className={classes.sectionTitle}>
                      <FormattedMessage {...messages.paymentOptionsSectionLabel} />
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" alignContent="flex-end">
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setSelectedBillingOption(null);
                        setSelectedBillingOptionIndex(null);
                        setIsOpenEditBillingModal({ isOpen: true, isOutOfTerm: false });
                      }}
                    >
                      <FormattedMessage {...commonMessages.addBtn} />
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Controller name="defaultBillingOptionPresent" control={control} render={() => null} />

            <Grid container spacing={2}>
              {!fields?.length && errors?.billingOptions?.message && (
                <Alert
                  title={getString(errors?.billingOptions?.message)}
                  severity={AlertTypes.Danger}
                  resolveLabel={<FormattedMessage {...commonMessages.configureBtn} />}
                />
              )}

              {!!fields?.length && errors.defaultBillingOptionPresent?.message && (
                <Alert
                  title={getString(errors.defaultBillingOptionPresent?.message)}
                  severity={AlertTypes.Danger}
                />
              )}

              {fields.map((field, index) => (
                <Grid item xs={12} key={field.id}>
                  <BillingOptionView
                    durationOption={durationOption}
                    billingOption={field}
                    totalTax={revenueCode?.totalTax}
                    onRemove={() => remove(index)}
                    onEdit={() => {
                      setSelectedBillingOption(field);
                      setSelectedBillingOptionIndex(index);
                      setIsOpenEditBillingModal({ isOpen: true, isOutOfTerm: false });
                    }}
                  />
                </Grid>
              ))}

              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  <Typography className={classes.sectionTitle}>
                    <FormattedMessage {...messages.outOfTermSectionLabel} />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {errors.outOfTermBillingOption?.message && (
                  <Alert
                    title={getString(errors.outOfTermBillingOption?.message)}
                    severity={AlertTypes.Danger}
                  />
                )}

                <OutOfTurmOptionView
                  durationOption={durationOption}
                  billingOption={outOfTurmOption}
                  totalTax={revenueCode?.totalTax}
                  onEdit={() => {
                    setSelectedBillingOption(outOfTurmOption);
                    setIsOpenEditBillingModal({ isOpen: true, isOutOfTerm: true });
                  }}
                  isOutOfTerm
                />
              </Grid>
            </Grid>
          </Grid>

          {isOpenEditBillingModal && (
            <EditBillingOptionModal
              billingOption={selectedBillingOption}
              open={isOpenEditBillingModal.isOpen}
              onSubmit={submitModalResult}
              onClose={closeModal}
              revenueCode={revenueCode}
              durationOption={durationOption}
              isOutOfTerm={isOpenEditBillingModal.isOutOfTerm}
            />
          )}

          {/* <InitialFeeSection formMethods={formMethods} defaultValues={defaultValues} /> */}
        </Grid>
      </CardContent>
    </Box>
  );
};

export default BillingStepForm;
