import axios from 'axios';

import ApiService from './ApiService';

import { ISignInResponse, IUnlockCredentials } from 'modules/authentication/interfaces';
import { ITableParams } from 'common/interfaces/table';
import {
  IAddClockInManuallyData,
  IClockedInEmployee,
  IClockIn,
  IClockInParams,
  IPaginatedClockInData,
  IPaginatedMyPayrollReportList,
  IPaginatedPayrollReportList,
  IPaginatedTimeclockList,
  IPaginatedUnitData,
  IPayrollReportActivityDTO,
  IPayrollStatistics,
  ITimeclockEventsRequestData,
  ITimeclockListItem,
} from 'modules/timeclock/interfaces/timeclock';
import { ITimeClockEmployeeJobPosition } from 'common/interfaces/employee';
import { IBookingEvent } from 'modules/booking/interfaces';
import { IPaginatedData } from 'common/interfaces/pagination';
import { ActionResult } from 'common/constants';
import { IPaginatedPayPeriods } from 'modules/corporations/interfaces';
import { IFilter } from 'common/interfaces/filter';
import { PeriodFilterType } from 'modules/reports/interfaces/common';

export default class TimeclockService extends ApiService {
  public clockIn = (data: IUnlockCredentials): Promise<IClockedInEmployee> =>
    axios.post('/api/v1/time-clock/sign-in', data);

  public unlockMode = (data: IUnlockCredentials): Promise<ISignInResponse> =>
    axios.post('/api/v1/time-clock/lock-mode/unlock', data);

  public getAllTimeclock = (requestOptions?: ITableParams): Promise<IPaginatedTimeclockList> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`/api/v1/time-clock`, { params: searchParams });
  };

  public getEmployeeTimeclockJobPositions = (
    employeeId: string,
  ): Promise<ITimeClockEmployeeJobPosition[]> => {
    return axios.get(`/api/v1/time-clock/employees/${employeeId}/job-positions`);
  };

  public getPayrollClockInDataJobPositions = (
    employeeId: string,
  ): Promise<ITimeClockEmployeeJobPosition[]> => {
    return axios.get(
      `/api/v1/time-clock/payroll-report/employees/${employeeId}/job-positions/clock-in`,
    );
  };

  public getPayrollUnitDataJobPositions = (
    employeeId: string,
  ): Promise<ITimeClockEmployeeJobPosition[]> => {
    return axios.get(
      `/api/v1/time-clock/payroll-report/employees/${employeeId}/job-positions/unit`,
    );
  };

  public getProfileClockInDataJobPositions = (): Promise<ITimeClockEmployeeJobPosition[]> => {
    return axios.get(`/api/v1/profile/time-clock/job-positions/clock-in`);
  };

  public getProfileUnitDataJobPositions = (): Promise<ITimeClockEmployeeJobPosition[]> => {
    return axios.get(`/api/v1/profile/time-clock/job-positions/unit`);
  };

  public clockInEmployeePosition = (employeeId: string, data: IClockInParams): Promise<IClockIn> =>
    axios.post(`/api/v1/time-clock/employees/${employeeId}/clock-in`, data);

  public clockOutEmployeePosition = (employeeId: string, data: IClockInParams): Promise<void> =>
    axios.put(`/api/v1/time-clock/employees/${employeeId}/clock-out`, data);

  public getTimeclockPersonEvent = (eventId: string): Promise<IBookingEvent> => {
    return axios.get(`/api/v1/time-clock/events/${eventId}`);
  };

  public getPositionEvents = (
    requestOptions: ITimeclockEventsRequestData<string>,
  ): Promise<IPaginatedData<IBookingEvent>> => {
    return axios.get(`/api/v1/time-clock/events`, { params: requestOptions });
  };

  public getMyTimeclockEvents = (
    requestOptions: ITimeclockEventsRequestData<string>,
  ): Promise<IPaginatedData<IBookingEvent>> => {
    return axios.get(`/api/v1/profile/time-clock/events`, { params: requestOptions });
  };

  public getPayrollReportActivity = (
    filters: IFilter[],
    period: PeriodFilterType,
  ): Promise<IPayrollReportActivityDTO> => {
    const searchParams = this.getSearchFromFilters(filters);

    return axios.get(`/api/v1/time-clock/payroll-report/activity`, {
      params: { ...searchParams, period },
    });
  };

  public getProfilePayrollReportActivity = (
    filters: IFilter[],
    period: PeriodFilterType,
  ): Promise<IPayrollReportActivityDTO> => {
    const searchParams = this.getSearchFromFilters(filters);

    return axios.get(`/api/v1/profile/payroll-report/activity`, {
      params: { ...searchParams, period },
    });
  };

  public deleteTimeclockById = (timeclockId: string): Promise<ActionResult> => {
    return axios.delete(`/api/v1/time-clock/${timeclockId}`);
  };

  public getFullPayrollReportList = (
    requestOptions: ITableParams,
    period: PeriodFilterType,
  ): Promise<IPaginatedPayrollReportList> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/time-clock/payroll-report`, { params: { ...searchParams, period } });
  };

  public getMyFullPayrollReportList = (
    requestOptions: ITableParams,
    period: PeriodFilterType,
  ): Promise<IPaginatedMyPayrollReportList> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/profile/payroll-report`, { params: { ...searchParams, period } });
  };

  public getClockInDataList = (
    employeeId: string,
    requestOptions?: ITableParams,
  ): Promise<IPaginatedClockInData> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`/api/v1/time-clock/payroll-report/employees/${employeeId}/clock-in-data`, {
      params: searchParams,
    });
  };

  public getUnitDataList = (
    employeeId: string,
    requestOptions?: ITableParams,
  ): Promise<IPaginatedUnitData> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`/api/v1/time-clock/payroll-report/employees/${employeeId}/unit-data`, {
      params: searchParams,
    });
  };

  public getStatisticsByEmployeeId = (
    employeeId: string,
    requestOptions?: ITableParams,
  ): Promise<IPayrollStatistics> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`/api/v1/time-clock/payroll-report/employees/${employeeId}/total-time-clock`, {
      params: searchParams,
    });
  };

  public addManualClockIn = (
    employeeId: string,
    data: IAddClockInManuallyData,
  ): Promise<ITimeclockListItem> => {
    return axios.post(
      `/api/v1/time-clock/payroll-report/employees/${employeeId}/manual-clock-in`,
      data,
    );
  };

  public addProfileManualClockIn = (data: IAddClockInManuallyData): Promise<ITimeclockListItem> => {
    return axios.post(`/api/v1/profile/time-clock/manual-clock-in`, data);
  };

  public getTimeclockEvents = (
    employeeId: string,
    data?: ITimeclockEventsRequestData<string>,
  ): Promise<IPaginatedData<IBookingEvent>> => {
    return axios.get(`/api/v1/time-clock/payroll-report/employees/${employeeId}/events`, {
      params: data,
    });
  };

  public getPayrollPayPeriods = (): Promise<IPaginatedPayPeriods> => {
    return axios.get('/api/v1/time-clock/payroll-report/pay-periods');
  };

  public deletePayrollItem = (employeeId: string, date: string): Promise<ActionResult> => {
    return axios.delete(`/api/v1/time-clock/payroll-report/employees/${employeeId}`, {
      data: { dateTime: date },
    });
  };

  public deleteClockInDataItem = (timeclockId: string): Promise<ActionResult> => {
    return axios.delete(`/api/v1/time-clock/payroll-report/${timeclockId}`);
  };
}
