// libraries
import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Control, Controller, UseFormTrigger } from 'react-hook-form';
import { FieldArray } from 'react-hook-form/dist/types/fieldArray';
import { makeStyles } from '@material-ui/core/styles';
import { Cancel } from '@material-ui/icons';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';
import CheckBoxWithReset from 'common/components/CheckBoxWithReset/CheckBoxWithReset';
import { MinMaxNumberInput, Select } from 'common/components';
// messages
import messages from '../../../../messages/messages';
import inputLabels from 'common/messages/inputLabels';
// constants
import {
  defaultNumberFormatProps,
  defaultPriceNumberProps,
} from 'common/components/NumberController/NumberController';
import { FeeType } from '../../../../interfaces/fees';
import { DurationType, FrequencyType, FrequencyTypes } from '../../../../constants/packages';
import { FeeTypeOptions } from '../../../../constants/fees';
import { IFeesSection, IPackageFee } from 'modules/services/interfaces/packages';
import { checkIsPaymentScheduleOptionDisabled } from 'modules/services/utils/billingUtils';

interface IIncludedFeeItemProps {
  control: Control<any>;
  errors: any;
  watch: any;
  trigger: UseFormTrigger<IFeesSection>;
  index: number;
  field: FieldArray;
  onRemove: (index: number) => void;
  durationType: DurationType;
  durationEditableNumber: number;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  wrapFlexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    width: '100%',
    paddingRight: theme.spacing(2.5),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
  },
  relative: {
    position: 'relative',
  },
  removeButton: {
    color: theme.palette.text.primary,
    opacity: 0.4,
    position: 'absolute',
    right: theme.spacing(1.5),
  },
  excludableCheckbox: {
    justifyContent: 'flex-end',
    marginRight: theme.spacing(4),
  },
}));

const IncludedFeeItem: React.FC<IIncludedFeeItemProps> = (
  props: IIncludedFeeItemProps,
): JSX.Element => {
  const {
    control,
    watch,
    errors,
    index,
    field: formField,
    trigger,
    onRemove,
    durationType,
    durationEditableNumber,
  } = props;

  const field = formField as IPackageFee; // TODO - PRM-1810 need types
  const classes = useStyles(props);

  // watches

  const isSplittingAllowed = watch(`includedFees.${index}.allowSplitting`);
  const isRegularFeeItem = field.fee.type === FeeType.Regular;

  // renders

  return (
    <Grid container spacing={2} key={field.id}>
      {/* title */}

      <Grid item xs={12} className={cx(classes.wrapFlexCenter, classes.relative)}>
        <Grid item xs={6}>
          <Box className={classes.header}>
            <Typography variant="h5">{field.fee.title}</Typography>
            <Typography variant="caption" color="textSecondary">
              {FeeTypeOptions.translate(field.fee.type)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name={`includedFees.${index}.excludable`}
            defaultValue={field.excludable || false}
            render={({ field: { onChange, value } }) => (
              <CheckBoxWithReset
                value={value}
                onChange={onChange}
                label={<FormattedMessage {...messages.canBeRemovedUponSaleLabel} />}
                wrapperClassName={classes.excludableCheckbox}
              />
            )}
          />
        </Grid>
        <IconButton
          size="small"
          type="button"
          className={classes.removeButton}
          onClick={() => onRemove(index)}
        >
          <Cancel fontSize="inherit" />
        </IconButton>
      </Grid>

      {/* configuration */}

      <MinMaxNumberInput
        xs={4}
        control={control}
        trigger={trigger}
        errors={errors}
        baseName={`includedFees.${index}.totalAmount`}
        label={<FormattedMessage {...inputLabels.totalAmount} />}
        baseDefaultValue={field.totalAmount}
        numberFormatProps={defaultPriceNumberProps}
      />

      <MinMaxNumberInput
        xs={4}
        control={control}
        trigger={trigger}
        errors={errors}
        baseName={`includedFees.${index}.chargeAfterDays`}
        label={<FormattedMessage {...inputLabels.chargeAfterDays} />}
        baseDefaultValue={field.chargeAfterDays}
        numberFormatProps={defaultNumberFormatProps}
      />

      {field.fee.type === FeeType.OneTime && (
        <>
          <Grid item xs={4}>
            <Controller
              name={`includedFees.${index}.allowSplitting`}
              control={control}
              defaultValue={field.allowSplitting || false}
              render={({ field: { value, onChange, onBlur } }) => (
                <CheckBoxWithReset
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label={<FormattedMessage {...messages.allowSplitting} />}
                />
              )}
            />
          </Grid>

          {isSplittingAllowed && (
            <MinMaxNumberInput
              xs={4}
              control={control}
              trigger={trigger}
              errors={errors}
              baseName={`includedFees.${index}.paymentsNumber`}
              label={<FormattedMessage {...inputLabels.payments} />}
              baseDefaultValue={field.paymentsNumber}
              numberFormatProps={defaultNumberFormatProps}
            />
          )}
        </>
      )}

      {(isRegularFeeItem || isSplittingAllowed) && (
        <Grid item xs={4}>
          <Controller
            name={`includedFees.${index}.${
              isRegularFeeItem ? 'paymentSchedule' : 'splitPaymentSchedule'
            }`}
            control={control}
            defaultValue={field[isRegularFeeItem ? 'paymentSchedule' : 'splitPaymentSchedule']}
            render={({ field: { name, value, onChange, onBlur } }) => (
              <Select
                fullWidth
                variant="outlined"
                label={<FormattedMessage {...messages.paymentScheduleLabel} />}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              >
                {FrequencyTypes.getSelectOptions({
                  isDisabledFunc: (type: FrequencyType) =>
                    checkIsPaymentScheduleOptionDisabled(
                      durationType,
                      durationEditableNumber,
                      type,
                    ),
                })}
              </Select>
            )}
          />
        </Grid>
      )}

      <Controller
        name={`includedFees.${index}.fee`}
        control={control}
        defaultValue={field.fee}
        render={() => null}
      />
    </Grid>
  );
};

export default IncludedFeeItem;
