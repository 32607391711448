import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import cx from 'classnames';
import { Box, Grid, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core';

import {
  fetchGuardianDaycareServices,
  resetGuardianDaycareServicesAction,
} from 'modules/kid-zone/state/actions';
import {
  selectDaycareServices,
  selectDaycareServicesLoading,
} from 'modules/kid-zone/state/selectors';
import { selectUserSelectedOrganization } from 'modules/authentication/state/selectors';
import { selectTimezone } from 'common/state/settings/selectors';

import { useAppDispatch } from 'store/hooks';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import {
  getLimitedQuantityLabel,
  getProgressDescriptionLabel,
} from 'common/utils/displayServiceQuantity';
import { checkIsNowInOperatingHours } from 'common/utils/time';

import { CustomTheme } from 'common/ui/interfaces';
import { IUserOrganizationImt } from 'common/interfaces/clients';
import { IGuardianDayCareService } from 'modules/kid-zone/interfaces';
import { AlertTypes } from 'common/interfaces/alerts';

import {
  Alert,
  AvailabilitiesResultBlock,
  DialogComponent,
  FieldInfo,
  LoadingBackdrop,
} from 'common/components';

import messages from 'modules/kid-zone/messages';
import commonMessages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';

const useStyles = makeStyles((theme: CustomTheme) => ({
  serviceItem: {
    '&:not(:first-child)': {
      borderTop: `1px solid ${theme.palette.borderColor.main}`,
    },
    padding: theme.spacing(1, 0),
  },
  unavailablePackage: {
    opacity: 0.5,
  },
  serviceName: {
    marginBottom: theme.spacing(1.5),
  },
  noPackagesLabel: {
    textAlign: 'center',
  },
}));

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (serviceId: string) => void;
  guardianId: string;
  isRedeemActionLoading: boolean;
}

const DaycareRedeemModal = ({
  isOpen,
  onClose,
  onSubmit,
  guardianId,
  isRedeemActionLoading,
}: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const daycareServices = useSelector(selectDaycareServices);
  const isDaycareServicesLoading = useSelector(selectDaycareServicesLoading);
  const selectedClub: IUserOrganizationImt = useSelector(selectUserSelectedOrganization);
  const currentTimezoneView: string = useSelector(selectTimezone);

  const clubTimezone = selectedClub.get('timezone');
  const clubZoneOffset = moment.tz(clubTimezone).utcOffset();
  const timezoneViewOffset = moment.tz(currentTimezoneView).utcOffset();

  const isSameTimezones = clubZoneOffset === timezoneViewOffset;

  const [serviceId, setServiceId] = useState(null);

  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchGuardianDaycareServices(guardianId, selectedClub.get('id')));
  }, [dispatch, guardianId, selectedClub]);

  useEffect(() => {
    return () => {
      dispatch(resetGuardianDaycareServicesAction());
    };
  }, [dispatch]);

  const handleRedeem = () => {
    onSubmit(serviceId);
  };

  const checkIsServiceAvailableForRedeem = (service: IGuardianDayCareService): boolean => {
    const clubDate = moment.tz(clubTimezone);

    const isExpiredPackage = moment
      .tz(`${service.expirationDate}Z`, clubTimezone)
      .isBefore(clubDate);

    if (isExpiredPackage) {
      return false;
    }

    const isServiceAvailable = (service.limited && !!service.leftNumber) || !service.limited;

    if (!isServiceAvailable) {
      return false;
    }

    if (!service.hasSameAsClubAvailability) {
      return checkIsNowInOperatingHours(service.customAvailability, clubTimezone);
    }

    return true;
  };

  useEffect(() => {
    const firstItemId = daycareServices.toJS()[0]?.packageServiceInstanceId;

    if (daycareServices.size === 1 && firstItemId !== serviceId) {
      setServiceId(firstItemId);
    }
  }, [daycareServices, serviceId]);

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage {...messages.daycareRedeemTitle} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.redeemBtn} />}
      disabled={!serviceId}
      loading={isRedeemActionLoading}
      onSubmit={handleRedeem}
    >
      {!!daycareServices.size && (
        <>
          {!isSameTimezones && (
            <Box mb={2}>
              <Alert
                severity={AlertTypes.Warning}
                title={
                  <FormattedMessage
                    {...commonMessages.timezoneIsDifferentBody}
                    values={{
                      timezoneViewOffset: `(GMT ${moment.tz(currentTimezoneView).format('Z')})`,
                      clubZoneOffset: `(GMT ${moment.tz(clubTimezone).format('Z')})`,
                    }}
                  />
                }
              />
            </Box>
          )}

          <RadioGroup value={serviceId} onChange={(_, value) => setServiceId(value)}>
            {daycareServices.map(service => {
              const isServiceAvailable = checkIsServiceAvailableForRedeem(service.toJS());

              return (
                <Box
                  display="flex"
                  alignItems="center"
                  gridGap={8}
                  key={service.get('packageServiceInstanceId')}
                  className={classes.serviceItem}
                >
                  <Radio
                    value={service.get('packageServiceInstanceId')}
                    color="primary"
                    disabled={!isServiceAvailable}
                  />

                  <Box
                    flex="1"
                    className={cx({ [classes.unavailablePackage]: !isServiceAvailable })}
                  >
                    <Typography component="p" variant="button" className={classes.serviceName}>
                      {service.get('title')}
                    </Typography>

                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <FieldInfo
                          label={<FormattedMessage {...inputLabels.expiryDate} />}
                          description={
                            service.get('expirationDate')
                              ? timezoneMoment(service.get('expirationDate')).format('MMM D, YYYY')
                              : '-'
                          }
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <FieldInfo
                          label={<FormattedMessage {...inputLabels.daysAndHoursForAccess} />}
                          hasNotEllipsesDescription
                          description={
                            service.get('hasSameAsClubAvailability') ? (
                              <FormattedMessage {...inputLabels.accessAsClub} />
                            ) : (
                              <AvailabilitiesResultBlock
                                availabilities={service.get('customAvailability')?.toJS()}
                              />
                            )
                          }
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <FieldInfo
                          label={<FormattedMessage {...inputLabels.duration} />}
                          description={
                            <FormattedMessage
                              {...(service.get('limited')
                                ? commonMessages.limitedLabel
                                : commonMessages.unlimitedLabel)}
                            />
                          }
                        />
                      </Grid>

                      {!!service.get('limited') && (
                        <Grid item xs={6}>
                          <FieldInfo
                            label={getLimitedQuantityLabel(
                              service.get('type'),
                              service.get('redeemType'),
                              service.get('redeemDurationUnit'),
                            )}
                            description={getProgressDescriptionLabel(
                              service.get('redeemType'),
                              service.get('redeemDurationUnit'),
                              service.get('leftNumber'),
                              service.get('totalNumber'),
                              service.get('limited'),
                            )}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Box>
              );
            })}
          </RadioGroup>
        </>
      )}

      {!isDaycareServicesLoading && !daycareServices.size && (
        <Typography variant="h5" color="textSecondary" className={classes.noPackagesLabel}>
          <FormattedMessage {...messages.noPurchasedPackagesLabel} />
        </Typography>
      )}

      <LoadingBackdrop isLoading={isDaycareServicesLoading} />
    </DialogComponent>
  );
};

export default DaycareRedeemModal;
