import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';

import { ReactComponent as PosIcon } from 'img/icons/sidebar/cash-register.svg';
import { ReactComponent as FrontDeskIcon } from 'img/icons/sidebar/check-in.svg';
import { ReactComponent as ServicesIcon } from 'img/icons/sidebar/mall.svg';
import { ReactComponent as DictionariesIcon } from 'img/icons/sidebar/book.svg';
import { ReactComponent as PTICon } from 'img/icons/sidebar/running.svg';
import { ReactComponent as ClubsIcon } from 'img/icons/sidebar/dumbbell.svg';
import { ReactComponent as CorporationsIcon } from 'img/icons/sidebar/building.svg';
import { ReactComponent as PosSettings } from 'img/icons/sidebar/POS-Settings-icon.svg';
import { ReactComponent as KidIcon } from 'img/icons/sidebar/Child.svg';
import { ReactComponent as SettingsIcon } from 'img/icons/sidebar/Gear.svg';
import { ReactComponent as TimeclockIcon } from 'img/icons/hourglass.svg';
import { ReactComponent as DaySheets } from 'img/icons/day-sheets.svg';
import { ReactComponent as CalendarIcon } from 'img/icons/calendar.svg';
import { ReactComponent as ShareIcon } from 'img/icons/share.svg';
import { ReactComponent as AddressCardIcon } from 'img/icons/address-card.svg';
import { ReactComponent as UsersIcon } from 'img/icons/users.svg';
import { ReactComponent as DocumentChartIcon } from 'img/icons/document-chart.svg';
import { ReactComponent as KeyIcon } from 'img/icons/key_deprecated.svg';
import { ReactComponent as Megaphon } from 'img/icons/sidebar/megaphon.svg';

import commonMessages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import clubSettingsMessages from 'modules/club-settings/messages';
import dictionaryMessages from 'modules/dictionaries/messages/messages';
import corporationsMessages from 'modules/corporations/messages';
import menuItems from 'common/messages/menuItems';

import { FRONT_DESK_PATH } from 'common/constants/globalConstants';
import {
  PermissionLevels,
  PoliciesPermissionSetItems as Permission,
} from 'common/constants/permissions';
import { PeakSettingsRoutes } from 'modules/peak-settings/constants';
import { Message } from 'common/interfaces/common';
import { SidebarId } from 'common/enums/html.enums';

export interface PageRouteSetting {
  id?: string;
  path: string;
  title: Message;
  icon: OverridableComponent<SvgIconTypeMap>;
  isDisabled?: boolean;
  isDivider?: boolean;
  isGroupTitle?: boolean;
  permissionsSet?: PermissionLevels[];
  permissions?: Permission[];
}

export const commonRoutes: PageRouteSetting[] = [
  // peak level

  // TODO: uncomment when it will be implemented
  // {
  //   path: '/dash-board',
  //   title: 'Dashboard',
  //   isDisabled: true,
  //   icon: DashboardIcon,
  //   permissionsSet: [PermissionLevels.PEAK],
  // },

  // club level
  {
    id: SidebarId.SidebarBtnFrontDesk,
    path: FRONT_DESK_PATH,
    title: commonMessages.frontDesk,
    icon: FrontDeskIcon,
    permissionsSet: [PermissionLevels.CLIENT],
  },
  {
    id: SidebarId.SidebarBtnPos,
    path: '/pos',
    title: commonMessages.pos,
    icon: PosIcon,
    permissionsSet: [PermissionLevels.CLIENT],
  },
  {
    id: SidebarId.SidebarBtnKidZone,
    path: '/kid-zone',
    title: commonMessages.kidZone,
    icon: KidIcon,
    permissionsSet: [PermissionLevels.CLIENT],
  },
  {
    path: '',
    title: commonMessages.clubDivider,
    icon: null,
    isDivider: true,
    permissionsSet: [PermissionLevels.CLIENT],
  },

  // club, corp level
  {
    id: SidebarId.SidebarBtnBooking,
    path: '/booking',
    title: commonMessages.booking,
    icon: CalendarIcon,
    permissionsSet: [PermissionLevels.CLIENT, PermissionLevels.CORPORATE],
    permissions: [Permission.BOOKING_EVENTS_VIEW, Permission.BOOKING_RESOURCES_VIEW],
  },
  {
    id: SidebarId.SidebarBtnCrm,
    path: '/crm',
    title: commonMessages.crm,
    icon: ShareIcon,
    permissionsSet: [PermissionLevels.CLIENT, PermissionLevels.CORPORATE],
    permissions: [
      Permission.CRM_TASK_VIEW,
      Permission.CRM_LEAD_VIEW,
      Permission.CRM_SCRIPT_VIEW,
      Permission.CRM_CAMPAIGN_VIEW,
    ],
  },
  {
    id: SidebarId.SidebarBtnPTCRM,
    path: '/personal-training-crm',
    title: commonMessages.ptcrm,
    icon: PTICon,
    permissionsSet: [PermissionLevels.CLIENT, PermissionLevels.CORPORATE],
    permissions: [
      Permission.PT_CRM_TASK_VIEW,
      Permission.PT_CRM_LEAD_VIEW,
      Permission.PT_CRM_SCRIPT_VIEW,
      Permission.PT_CRM_CAMPAIGN_VIEW,
    ],
  },
  {
    id: SidebarId.SidebarBtnMembers,
    path: '/members',
    title: commonMessages.members,
    icon: AddressCardIcon,
    permissionsSet: [PermissionLevels.CLIENT, PermissionLevels.CORPORATE],
  },
  {
    id: SidebarId.SidebarBtnEmployees,
    path: '/employees',
    title: inputLabels.employeesLabel,
    icon: UsersIcon,
    permissionsSet: [PermissionLevels.CLIENT, PermissionLevels.CORPORATE],
  },
  {
    id: SidebarId.SidebarBtnTimeclock,
    path: `/timeclock/log`,
    title: commonMessages.timeclock,
    icon: TimeclockIcon,
    permissionsSet: [PermissionLevels.CLIENT, PermissionLevels.CORPORATE],
  },
  {
    id: SidebarId.SidebarBtnDaySheet,
    path: `/day-sheet`,
    title: commonMessages.daySheet,
    icon: DaySheets,
    permissionsSet: [PermissionLevels.CLIENT, PermissionLevels.CORPORATE],
  },
  {
    id: SidebarId.SidebarBtnReports,
    path: '/reports/overview',
    title: commonMessages.reports,
    icon: DocumentChartIcon,
    permissionsSet: [PermissionLevels.CLIENT, PermissionLevels.CORPORATE],
  },

  {
    id: SidebarId.SidebarBtnClubSettings,
    path: '/club-settings',
    title: clubSettingsMessages.clubSettingsTitle,
    icon: SettingsIcon,
    permissionsSet: [PermissionLevels.CLIENT],
  },
  // corp settings menu
  {
    path: '',
    title: commonMessages.clubCorpDivider,
    icon: null,
    isGroupTitle: true,
    isDisabled: true,
    permissionsSet: [PermissionLevels.CLIENT, PermissionLevels.CORPORATE],
  },

  {
    id: SidebarId.SidebarBtnServices,
    path: '/services',
    title: commonMessages.services,
    icon: ServicesIcon,
    permissionsSet: [PermissionLevels.CLIENT, PermissionLevels.CORPORATE],
  },
  {
    id: SidebarId.SidebarBtnPosSettings,
    path: '/pos-settings',
    title: commonMessages.posContent,
    icon: PosSettings,
    permissionsSet: [PermissionLevels.CLIENT, PermissionLevels.CORPORATE],
  },
  {
    id: SidebarId.SidebarBtnDictionaries,
    path: '/dictionaries',
    title: dictionaryMessages.dictionaryLabel,
    icon: DictionariesIcon,
    permissionsSet: [PermissionLevels.CLIENT, PermissionLevels.CORPORATE, PermissionLevels.PEAK],
  },
  {
    id: SidebarId.SidebarBtnPermissions,
    path: '/permissions',
    title: commonMessages.permissions,
    icon: KeyIcon,
    permissionsSet: [PermissionLevels.CORPORATE, PermissionLevels.PEAK],
  },
  {
    id: SidebarId.SidebarBtnClubs,
    path: '/clubs',
    title: commonMessages.clubsLabel,
    icon: ClubsIcon,
    permissionsSet: [PermissionLevels.CORPORATE],
  },

  {
    id: SidebarId.SidebarBtnCorporateSettings,
    path: '/corporate-settings',
    title: commonMessages.corporateSettings,
    icon: CorporationsIcon,
    permissionsSet: [PermissionLevels.CORPORATE],
  },
  {
    id: SidebarId.SidebarBtnCommunication,
    path: '/communication',
    title: commonMessages.communication,
    icon: Megaphon,
    permissionsSet: [PermissionLevels.CLIENT, PermissionLevels.CORPORATE],
  },

  // Peak level
  {
    id: SidebarId.SidebarBtnPeakEmployees,
    path: '/peak-employees',
    title: inputLabels.employeesLabel,
    icon: UsersIcon,
    permissionsSet: [PermissionLevels.PEAK],
  },
  {
    id: SidebarId.SidebarBtnCorporations,
    path: '/corporations',
    title: corporationsMessages.corporationsTitle,
    icon: CorporationsIcon,
    permissionsSet: [PermissionLevels.PEAK],
  },
  {
    id: SidebarId.SidebarBtnSettings,
    path: `/${PeakSettingsRoutes.PEAK_SETTINGS}/${PeakSettingsRoutes.PEAK_SETTINGS_INTEGRATIONS}`,
    title: menuItems.settings,
    icon: SettingsIcon,
    permissionsSet: [PermissionLevels.PEAK],
  },
];
