import { createAction } from '@reduxjs/toolkit';

import Services from 'services/network';
import TokenService from 'services/auth/TokenService';

import { actionTypes } from 'common/state/kioskMode/constants';
import { PeakModules } from 'common/constants/peakModules';

import { GeneralThunkAction } from 'common/state/interfaces';
import { ActionResult } from 'common/constants';
import { IUnlockCredentials } from 'modules/authentication/interfaces';

export const unlockKioskModeAction = createAction<ActionResult | null>(
  actionTypes.UNLOCK_KIOSK_MODE,
);
const unlockKioskModeActionLoading = createAction<boolean>(actionTypes.UNLOCK_KIOSK_MODE_LOADING);
export const unlockKioskModeActionError = createAction<Record<string, any> | boolean | null>(
  actionTypes.UNLOCK_KIOSK_MODE_ERROR,
);

export const resetKioskModeActionResult = createAction(actionTypes.RESET_UNLOCK_KIOSK_MODE_RESULT);

export const unlockKioskMode = (
  data: IUnlockCredentials,
  module: PeakModules,
): GeneralThunkAction => {
  return async (dispatch, getState) => {
    const state = getState();
    const corporationId = state.getIn(['currentUser', 'profile', 'corporation', 'id']);

    dispatch(unlockKioskModeActionLoading(true));

    try {
      let signInData;

      switch (module) {
        case PeakModules.FrontDesk:
          signInData = await Services.FrontDesk.unlockKioskMode({ ...data, corporationId });
          break;
        case PeakModules.Timeclock:
          signInData = await Services.Timeclock.unlockMode({ ...data, corporationId });
          break;
        default:
          signInData = await Services.PosKiosk.unlockKioskMode({ ...data, corporationId });
      }

      TokenService.setAccessToken(signInData.accessToken);
      TokenService.setRefreshToken(signInData.refreshToken);

      dispatch(unlockKioskModeAction(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(unlockKioskModeActionError(true));
    } finally {
      dispatch(unlockKioskModeActionLoading(false));
    }
  };
};
