import React, { useCallback, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { Box, Grid, InputAdornment, SvgIcon, TextField } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

import { DeviceActionButton } from 'common/components';
import { DialogComponent } from 'common/components/index';

import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import { CreditCardValidationSchema } from 'common/components/CreditCardData/CreditCardValidationSchema';

import {
  CreditCardItems,
  IAddCreditCard,
  PDCreditCardType,
} from 'common/components/CreditCardData/interfaces';

import { masks } from 'common/constants';
import { getCreditCardIconByPDValue } from 'common/components/CreditCardData/constants';
import { DeviceType } from 'common/constants/scanner';

import { getCardItemMask, getCardType } from 'common/components/CreditCardData/helpers';
import { useRenderIntlMessage } from '../../../../hooks/useRenderIntlMessage';

const CardNumberMaskCustom = ({ inputRef, value, cardType, ...other }: TextMaskCustomProps) => {
  return (
    <MaskedInput
      {...other}
      value={value}
      ref={(reference: any) => {
        inputRef(reference ? reference.inputElement : null);
      }}
      mask={getCardItemMask(CreditCardItems.CardNumber, cardType)}
      guide={false}
      showMask
    />
  );
};

const ExpiredDateMaskCustom = ({ inputRef, ...other }: TextMaskCustomProps) => (
  <MaskedInput
    {...other}
    ref={(reference: any) => {
      inputRef(reference ? reference.inputElement : null);
    }}
    mask={masks.EXPIRED_CARD_DATE}
    placeholderChar={'\u2215'}
    guide={false}
    showMask
  />
);

const CVVCodeMaskCustom = ({ inputRef, value, cardType, ...other }: TextMaskCustomProps) => {
  return (
    <MaskedInput
      {...other}
      value={value}
      ref={(reference: any) => {
        inputRef(reference ? reference.inputElement : null);
      }}
      mask={getCardItemMask(CreditCardItems.CardCVV, cardType)}
      guide={false}
      showMask
    />
  );
};

const initialValues: IAddCreditCard = {
  cardNumber: '',
  cardHolderName: '',
  expiredIn: '',
  code: '',
};

interface TextMaskCustomProps {
  cardType: PDCreditCardType;
  inputRef: (reference: HTMLInputElement | null) => void;
  value?: string;
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  withScanning: boolean;
  onCardAdd: (values: IAddCreditCard) => void;
}

const AddCreditCardModal = ({ isOpen, onClose, withScanning, onCardAdd }: IProps): JSX.Element => {
  const [scannerStr, setScannerStr] = useState<string>();
  const renderIntlMessage = useRenderIntlMessage();

  const { control, handleSubmit, formState, reset, setValue } = useForm<any>({
    defaultValues: initialValues,
    resolver: yupResolver(CreditCardValidationSchema),
    mode: 'onTouched',
  });

  const { errors } = formState;
  // we need to us watch to update correctly error message, currently it always shows PREVIOUS error message
  useWatch({ control, name: 'code' });

  const cardNumberWatcher: string = useWatch({ control, name: 'cardNumber' });
  const cardType: PDCreditCardType = getCardType(cardNumberWatcher);

  const handleClose = () => (onClose ? onClose() : reset(initialValues));

  const onScanningSuccess = useCallback(
    (scannerString: string) => {
      setScannerStr(scannerString);
    },
    [setScannerStr],
  );

  const onSubmit = values => {
    onCardAdd(values);
    handleClose();
  };

  const onCreditCardNumberChange = (e, onChange) => {
    setValue('expiredIn', '');
    setValue('code', '');
    onChange(e.target.value);
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      title={<FormattedMessage {...commonMessages.addCreditCardLabel} />}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      loading={false}
      size="xs"
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controller
            name="cardHolderName"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                value={field.value}
                onChange={field.onChange}
                variant="outlined"
                label={<FormattedMessage {...inputLabels.cardHolderName} />}
                helperText={renderIntlMessage(errors?.cardHolderName?.message)}
                error={!!errors?.cardHolderName}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="cardNumber"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  fullWidth
                  value={field.value}
                  onChange={e => onCreditCardNumberChange(e, field.onChange)}
                  variant="outlined"
                  label={<FormattedMessage {...inputLabels.cardNumber} />}
                  helperText={renderIntlMessage(errors?.cardNumber?.message)}
                  error={!!errors?.cardNumber}
                  InputProps={{
                    inputComponent: CardNumberMaskCustom,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          component={getCreditCardIconByPDValue(cardType)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={8}>
          <Controller
            name="expiredIn"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                value={field.value}
                onChange={field.onChange}
                variant="outlined"
                onBlur={field.onBlur}
                label={<FormattedMessage {...inputLabels.cardExpiredIn} />}
                error={!!errors?.expiredIn}
                helperText={renderIntlMessage(errors?.expiredIn?.message)}
                InputProps={{
                  inputComponent: ExpiredDateMaskCustom,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                type="password"
                value={field.value}
                onChange={field.onChange}
                variant="outlined"
                onBlur={field.onBlur}
                label={<FormattedMessage {...inputLabels.cvvCode} />}
                error={!!errors?.code}
                helperText={renderIntlMessage(errors?.code?.message)}
                InputProps={{
                  inputComponent: CVVCodeMaskCustom,
                  inputProps: { cardType },
                }}
              />
            )}
          />
        </Grid>

        {withScanning && (
          <Grid item xs={12}>
            <DeviceActionButton
              deviceType={DeviceType.MagneticStripe}
              onScanningSuccess={onScanningSuccess}
            />
            {scannerStr && <Box>{scannerStr}</Box>}
          </Grid>
        )}
      </Grid>
    </DialogComponent>
  );
};

export default AddCreditCardModal;
