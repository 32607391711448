import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, Chip, Collapse, Grid, makeStyles, Theme } from '@material-ui/core';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import {
  DurationType,
  DurationTypes,
  PackageCostType,
  PackageTypes,
} from 'modules/services/constants/packages';

import { IBundleView } from 'modules/services/interfaces/packages';

import { AvailabilitiesResultBlock, FieldInfo } from 'common/components';
import { ReactComponent as AngleDownIcon } from 'img/icons/angle-down.svg';
import { ReactComponent as AngleRightIcon } from 'img/icons/angle-right.svg';

import commonMessages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import packageMessages from 'modules/services/messages/packages';
import servicesMessages from 'modules/services/messages/messages';
import useScrollIntoView from 'common/hooks/useScrollIntoView';

const useStyles = makeStyles((theme: Theme) => ({
  sectionBtn: {
    padding: 0,
    color: theme.palette.text.secondary,

    '& svg': {
      width: '0.75rem',
      height: '0.75rem',
      marginRight: theme.spacing(1),
    },
  },
  sectionWrapper: {
    marginTop: theme.spacing(1),
  },
  chip: {
    height: 'initial',
    padding: theme.spacing(0.25, 1),
    marginBottom: theme.spacing(0.5),

    color: theme.palette.primary.contrastText,
    background: theme.palette.darkBackground?.light,
    fontWeight: 700,

    '& .MuiChip-label': {
      padding: 0,
      lineHeight: '1rem',
    },

    '&:not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
  },
  renewalStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    width: '1rem',
    height: '1rem',
    marginRight: theme.spacing(0.5),

    '&.primary': {
      color: theme.palette.primary.main,
    },
    '&.error': {
      color: theme.palette.error.main,
    },
  },
}));

interface IProps {
  bundle: IBundleView;
}

const GeneralInfoView = ({ bundle }: IProps): JSX.Element => {
  const [showContent, setShowContent] = useState<boolean>(true);

  const { scrollRef } = useScrollIntoView(showContent);

  const [timezoneMoment] = useTimezoneMoment();

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();
  const intl = useIntl();

  const customPeriodDescription: string = useMemo(() => {
    if (!bundle?.customDates) return '';

    const {
      customDates: { startDate, endDate },
    } = bundle;

    return `${timezoneMoment(startDate).format('MMM D')} - ${timezoneMoment(endDate).format(
      'MMM D',
    )}`;
  }, [bundle, timezoneMoment]);

  return (
    <Box>
      <Button
        onClick={() => setShowContent(!showContent)}
        startIcon={showContent ? <AngleDownIcon /> : <AngleRightIcon />}
        className={classes.sectionBtn}
      >
        <FormattedMessage {...commonMessages.generalLabel} />
      </Button>

      <Collapse in={showContent} ref={scrollRef}>
        <Grid container spacing={2} className={classes.sectionWrapper}>
          <Grid item xs={4}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.packageType} />}
              description={
                <Box display="flex">
                  {PackageTypes.translate(bundle.type)}
                  {bundle.costType === PackageCostType.Free &&
                    ` (${intl.formatMessage({ ...servicesMessages.freeOption })})`}
                </Box>
              }
            />
          </Grid>

          <Grid item xs={4}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.duration} />}
              description={
                bundle.durationType === DurationType.Custom
                  ? customPeriodDescription
                  : `${bundle.durationNumber} ${renderIntlMessage(
                      DurationTypes.message(bundle.durationType),
                    )}`
              }
            />
          </Grid>

          <Grid item xs={4}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.daysAndHoursForAccess} />}
              description={
                !bundle.dayTimeAvailDtoList?.length ? (
                  <FormattedMessage {...inputLabels.accessAsClub} />
                ) : (
                  <AvailabilitiesResultBlock availabilities={bundle.dayTimeAvailDtoList} />
                )
              }
            />
          </Grid>

          <Grid item xs={12}>
            <FieldInfo
              label={<FormattedMessage {...commonMessages.clubsAvailabilityLabel} />}
              labelVariant="subtitle2"
              labelColor="textPrimary"
              description={
                !bundle.clubs?.length ? (
                  <FormattedMessage {...packageMessages.limitClubsAllAllowed} />
                ) : (
                  undefined
                )
              }
              content={
                bundle.clubs?.length ? (
                  <Box display="flex">
                    {bundle.clubs.map(clubItem => (
                      <Chip key={clubItem.id} label={clubItem.title} className={classes.chip} />
                    ))}
                  </Box>
                ) : (
                  undefined
                )
              }
            />
          </Grid>

          <Grid item xs={12}>
            <FieldInfo
              label={<FormattedMessage {...servicesMessages.renewalsLabel} />}
              description={(bundle.renewalNumber || 0).toString()}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
};

export default GeneralInfoView;
