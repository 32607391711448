// libraries
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { getStepStyles } from '../styleConstants';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { IInvoicePaymentSplit, PaymentsType } from 'common/interfaces/invoices';
import Button from '../../../../Button/Button';
import { FormattedMessage } from 'react-intl';
import commonMessages from '../../../../../messages/messages';
import { getCreditCardIcon } from '../../../../CreditCardData/constants';
import { Add as AddIcon } from '@material-ui/icons';
import CreditCardData from '../../../../CreditCardData/CreditCardData';
import messages from '../../../messages';
import { LoadingBackdrop, PaymentField } from 'common/components';
import { defaultPriceNumberProps } from '../../../../NumberController/NumberController';
import * as actions from 'common/state/invoice/actions';
import inputLabels from '../../../../../messages/inputLabels';
import { PeakModules } from 'common/constants/peakModules';
import {
  selectAddCreditCardLoading,
  selectAddCreditCardResult,
  selectCurrentInvoice,
  selectStoredCreditCardsLoading,
  selectStoredCreditCardsResult,
  selectUpdatedInvoiceLoading,
} from 'common/state/invoice/selectors';
import { CreditCardTypes, IAddCreditCard } from 'common/components/CreditCardData/interfaces';
import { resetUpdateInvoiceWithSync, updateInvoiceWithSync } from 'common/state/invoice/actions';
import { ActionResult } from 'common/constants';
import { PaymentOptionType } from 'common/components/InvoiceOperating/constants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getExpiryDate } from 'common/utils/time';
import { StepContext } from 'common/createContext/stepContext';
import { useUpdatePaymentData } from 'common/components/InvoiceOperating/InvoicePaymentWizard/steps/useUpdatePaymentData';
import { selectCurrentPaymentProcessor } from '../../../../../../modules/authentication/state/selectors';
import { BankCode } from '../../../../../../modules/corporate-settings/interfaces';
import TokenizeCreditCard from '../../../../TokenizeCreditCard/TokenizeCreditCard';
import { snackbar } from '../../../../../utils/snackbarUtils';
import Alert from '../../../../Alert/Alert';
import { AlertTypes } from '../../../../../interfaces/alerts';

interface ICreditCardPaymentStepProps {
  memberId?: string;
  leftToPay: number;
  clubId: string;
  creditCardType: PaymentsType;

  module: PeakModules;
  isPaymentStep?: boolean;
  paymentTypeId?: string;

  onClose: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  ...getStepStyles(theme),
  cardNumber: {
    margin: theme.spacing(0, 1, 0, 1.5),
  },
  addNewBtn: {
    paddingLeft: theme.spacing(0.375),
    paddingRight: theme.spacing(0.375),
  },
  radioLabel: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2.5),
    },
  },
  radio: {
    '&:hover': {
      boxShadow: 'none',
      borderRadius: '50%',
    },
  },
}));

interface IPaymentOption {
  type: PaymentOptionType;
  id?: string;
  lastFour?: string;
  creditCardType?: CreditCardTypes;
  expired?: boolean;
  cardNumber?: string;
  expDate?: string;
}

const defaultPaymentOptions: IPaymentOption[] = [
  { type: PaymentOptionType.PaymentTerminal },
  { type: PaymentOptionType.MagneticStripe },
  { type: PaymentOptionType.ImmediateCreditCardPayment },
];

const CreditCardPaymentStep: React.FC<ICreditCardPaymentStepProps> = (
  props: ICreditCardPaymentStepProps,
): JSX.Element => {
  const classes = useStyles(props);
  const {
    memberId,
    creditCardType,
    isPaymentStep,
    leftToPay,
    onClose,
    paymentTypeId,
    module,
    clubId,
  } = props;

  // local state

  const [paymentOptions, setPaymentOptions] = useState<IPaymentOption[]>(defaultPaymentOptions);
  const [isAddNewCardOpen, setIsAddNewCardOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<IPaymentOption>(paymentOptions[0]);
  const [amountToPay, setAmountToPay] = useState<string | number>(leftToPay);
  const [immediateCardData, setImmediateCardData] = useState<IAddCreditCard | null>(null);
  const [isIFrameModalOpen, setIsIFrameModalOpen] = useState<boolean>(false);

  // global state
  const dispatch = useDispatch();

  const storedCreditCardsResult = useSelector(selectStoredCreditCardsResult);
  const storedCreditCardsLoading = useSelector(selectStoredCreditCardsLoading);
  const addCreditCardLoading = useSelector(selectAddCreditCardLoading);
  const addCreditCardResult = useSelector(selectAddCreditCardResult);
  const currentInvoice = useSelector(selectCurrentInvoice);
  const isUpdatingInvoiceLoading = useSelector(selectUpdatedInvoiceLoading);
  const paymentProcessorType = useSelector(selectCurrentPaymentProcessor);

  const { helperData } = useContext(StepContext);
  const { profileId } = helperData || {};

  const renderIntlMessage = useRenderIntlMessage();

  const isImmediateCreditCardOption =
    selectedOption.type === PaymentOptionType.ImmediateCreditCardPayment;

  // handlers

  const transformImmediateCardData = () => {
    const { expiredIn, ...otherData } = immediateCardData;
    const expireSplit: string[] = expiredIn.split('/');

    return {
      ...otherData,
      number: otherData.cardNumber.replaceAll(' ', ''),
      expirationMonth: expireSplit[0],
      expirationYear: expireSplit[1],
    };
  };

  const handleProceed = (): void => {
    const invoiceData = currentInvoice.toJS();
    const invoicePaymentSplit: IInvoicePaymentSplit = {
      paymentMethodId: paymentTypeId,
      type: creditCardType,
      paymentAmount: amountToPay,
      creditCardPaymentOptionType: selectedOption.type,
      paymentAccountId:
        selectedOption.type === PaymentOptionType.StoredCard ? selectedOption.id : undefined,
      ...(!memberId &&
        isImmediateCreditCardOption && {
          creditCard: transformImmediateCardData(),
        }),
    };

    dispatch(
      updateInvoiceWithSync(
        module,
        clubId,
        invoiceData,
        invoicePaymentSplit,
        profileId,
        isPaymentStep,
      ),
    );
  };

  /* const handleAddCard = (data: IAddCreditCard): void => {
    switch (paymentProcessorType) {
      case PaymentProcessorType.COMMERCEHUB:
        // dispatch(actions.tokenizeCommerceHubEncryptedCard(memberId, data));
        break;
      case PaymentProcessorType.ITRANSACT:
        // dispatch(actions.storeCreditCard(module, memberId, data));
        break;
      case PaymentProcessorType.DISABLED:
      default:
        snackbar.warning(
          <FormattedMessage {...commonMessages.creditCardBankNotConfigured} />,
        );
    }
  }; */

  const handleSubmitTokenize = (data: any): void => {
    switch (paymentProcessorType) {
      case BankCode.COMMERCEHUB:
        dispatch(actions.tokenizeCommerceHubEncryptedCard(memberId, data));
        break;
      case BankCode.ITRANSACT:
        // we do nothing, because we don't have response for the action.
        // ITransact will trigger backend api endpoint with memberId and xid to tokenize cc
        break;
      default:
        snackbar.warning(<FormattedMessage {...commonMessages.creditCardBankNotConfigured} />);
    }

    setIsIFrameModalOpen(false);
  };

  const handleAddImmediateCard = (data: IAddCreditCard): void => {
    setImmediateCardData(data);
    setIsAddNewCardOpen(false);
  };

  // effects
  useEffect(() => {
    dispatch(actions.resetStoredCreditCardsReducer());
  }, [dispatch]);

  useEffect(() => {
    if (memberId) {
      dispatch(actions.fetchStoredCreditCards(module, creditCardType, memberId));
    }
  }, [creditCardType, dispatch, memberId, module]);

  useEffect(() => {
    const cards = storedCreditCardsResult || [];
    setPaymentOptions(prev => [
      ...prev,
      ...cards.map(card => ({
        type: PaymentOptionType.StoredCard,
        creditCardType: card.get('creditCardType'),
        lastFour: card.get('lastFour'),
        expDate: card.get('expDate'),
        expired: card.get('expired'),
        id: card.get('id'),
      })),
    ]);

    return () => setPaymentOptions(defaultPaymentOptions);
  }, [storedCreditCardsResult]);

  useEffect(() => {
    if (addCreditCardResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.fetchStoredCreditCards(module, creditCardType, memberId));
      dispatch(actions.resetStoredCreditCardResult());
      setIsAddNewCardOpen(false);
    }
  }, [addCreditCardResult, creditCardType, dispatch, memberId, module]);

  const resetStep = () => {
    dispatch(resetUpdateInvoiceWithSync());
  };

  useUpdatePaymentData(module, profileId, onClose);

  useEffect(() => {
    return resetStep;
    // Should perform reset only on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // renders

  return (
    <>
      <Grid container spacing={2} className={classes.body}>
        <Grid item sm={12} xs={12}>
          <FormControl component="fieldset" disabled={isAddNewCardOpen}>
            <RadioGroup aria-label="cardPaymentSelector" name="cardPaymentSelector">
              {paymentOptions.map(option => (
                <>
                  {option.type === PaymentOptionType.PaymentTerminal && (
                    <FormControlLabel
                      key={PaymentOptionType.PaymentTerminal}
                      className={classes.radioLabel}
                      control={<Radio className={classes.radio} color="primary" />}
                      checked={option === selectedOption}
                      onClick={() => setSelectedOption(option)}
                      label={<FormattedMessage {...messages.payViaTerminal} />}
                    />
                  )}

                  {option.type === PaymentOptionType.MagneticStripe && (
                    <FormControlLabel
                      key={PaymentOptionType.MagneticStripe}
                      className={classes.radioLabel}
                      control={<Radio className={classes.radio} color="primary" />}
                      checked={option === selectedOption}
                      onClick={() => setSelectedOption(option)}
                      label={<FormattedMessage {...messages.payWithMagneticStripe} />}
                    />
                  )}

                  {option.type === PaymentOptionType.StoredCard && (
                    <FormControlLabel
                      key={option.id}
                      value={option}
                      disabled={option.expired}
                      checked={option === selectedOption}
                      onClick={() => !option.expired && setSelectedOption(option)}
                      className={classes.radioLabel}
                      control={
                        <Radio
                          className={classes.radio}
                          color="primary"
                          disabled={option.expired}
                        />
                      }
                      label={
                        <Box key={option.id} display="flex" alignItems="center">
                          <SvgIcon
                            fontSize="small"
                            component={getCreditCardIcon(option.creditCardType)}
                          />
                          <Typography
                            className={classes.cardNumber}
                            color={option.expired ? 'error' : 'initial'}
                          >
                            {`**** ${option.lastFour} ${getExpiryDate(option.expDate)} ${
                              option.expired ? renderIntlMessage(commonMessages.expired) : ''
                            }`}
                          </Typography>
                        </Box>
                      }
                    />
                  )}

                  {option.type === PaymentOptionType.ImmediateCreditCardPayment && !memberId && (
                    <FormControlLabel
                      key={option.id}
                      value={option}
                      disabled={option.expired}
                      checked={option === selectedOption}
                      onClick={() => !option.expired && setSelectedOption(option)}
                      className={classes.radioLabel}
                      control={
                        <Radio
                          className={classes.radio}
                          color="primary"
                          disabled={option.expired}
                        />
                      }
                      label={`${renderIntlMessage(
                        messages.paymentOptionTypeViaImmediateCreditCard,
                      )} ${
                        immediateCardData ? `(**** ${immediateCardData.cardNumber.slice(-4)})` : ''
                      }`}
                    />
                  )}
                </>
              ))}
            </RadioGroup>
          </FormControl>
          <LoadingBackdrop isLoading={storedCreditCardsLoading} />
        </Grid>

        {memberId && (
          <Grid item xs={12}>
            {!paymentProcessorType ? (
              <Alert
                title={<FormattedMessage {...commonMessages.creditCardBankNotConfigured} />}
                severity={AlertTypes.Warning}
              />
            ) : (
              <Button
                color="primary"
                startIcon={<AddIcon />}
                className={classes.addNewBtn}
                // onClick={() => setIsAddNewCardOpen(true)}
                onClick={() => setIsIFrameModalOpen(true)}
              >
                <FormattedMessage {...commonMessages.addCardBtn} />
              </Button>
            )}

            {/* TODO remove probably some time later, if we will not enter credit card data except iFrame */}
            {/* {isAddNewCardOpen ? ( */}
            {/*  <CreditCardData */}
            {/*    onClose={() => setIsAddNewCardOpen(false)} */}
            {/*    onCardAdd={handleAddCard} */}
            {/*  /> */}
            {/* ) : ( */}
            {/*  <Button */}
            {/*    color="primary" */}
            {/*    startIcon={<AddIcon />} */}
            {/*    className={classes.addNewBtn} */}
            {/*    // onClick={() => setIsAddNewCardOpen(true)} */}
            {/*    onClick={() => setIsIFrameModalOpen(true)} */}
            {/*  > */}
            {/*    <FormattedMessage {...commonMessages.addCardBtn} /> */}
            {/*  </Button> */}
            {/* )} */}

            {isIFrameModalOpen && (
              <TokenizeCreditCard
                memberId={memberId}
                isOpen={isIFrameModalOpen}
                onClose={() => setIsIFrameModalOpen(false)}
                onSubmit={data => handleSubmitTokenize(data)}
              />
            )}

            <LoadingBackdrop isLoading={addCreditCardLoading} />
          </Grid>
        )}

        <Grid item xs={12}>
          {isImmediateCreditCardOption && !immediateCardData && (
            <CreditCardData onCardAdd={handleAddImmediateCard} withScanning />
          )}
        </Grid>

        <Grid item xs={12}>
          <PaymentField
            fullWidth
            variant="outlined"
            defaultValue={leftToPay}
            value={amountToPay}
            onChange={setAmountToPay}
            onBlur={setAmountToPay}
            label={<FormattedMessage {...inputLabels.amountToPay} />}
            numberFormatProps={{
              ...defaultPriceNumberProps,
              max: leftToPay,
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.footerActions}>
        <Grid item sm={12} xs={12}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            disabled={
              isUpdatingInvoiceLoading || (isImmediateCreditCardOption && !immediateCardData)
            }
            onClick={handleProceed}
          >
            <FormattedMessage {...commonMessages.proceedBtn} />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CreditCardPaymentStep;
