import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { EditorState, convertToRaw } from 'draft-js';
import { FormattedMessage } from 'react-intl';
import { Box, Grid, makeStyles, MenuItem, TextField } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';

import { FormContainer, Select } from 'common/components';
import inputLabels from 'common/messages/inputLabels';
import { TemplateEditor } from 'modules/services/components';
import { IDocumentTemplateDtoImt } from 'common/interfaces/documentTemplate';
import { TemplatePurpose, TemplateTypes } from 'common/constants/documentTemplate';
import { ActiveInactiveStatus } from 'common/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      '& .scrollBox>div': {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        '&>.MuiCardContent-root': {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
    inputName: {
      marginBottom: theme.spacing(2),
    },
    submitBtn: {
      marginLeft: theme.spacing(3),
    },
  }),
);

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .max(120, 'Name must be 120 characters or less')
    .required('Required field'),
  hasText: yup.bool().oneOf([true], 'Required field'),
});

interface ITemplateFormValues {
  name: string;
  status: boolean;
  hasText: boolean;
}

interface ITemplateFormProps {
  template?: IDocumentTemplateDtoImt;
  defaultEditor?: any;
  title: JSX.Element;
  isLoading: boolean;
  onCancel: () => void;
  onSave: (name) => void;
}

export default function TemplateForm({
  template,
  defaultEditor,
  title,
  isLoading,
  onCancel,
  onSave,
}: ITemplateFormProps): JSX.Element {
  const [editor, changeEditor] = useState(defaultEditor || EditorState.createEmpty());
  const classes = useStyles();
  const location = useLocation();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ITemplateFormValues>({
    defaultValues: {
      name: template ? template.get('title') : '',
      status: Boolean(template && template.get('active')),
      hasText: Boolean(template && template.get('content')),
    },
    resolver: yupResolver(validationSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'onBlur',
  });

  const getPurposeType = () => {
    const isContractTemplatesRoute = location.pathname.includes('/contract');

    return isContractTemplatesRoute
      ? TemplatePurpose.ServiceContractTemplate
      : TemplatePurpose.ServiceWaiverTemplate;
  };

  const onHandleSubmit = (values: ITemplateFormValues) => {
    onSave({
      title: values.name,
      content: draftToHtml(convertToRaw(editor.getCurrentContent())),
      active: values.status,
      purposeType: getPurposeType(),
      attachments: [],
      type: TemplateTypes.Document,
    });
  };

  return (
    <Box className={classes.root}>
      <FormContainer
        title={title}
        maxWidth={620}
        onSubmit={handleSubmit(onHandleSubmit)}
        onCancel={onCancel}
        isSubmitting={isLoading}
      >
        <Box flex={1} display="flex" flexDirection="column">
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    className={classes.inputName}
                    fullWidth
                    variant="outlined"
                    autoComplete="none"
                    onChange={onChange}
                    label={<FormattedMessage {...inputLabels.name} />}
                    value={value}
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="status"
                control={control}
                render={({ field: { name, value, onChange, onBlur } }) => (
                  <Select
                    fullWidth
                    label={<FormattedMessage {...inputLabels.status} />}
                    variant="outlined"
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  >
                    {ActiveInactiveStatus.map(item => (
                      <MenuItem key={item.key} value={`${item.value}`}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
          </Grid>
          <Controller
            // this value is used to validate the DraftEditor text field
            name="hasText"
            control={control}
            render={({ field: { onChange, onBlur } }) => (
              <TemplateEditor
                editorState={editor}
                onChangeEditorState={value => {
                  onChange(value.getCurrentContent().hasText());
                  changeEditor(value);
                }}
                onBlur={onBlur}
                hasError={!!errors.hasText}
                helperText={errors?.hasText?.message}
                hasHiddenAddContractLinkMenu
                hasHiddenAddContractNameMenu
                hasHiddenAddWaiverLinkMenu
                hasHiddenAddWaiverNameMenu
              />
            )}
          />
        </Box>
      </FormContainer>
    </Box>
  );
}
