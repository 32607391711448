import { ICheckIn, ICheckInResponse } from 'modules/front-desk/interfaces';
import { IAddress } from 'common/interfaces/common';

export interface CheckInSocketDto {
  corporationId: string;
  clubId: string;
  clubEntranceId: string;
  checkInId: string;
  kidZone: boolean;
}

export const mapCheckInDto = (item: ICheckInResponse): ICheckIn => {
  return {
    id: item.id,
    alerts: item.alerts,
    checkInTime: item.date,
    checkInType: item.type,
    birthday: null,
    entranceId: item.entrance.id,
    personId: item.customer.id,
    imageUrl: item.customer.imageUrl,
    firstName: item.customer.firstName,
    lastName: item.customer.lastName,
    personType: item.customer.type,
    barcode: item.customer.barcode,
    personalCode: item.customer.personalCode,
    type: item.customer.type,
    serviceLimitation: item.serviceLimitation ?? null,
  };
};

export const mapToAddressLine = (address: IAddress) => {
  const { line1, line2, city, zipCode, country, state } = address;

  const addressParts = [];
  if (line1) {
    addressParts.push(line1);
  }

  if (line2) {
    addressParts.push(line2);
  }

  if (city) {
    addressParts.push(city);
  }

  if (country && country.title) {
    addressParts.push(country.title);
  }

  if (zipCode) {
    addressParts.push(zipCode);
  }

  if (state && state.title) {
    addressParts.push(state.title);
  }

  return addressParts.join(', ');
};
