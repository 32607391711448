import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Grid } from '@material-ui/core';
import { DialogComponent } from 'common/components/index';
import { useDispatch, useSelector } from 'react-redux';

// components
import TimeClockJobPositionList from '../TimeclockJobPositionList/TimeclockJobPositionList';
import PersonSimpleView from 'common/components/PersonSimpleView/PersonSimpleView';
import { ActionResult } from 'common/constants';

// interfaces
import { IClockedInEmployee } from 'modules/timeclock/interfaces/timeclock';
import { ITimeClockEmployeeJobPosition } from 'common/interfaces/employee';

// state
import {
  clockInEmployeePositionAction,
  getEmployeeTimeclockJobPositions,
} from 'modules/timeclock/state/actions';
import {
  selectClockedInActionResult,
  selectTimeclockJobPositions,
} from 'modules/timeclock/state/selectors';

// messages
import commonMessages from 'common/messages/messages';
import timeclockMessages from 'modules/timeclock/messages';

interface IProps {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  clockedInEmployee: IClockedInEmployee;
}

const ClockInWithEmployeeAndPositionsModal = ({
  isOpen,
  isLoading = false,
  onClose,
  clockedInEmployee,
}: IProps): JSX.Element => {
  const dispatch = useDispatch();

  const jobPositions: ITimeClockEmployeeJobPosition[] = useSelector(selectTimeclockJobPositions);
  const clockedInResult: ActionResult = useSelector(selectClockedInActionResult);

  useEffect(() => {
    dispatch(getEmployeeTimeclockJobPositions(String(clockedInEmployee.id)));
  }, [dispatch, clockedInEmployee]);

  useEffect(() => {
    if (clockedInResult === ActionResult.SUCCESS_ACTION) {
      dispatch(clockInEmployeePositionAction(null));
      dispatch(getEmployeeTimeclockJobPositions(String(clockedInEmployee.id)));
    }
  }, [dispatch, clockedInEmployee, clockedInResult]);

  return (
    <DialogComponent
      isOpen={isOpen}
      loading={isLoading}
      onClose={onClose}
      title={<FormattedMessage {...timeclockMessages.clockInEmployeeBtn} />}
      hasCustomFooter
      size="xs"
      supressBottomShadow
      hasShadowsOnScroll={false}
    >
      <Box marginBottom={2}>
        <PersonSimpleView
          bold
          firstName={clockedInEmployee.firstName}
          lastName={clockedInEmployee.lastName}
          imageUrl={clockedInEmployee.imageUrl}
        />
      </Box>
      <TimeClockJobPositionList
        jobPositions={jobPositions}
        employeeId={String(clockedInEmployee.id)}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button color="primary" fullWidth variant="text" onClick={onClose}>
            <FormattedMessage {...commonMessages.cancelBtn} />
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" fullWidth color="primary" onClick={onClose}>
            <FormattedMessage {...commonMessages.confirmBtn} />
          </Button>
        </Grid>
      </Grid>
    </DialogComponent>
  );
};

export default ClockInWithEmployeeAndPositionsModal;
