import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentAdapter from '@date-io/moment';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getRequiredMessage } from 'common/constants/globalConstants';

import { AdaptiveDatePicker, DialogComponent } from 'common/components';

import inputLabels from 'common/messages/inputLabels';
import menuItems from 'common/messages/menuItems';

// TODO - PRM-1810 tmp form interface
interface IFormValues {
  date: string | null;
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (date: string) => void;
  isAddDOBActionLoading: boolean;
}

const ValidationSchema = yup.object().shape({
  date: yup
    .string()
    .nullable()
    .required(getRequiredMessage),
});

const AddDateOfBirthdayModal = ({
  isOpen,
  onClose,
  onSubmit,
  isAddDOBActionLoading,
}: IProps): JSX.Element => {
  const formMethods = useForm<IFormValues>({
    defaultValues: { date: null },
    resolver: yupResolver(ValidationSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'all',
  });

  const { control, formState, handleSubmit } = formMethods;
  const { errors } = formState;

  const renderIntlMessage = useRenderIntlMessage();

  const handleSubmitForm = formValues => {
    onSubmit(formValues.date);
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage {...menuItems.addDOB} />}
      onSubmit={handleSubmit(handleSubmitForm)}
      loading={isAddDOBActionLoading}
    >
      <form>
        <MuiPickersUtilsProvider utils={MomentAdapter}>
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <AdaptiveDatePicker
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                disableFuture
                label={<FormattedMessage {...inputLabels.dateOfBirth} />}
                error={!!errors.date}
                helperText={renderIntlMessage(errors.date?.message)}
              />
            )}
          />
        </MuiPickersUtilsProvider>
      </form>
    </DialogComponent>
  );
};

export default AddDateOfBirthdayModal;
