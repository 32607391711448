import React from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentAdapter from '@date-io/moment';

import { INamedEntity, INamedEntityImt } from 'common/interfaces/common';

import AvailabilityCard from './AvailabilityCard/AvailabilityCard';
import ResourceButton from '../ResourceButton/ResourceButton';

import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  rootTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rootTitle: {
    color: theme.palette.secondary.main,
    opacity: 0.4,
    lineHeight: 'bold',
  },
  availabilityCard: {
    marginBottom: theme.spacing(1.5),
  },
  availabilityCardContent: {
    backgroundColor: theme.palette.background.default,
  },
  dayBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    border: '1px solid  #f3f3f3',
    backgroundColor: 'white',
  },
  dayBoxSelected: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  dayBoxLabel: {
    fontSize: 18,
    fontWeight: 'bold',
  },
}));

interface IProps {
  clubs: ImmutableList<INamedEntityImt>;
  formMethods: UseFormReturn<any>;
  occupiedClubs: INamedEntity[];
  setOccupiedClubs: (clubs) => void;
}

const defaultAvailability = {
  startDate: null,
  endDate: null,
  clubs: [],
  dayTimeAvails: [],
  weekdays: [],
};

const AvailabilitiesSection = ({
  clubs,
  formMethods,
  setOccupiedClubs,
  occupiedClubs,
}: IProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const { control } = formMethods;

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'availability',
    keyName: 'availKey',
  });

  // renders

  return (
    <MuiPickersUtilsProvider utils={MomentAdapter}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box className={classes.rootTitleContainer}>
              <Typography variant="button" className={classes.rootTitle}>
                <FormattedMessage {...commonMessages.clubsAvailabilityLabel} />
              </Typography>
              <ResourceButton
                noWrap
                label={intl.formatMessage({ ...commonMessages.availabilityBtn })}
                ButtonProps={{
                  color: 'primary',
                  startIcon: <AddIcon />,
                }}
                onClick={() =>
                  // Regular append don't work for item with nested array appending
                  // for some reason. This was taken from RHF lib creator csb
                  {
                    append(defaultAvailability);
                  }
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {fields.map((availability, availabilityIndex) => (
              <AvailabilityCard
                key={availability.availKey}
                availabilityIndex={availabilityIndex}
                clubs={clubs}
                occupiedClubs={occupiedClubs}
                setOccupiedClubs={setOccupiedClubs}
                availability={availability}
                disableRemoveCard={fields.length === 1}
                remove={remove}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default React.memo(AvailabilitiesSection);
