import React from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { AddressBlock, PhoneNumberInput } from 'common/components/index';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

const ContactSection = (): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();
  const { control } = useFormContext();

  return (
    <AddressBlock profileMode name="address">
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name="phone"
          render={({ field, fieldState }) => (
            <PhoneNumberInput
              error={!!fieldState.error}
              helperText={renderIntlMessage(fieldState.error?.message)}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
            />
          )}
        />
      </Grid>
    </AddressBlock>
  );
};

export default ContactSection;
