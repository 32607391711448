import React from 'react';
import { List as ImmutableList } from 'immutable';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField, Typography } from '@material-ui/core';

import SectionTitle from '../SectionTitle/SectionTitle';
import { INamedEntityImt } from 'common/interfaces/common';
import { FormattedMessage } from 'react-intl';
import messages from 'common/components/PersonProfile/messages';

interface IMoreInfoSectionProps {
  title: JSX.Element;
  additionalFields: ImmutableList<INamedEntityImt>;
}

const MoreInfoSection = (props: IMoreInfoSectionProps): JSX.Element => {
  const { title, additionalFields } = props;

  const { control } = useFormContext();

  return (
    <Grid container spacing={1}>
      {title && (
        <Grid item xs={12}>
          <SectionTitle title={title} />
        </Grid>
      )}

      {additionalFields.map((field: INamedEntityImt, index) => (
        <Grid key={field.get('id')} item xs={12}>
          <Controller
            defaultValue=""
            name={`additionalFields.${index}.value`}
            control={control}
            render={({ field: { value, onBlur, onChange } }) => (
              <TextField
                variant="outlined"
                label={field.get('title')}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fullWidth
              />
            )}
          />

          <Controller
            defaultValue=""
            name={`additionalFields.${index}.id`}
            control={control}
            render={() => <></>}
          />
        </Grid>
      ))}

      {!additionalFields.size && (
        <Grid item xs={12}>
          <Typography align="left" variant="h6" component="p" color="textSecondary">
            <FormattedMessage {...messages.noAdditionalFields} />
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default MoreInfoSection;
