// libraries
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormContext, useWatch, Controller } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
// components
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  MultipleSelect,
  TextArea,
  TextFieldWithReset,
  ZipCodeMaskedInput,
} from 'common/components/index';
// messages
import messages from 'common/components/PersonProfile/messages';
import inputLabels from 'common/messages/inputLabels';
// hooks
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
// constants
import { CancelMembershipData } from '../../../interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { IStateItemImt } from 'common/interfaces/common';
import { List as ImmutableList } from 'immutable';
import { DictionaryList } from 'common/constants';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { EndReasonActionTypes } from 'modules/dictionaries/interfaces/interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reasonWrapper: {
      marginTop: theme.spacing(2),
    },
    groupLabel: {
      marginBottom: theme.spacing(1),
      fontSize: '1rem',
    },
    radioOption: {
      marginTop: theme.spacing(2),
    },
  }),
);

const NotInTermReasonStep = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const statesList: ImmutableList<IStateItemImt> = useSelector(
    selectDictionaryList(DictionaryList.END_REASON),
  );

  const { control, formState, setValue } = useFormContext<CancelMembershipData>();
  const { errors } = formState;
  const watchReason = useWatch({ control, name: 'endReason' });
  const renderIntlMessage = useRenderIntlMessage();

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.END_REASON));
  }, [dispatch]);

  // form

  return (
    <FormControl
      fullWidth
      component="fieldset"
      error={!!errors.endReasonId}
      className={classes.reasonWrapper}
    >
      <Box mb={1}>
        <Typography variant="h4" className={classes.groupLabel}>
          <FormattedMessage {...messages.cancelMembershipModalCancellationReasonQuestion} />
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Controller
            name="endReason"
            control={control}
            render={({ field }) => (
              <MultipleSelect
                disableTagsRender
                disableClearable
                onChange={v => {
                  field.onChange(v);
                  setValue('endReasonId', v.id);
                }}
                label={<FormattedMessage {...inputLabels.state} />}
                value={field.value}
                options={statesList?.toJS() || []}
                fullWidth
                error={!!errors.endReason}
                helperText={renderIntlMessage(errors.endReason) || ''}
              />
            )}
          />

          {errors.endReasonId?.message && (
            <FormHelperText>{renderIntlMessage(errors.endReasonId?.message)}</FormHelperText>
          )}
        </Grid>

        <Grid item sm={6} xs={12}>
          {watchReason?.actionType === EndReasonActionTypes.ZIP_CODE && (
            <Controller
              name="newZipCode"
              control={control}
              render={({ field }) => (
                <TextFieldWithReset
                  variant="outlined"
                  label={<FormattedMessage {...inputLabels.newZipCode} />}
                  fullWidth
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!errors.newZipCode}
                  helperText={renderIntlMessage(errors.newZipCode?.message)}
                  InputProps={{
                    inputComponent: ZipCodeMaskedInput,
                  }}
                />
              )}
            />
          )}
          {watchReason?.actionType === EndReasonActionTypes.MD_LICENSE && (
            <Controller
              name="mdLicense"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label={<FormattedMessage {...inputLabels.mdLicence} />}
                  variant="outlined"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!errors.mdLicense}
                  helperText={renderIntlMessage(errors.mdLicense?.message)}
                />
              )}
            />
          )}
          {watchReason?.actionType === EndReasonActionTypes.NOTE_CODE && (
            <Controller
              name="note.text"
              control={control}
              render={({ field }) => (
                <TextArea
                  fullWidth
                  minRows={8}
                  variant="outlined"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  maxSymbols={1000}
                  label={<FormattedMessage {...inputLabels.note} />}
                  error={!!errors.note?.text}
                  helperText={renderIntlMessage(errors.note?.text?.message)}
                />
              )}
            />
          )}
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default React.memo(NotInTermReasonStep);
