import { PoliciesPermissionSetItems } from '../constants/permissions';
import usePermissionContext from './context/usePermissionContext';
import useInitial from './useInitial';

export default function useHasPermissions(): (
  permissions: PoliciesPermissionSetItems[],
) => boolean {
  const permissionEntries = useInitial(() => Object.entries(PoliciesPermissionSetItems));
  const permissionContext = usePermissionContext();

  return (permissions: PoliciesPermissionSetItems[]): boolean => {
    let hasMenuItemPermission = false;

    for (const permission of permissions) {
      let pKey: string | null = null;

      for (const [key, val] of permissionEntries) {
        if (val === permission) {
          pKey = key;
          break;
        }
      }

      if (pKey && pKey in permissionContext && permissionContext[pKey]) {
        hasMenuItemPermission = true;
        break;
      }
    }

    return hasMenuItemPermission;
  };
}
