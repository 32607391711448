import React from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import CheckBoxWithReset from '../CheckBoxWithReset/CheckBoxWithReset';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: '0 0 4px 4px',
      padding: theme.spacing(0.5, 1.25, 0.75, 1.25),
      backgroundColor: theme.palette.background.default,
    },
    label: {
      display: 'flex',
      '& .MuiFormControlLabel-label': {
        fontSize: '0.625rem',
        paddingTop: 1,
      },
      '& .MuiIconButton-label span': {
        height: theme.spacing(1.5),
        width: theme.spacing(1.5),
      },
      '& .MuiIconButton-label svg': {
        height: theme.spacing(2),
        width: theme.spacing(2),
      },
    },
  }),
);

type CheckBoxWrapProps = {
  name?: string;
  label?: string | JSX.Element;
  control: Control<any>;
  children: React.ReactChild | React.ReactChild[];
  show?: boolean;
  disabled?: boolean;
  className?: string;
  resetValue?: boolean | null;
  defaultValue?: unknown;
  trigger?: (name?: string | string[]) => Promise<boolean>;
  additionalTriggeredFields?: string | string[];
};

const CheckBoxWrap = ({
  label,
  children,
  control,
  name,
  show = true,
  disabled,
  className,
  resetValue,
  defaultValue,
  trigger,
  additionalTriggeredFields,
}: CheckBoxWrapProps): JSX.Element => {
  const classes = useStyles();

  // handlers

  const handleChange = (e, onChange: (value: string | number) => void) => {
    onChange(e);
    if (trigger && additionalTriggeredFields) {
      // need to do so because trigger will send validation when min/max not registered yet and will result in an error
      setTimeout(() => trigger(additionalTriggeredFields), 0);
    }
  };

  // renders

  return (
    <Box>
      <Grid container direction="row" className={className}>
        {children}
      </Grid>
      {show && (
        <Box className={classes.container}>
          <Controller
            render={({ field }) => (
              <CheckBoxWithReset
                value={field.value}
                onChange={e => handleChange(e, field.onChange)}
                onResetValue={resetValue !== undefined ? field.onChange : undefined}
                label={label}
                resetValue={resetValue}
                disabled={disabled}
                className={classes.label}
                isCheckBoxWrap
                onBlur={field.onBlur}
              />
            )}
            control={control}
            defaultValue={defaultValue}
            name={name || ''}
          />
        </Box>
      )}
    </Box>
  );
};

export default CheckBoxWrap;
