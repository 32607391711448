/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutableRefObject, useEffect, useImperativeHandle } from 'react';
import { UseFormTrigger } from 'react-hook-form';

interface IFormDataPromise {
  isValidForm: boolean;
  formData: any;
}

interface IReturn {
  onSubmit: (values) => void;
  onNext: () => Promise<boolean>;
  onBack: () => Promise<boolean>;
  triggerWithData: () => Promise<IFormDataPromise>;
  handleStepChange: (formData, formError: boolean) => void;
}

export const useStepsLogic = (
  stepSectionName: string,
  ref: MutableRefObject<any> | ((instance: unknown) => void),
  getValues: () => Record<string, any>,
  trigger: UseFormTrigger<any>,
  onPackageSubmit: (data, errors?) => void,
  handleChange: (data, errors?) => void,
  submitAttempted: boolean,
): IReturn => {
  const triggerWithData = (): Promise<IFormDataPromise> => {
    const formData = getValues();

    return trigger().then((isValidForm: boolean) => {
      return { isValidForm, formData };
    });
  };

  const handleStepChange = (formData, formError: boolean) => {
    handleChange({ [stepSectionName]: formData }, { [stepSectionName]: formError });
  };

  const onNext = async (): Promise<boolean> => {
    return triggerWithData().then(({ isValidForm, formData }) => {
      handleStepChange(formData, !isValidForm);
      return isValidForm;
    });
  };

  const onBack = async (): Promise<boolean> => {
    return triggerWithData().then(({ isValidForm, formData }) => {
      handleStepChange(formData, !isValidForm);
      return isValidForm;
    });
  };

  const onSubmit = async () => {
    await triggerWithData().then(({ isValidForm, formData }) => {
      onPackageSubmit({ [stepSectionName]: formData }, { [stepSectionName]: !isValidForm });
    });
  };

  useImperativeHandle(ref, () => ({
    getFormData() {
      return triggerWithData().then(({ isValidForm, formData }) => ({
        formData: { [stepSectionName]: formData },
        errors: { [stepSectionName]: !isValidForm },
      }));
    },
  }));

  useEffect(() => {
    if (submitAttempted) {
      void trigger();
    }
  }, [submitAttempted, trigger]);

  return { onSubmit, onNext, onBack, triggerWithData, handleStepChange };
};
