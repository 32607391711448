import React from 'react';
import { FormControlLabel, Grid, Typography } from '@material-ui/core';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import messages from 'modules/services/messages/messages';
import {
  CreditCardFeeUnitsSymbols,
  defaultBillingOption,
} from 'modules/services/constants/packages';
import { Checkbox, NumberTextField, Select } from 'common/components';
import { makeStyles } from '@material-ui/core/styles';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { IBillingOption } from '../../../../interfaces/packages';

const useStyles = makeStyles(() => ({
  alignItemsFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontWeight: 500,
  },
}));

interface ICreditCardServiceFee {
  formMethods: UseFormReturn<IBillingOption>;
}

const CreditCardServiceFee = ({ formMethods }: ICreditCardServiceFee): JSX.Element => {
  const classes = useStyles();
  const renderIntlMessage = useRenderIntlMessage();

  const {
    control,
    watch,
    formState: { errors },
  } = formMethods;

  const enabled = watch('creditCardServiceFee.enabled');

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="creditCardServiceFee.enabled"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                label={
                  <Typography className={classes.label}>
                    <FormattedMessage {...messages.creditCardFeeLabel} />
                  </Typography>
                }
                control={
                  <Checkbox
                    size="small"
                    checked={value}
                    onChange={e => onChange(e.target.checked)}
                  />
                }
              />
            )}
          />
        </Grid>
        {enabled && (
          <>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="creditCardServiceFee.value"
                defaultValue={defaultBillingOption.creditCardServiceFee.value}
                render={({ field: { value, onChange } }) => (
                  <NumberTextField
                    numberFormatProps={{
                      decimalScale: 2,
                      fixedDecimalScale: true,
                      allowLeadingZeros: false,
                      allowNegative: false,
                      isNumericString: true,
                      maxLength: 8,
                      type: 'text',
                    }}
                    variant="outlined"
                    label={<FormattedMessage {...messages.creditCardFeeValue} />}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    error={!!errors.creditCardServiceFee?.value}
                    helperText={renderIntlMessage(errors.creditCardServiceFee?.value?.message)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="creditCardServiceFee.unit"
                control={control}
                defaultValue={defaultBillingOption.creditCardServiceFee.unit}
                render={({ field: { name, value, onChange, onBlur } }) => (
                  <Select
                    fullWidth
                    label={<FormattedMessage {...messages.creditCardFeeUnit} />}
                    variant="outlined"
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.creditCardServiceFee?.unit}
                    helperText={renderIntlMessage(errors.creditCardServiceFee?.unit?.message)}
                  >
                    {CreditCardFeeUnitsSymbols.getSelectOptions()}
                  </Select>
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CreditCardServiceFee;
