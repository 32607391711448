import React, { useCallback, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
// interfaces
import {
  CreditCardItems,
  IAddCreditCard,
  PDCreditCardType,
} from 'common/components/CreditCardData/interfaces';
// constants
import { masks } from 'common/constants';
import { getCreditCardIconByPDValue } from 'common/components/CreditCardData/constants';
import { DeviceType } from 'common/constants/scanner';
// utils
import { getCardItemMask, getCardType } from 'common/components/CreditCardData/helpers';
// components
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';
import { ReactComponent as CloseIcon } from 'img/icons/times.svg';
import { DeviceActionButton } from 'common/components';
// messages
import inputLabels from 'common/messages/inputLabels';

import { CreditCardValidationSchema } from 'common/components/CreditCardData/CreditCardValidationSchema';
import { useRenderIntlMessage } from '../../hooks/useRenderIntlMessage';
import { getString } from 'common/utils/typeUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    numberAndCardWrapper: {
      maxWidth: '205px',
      width: '100%',
    },
    dateAndCodeWrapper: {
      maxWidth: '70px',
      width: '100%',
    },
    actionButton: {
      padding: theme.spacing(1),
    },
  }),
);

const CardNumberMaskCustom = ({ inputRef, value, ...other }: TextMaskCustomProps) => {
  const card = getCardType(value);

  return (
    <MaskedInput
      {...other}
      value={value}
      ref={(reference: any) => {
        inputRef(reference ? reference.inputElement : null);
      }}
      mask={getCardItemMask(CreditCardItems.CardNumber, card)}
      guide={false}
      showMask
    />
  );
};

const ExpiredDateMaskCustom = ({ inputRef, ...other }: TextMaskCustomProps) => (
  <MaskedInput
    {...other}
    ref={(reference: any) => {
      inputRef(reference ? reference.inputElement : null);
    }}
    mask={masks.EXPIRED_CARD_DATE}
    placeholderChar={'\u2215'}
    guide={false}
    showMask
  />
);

const CVVCodeMaskCustom = ({ inputRef, value, cardType, ...other }: TextMaskCustomProps) => {
  return (
    <MaskedInput
      {...other}
      value={value}
      ref={(reference: any) => {
        inputRef(reference ? reference.inputElement : null);
      }}
      mask={getCardItemMask(CreditCardItems.CardCVV, cardType)}
      guide={false}
      showMask
    />
  );
};

interface IProps {
  withScanning?: boolean;
  onClose?: () => void;
  onCardAdd: (values: IAddCreditCard) => void;
}

interface TextMaskCustomProps {
  cardType: PDCreditCardType;
  inputRef: (reference: HTMLInputElement | null) => void;
  value?: string;
}

const initialValues: IAddCreditCard = {
  cardNumber: '',
  cardHolderName: '',
  expiredIn: '',
  code: '',
};

const CreditCardData = ({ withScanning, onClose, onCardAdd }: IProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  const [scannerStr, setScannerStr] = useState<string>();

  const renderIntlMessage = useRenderIntlMessage();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<any>({
    defaultValues: initialValues,
    resolver: yupResolver(CreditCardValidationSchema),
    mode: 'onTouched',
  });

  // we need to us watch to update correctly error message, currently it always shows PREVIOUS error message
  useWatch({ control, name: 'code' });

  const cardNumberWatcher: string = useWatch({ control, name: 'cardNumber' });
  const cardType: PDCreditCardType = getCardType(cardNumberWatcher);

  const handleClose = () => (onClose ? onClose() : reset(initialValues));

  const onScanningSuccess = useCallback(
    (scannerString: string) => {
      setScannerStr(scannerString);
    },
    [setScannerStr],
  );

  const onCreditCardNumberChange = (e, onChange) => {
    setValue('expiredIn', '');
    setValue('code', '');
    onChange(e.target.value);
  };

  return (
    <Grid container spacing={1}>
      <Grid item className={classes.numberAndCardWrapper}>
        <Controller
          name="cardHolderName"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              value={field.value}
              onChange={field.onChange}
              variant="outlined"
              size="small"
              placeholder={intl.formatMessage({ ...inputLabels.cardHolderName })}
              helperText={getString(errors?.cardHolderName?.message)}
              error={!!errors?.cardHolderName}
            />
          )}
        />
      </Grid>

      <Grid item className={classes.numberAndCardWrapper}>
        <Controller
          name="cardNumber"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <TextField
                fullWidth
                value={value}
                onChange={e => onCreditCardNumberChange(e, onChange)}
                variant="outlined"
                size="small"
                placeholder={intl.formatMessage({ ...inputLabels.cardNumber })}
                helperText={renderIntlMessage(errors?.cardNumber?.message)}
                error={!!errors?.cardNumber}
                InputProps={{
                  inputComponent: CardNumberMaskCustom,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" component={getCreditCardIconByPDValue(cardType)} />
                    </InputAdornment>
                  ),
                }}
              />
            );
          }}
        />
      </Grid>

      <Grid item className={classes.dateAndCodeWrapper}>
        <Controller
          name="expiredIn"
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <TextField
              fullWidth
              value={value}
              onChange={onChange}
              variant="outlined"
              size="small"
              onBlur={onBlur}
              placeholder={intl.formatMessage({ ...inputLabels.cardExpiredIn })}
              error={!!errors?.expiredIn}
              helperText={renderIntlMessage(errors?.expiredIn?.message)}
              InputProps={{
                inputComponent: ExpiredDateMaskCustom,
              }}
            />
          )}
        />
      </Grid>

      <Grid item className={classes.dateAndCodeWrapper}>
        <Controller
          name="code"
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <TextField
              fullWidth
              type="password"
              value={value}
              onChange={onChange}
              variant="outlined"
              size="small"
              onBlur={onBlur}
              placeholder={intl.formatMessage({ ...inputLabels.cvvCode })}
              error={!!errors?.code}
              helperText={renderIntlMessage(errors?.code?.message)}
              InputProps={{
                inputComponent: CVVCodeMaskCustom,
                inputProps: { cardType },
              }}
            />
          )}
        />
      </Grid>

      <Grid item>
        <IconButton
          type="submit"
          color="primary"
          onClick={handleSubmit(onCardAdd)}
          className={classes.actionButton}
        >
          <SvgIcon fontSize="small" component={CheckIcon} />
        </IconButton>
      </Grid>

      <Grid item>
        <IconButton color="primary" onClick={handleClose} className={classes.actionButton}>
          <SvgIcon fontSize="small" component={CloseIcon} />
        </IconButton>
      </Grid>

      {withScanning && (
        <Grid item xs={12}>
          <DeviceActionButton
            deviceType={DeviceType.MagneticStripe}
            onScanningSuccess={onScanningSuccess}
          />
          {scannerStr && <Box>{scannerStr}</Box>}
        </Grid>
      )}
    </Grid>
  );
};

export default CreditCardData;
