import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useForm, FormProvider } from 'react-hook-form';
import { List as ImmutableList } from 'immutable';
import { Grid, makeStyles, createStyles } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  selectPersonsFoundList,
  selectPersonsFoundListLoading,
} from 'common/state/newPerson/primaryInfo/selectors';
import { createLeadActivity, fetchLeadActivities } from 'modules/crm/state/leads/actions';
import {
  resetPersonsFound,
  searchReferralMembers,
} from 'common/state/newPerson/primaryInfo/actions';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import {
  selectPersonSalesInfoUpdateLoading,
  selectPersonSalesInfoUpdateResult,
} from 'common/components/PersonProfile/state/selectors';
import { resetUpdatePersonSalesInfoAction } from 'common/components/PersonProfile/state/actions';
import { getRequiredMessage } from 'common/constants/globalConstants';

import { useAppDispatch } from 'store/hooks';

import { Communications, LeadValue } from 'modules/crm/constants/leads';
import { CameFromType, DictionaryList } from 'common/constants';

import { ILeadProfile, ILeadProfileImt, INewLeadProfileData } from 'modules/crm/interfaces/leads';
import { IPersonSalesInfoImt } from 'common/components/PersonProfile/interfaces';
import { INamedEntityImt } from 'common/interfaces/common';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';
import {
  ICameFromIndicatorDictionaryItemImt,
  IEmployeeListItemImt,
} from 'common/interfaces/dictionary';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';

import InterestsSection from 'modules/crm/components/LeadForm/LeadPrimaryInfoStep/InterestsSection/InterestsSection';
import SalesInfoSection from 'modules/crm/components/LeadForm/LeadPrimaryInfoStep/SalesInfoSection/SalesInfoSection';
import { DialogComponent, ScrollBox } from 'common/components';

import messages from 'common/components/PersonProfile/messages';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';

const useStyles = makeStyles(() =>
  createStyles({
    formContent: {
      overflow: 'hidden',
    },
  }),
);

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formValues: INewLeadProfileData) => void;
  salesInfo: IPersonSalesInfoImt;
  primaryInfo: ILeadProfileImt;
  module: PeakModuleForNewPersonType;
}

const initialValues = {
  activitiesIds: [],
  salesperson: null,
  cameFromIndicatorId: '',
  cameFromValue: '',
  createdDate: new Date(),
  doNotContactHours: {
    from: '',
    to: '',
  },
  limitations: '',
  potentialClubId: '',
  preferableWayOfCommunication: Communications.ANY,
  referralMemberId: '',
  salesValue: LeadValue.LOW,
};

const EditSalesInfoModal = ({
  isOpen,
  onClose,
  salesInfo,
  primaryInfo,
  onSubmit,
  module,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const personId = primaryInfo.get('id');

  const leadsAutocompleteList: ImmutableList<ILeadProfile | INamedEntityImt> = useSelector(
    selectPersonsFoundList,
  );
  const isLoading = useSelector(selectPersonsFoundListLoading);
  const isSalesInfoUpdating = useSelector(selectPersonSalesInfoUpdateLoading(personId));
  const salesInfoUpdateResult: ILeadProfileImt = useSelector(
    selectPersonSalesInfoUpdateResult(personId),
  );
  const employees: ImmutableList<IEmployeeListItemImt> = useSelector(
    selectDictionaryList(DictionaryList.EMPLOYEES),
  );
  const cameFromIndicators: ImmutableList<ICameFromIndicatorDictionaryItemImt> = useSelector(
    selectDictionaryList(DictionaryList.CAME_FROM),
  );
  const isSubmitted = useSelector(selectIsSubmittedRecommendedFieldsModal);
  const profileRequiredFields = useSelector(selectProfileRequiredFields);

  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();

  const classes = useStyles();

  const formMethods = useForm<
    INewLeadProfileData & { cameFromType: CameFromType; optional: boolean }
  >({
    defaultValues: initialValues,
    resolver: yupResolver(
      yup.object().shape({
        assignedSalesperson: yup
          .object()
          .nullable()
          .when('salesperson', {
            is: () => {
              return (
                profileRequiredFields.get(RequiredProfileInfo.Salesperson) ===
                FieldNecessity.Required
              );
            },
            then: schema => schema.required(getRequiredMessage),
            otherwise: schema => schema,
          }),
      }),
    ) as any, // TODO - PRM-1810 need resolver type
    mode: 'onBlur',
  });

  const { handleSubmit, reset, getValues } = formMethods;

  useEffect(() => {
    if (isSubmitted) {
      const values = getValues();

      onSubmit(values);
      dispatch(showRecommendedFieldsModal(false));
    }
  }, [dispatch, getValues, isSubmitted, onSubmit]);

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.EMPLOYEES, { module }));
    dispatch(fetchDictionaryList(DictionaryList.CAME_FROM, { module }));
    dispatch(fetchLeadActivities(module));
  }, [dispatch, module]);

  useEffect(() => {
    if (salesInfo?.size) {
      const salesInfoData = salesInfo.toJS();

      reset({
        ...initialValues,
        ...salesInfoData,
        activitiesIds: salesInfoData.activities,
        potentialClubId: salesInfoData.potentialClub?.id,
        cameFromIndicatorId: salesInfoData.cameFromIndicator?.id,
        cameFromType: salesInfoData.cameFromIndicator?.type,
        optional: salesInfoData.cameFromIndicator?.optional,
      } as any); // TODO - PRM-1810 need type
    }
  }, [reset, salesInfo]);

  useEffect(() => {
    if (salesInfoUpdateResult?.size) {
      dispatch(resetUpdatePersonSalesInfoAction(null, personId));
      onClose();
    }
  }, [dispatch, onClose, personId, salesInfoUpdateResult]);

  const getReferralMembersByValue = useCallback(
    (searchString: string) => {
      dispatch(searchReferralMembers(searchString));
    },
    [dispatch],
  );

  const clearSearchResults = useCallback(() => {
    dispatch(resetPersonsFound());
  }, [dispatch]);

  const onActivityCreate = useCallback(
    (activity: IDictionaryCommonItem) => {
      dispatch(createLeadActivity(activity, module));
    },
    [dispatch, module],
  );

  const modifiedPrimaryInfo = useMemo(
    () => primaryInfo.set('cameFromIndicator', salesInfo?.get('cameFromIndicator')),
    [primaryInfo, salesInfo],
  );

  const onSave = (values: INewLeadProfileData) => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty({
      ...values,
      salesperson: values.assignedSalesperson,
    });

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit(values);
    }
  };

  return (
    <DialogComponent
      title={<FormattedMessage {...messages.editSalesInfoModalTitle} />}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      loading={isSalesInfoUpdating}
      formId="sales-info-form"
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSave)} autoComplete="none" id="sales-info-form">
          <ScrollBox hasShadowsOnScroll suppressBottomShadow className={classes.formContent}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <InterestsSection onActivityCreate={onActivityCreate} />
              </Grid>

              <Grid item xs={12}>
                <SalesInfoSection
                  primaryInfo={modifiedPrimaryInfo}
                  getReferralMembersByValue={getReferralMembersByValue}
                  persons={leadsAutocompleteList as ImmutableList<INamedEntityImt>}
                  isPersonSearchLoading={isLoading}
                  clearSearchResults={clearSearchResults}
                  employees={employees}
                  cameFromIndicators={cameFromIndicators}
                  recommendedFields={profileRecommendedFields}
                />
              </Grid>
            </Grid>
          </ScrollBox>
        </form>
      </FormProvider>
    </DialogComponent>
  );
};

export default EditSalesInfoModal;
