import React, { Dispatch, SetStateAction } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';

import PrintKeysService from 'services/PrintKeyStorageService/PrintKeyStorageService';
import { DialogComponent, TextArea } from 'common/components';
import commonMessages from 'common/messages/messages';
import { getRequiredMessage } from 'common/constants/globalConstants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import inputLabels from 'common/messages/inputLabels';

interface ISavePrintKeysFormValues {
  digitalCertificateKey: string;
  privateKey: string;
}

interface ISavePrintKeysModalProps {
  isOpen: boolean;
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
}

const validationSchema = yup.object().shape({
  digitalCertificateKey: yup
    .string()
    .trim()
    .required(getRequiredMessage),
  privateKey: yup
    .string()
    .trim()
    .required(getRequiredMessage),
});

const SAVE_PRINT_KEYS_MODAL = 'save-print-keys-modal';

const SavePrintKeysModal = (props: ISavePrintKeysModalProps): JSX.Element => {
  const { isOpen, setIsOpenModal } = props;

  const renderIntlMessage = useRenderIntlMessage();

  const formMethods = useForm<any>({
    defaultValues: {
      digitalCertificateKey: '',
      privateKey: '',
    },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: 'onChange',
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = formMethods;

  const onSaveKeys = (values: ISavePrintKeysFormValues) => {
    PrintKeysService.setDigitalCertificateKey(values.digitalCertificateKey);
    PrintKeysService.setPrivateKey(values.privateKey);
    setIsOpenModal(false);
  };

  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  return (
    <DialogComponent
      formId={SAVE_PRINT_KEYS_MODAL}
      size="xs"
      isOpen={isOpen}
      submitBtnTitle={<FormattedMessage {...commonMessages.applyBtn} />}
      title={<FormattedMessage {...commonMessages.certificatesPerCurrentPC} />}
      onSubmit={handleSubmit(onSaveKeys)}
      onClose={onCloseModal}
    >
      <form id={SAVE_PRINT_KEYS_MODAL}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="textSecondary">
              <FormattedMessage {...commonMessages.qzGenerateCertificates} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="digitalCertificateKey"
              control={control}
              render={({ field: { value, onBlur, onChange } }) => (
                <TextArea
                  value={value}
                  label={<FormattedMessage {...inputLabels.digitalCertificateKey} />}
                  variant="outlined"
                  autoComplete="none"
                  fullWidth
                  rows={4}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.digitalCertificateKey}
                  helperText={renderIntlMessage(errors.digitalCertificateKey?.message)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="privateKey"
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextArea
                  value={value}
                  label={<FormattedMessage {...inputLabels.privateKey} />}
                  variant="outlined"
                  autoComplete="none"
                  fullWidth
                  rows={4}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(errors.privateKey)}
                  helperText={renderIntlMessage(errors.privateKey?.message)}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </DialogComponent>
  );
};

export default SavePrintKeysModal;
