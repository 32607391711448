import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  createStyles,
  Theme,
} from '@material-ui/core';

import { YesNoStatus } from 'common/constants';

import messages from 'common/components/PersonProfile/messages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupLabel: {
      fontSize: '1rem',
      fontWeight: 700,
      opacity: 1,
    },
    radioOption: {
      marginTop: theme.spacing(2),
    },
  }),
);

const InTermReasonStep = () => {
  const { control } = useFormContext();

  const classes = useStyles();

  return (
    <FormControl component="fieldset">
      <FormLabel className={classes.groupLabel}>
        <FormattedMessage {...messages.cancelMembershipModalAcceptedResaleQuestion} />
      </FormLabel>
      <Controller
        name="resaleOptionAccepted"
        control={control}
        render={({ field }) => (
          <RadioGroup
            aria-label="isAcceptedResale"
            value={Number(field.value)}
            onChange={(_, value) => field.onChange(!!Number(value))}
          >
            {YesNoStatus.map(({ label, value }) => (
              <FormControlLabel
                className={classes.radioOption}
                value={Number(value)}
                control={<Radio color="primary" />}
                label={label}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};

export default React.memo(InTermReasonStep);
