import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';

import { formatNumberToPrice } from 'common/utils';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { CreditCardFeeUnit, CreditCardFeeUnitsSymbols } from 'modules/services/constants/packages';

import { FieldInfo } from 'common/components/index';

import servicesMessages from 'modules/services/messages/messages';

const CreditCardFeeSection = ({ isOutOfTerm }: { isOutOfTerm: boolean }): JSX.Element => {
  const { control } = useFormContext();

  const renderIntlMessage = useRenderIntlMessage();

  const valuePath = isOutOfTerm ? 'outOfTermBillingOption.' : '';

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle2" gutterBottom>
          <FormattedMessage {...servicesMessages.creditCardServiceFeeEnabledLabel} />
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Controller
          control={control}
          name={`${valuePath}creditCardServiceFee`}
          render={({ field }) => {
            const { value, unit } = field.value;

            return (
              <FieldInfo
                inputMode
                grayedOut
                label={<FormattedMessage {...servicesMessages.creditCardServiceFeeValueLabel} />}
                description={
                  unit === CreditCardFeeUnit.FLAT
                    ? `$${formatNumberToPrice(value)}`
                    : `${value}${renderIntlMessage(CreditCardFeeUnitsSymbols.message(unit))}`
                }
              />
            );
          }}
        />
      </Grid>
    </>
  );
};

export default React.memo(CreditCardFeeSection);
