import React, { useEffect, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditorState } from 'draft-js';
import * as yup from 'yup';
import {
  Box,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
  Theme,
} from '@material-ui/core';

import {
  ICreateDocumentTemplateDto,
  IDocumentTemplateDtoImt,
} from 'common/interfaces/documentTemplate';
import { IAttachment, ImageFormatType } from 'common/interfaces/uploadFile';

import { TemplatePurpose, TemplateTypes } from 'common/constants/documentTemplate';
import { ActiveInactiveStatus } from 'common/constants';
import { getRequiredMessage } from 'common/constants/globalConstants';
import { decorators } from 'modules/services/components/TemplateEditor/TemplateEditor';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getSegmentsCount } from 'common/utils';

import { DraftEditor, FormContainer, Select, EditorMenu } from 'common/components';
import EmojiTool from 'common/components/DraftEditor/EmojiTool';
import { AttachmentsSection } from 'components/EmployeeForm';

import inputLabels from 'common/messages/inputLabels';
import inputErrors from 'common/messages/inputErrors';
import { convertToText } from 'common/utils/draftJS';
import { IObject } from 'common/interfaces/common';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    '& .scrollBox>div': {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      '&>.MuiCardContent-root': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  inputName: {
    marginBottom: theme.spacing(2),
  },
  formWrapper: {
    overflow: 'hidden',
    flex: 1,
    flexDirection: 'column',
  },
  textField: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',

    '& .draftEditorToolbar': {
      paddingBottom: '5px',
      justifyContent: 'flex-end',
    },

    '& .draftEditorInner': {
      minHeight: '160px',
      padding: theme.spacing(0, 1, 1, 1),
      overflow: 'auto',
    },
  },
  row: {
    marginTop: '6px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowItemLeft: {
    marginRight: 'auto',
  },
  rowItemRight: {
    marginLeft: 'auto',
  },
  symbols: {
    textAlign: 'right',
    opacity: 0.4,
  },
}));

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .max(100, () => inputErrors.nameLengthError)
    .required(getRequiredMessage),
  hasText: yup.bool().oneOf([true], getRequiredMessage),
});

interface ISmsFormValues {
  name: string;
  status: boolean;
  hasText: boolean;
  attachments: Array<IAttachment>;
}

interface ISmsFormProps {
  template?: IDocumentTemplateDtoImt;
  defaultEditor?: IObject;
  title: JSX.Element;
  isLoading: boolean;
  onSave: (template: ICreateDocumentTemplateDto) => void;
  onCancel: () => void;
}

const initialValues = {
  name: '',
  status: false,
  hasText: false,
  attachments: [],
};

export default function SmsForm({
  template,
  title,
  isLoading,
  onCancel,
  onSave,
  defaultEditor,
}: ISmsFormProps): JSX.Element {
  const [editor, changeEditor] = useState(defaultEditor || EditorState.createEmpty());

  const classes = useStyles();

  const textInEditor: string = editor.getCurrentContent().getPlainText();

  const formMethods = useForm<any>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const renderIntlMessage = useRenderIntlMessage();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = formMethods;

  useEffect(() => {
    if (template?.size) {
      reset({
        name: template.get('title'),
        status: template.get('active'),
        hasText: !!template.get('content'),
        attachments: template.get('attachments')?.toJS() || [],
      });
    }
  }, [reset, template]);

  const onSubmitHandle = (values: ISmsFormValues) => {
    onSave({
      title: values.name,
      content: convertToText(editor).trim(),
      active: values.status,
      purposeType: TemplatePurpose.CampaignTemplate,
      attachments: values.attachments,
      type: TemplateTypes.SMS,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Box className={classes.root}>
        <FormContainer
          title={title}
          maxWidth={540}
          onSubmit={handleSubmit(onSubmitHandle)}
          onCancel={onCancel}
          isSubmitting={isLoading}
        >
          <Box flex={1} display="flex" flexDirection="column">
            <Grid className={classes.formWrapper} container spacing={2}>
              <Grid item xs={12} style={{ flex: 0 }}>
                <Grid container spacing={1}>
                  <Grid item xs={9}>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          variant="outlined"
                          autoComplete="none"
                          onChange={field.onChange}
                          label={<FormattedMessage {...inputLabels.name} />}
                          value={field.value}
                          error={!!errors.name}
                          helperText={renderIntlMessage(errors.name?.message)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <Select
                          fullWidth
                          label={<FormattedMessage {...inputLabels.status} />}
                          variant="outlined"
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                        >
                          {ActiveInactiveStatus.map(item => (
                            <MenuItem key={item.key} value={`${item.value}`}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.textField}>
                <Controller
                  // this value is used to validate the DraftEditor text field
                  name="hasText"
                  control={control}
                  render={({ field }) => (
                    <DraftEditor
                      editorState={editor}
                      decorators={decorators}
                      onEditorStateChange={value => {
                        field.onChange(value.getCurrentContent().hasText());
                        changeEditor(value);
                      }}
                      toolbarConfig={{
                        options: [],
                      }}
                      toolbarCustomButtons={[
                        <EmojiTool />,
                        <EditorMenu
                          hasHiddenAddContractLinkMenu
                          hasHiddenAddContractNameMenu
                          hasHiddenAddWaiverNameMenu
                          hasHiddenAddWaiverLinkMenu
                          hasHiddenAddSignatureMenu
                          relative
                        />,
                      ]}
                      hasError={!!errors.hasText}
                    />
                  )}
                />

                <Grid container className={classes.row}>
                  {!!errors.hasText && (
                    <Grid item className={classes.rowItemLeft}>
                      <FormHelperText error={!!errors.hasText}>
                        {renderIntlMessage(errors.hasText?.message)}
                      </FormHelperText>
                    </Grid>
                  )}
                  <Grid item className={classes.rowItemRight}>
                    <Typography component="p" className={classes.symbols} variant="body2">
                      {`${getSegmentsCount(textInEditor)} SMS`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <AttachmentsSection
                  name="attachments"
                  acceptFileTypes={{
                    [ImageFormatType.PNG]: ['.png'],
                    [ImageFormatType.JPEG]: ['.jpeg'],
                    [ImageFormatType.JPG]: ['.jpg'],
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </FormContainer>
      </Box>
    </FormProvider>
  );
}
