import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid, TextField } from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage } from 'react-intl';

import { getRequiredMessage } from 'common/constants/globalConstants';
import { DialogComponent, NumberTextField, Select } from 'common/components/index';
import commonMessages from 'common/messages/messages';
import { checkingSavingsOptions } from '../../constants';
import { defaultNumberFormatProps } from 'common/components/NumberController/NumberController';
import inputErrors from 'common/messages/inputErrors';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { IPaymentAccount } from '../../interfaces';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  onSubmit: (values: Omit<IPaymentAccount, 'id'>) => void;
}

const MIN_NUMBER_SIZE = 9;
const MAX_NUMBER_SIZE = 10;
const MAX_OWNER_NAME_SIZE = 22;

const CheckingSavingsValidationSchema = yup.object().shape({
  ownerName: yup
    .string()
    .required(getRequiredMessage)
    .max(MAX_OWNER_NAME_SIZE, () => inputErrors.ownerNameMaxLengthError),
  routingNumber: yup
    .string()
    .required(getRequiredMessage)
    .min(MIN_NUMBER_SIZE, () => inputErrors.sizeMustBeError)
    .max(MIN_NUMBER_SIZE, () => inputErrors.sizeMustBeError),
  accountNumber: yup
    .string()
    .required(getRequiredMessage)
    .min(MIN_NUMBER_SIZE, () => inputErrors.sizeMustBeBetweenError)
    .max(MAX_NUMBER_SIZE, () => inputErrors.sizeMustBeBetweenError),
  type: yup.string().required(getRequiredMessage),
});

const initialValues = {
  ownerName: '',
  routingNumber: '',
  accountNumber: '',
  type: '',
};

const AddCheckingSavingsModal = ({ isOpen, onClose, isLoading, onSubmit }: IProps): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: initialValues,
    resolver: yupResolver(CheckingSavingsValidationSchema),
    mode: 'onTouched',
  });

  return (
    <DialogComponent
      isOpen={isOpen}
      title={<FormattedMessage {...commonMessages.addCheckingSavingsLabel} />}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading}
      size="xs"
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controller
            name="ownerName"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                fullWidth
                value={value}
                onChange={onChange}
                variant="outlined"
                label={<FormattedMessage {...commonMessages.ownerNameLabel} />}
                helperText={renderIntlMessage(errors?.ownerName?.message)}
                error={!!errors?.ownerName}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="routingNumber"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => {
              return (
                <NumberTextField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fullWidth
                  label={<FormattedMessage {...commonMessages.routingNumberLabel} />}
                  variant="outlined"
                  numberFormatProps={{
                    ...defaultNumberFormatProps,
                    maxLength: MIN_NUMBER_SIZE,
                  }}
                  helperText={renderIntlMessage(errors?.routingNumber?.message, {
                    startNumber: MIN_NUMBER_SIZE,
                    endNumber: MIN_NUMBER_SIZE,
                  })}
                  error={!!errors?.routingNumber}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="accountNumber"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <NumberTextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fullWidth
                label={<FormattedMessage {...commonMessages.accountNumberLabel} />}
                variant="outlined"
                numberFormatProps={{
                  ...defaultNumberFormatProps,
                  maxLength: MAX_NUMBER_SIZE,
                }}
                error={!!errors?.accountNumber}
                helperText={renderIntlMessage(errors?.accountNumber?.message, {
                  startNumber: MIN_NUMBER_SIZE,
                  endNumber: MAX_NUMBER_SIZE,
                })}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="type"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <Select
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fullWidth
                helperText={renderIntlMessage(errors?.type?.message)}
                error={!!errors?.type}
                label={<FormattedMessage {...commonMessages.typeLabel} />}
              >
                {checkingSavingsOptions.getSelectOptions()}
              </Select>
            )}
          />
        </Grid>
      </Grid>
    </DialogComponent>
  );
};

export default AddCheckingSavingsModal;
