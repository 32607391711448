import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TextField, makeStyles, Typography, Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import * as selectors from 'common/components/PersonProfile/state/servicesModals/selectors';
import {
  selectCurrentUserClubs,
  selectUserSelectedClubId,
} from 'modules/authentication/state/selectors';

import {
  DialogComponent,
  MultipleSelect,
  PasswordField,
  PinCodeMaskedInput,
} from 'common/components/index';
import RedeemSubHeader from 'common/components/PersonProfile/components/Services/modals/RedeemSubHeader/RedeemSubHeader';
import { CustomTheme } from 'common/ui/interfaces';
import { redeemInventorySchema } from './redeemInventorySchema';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { IRedeemInventoryForm } from 'common/interfaces/service';

import messages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';

const useStyles = makeStyles((theme: CustomTheme) => ({
  dialogBody: {
    overflowY: 'hidden',
    paddingTop: theme.spacing(1),
  },
}));

interface IProps {
  onClose: () => void;
  onSubmit: (data: IRedeemInventoryForm & { inventoryId: string }) => void;
  isOpen: boolean;
  isLoading: boolean;
}

const RedeemInventoryModal = ({ onClose, isOpen, onSubmit, isLoading }: IProps): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();

  const inventoryDetails = useSelector(selectors.selectInventoryDetails);
  const clubs = useSelector(selectCurrentUserClubs);
  const currentClubId: string = useSelector(selectUserSelectedClubId);

  const classes = useStyles();

  const initialClub = clubs.find(clubItem => clubItem.get('id') === currentClubId)?.toJS() ?? null;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IRedeemInventoryForm>({
    defaultValues: {
      redeemedNumber: 1,
      pinCode: null,
      club: initialClub,
    },
    resolver: yupResolver(redeemInventorySchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'onSubmit',
  });

  const availableToRedeem = inventoryDetails.get('leftNumber');
  const formId = 'redeem-inventory-form-id';

  const submitForm = (values: IRedeemInventoryForm) => {
    onSubmit({ ...values, inventoryId: inventoryDetails.get('id') });
  };

  const subHeader = Boolean(inventoryDetails) && (
    <RedeemSubHeader
      limited
      totalToRedeem={inventoryDetails.get('totalNumber')}
      availableToRedeem={inventoryDetails.get('leftNumber')}
    />
  );

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={inventoryDetails.get('title')}
      submitBtnTitle={<FormattedMessage {...messages.redeemBtn} />}
      subHeader={subHeader}
      scroll="paper"
      supressBottomShadow
      disabled={!availableToRedeem}
      loading={isLoading}
      formId={formId}
    >
      <form id={formId} onSubmit={handleSubmit(submitForm)}>
        {availableToRedeem ? (
          <Grid container spacing={2} direction="column" className={classes.dialogBody}>
            <Grid item xs={6}>
              <Controller
                name="club"
                control={control}
                render={({ field }) => (
                  <MultipleSelect
                    label={<FormattedMessage {...inputLabels.club} />}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    fullWidth
                    options={clubs.toJS()}
                    error={!!errors.club}
                    helperText={renderIntlMessage(errors?.club?.message)}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="redeemedNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    fullWidth
                    variant="outlined"
                    onBlur={field.onBlur}
                    label={
                      <FormattedMessage
                        {...inputLabels.availableItemsForRedeem}
                        values={{ items: availableToRedeem }}
                      />
                    }
                    value={field.value}
                    onChange={numValue => {
                      const number = +numValue.target.value;

                      if (number <= availableToRedeem && number >= 1) {
                        field.onChange(+number);
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="pinCode"
                control={control}
                render={({ field }) => (
                  <PasswordField
                    autoComplete="off"
                    variant="outlined"
                    onBlur={field.onBlur}
                    label={<FormattedMessage {...inputLabels.pinCode} />}
                    onChange={field.onChange}
                    fullWidth
                    value={field.value}
                    error={Boolean(errors.pinCode)}
                    helperText={renderIntlMessage(errors.pinCode?.message)}
                    InputProps={{ inputComponent: PinCodeMaskedInput }}
                  />
                )}
              />
            </Grid>
          </Grid>
        ) : (
          <Typography>
            <FormattedMessage
              {...inputLabels.availableItemsForRedeem}
              values={{ items: renderIntlMessage(messages.noOption) }}
            />
          </Typography>
        )}
      </form>
    </DialogComponent>
  );
};

export default RedeemInventoryModal;
