import { defineMessages } from 'react-intl';

export default defineMessages({
  policyLevelText: {
    id: 'app.modules.permissions.policyLevelText',
    defaultMessage: 'Policy level',
  },
  policyItemSuccessCreatedMessage: {
    id: 'app.modules.permissions.policyItemSuccessCreatedMessage',
    defaultMessage: 'New policy has been created',
  },
  roleItemSuccessCreatedMessage: {
    id: 'app.modules.permissions.roleItemSuccessCreatedMessage',
    defaultMessage: 'New role has been created',
  },
  roles: {
    id: 'app.modules.permissions.roles',
    defaultMessage: 'Roles',
  },
  newRoleItemTitle: {
    id: 'app.modules.permissions.newRoleItemTitle',
    defaultMessage: 'New Role',
  },
  editRoleItemTitle: {
    id: 'app.modules.permissions.editRoleItemTitle',
    defaultMessage: 'Edit {title}',
  },
  policies: {
    id: 'app.modules.permissions.policies',
    defaultMessage: 'Policies',
  },
  policy: {
    id: 'app.modules.permissions.policy',
    defaultMessage: 'Policy',
  },
  policyType: {
    id: 'app.modules.permissions.policyType',
    defaultMessage: 'Policy type',
  },
  permissions: {
    id: 'app.modules.permissions.permissions',
    defaultMessage: 'Permissions',
  },
  permission: {
    id: 'app.modules.permissions.permission',
    defaultMessage: 'Permission',
  },
  newPolicyItemTitle: {
    id: 'app.modules.permissions.newPolicyTitle',
    defaultMessage: 'New Policy',
  },
  editPolicyItemTitle: {
    id: 'app.modules.permissions.editPolicyTitle',
    defaultMessage: 'Edit {title}',
  },
  dictionaries: {
    id: 'app.modules.permissions.permissionGroupsTitles.dictionaries',
    defaultMessage: 'Dictionaries',
  },
  crm: {
    id: 'app.modules.permissions.permissionGroupsTitles.crm',
    defaultMessage: 'CRM',
  },
  financial: {
    id: 'app.modules.permissions.permissionGroupsTitles.financial',
    defaultMessage: 'Financial',
  },
  viewAll: {
    id: 'app.modules.permissions.permissionSetType.viewAll',
    defaultMessage: 'View all',
  },
  manage: {
    id: 'app.modules.permissions.permissionSetType.manage',
    defaultMessage: 'Manage',
  },
  edit: {
    id: 'app.modules.permissions.permissionSetType.edit',
    defaultMessage: 'Edit',
  },
  tasks: {
    id: 'app.modules.permissions.permissionSetType.tasks',
    defaultMessage: 'Tasks',
  },
  leads: {
    id: 'app.modules.permissions.permissionSetType.leads',
    defaultMessage: 'Leads',
  },
  script: {
    id: 'app.modules.permissions.permissionSetType.script',
    defaultMessage: 'Script',
  },
  campaign: {
    id: 'app.modules.permissions.permissionSetType.campaign',
    defaultMessage: 'Campaign',
  },
  // financial
  setClientFeeSchedule: {
    id: 'app.modules.permissions.permissionSetType.setClientFeeSchedule',
    defaultMessage: 'Set client fee schedule',
  },
  // members
  members: {
    id: 'app.modules.permissions.permissionGroupsTitles.members',
    defaultMessage: 'Members',
  },
  // pos
  pos: {
    id: 'app.modules.permissions.permissionGroupsTitles.pos',
    defaultMessage: 'POS',
  },
  register: {
    id: 'app.modules.permissions.permissionGroupsTitles.register',
    defaultMessage: 'Register',
  },
  openRegister: {
    id: 'app.modules.permissions.permissionSetType.openRegister',
    defaultMessage: 'Open',
  },
  receipts: {
    id: 'app.modules.permissions.permissionSetType.receipts',
    defaultMessage: 'Receipts',
  },
  taxRates: {
    id: 'app.modules.permissions.permissionSetType.taxRates',
    defaultMessage: 'Tax rates',
  },
  rCodes: {
    id: 'app.modules.permissions.permissionSetType.rCodes',
    defaultMessage: 'Revenu codes',
  },
  vendors: {
    id: 'app.modules.permissions.permissionSetType.vendors',
    defaultMessage: 'Vendors',
  },
  inventory: {
    id: 'app.modules.permissions.permissionSetType.inventory',
    defaultMessage: 'Inventory',
  },
  payMethods: {
    id: 'app.modules.permissions.permissionSetType.payMethods',
    defaultMessage: 'Payment methods',
  },
  // collections
  collections: {
    id: 'app.modules.permissions.permissionGroupsTitles.collections',
    defaultMessage: 'Collections',
  },
  sendLetter: {
    id: 'app.modules.permissions.permissionSetType.sendLetter',
    defaultMessage: 'Send a letter',
  },
  // sales manage
  salesManagement: {
    id: 'app.modules.permissions.permissionGroupsTitles.salesManagement',
    defaultMessage: 'Sales management',
  },
  viewLead: {
    id: 'app.modules.permissions.permissionSetType.viewLead',
    defaultMessage: 'View leads',
  },
  editLead: {
    id: 'app.modules.permissions.permissionSetType.editLead',
    defaultMessage: 'Edit leads',
  },
  commissionRuleView: {
    id: 'app.modules.permissions.permissionSetType.commissionRuleView',
    defaultMessage: 'Commission rule view',
  },
  commissionRuleCreate: {
    id: 'app.modules.permissions.permissionSetType.commissionRuleCreate',
    defaultMessage: 'Commission rule create',
  },
  commissionRuleEdit: {
    id: 'app.modules.permissions.permissionSetType.commissionRuleEdit',
    defaultMessage: 'Commission rule edit',
  },
  commissionRuleDelete: {
    id: 'app.modules.permissions.permissionSetType.commissionRuleDelete',
    defaultMessage: 'Commission rule delete',
  },
  daysheetView: {
    id: 'app.modules.permissions.permissionSetType.daysheetView',
    defaultMessage: 'Daysheet view',
  },
  // corporate or club level
  // scheduling group
  scheduling: {
    id: 'app.modules.permissions.permissionGroupsTitles.scheduling',
    defaultMessage: 'Scheduling',
  },
  bookingEventsView: {
    id: 'app.modules.permissions.permissionSetType.bookingEventsView',
    defaultMessage: 'Booking events view',
  },
  bookingEventsViewOne: {
    id: 'app.modules.permissions.permissionSetType.bookingEventsViewOne',
    defaultMessage: 'Booking events view one',
  },
  bookingEventsCreate: {
    id: 'app.modules.permissions.permissionSetType.bookingEventsCreate',
    defaultMessage: 'Booking events create',
  },
  bookingEventsEdit: {
    id: 'app.modules.permissions.permissionSetType.bookingEventsEdit',
    defaultMessage: 'Booking events edit',
  },
  bookingEventsCancel: {
    id: 'app.modules.permissions.permissionSetType.bookingEventsCancel',
    defaultMessage: 'Booking events cancel',
  },
  bookingEventsDelete: {
    id: 'app.modules.permissions.permissionSetType.bookingEventsDelete',
    defaultMessage: 'Booking events delete',
  },
  bookingEventsDuplicate: {
    id: 'app.modules.permissions.permissionSetType.bookingEventsDuplicate',
    defaultMessage: 'Booking events duplicate',
  },
  bookingEventsRemindParticipants: {
    id: 'app.modules.permissions.permissionSetType.bookingEventsDuplicate',
    defaultMessage: 'Booking events remind participants',
  },
  bookingEventsNotSelfEdit: {
    id: 'app.modules.permissions.permissionSetType.bookingEventsNotSelfEdit',
    defaultMessage: 'Booking events not self edit',
  },
  bookingResourcesView: {
    id: 'app.modules.permissions.permissionSetType.bookingResourcesView',
    defaultMessage: 'Booking resources view',
  },
  bookingResourcesCreate: {
    id: 'app.modules.permissions.permissionSetType.bookingResourcesCreate',
    defaultMessage: 'Booking resources create',
  },
  bookingResourcesEdit: {
    id: 'app.modules.permissions.permissionSetType.bookingResourcesEdit',
    defaultMessage: 'Booking resources edit',
  },
  bookingResourcesChangeStatus: {
    id: 'app.modules.permissions.permissionSetType.bookingEventsNotSelfEdit',
    defaultMessage: 'Booking resources change status',
  },
  // crm group
  crmTaskView: {
    id: 'app.modules.permissions.permissionSetType.crmTaskView',
    defaultMessage: 'CRM task view',
  },
  crmTaskEditStatus: {
    id: 'app.modules.permissions.permissionSetType.crmTaskEditStatus',
    defaultMessage: 'CRM task edit status',
  },
  crmTaskEditSalesperson: {
    id: 'app.modules.permissions.permissionSetType.crmTaskEditSalesperson',
    defaultMessage: 'CRM task edit salesperson',
  },
  crmLeadView: {
    id: 'app.modules.permissions.permissionSetType.crmLeadView',
    defaultMessage: 'CRM lead view',
  },
  crmLeadCreate: {
    id: 'app.modules.permissions.permissionSetType.crmLeadCreate',
    defaultMessage: 'CRM lead create',
  },
  crmLeadEdit: {
    id: 'app.modules.permissions.permissionSetType.crmLeadEdit',
    defaultMessage: 'CRM lead edit',
  },
  crmLeadEditStatus: {
    id: 'app.modules.permissions.permissionSetType.crmLeadEditStatus',
    defaultMessage: 'CRM lead edit status',
  },
  crmLeadEditSalesperson: {
    id: 'app.modules.permissions.permissionSetType.crmLeadEditSalesperson',
    defaultMessage: 'CRM lead edit salesperson',
  },
  crmScriptView: {
    id: 'app.modules.permissions.permissionSetType.crmScriptView',
    defaultMessage: 'CRM script view',
  },
  crmScriptCreate: {
    id: 'app.modules.permissions.permissionSetType.crmScriptCreate',
    defaultMessage: 'CRM script create',
  },
  crmScriptEdit: {
    id: 'app.modules.permissions.permissionSetType.crmScriptEdit',
    defaultMessage: 'CRM script edit',
  },
  crmScriptChangeStatus: {
    id: 'app.modules.permissions.permissionSetType.crmScriptChangeStatus',
    defaultMessage: 'CRM script change status',
  },
  crmCampaignView: {
    id: 'app.modules.permissions.permissionSetType.crmCampaignView',
    defaultMessage: 'CRM campaign view',
  },
  crmCampaignCreate: {
    id: 'app.modules.permissions.permissionSetType.crmCampaignCreate',
    defaultMessage: 'CRM campaign create',
  },
  crmCampaignEdit: {
    id: 'app.modules.permissions.permissionSetType.crmCampaignEdit',
    defaultMessage: 'CRM campaign edit',
  },
  // pt crm group
  ptCrmTaskView: {
    id: 'app.modules.permissions.permissionSetType.ptCrmTaskView',
    defaultMessage: 'PT CRM task view',
  },
  ptCrmTaskEditStatus: {
    id: 'app.modules.permissions.permissionSetType.ptCrmTaskEditStatus',
    defaultMessage: 'PT CRM task edit status',
  },
  ptCrmTaskEditSalesperson: {
    id: 'app.modules.permissions.permissionSetType.ptCrmTaskEditSalesperson',
    defaultMessage: 'PT CRM task edit salesperson',
  },
  ptCrmLeadView: {
    id: 'app.modules.permissions.permissionSetType.ptCrmLeadView',
    defaultMessage: 'PT CRM lead view',
  },
  ptCrmLeadCreate: {
    id: 'app.modules.permissions.permissionSetType.ptCrmLeadCreate',
    defaultMessage: 'PT CRM lead create',
  },
  ptCrmLeadEdit: {
    id: 'app.modules.permissions.permissionSetType.ptCrmLeadEdit',
    defaultMessage: 'PT CRM lead edit',
  },
  ptCrmLeadEditStatus: {
    id: 'app.modules.permissions.permissionSetType.ptCrmLeadEditStatus',
    defaultMessage: 'PT CRM lead edit status',
  },
  ptCrmLeadEditSalesperson: {
    id: 'app.modules.permissions.permissionSetType.ptCrmLeadEditSalesperson',
    defaultMessage: 'PT CRM lead edit salesperson',
  },
  ptCrmScriptView: {
    id: 'app.modules.permissions.permissionSetType.ptCrmScriptView',
    defaultMessage: 'PT CRM script view',
  },
  ptCrmScriptCreate: {
    id: 'app.modules.permissions.permissionSetType.ptCrmScriptCreate',
    defaultMessage: 'PT CRM script create',
  },
  ptCrmScriptEdit: {
    id: 'app.modules.permissions.permissionSetType.ptCrmScriptEdit',
    defaultMessage: 'PT CRM script edit',
  },
  ptCrmScriptChangeStatus: {
    id: 'app.modules.permissions.permissionSetType.ptCrmScriptChangeStatus',
    defaultMessage: 'PT CRM script change status',
  },
  ptCrmCampaignView: {
    id: 'app.modules.permissions.permissionSetType.ptCrmCampaignView',
    defaultMessage: 'PT CRM campaign view',
  },
  ptCrmCampaignCreate: {
    id: 'app.modules.permissions.permissionSetType.ptCrmCampaignCreate',
    defaultMessage: 'PT CRM campaign create',
  },
  ptCrmCampaignEdit: {
    id: 'app.modules.permissions.permissionSetType.ptCrmCampaignEdit',
    defaultMessage: 'PT CRM campaign edit',
  },
  // member management group
  memberManagement: {
    id: 'app.modules.permissions.permissionSetType.memberManagement',
    defaultMessage: 'Member management',
  },
  memberView: {
    id: 'app.modules.permissions.permissionSetType.memberView',
    defaultMessage: 'Member view',
  },
  memberCreate: {
    id: 'app.modules.permissions.permissionSetType.memberCreate',
    defaultMessage: 'Member create',
  },
  memberEdit: {
    id: 'app.modules.permissions.permissionSetType.memberEdit',
    defaultMessage: 'Member edit',
  },
  // employee management group
  employeeManagement: {
    id: 'app.modules.permissions.permissionSetType.employeeManagement',
    defaultMessage: 'Employee management',
  },
  employeeView: {
    id: 'app.modules.permissions.permissionSetType.employeeView',
    defaultMessage: 'Employee view',
  },
  employeeCreate: {
    id: 'app.modules.permissions.permissionSetType.employeeCreate',
    defaultMessage: 'Employee create',
  },
  employeeEdit: {
    id: 'app.modules.permissions.permissionSetType.employeeEdit',
    defaultMessage: 'Employee edit',
  },
  employeeChangeStatus: {
    id: 'app.modules.permissions.permissionSetType.memberEdit',
    defaultMessage: 'Employee change status',
  },
  employeeUpdatePermissions: {
    id: 'app.modules.permissions.permissionSetType.employeeUpdatePermissions',
    defaultMessage: 'Employee update permissions',
  },
  employeeTakePhoto: {
    id: 'app.modules.permissions.permissionSetType.employeeTakePhoto',
    defaultMessage: 'Employee take photo',
  },
  employeeViewDocuments: {
    id: 'app.modules.permissions.permissionSetType.employeeViewDocuments',
    defaultMessage: 'Employee view documents',
  },
  employeeUploadDocuments: {
    id: 'app.modules.permissions.permissionSetType.employeeUploadDocuments',
    defaultMessage: 'Employee upload documents',
  },
  // time clock group
  timeClockView: {
    id: 'app.modules.permissions.permissionSetType.employeeView',
    defaultMessage: 'Time clock view',
  },
  timeClockDelete: {
    id: 'app.modules.permissions.permissionSetType.timeClockDelete',
    defaultMessage: 'Time clock delete',
  },
  timeClockIn: {
    id: 'app.modules.permissions.permissionSetType.timeClockIn',
    defaultMessage: 'Time clock in',
  },
  timeClockOut: {
    id: 'app.modules.permissions.permissionSetType.timeClockOut',
    defaultMessage: 'Time clock out',
  },
  timeClockPayrollReportView: {
    id: 'app.modules.permissions.permissionSetType.timeClockPayrollReportView',
    defaultMessage: 'Time clock payroll report view',
  },
  timeClockPayrollReportExport: {
    id: 'app.modules.permissions.permissionSetType.timeClockPayrollReportExport',
    defaultMessage: 'Time clock payroll report export',
  },
  // reporting group
  reporting: {
    id: 'app.modules.permissions.permissionGroupsTitles.reporting',
    defaultMessage: 'Reporting',
  },
  reportsView: {
    id: 'app.modules.permissions.permissionSetType.reportsView',
    defaultMessage: 'Reports view',
  },
  reportsExport: {
    id: 'app.modules.permissions.permissionSetType.reportsExport',
    defaultMessage: 'Reports export',
  },
  overviewReportView: {
    id: 'app.modules.permissions.permissionSetType.overviewReportView',
    defaultMessage: 'Overview report view',
  },
  overviewReportExport: {
    id: 'app.modules.permissions.permissionSetType.overviewReportExport',
    defaultMessage: 'Overview report export',
  },
  salesReportView: {
    id: 'app.modules.permissions.permissionSetType.salesReportView',
    defaultMessage: 'Sales report view',
  },
  salesReportExport: {
    id: 'app.modules.permissions.permissionSetType.salesReportExport',
    defaultMessage: 'Sales report export',
  },
  invoiceReportView: {
    id: 'app.modules.permissions.permissionSetType.invoiceReportView',
    defaultMessage: 'Invoice report view',
  },
  invoiceReportDetailedView: {
    id: 'app.modules.permissions.permissionSetType.invoiceReportDetailedView',
    defaultMessage: 'Invoice report detailed view',
  },
  invoiceReportExport: {
    id: 'app.modules.permissions.permissionSetType.invoiceReportExport',
    defaultMessage: 'Invoice report export',
  },
  invoiceReportViewSnapshots: {
    id: 'app.modules.permissions.permissionSetType.invoiceReportViewSnapshots',
    defaultMessage: 'Invoice report view snapshots',
  },
  invoiceReportMakeSnapshots: {
    id: 'app.modules.permissions.permissionSetType.invoiceReportMakeSnapshot',
    defaultMessage: 'Invoice report make snapshot',
  },
  invoiceReportAssignMember: {
    id: 'app.modules.permissions.permissionSetType.invoiceReportAssignMember',
    defaultMessage: 'Invoice report assign member',
  },
  invoiceReportVoid: {
    id: 'app.modules.permissions.permissionSetType.invoiceReportVoid',
    defaultMessage: 'Invoice report void',
  },
  zOutReportView: {
    id: 'app.modules.permissions.permissionSetType.zOutReportView',
    defaultMessage: 'Z-Out view',
  },
  zOutReportDetailedView: {
    id: 'app.modules.permissions.permissionSetType.zOutReportDetailedView',
    defaultMessage: 'Z-Out detailed view',
  },
  zOutReportExport: {
    id: 'app.modules.permissions.permissionSetType.zOutReportExport',
    defaultMessage: 'Z-Out export',
  },
  overTimeReportView: {
    id: 'app.modules.permissions.permissionSetType.overTimeReportView',
    defaultMessage: 'Overtime report view',
  },
  overTimeReportExport: {
    id: 'app.modules.permissions.permissionSetType.overTimeReportExport',
    defaultMessage: 'Overtime report export',
  },
  registerStatusReportView: {
    id: 'app.modules.permissions.permissionSetType.registerStatusReportView',
    defaultMessage: 'Register status report view',
  },
  registerStatusReportExport: {
    id: 'app.modules.permissions.permissionSetType.registerStatusReportExport',
    defaultMessage: 'Register status report export',
  },
  registerStatusReportClose: {
    id: 'app.modules.permissions.permissionSetType.registerStatusReportClose',
    defaultMessage: 'Register status report close',
  },
  transactionReportView: {
    id: 'app.modules.permissions.permissionSetType.transactionReportView',
    defaultMessage: 'Transaction report view',
  },
  transactionReportExport: {
    id: 'app.modules.permissions.permissionSetType.transactionReportExport',
    defaultMessage: 'Transaction report export',
  },
  redemptionReportView: {
    id: 'app.modules.permissions.permissionSetType.redemptionReportView',
    defaultMessage: 'Redemption report view',
  },
  redemptionReportExport: {
    id: 'app.modules.permissions.permissionSetType.redemptionReportExport',
    defaultMessage: 'Redemption report export',
  },
  redemptionReportUnredeem: {
    id: 'app.modules.permissions.permissionSetType.redemptionReportUnredeem',
    defaultMessage: 'Redemption report unredeem',
  },
  dashboardReportView: {
    id: 'app.modules.permissions.permissionSetType.dashboardReportView',
    defaultMessage: 'Dashboard report view',
  },
  dashboardReportExport: {
    id: 'app.modules.permissions.permissionSetType.dashboardReportExport',
    defaultMessage: 'Dashboard report export',
  },
  campaignReportView: {
    id: 'app.modules.permissions.permissionSetType.campaignReportView',
    defaultMessage: 'Campaign report view',
  },
  campaignReportDetailedView: {
    id: 'app.modules.permissions.permissionSetType.campaignReportDetailedView',
    defaultMessage: 'Campaign report detailed view',
  },
  campaignReportExport: {
    id: 'app.modules.permissions.permissionSetType.campaignReportExport',
    defaultMessage: 'Campaign report export',
  },
  appointmentReportView: {
    id: 'app.modules.permissions.permissionSetType.appointmentReportView',
    defaultMessage: 'Appointment report view',
  },
  appointmentReportDetailedView: {
    id: 'app.modules.permissions.permissionSetType.appointmentReportDetailedView',
    defaultMessage: 'Appointment report detailed view',
  },
  appointmentReportExport: {
    id: 'app.modules.permissions.permissionSetType.appointmentReportExport',
    defaultMessage: 'Appointment report export',
  },
  // corporate level
  corporateSuperUser: {
    id: 'app.modules.permissions.permissionSetType.corporateSuperUser',
    defaultMessage: 'Corporate super user',
  },
  // corporate service management group
  corporateServiceManagement: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateServiceManagement',
    defaultMessage: 'Service management',
  },
  corporateServiceView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateServiceView',
    defaultMessage: 'Service view',
  },
  corporateServiceCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateServiceCreate',
    defaultMessage: 'Service create',
  },
  corporateServiceEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateServiceEdit',
    defaultMessage: 'Service edit',
  },
  corporateServiceChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateServiceChangeStatus',
    defaultMessage: 'Service change status',
  },
  corporateFeeView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateFeeView',
    defaultMessage: 'Fee view',
  },
  corporateFeeCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateFeeCreate',
    defaultMessage: 'Fee create',
  },
  corporateFeeEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateFeeEdit',
    defaultMessage: 'Fee edit',
  },
  corporateFeeChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateFeeChangeStatus',
    defaultMessage: 'Fee change status',
  },
  corporateServiceBundleView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateServiceBundleView',
    defaultMessage: 'Service bundle view',
  },
  corporateServiceBundleCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateServiceBundleCreate',
    defaultMessage: 'Service bundle create',
  },
  corporateServiceBundleEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateServiceBundleEdit',
    defaultMessage: 'Service bundle edit',
  },
  corporateServiceBundleChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateServiceBundleChangeStatus',
    defaultMessage: 'Service bundle change status',
  },
  corporateServiceBundleDuplicate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateFeeChangeStatus',
    defaultMessage: 'Service bundle duplicate',
  },
  corporateContractView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateContractView',
    defaultMessage: 'Contract view',
  },
  corporateContractCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateContractCreate',
    defaultMessage: 'Contract create',
  },
  corporateContractEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateContractEdit',
    defaultMessage: 'Contract edit',
  },
  corporateContractChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateContractChangeStatus',
    defaultMessage: 'Contract change status',
  },
  corporateWaiverView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateWaiverView',
    defaultMessage: 'Waiver view',
  },
  corporateWaiverCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateWaiverCreate',
    defaultMessage: 'Waiver create',
  },
  corporateWaiverEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateWaiverEdit',
    defaultMessage: 'Waiver edit',
  },
  corporateWaiverChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateWaiverChangeStatus',
    defaultMessage: 'Waiver change status',
  },
  // corporate pos content group
  corporateReceiptTemplatesView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateReceiptTemplatesView',
    defaultMessage: 'Receipt templates view',
  },
  corporateReceiptTemplatesCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateReceiptTemplatesCreate',
    defaultMessage: 'Receipt templates create',
  },
  corporateReceiptTemplatesEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateReceiptTemplatesEdit',
    defaultMessage: 'Receipt templates edit',
  },
  corporateReceiptTemplatesChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateReceiptTemplatesChangeStatus',
    defaultMessage: 'Receipt templates change status',
  },
  corporateTaxRateView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateTaxRateView',
    defaultMessage: 'Tax rate view',
  },
  corporateTaxRateCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateTaxRateCreate',
    defaultMessage: 'Tax rate create',
  },
  corporateTaxRateEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateTaxRateEdit',
    defaultMessage: 'Tax rate edit',
  },
  corporateTaxRateChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateTaxRateChangeStatus',
    defaultMessage: 'Tax rate change status',
  },
  corporateRevenueCodeView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateRevenueCodeView',
    defaultMessage: 'Revenue code view',
  },
  corporateRevenueCodeCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateRevenueCodeCreate',
    defaultMessage: 'Revenue code create',
  },
  corporateRevenueCodeEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateRevenueCodeEdit',
    defaultMessage: 'Revenue code edit',
  },
  corporateRevenueCodeChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateRevenueCodeChangeStatus',
    defaultMessage: 'Revenue code change status',
  },
  corporateVendorView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateVendorView',
    defaultMessage: 'Vendor view',
  },
  corporateVendorCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateVendorCreate',
    defaultMessage: 'Vendor create',
  },
  corporateVendorEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateVendorEdit',
    defaultMessage: 'Vendor edit',
  },
  corporateVendorChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateVendorChangeStatus',
    defaultMessage: 'Vendor change status',
  },
  corporateInventoryView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateInventoryView',
    defaultMessage: 'Inventory view',
  },
  corporateInventoryCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateInventoryCreate',
    defaultMessage: 'Inventory create',
  },
  corporateInventoryEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateInventoryEdit',
    defaultMessage: 'Inventory edit',
  },
  corporateInventoryChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateInventoryChangeStatus',
    defaultMessage: 'Inventory change status',
  },
  corporateRegisterView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateRegisterView',
    defaultMessage: 'Register view',
  },
  corporateRegisterViewOne: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateRegisterViewOne',
    defaultMessage: 'Register view one',
  },
  corporateRegisterCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateRegisterCreate',
    defaultMessage: 'Register create',
  },
  corporateRegisterEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateRegisterEdit',
    defaultMessage: 'Register edit',
  },
  corporateRegisterChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateRegisterChangeStatus',
    defaultMessage: 'Register change status',
  },
  corporateDefaultPaymentMethodView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateDefaultPaymentMethodView',
    defaultMessage: 'Default payment method view',
  },
  corporateDefaultPaymentMethodEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateDefaultPaymentMethodEdit',
    defaultMessage: 'Default payment method edit',
  },
  corporateDefaultPaymentMethodChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateDefaultPaymentMethodChangeStatus',
    defaultMessage: 'Default payment method change status',
  },
  corporateCustomPaymentMethodView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateDefaultPaymentMethodView',
    defaultMessage: 'Default payment method view',
  },
  corporateCustomPaymentMethodCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateCustomPaymentMethodCreate',
    defaultMessage: 'Default payment method create',
  },
  corporateCustomPaymentMethodEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateDefaultPaymentMethodEdit',
    defaultMessage: 'Default payment method edit',
  },
  corporateCustomPaymentMethodChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateDefaultPaymentMethodChangeStatus',
    defaultMessage: 'Default payment method change status',
  },
  // corporate dictionaries group
  corporateDictionariesView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateDictionariesView',
    defaultMessage: 'Dictionaries view',
  },
  corporateDictionariesCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateDictionariesCreate',
    defaultMessage: 'Dictionaries create',
  },
  corporateDictionariesEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateDictionariesEdit',
    defaultMessage: 'Dictionaries edit',
  },
  corporateDictionariesChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateDictionariesChangeStatus',
    defaultMessage: 'Dictionaries change status',
  },
  corporateCameFromIndicationView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateCameFromIndicationView',
    defaultMessage: 'Came from indication view',
  },
  corporateCameFromIndicationCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateCameFromIndicationCreate',
    defaultMessage: 'Came from indication create',
  },
  corporateCameFromIndicationEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateCameFromIndicationEdit',
    defaultMessage: 'Came from indication edit',
  },
  corporateCameFromIndicationChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateCameFromIndicationChangeStatus',
    defaultMessage: 'Came from indication change status',
  },
  corporateAdditionalGuestFieldView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateAdditionalGuestFieldView',
    defaultMessage: 'Additional guest field view',
  },
  corporateAdditionalGuestFieldCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateAdditionalGuestFieldCreate',
    defaultMessage: 'Additional guest field create',
  },
  corporateAdditionalGuestFieldEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateAdditionalGuestFieldEdit',
    defaultMessage: 'Additional guest field edit',
  },
  corporateAdditionalGuestFieldChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateAdditionalGuestFieldChangeStatus',
    defaultMessage: 'Additional guest field change status',
  },
  corporateAlertSoundView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateAlertSoundView',
    defaultMessage: 'Alert sound view',
  },
  corporateAlertSoundEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateAlertSoundEdit',
    defaultMessage: 'Alert sound edit',
  },
  corporateNoteCodeView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateNoteCodeView',
    defaultMessage: 'Note code view',
  },
  corporateNoteCodeCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateNoteCodeCreate',
    defaultMessage: 'Note code create',
  },
  corporateNoteCodeEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateNoteCodeEdit',
    defaultMessage: 'Note code edit',
  },
  corporateNoteCodeChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateNoteCodeChangeStatus',
    defaultMessage: 'Note code change status',
  },
  corporateFallthroughReasonView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateFallthroughReasonView',
    defaultMessage: 'Fallthrough reason view',
  },
  corporateFallthroughReasonCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateFallthroughReasonCreate',
    defaultMessage: 'Fallthrough reason create',
  },
  corporateFallthroughReasonEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateFallthroughReasonEdit',
    defaultMessage: 'Fallthrough reason edit',
  },
  corporateFallthroughReasonChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateFallthroughReasonChangeStatus',
    defaultMessage: 'Fallthrough reason change status',
  },
  corporateDepartmentView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateDepartmentView',
    defaultMessage: 'Department view',
  },
  corporateDepartmentCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateDepartmentCreate',
    defaultMessage: 'Department create',
  },
  corporateDepartmentEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateDepartmentEdit',
    defaultMessage: 'Department edit',
  },
  corporateDepartmentChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateDepartmentChangeStatus',
    defaultMessage: 'Department change status',
  },
  corporateClubGroupView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClubGroupView',
    defaultMessage: 'Club group view',
  },
  corporateClubGroupCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClubGroupCreate',
    defaultMessage: 'Club group create',
  },
  corporateClubGroupEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClubGroupEdit',
    defaultMessage: 'Club group edit',
  },
  corporateClubGroupChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClubGroupChangeStatus',
    defaultMessage: 'Club group change status',
  },
  corporateInsuranceCompanyView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateInsuranceCompanyView',
    defaultMessage: 'Insurance company view',
  },
  corporateInsuranceCompanyCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClubGroupCreate',
    defaultMessage: 'Insurance company create',
  },
  corporateInsuranceCompanyEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClubGroupEdit',
    defaultMessage: 'Insurance company edit',
  },
  corporateInsuranceCompanyChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClubGroupChangeStatus',
    defaultMessage: 'Insurance company change status',
  },
  corporateAccessHourView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateAccessHourView',
    defaultMessage: 'Access hour view',
  },
  corporateAccessHourCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateAccessHourCreate',
    defaultMessage: 'Access hour create',
  },
  corporateAccessHourEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateAccessHourEdit',
    defaultMessage: 'Access hour edit',
  },
  corporateAccessHourChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateAccessHourChangeStatus',
    defaultMessage: 'Access hour change status',
  },
  corporateEventTagView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateEventTagView',
    defaultMessage: 'Event tag view',
  },
  corporateEventTagCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateEventTagCreate',
    defaultMessage: 'Event tag create',
  },
  corporateEventTagEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateEventTagEdit',
    defaultMessage: 'Event tag edit',
  },
  corporateEventTagChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateEventTagChangeStatus',
    defaultMessage: 'Event tag change status',
  },
  corporateResourceTagView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateResourceTagView',
    defaultMessage: 'Resource tag view',
  },
  corporateResourceTagCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateResourceTagCreate',
    defaultMessage: 'Resource tag create',
  },
  corporateResourceTagEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateResourceTagEdit',
    defaultMessage: 'Resource tag edit',
  },
  corporateResourceTagChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateResourceTagChangeStatus',
    defaultMessage: 'Resource tag change status',
  },
  corporateNotificationView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateNotificationView',
    defaultMessage: 'Notification view',
  },
  corporateNotificationEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateNotificationEdit',
    defaultMessage: 'Notification edit',
  },
  corporateHelpInformationView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateHelpInformationView',
    defaultMessage: 'Help information view',
  },
  corporateHelpInformationCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateHelpInformationCreate',
    defaultMessage: 'Help information create',
  },
  corporateHelpInformationEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateHelpInformationEdit',
    defaultMessage: 'Help information edit',
  },
  corporateHelpInformationChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateHelpInformationChangeStatus',
    defaultMessage: 'Help information change status',
  },
  // corporate permissions group
  corporateSecurityRoleView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateSecurityRoleView',
    defaultMessage: 'Security role view',
  },
  corporateSecurityRoleCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateSecurityRoleCreate',
    defaultMessage: 'Security role create',
  },
  corporateSecurityRoleEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateSecurityRoleEdit',
    defaultMessage: 'Security role edit',
  },
  corporateSecurityRoleChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateSecurityRoleChangeStatus',
    defaultMessage: 'Security role change status',
  },
  corporateSecurityPolicyView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateSecurityPolicyView',
    defaultMessage: 'Security policy view',
  },
  corporateSecurityPolicyCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateSecurityPolicyCreate',
    defaultMessage: 'Security policy create',
  },
  corporateSecurityPolicyEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateSecurityPolicyEdit',
    defaultMessage: 'Security policy edit',
  },
  corporateSecurityPolicyChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateSecurityPolicyChangeStatus',
    defaultMessage: 'Security policy change status',
  },
  // corporate client management group
  corporateClientManagement: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientManagement',
    defaultMessage: 'Client management',
  },
  corporateClientView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientView',
    defaultMessage: 'Client view',
  },
  corporateClientCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientCreate',
    defaultMessage: 'Client create',
  },
  corporateClientChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientChangeStatus',
    defaultMessage: 'Client change status',
  },
  corporateClientGeneralInfoView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientGeneralInfoView',
    defaultMessage: 'Client general info view',
  },
  corporateClientGeneralInfoEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientGeneralInfoEdit',
    defaultMessage: 'Client general info edit',
  },
  corporateClientBankInfoView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientBankInfoView',
    defaultMessage: 'Client bank info view',
  },
  corporateClientBankInfoEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientBankInfoEdit',
    defaultMessage: 'Client bank info edit',
  },
  corporateClientBillingInfoView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientBillingInfoView',
    defaultMessage: 'Client billing info view',
  },
  corporateClientBillingInfoEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientBillingInfoEdit',
    defaultMessage: 'Client billing info edit',
  },
  corporateClientTaxesInfoView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientTaxesInfoView',
    defaultMessage: 'Client taxes info view',
  },
  corporateClientTaxesInfoEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientTaxesInfoEdit',
    defaultMessage: 'Client taxes info edit',
  },
  corporateClientOtherInfoView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientOtherInfoView',
    defaultMessage: 'Client other info view',
  },
  corporateClientOtherInfoEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientOtherInfoEdit',
    defaultMessage: 'Client other info edit',
  },
  corporateClientWorkingHoursView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientWorkingHoursView',
    defaultMessage: 'Client working hours view',
  },
  corporateClientWorkingHoursEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientWorkingHoursEdit',
    defaultMessage: 'Client working hours edit',
  },
  corporateClientKidzoneSettingsView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientKidzoneSettingsView',
    defaultMessage: 'Client kidzone settings view',
  },
  corporateClientKidzoneSettingsEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientKidzoneSettingsEdit',
    defaultMessage: 'Client kidzone settings edit',
  },
  corporateClientKidzoneSettingsChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateClientKidzoneSettingsChangeStatus',
    defaultMessage: 'Client kidzone settings change status',
  },
  // corporate management group
  corporateManagement: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateManagement',
    defaultMessage: 'Corporate management',
  },
  corporateView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateView',
    defaultMessage: 'Corporate view',
  },
  corporateCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateCreate',
    defaultMessage: 'Corporate create',
  },
  corporateChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateChangeStatus',
    defaultMessage: 'Corporate change status',
  },
  corporateGeneralInfoView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateGeneralInfoView',
    defaultMessage: 'Corporate general info view',
  },
  corporateGeneralInfoEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateGeneralInfoEdit',
    defaultMessage: 'Corporate general info edit',
  },
  corporateTransferOwnership: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateTransferOwnership',
    defaultMessage: 'Corporate transfer ownership',
  },
  corporateBankInfoView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateBankInfoView',
    defaultMessage: 'Corporate bank info view',
  },
  corporateBankInfoEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateBankInfoEdit',
    defaultMessage: 'Corporate bank info edit',
  },
  corporateBillingInfoView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateBillingInfoView',
    defaultMessage: 'Corporate billing info view',
  },
  corporateBillingInfoEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateBillingInfoEdit',
    defaultMessage: 'Corporate billing info edit',
  },
  corporateTaxesInfoView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateTaxesInfoView',
    defaultMessage: 'Corporate taxes info view',
  },
  corporateTaxesInfoEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateTaxesInfoEdit',
    defaultMessage: 'Corporate taxes info edit',
  },
  corporateOtherInfoView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateOtherInfoView',
    defaultMessage: 'Corporate other info view',
  },
  corporateOtherInfoEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateOtherInfoEdit',
    defaultMessage: 'Corporate other info edit',
  },
  corporateIntegrationView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateIntegrationView',
    defaultMessage: 'Corporate integration view',
  },
  corporateIntegrationEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateIntegrationEdit',
    defaultMessage: 'Corporate integration edit',
  },
  corporateIntegrationChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateIntegrationChangeStatus',
    defaultMessage: 'Corporate integration change status',
  },
  corporateRequiredFieldsView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateRequiredFieldsView',
    defaultMessage: 'Corporate required fields view',
  },
  corporateRequiredFieldsEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporateRequiredFieldsEdit',
    defaultMessage: 'Corporate required fields edit',
  },
  corporatePayPeriodSettingsView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporatePayPeriodSettingsView',
    defaultMessage: 'Corporate pay period settings view',
  },
  corporatePayPeriodSettingsCreate: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporatePayPeriodSettingsCreate',
    defaultMessage: 'Corporate pay period settings create',
  },
  corporatePayPeriodSettingsEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporatePayPeriodSettingsEdit',
    defaultMessage: 'Corporate pay period settings edit',
  },
  corporatePayPeriodView: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporatePayPeriodView',
    defaultMessage: 'Corporate pay period view',
  },
  corporatePayPeriodEdit: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporatePayPeriodEdit',
    defaultMessage: 'Corporate pay period edit',
  },
  corporatePayPeriodChangeStatus: {
    id: 'app.modules.permissions.permissionGroupsTitles.corporatePayPeriodChangeStatus',
    defaultMessage: 'Corporate pay period change status',
  },
  // communication
  communication: {
    id: 'app.modules.permissions.permissionGroupsTitles.communication',
    defaultMessage: 'Communication',
  },
  bannerAdvertisementView: {
    id: 'app.modules.permissions.permissionSetType.bannerAdvertisementView',
    defaultMessage: 'Banner advertisement view',
  },
  bannerAdvertisementCreate: {
    id: 'app.modules.permissions.permissionSetType.bannerAdvertisementCreate',
    defaultMessage: 'Banner advertisement create',
  },
  bannerAdvertisementEdit: {
    id: 'app.modules.permissions.permissionSetType.bannerAdvertisementEdit',
    defaultMessage: 'Banner advertisement edit',
  },
  bannerAdvertisementChangeStatus: {
    id: 'app.modules.permissions.permissionSetType.bannerAdvertisementChangeStatus',
    defaultMessage: 'Banner advertisement change status',
  },
  pushNotificationView: {
    id: 'app.modules.permissions.permissionSetType.pushNotificationView',
    defaultMessage: 'Push notification view',
  },
  pushNotificationCreate: {
    id: 'app.modules.permissions.permissionSetType.pushNotificationCreate',
    defaultMessage: 'Push notification create',
  },
  pushNotificationEdit: {
    id: 'app.modules.permissions.permissionSetType.pushNotificationEdit',
    defaultMessage: 'Push notification edit',
  },
  pushNotificationChangeStatus: {
    id: 'app.modules.permissions.permissionSetType.pushNotificationChangeStatus',
    defaultMessage: 'Push notification change status',
  },
  // peak level
  // peak super user
  peakSuperUser: {
    id: 'app.modules.permissions.permissionSetType.peakSuperUser',
    defaultMessage: 'Peak super user',
  },
  // peak help information
  peakHelpInformationView: {
    id: 'app.modules.permissions.permissionSetType.peakHelpInformationView',
    defaultMessage: 'Peak help information view',
  },
  peakHelpInformationCreate: {
    id: 'app.modules.permissions.permissionSetType.peakHelpInformationCreate',
    defaultMessage: 'Peak help information create',
  },
  peakHelpInformationEdit: {
    id: 'app.modules.permissions.permissionSetType.peakHelpInformationEdit',
    defaultMessage: 'Peak help information edit',
  },
  peakHelpInformationChangeStatus: {
    id: 'app.modules.permissions.permissionSetType.peakHelpInformationChangeStatus',
    defaultMessage: 'Peak help information change status',
  },
  // peak employee
  peakEmployeeView: {
    id: 'app.modules.permissions.permissionSetType.peakEmployeeView',
    defaultMessage: 'Peak employee view',
  },
  peakEmployeeCreate: {
    id: 'app.modules.permissions.permissionSetType.peakEmployeeCreate',
    defaultMessage: 'Peak employee create',
  },
  peakEmployeeEdit: {
    id: 'app.modules.permissions.permissionSetType.peakEmployeeEdit',
    defaultMessage: 'Peak employee edit',
  },
  peakEmployeeChangeStatus: {
    id: 'app.modules.permissions.permissionSetType.peakEmployeeChangeStatus',
    defaultMessage: 'Peak employee change status',
  },
  // peak organization create
  peakOrganizationCreate: {
    id: 'app.modules.permissions.permissionSetType.peakOrganizationCreate',
    defaultMessage: 'Peak organization create',
  },
  peakOrganizationView: {
    id: 'app.modules.permissions.permissionSetType.peakOrganizationView',
    defaultMessage: 'Peak organization view',
  },
  peakOrganizationEdit: {
    id: 'app.modules.permissions.permissionSetType.peakOrganizationEdit',
    defaultMessage: 'Peak organization edit',
  },
  peakOrganizationChangeStatus: {
    id: 'app.modules.permissions.permissionSetType.peakOrganizationChangeStatus',
    defaultMessage: 'Peak organization change status',
  },
  // peak integration
  peakIntegrationView: {
    id: 'app.modules.permissions.permissionSetType.peakIntegrationView',
    defaultMessage: 'Peak integration view',
  },
  peakIntegrationEdit: {
    id: 'app.modules.permissions.permissionSetType.peakIntegrationEdit',
    defaultMessage: 'Peak integration edit',
  },
  peakIntegrationChangeStatus: {
    id: 'app.modules.permissions.permissionSetType.peakIntegrationChangeStatus',
    defaultMessage: 'Peak integration change status',
  },
  // peak security role
  peakSecurityRoleView: {
    id: 'app.modules.permissions.permissionSetType.peakSecurityRoleView',
    defaultMessage: 'Peak security role view',
  },
  peakSecurityRoleCreate: {
    id: 'app.modules.permissions.permissionSetType.peakSecurityRoleCreate',
    defaultMessage: 'Peak security role create',
  },
  peakSecurityRoleEdit: {
    id: 'app.modules.permissions.permissionSetType.peakSecurityRoleEdit',
    defaultMessage: 'Peak security role edit',
  },
  peakSecurityRoleChangeStatus: {
    id: 'app.modules.permissions.permissionSetType.peakSecurityRoleChangeStatus',
    defaultMessage: 'Peak security role change status',
  },
  // peak security policy
  peakSecurityPolicyView: {
    id: 'app.modules.permissions.permissionSetType.peakSecurityPolicyView',
    defaultMessage: 'Peak security policy view',
  },
  peakSecurityPolicyCreate: {
    id: 'app.modules.permissions.permissionSetType.peakSecurityPolicyCreate',
    defaultMessage: 'Peak security policy create',
  },
  peakSecurityPolicyEdit: {
    id: 'app.modules.permissions.permissionSetType.peakSecurityPolicyEdit',
    defaultMessage: 'Peak security policy edit',
  },
  peakSecurityPolicyChangeStatus: {
    id: 'app.modules.permissions.permissionSetType.peakSecurityPolicyChangeStatus',
    defaultMessage: 'Peak security policy change status',
  },

  searchPermissions: {
    id: 'app.modules.permissions.permissionGroupsTitles.searchPermissions',
    defaultMessage: 'Search permissions',
  },
});
