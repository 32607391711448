import React, { useCallback, useEffect, useState } from 'react';
import { batch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import cx from 'classnames';
import { Dialog, makeStyles, Paper, Popover, useMediaQuery } from '@material-ui/core';
import { createStyles, useTheme } from '@material-ui/core/styles';

import { useAppDispatch } from 'store/hooks';

// interfaces
import {
  EventUpdateType,
  IBookingEventImt,
  IEventFormValues,
  IUpdatedEventDto,
} from 'modules/booking/interfaces';
// constants
import { DictionaryList } from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import {
  BookingNotificationsEvents,
  DefaultNotificationsEvents,
  ENotificationType,
  EShortNotificationType,
  FrontDeskAppointmentNotificationsEvents,
} from 'modules/booking/constants/notificationType';
// components
import { EventForm } from 'modules/booking/components/index';
import UpdateTypeModal from '../EventForm/UpdateTypeModal/UpdateTypeModal';
// state
import { fetchDictionaryList, resetDictionaryListAction } from 'common/state/dictionary/actions';
import {
  fetchAppointmentServices,
  resetPersonAppointmentServices,
} from 'common/components/PersonProfile/state/appointments/actions';
import { fetchEventServicesThunk, resetEventServices } from 'modules/booking/state/events/actions';
import {
  fetchSenderAvailabilityThunk,
  resetBookingEvents,
} from 'modules/booking/state/senderAvailability/actions';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';
import { changeDateWithTimezone } from 'modules/booking/utils/time';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '&>.MuiPaper-root': {
        height: isOverflow => (isOverflow ? 'calc(100vh - 64px)' : 'auto'),
        top: '50% !important',
        transform: 'translateY(-50%) !important',
        'scrollbar-width': 'none',
        '-ms-overflow-style': 'none',

        '&::-webkit-scrollbar': {
          width: 0,
          height: 0,
        },
      },
      '&>.MuiDialog-container>.MuiPaper-root': {
        height: isOverflow => (isOverflow ? 'calc(100vh - 64px)' : 'auto'),
      },
      '&.fullScreenDialog': {
        '&>.MuiDialog-container>.MuiPaper-root': {
          height: '100%',
        },
      },
    },
    paper: {
      width: '400px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      '&.fullWidth': {
        width: '100%',
      },
    },
  }),
);

interface IEditEventProps {
  personId?: string;
  eventId?: string;
  eventDate?: string;
  event?: IBookingEventImt;
  isDialog?: boolean;
  anchorEl: HTMLElement;
  initialData?: Partial<IEventFormValues>;
  module: PeakModules;
  isEventActionLoading?: boolean;
  isEventLoading: boolean;
  onClose?: () => void;
  onSubmit: (formValues: IUpdatedEventDto, eventDate: string) => void;
  onLoadEvent?: (eventId: string, eventDate: string) => void;
  onResetEvent?: () => void;
  type: SenderAvailabilityTypeList;
}

const transformFormValues = (formValues: IEventFormValues): IUpdatedEventDto => {
  const { durationInMinutes } = formValues;

  return {
    ...formValues,
    durationInMinutes: moment.duration(durationInMinutes).asMinutes(),
  };
};

const EditEventModal = ({
  eventId,
  eventDate,
  event,
  anchorEl,
  initialData,
  isDialog,
  module,
  personId,
  isEventActionLoading,
  isEventLoading,
  onClose,
  onSubmit,
  onLoadEvent,
  onResetEvent,
  type,
}: IEditEventProps): JSX.Element => {
  const theme = useTheme();
  const fullScreen = !useMediaQuery(theme.breakpoints.up('laptop'), { noSsr: true });

  // local state
  const [isOverflow, setIsOverflow] = useState(true);
  const [isOpenEditType, setIsOpenEditType] = useState<boolean>(false);
  const classes = useStyles(isOverflow);
  const [updatedFormData, setUpdatedFormData] = useState<IUpdatedEventDto>(null);

  // global state

  const dispatch = useAppDispatch();

  const selectedClubId = useSelector(selectUserSelectedClubId);

  // handlers
  useEffect(() => {
    if (anchorEl) {
      dispatch(fetchDictionaryList(DictionaryList.EVENT_TAGS, { module, usedId: personId }));
    }
  }, [dispatch, anchorEl, module, personId]);

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(resetDictionaryListAction({ dictionary: DictionaryList.PARTICIPANTS }));
        onResetEvent();
      });
    };
  }, [dispatch, onResetEvent, anchorEl]);

  const handleOverflow = useCallback(
    overflow => {
      setIsOverflow(overflow);
    },
    [setIsOverflow],
  );

  const loadResources = useCallback(
    (clubId: string) => {
      dispatch(fetchDictionaryList(DictionaryList.RESOURCES, { module, personId, clubId }));
    },
    [dispatch, module, personId],
  );

  const handleSubmit = useCallback(
    (values: IEventFormValues) => {
      const formattedValues = {
        ...values,
        persons: values.persons.map(person => {
          return {
            ...person,
            joinedDate: moment(`${moment(person.joinedDate).format('YYYY-MM-DD')} ${values.time}`)
              .utc(true)
              .toISOString(),
            ...(person.eventDate
              ? {
                  eventDate: moment(
                    `${moment(person.eventDate).format('YYYY-MM-DD')} ${values.time}`,
                  )
                    .utc(true)
                    .toISOString(),
                }
              : {}),
          };
        }),
      };

      if (eventId && event && event.get('repeated')) {
        setIsOpenEditType(true);
        setUpdatedFormData(transformFormValues(formattedValues));
      } else {
        const { date, time, club } = values;
        // TODO: do we need it on backend for non recurring?

        // TODO: Check the necessity of this logic
        // const isSameDate = moment(event.get('date')).isSame(date);

        // let dateWithTime = [];
        //
        // if (isSameDate) {
        //   dateWithTime = [event.get('date'), event.get('time')];
        // } else {
        //   dateWithTime = moment
        //     .tz(`${date} ${time}`, club?.timezone)
        //     .utc()
        //     .utcOffset(moment.tz(club?.timezone).utcOffset())
        //     .format('YYYY-MM-DD HH:mm')
        //     .split(' ');
        // }
        //
        // const [convertedDate, convertedTime] = dateWithTime;

        const updateDto: IUpdatedEventDto = {
          ...transformFormValues(formattedValues),
          oldDate: eventDate,
          updateType: EventUpdateType.All,
          repeatingEndDate: moment(`${event.get('repeatingEndDate')}Z`).isSame(
            values.repeatingEndDate,
          )
            ? event.get('repeatingEndDate')
            : changeDateWithTimezone(values?.repeatingEndDate, club?.timezone),
          date,
          time,
        };

        onSubmit(
          updateDto,
          moment.utc(`${values.date} ${values.time}`, 'YYYY-MM-DD HH:mm').format(),
        );
      }
    },
    [eventId, event, eventDate, onSubmit],
  );

  const clearSearchServicesResult = (id?: string) => {
    dispatch(id ? resetPersonAppointmentServices(null, id) : resetEventServices());
  };

  const searchServices = useCallback(
    (search: string, id?: string) => {
      dispatch(
        module === PeakModules.Booking
          ? fetchEventServicesThunk(search)
          : fetchAppointmentServices(id, search, module),
      );
    },
    [dispatch, module],
  );

  const handleSubmitEditTypeModal = (updateType: EventUpdateType): void => {
    onSubmit(
      { ...updatedFormData, updateType, oldDate: eventDate },
      moment(eventDate).toISOString(),
    );

    setUpdatedFormData(null);
    setIsOpenEditType(false);
  };

  const handleCancelEditTypeModal = () => setIsOpenEditType(false);

  // effects

  useEffect(() => {
    onLoadEvent(eventId, eventDate);
  }, [eventDate, eventId, onLoadEvent]);

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(resetDictionaryListAction({ dictionary: DictionaryList.PARTICIPANTS }));
        onResetEvent();
      });
    };
  }, [dispatch, onResetEvent, anchorEl]);

  // renders

  const renderEventForm = () => (
    <EventForm
      eventDate={eventDate}
      event={event}
      personId={personId}
      isSubmitting={isEventActionLoading}
      isEventLoading={isEventLoading}
      onCancel={onClose}
      onLoadResources={loadResources}
      clearSearchServicesResult={clearSearchServicesResult}
      searchServices={searchServices}
      handleOverflow={handleOverflow}
      onSubmit={handleSubmit}
      initialData={initialData}
      selectedClubId={selectedClubId}
      module={module}
      type={type}
    />
  );

  const getNotificationEvent = useCallback(
    (shortTypeEvent: EShortNotificationType): ENotificationType => {
      switch (module) {
        case PeakModules.Booking:
          return BookingNotificationsEvents[shortTypeEvent];
        case PeakModules.FrontDesk:
          return FrontDeskAppointmentNotificationsEvents[shortTypeEvent];
        default:
          return DefaultNotificationsEvents[shortTypeEvent];
      }
    },
    [module],
  );

  useEffect(() => {
    dispatch(
      fetchSenderAvailabilityThunk([type, SenderAvailabilityTypeList.REMIND], {
        module,
        events: [
          getNotificationEvent(eventId ? EShortNotificationType.Edit : EShortNotificationType.New),
          getNotificationEvent(EShortNotificationType.Remind),
        ],
      }),
    );

    return () => {
      batch(() => {
        dispatch(resetBookingEvents({ type }));
        dispatch(resetBookingEvents({ type: SenderAvailabilityTypeList.REMIND }));
      });
    };
  }, [dispatch, getNotificationEvent, module, type, eventId]);

  return (
    <>
      {fullScreen || isDialog ? (
        <Dialog
          className={cx(classes.root, {
            fullScreenDialog: fullScreen,
          })}
          open={Boolean(anchorEl)}
          fullScreen={fullScreen}
          onClose={onClose}
          disableBackdropClick
          disableRestoreFocus
        >
          <Paper className={cx(classes.paper, { fullWidth: fullScreen })}>
            {renderEventForm()}
          </Paper>
        </Dialog>
      ) : (
        <Popover
          className={classes.root}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          disableBackdropClick
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={onClose}
        >
          <Paper className={classes.paper}>{renderEventForm()}</Paper>
        </Popover>
      )}

      {isOpenEditType && (
        <UpdateTypeModal
          open={isOpenEditType}
          onSubmit={handleSubmitEditTypeModal}
          onCancel={handleCancelEditTypeModal}
        />
      )}
    </>
  );
};

export default EditEventModal;
