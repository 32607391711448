import axios from 'axios';
import ApiService from './ApiService';
import {
  ICameFromIndicatorDictionaryItem,
  IDictionaryOptions,
  IEmployeeListItem,
  IEventListItem,
  IInventoryCategoriesListItem,
  IMemberListItem,
  INoteCodeDictionaryItem,
  IParticipantListItem,
  IRecieptsListItem,
  IRegisterDictionary,
  IResourceListItem,
  IRevenueCodesListItem,
  ISalesPersonListItem,
  IServiceTagListItem,
  IStatusListItem,
  ITagListItem,
  ITaxesListItem,
  IVendorsListItem,
  TAllergyListItem,
  TCampaignsDictionaryImt,
  TDocumentTemplateListItem,
} from 'common/interfaces/dictionary';
import { INamedEntity, IStateItem } from 'common/interfaces/common';
import { IPageMeta, IPaginatedData } from 'common/interfaces/pagination';
import { EventAttendeeType, IShortResource } from 'modules/booking/interfaces';
import { IShortPackage } from 'common/interfaces/membership';

import { TemplatePurpose, TemplateTypes } from 'common/constants/documentTemplate';
import { CrmSubModules, PeakModules, ServicesSubModules } from 'common/constants/peakModules';
import { DictionaryList } from 'common/constants/dictionaryConstants';
import { ITableParams } from 'common/interfaces/table';
import { INoteCode } from 'common/components/PersonProfile/interfaces';
import { IDictionaryCommonItem, IEndReasonItem } from 'modules/dictionaries/interfaces/interfaces';
import { IAccessHoursItem } from 'modules/dictionaries/interfaces/accessHours';
import { GrapesCustomCommands } from 'modules/crm/components/EmailEditor/constants';
import { services } from 'server/mocks/dictionaries';
import { PtCrmRoutes } from 'modules/personal-training-crm/routes';

export type ResultListItems =
  | INamedEntity[]
  | IEmployeeListItem[]
  | TCampaignsDictionaryImt[]
  | IParticipantListItem[]
  | IMemberListItem[]
  | IRevenueCodesListItem[]
  | IResourceListItem[]
  | IRecieptsListItem[]
  | IEventListItem[]
  | ITagListItem[]
  | TDocumentTemplateListItem[]
  | IStatusListItem[]
  | ISalesPersonListItem[]
  | IInventoryCategoriesListItem[]
  | IVendorsListItem[]
  | ITaxesListItem[]
  | IShortPackage[]
  | IStateItem[]
  | ICameFromIndicatorDictionaryItem[]
  | INoteCodeDictionaryItem[]
  | IDictionaryCommonItem[]
  | TAllergyListItem[]
  | IRegisterDictionary[];

export interface IResult<T extends ResultListItems = ResultListItems> {
  data: T;
  meta?: IPageMeta | null;
}

const getObjectRequestParams = (page, perPage) => {
  const requestParams: { page?: number | string; perPage?: number | string } = {};
  if (page !== undefined) {
    requestParams.page = page;
  }

  if (perPage !== undefined) {
    requestParams.perPage = perPage;
  }

  return requestParams;
};

export default class DictionaryListsService extends ApiService {
  public fetchDictionaryByName = async (
    dictionary: DictionaryList,
    options?: IDictionaryOptions,
  ): Promise<IResult> => {
    switch (dictionary) {
      case DictionaryList.RECEIPTS:
        return this.getReceiptsList(options);
      case DictionaryList.REGISTERS:
        return this.getRegistersList(options);
      case DictionaryList.CAMPAIGNS:
        return this.getCampaignList();
      case DictionaryList.PT_CAMPAIGNS:
        return this.getPtCampaignList();
      case DictionaryList.LEADS:
        return this.getLeadList(options);
      case DictionaryList.REPORTS_MEMBERS:
        return this.getReportsMembersList(options);
      case DictionaryList.REPORTS_INVENTORIES:
        return this.getReportsInventoriesList(options);
      case DictionaryList.REPORTS_SERVICES:
        return this.getReportsServicesList(options);
      case DictionaryList.REPORTS_EMPLOYEES:
        return this.getReportsEmployeeList(options);
      case DictionaryList.REVENUE_CODES:
        return this.getRevenueCodesList(options);
      case DictionaryList.CLUBS:
        return this.getClubsList(options);
      case DictionaryList.EMPLOYEES:
        return this.getEmployeesList(options);
      case DictionaryList.CRM_TEMPLATE_ITEMS:
        return this.getCrmTemplateItemList(options);
      case DictionaryList.CAME_FROM:
        return this.getCameFromIndicatorsDictionary(options.module);
      case DictionaryList.NOTE_CODES:
        return this.getNoteCodesDictionary();
      case DictionaryList.MESSAGING_CAMPAIGN_EMAIL:
        return this.getMessagingCampaignEmail(options);
      case DictionaryList.MESSAGING_CAMPAIGN_SMS:
        return this.getMessagingCampaignSMS(options);
      case DictionaryList.MESSAGING_CAMPAIGN_PHONE:
        return this.getMessagingCampaignPhone(options);
      case DictionaryList.SALESPERSONS:
        return this.getSalesPersons(options);
      case DictionaryList.MEMBERS:
        return this.getMembersList();
      case DictionaryList.INVENTORY_CATEGORIES:
      case DictionaryList.FRONTDESK_PROFILES:
      case DictionaryList.TAGS:
        return this.getTags(options);
      case DictionaryList.ALLERGIES:
        return this.getAllergies(options);
      case DictionaryList.EVENT_TAGS:
        return this.getEventTags(options.module, options.usedId);
      case DictionaryList.RESOURCE_TAGS: {
        return this.getResourceTags({ ...options });
      }
      case DictionaryList.SERVICES:
        return this.getServiceTagsList();
      case DictionaryList.DOCUMENT_TEMPLATES:
        return this.getDocumentTemplatesList(options.type, options.purpose, options.module);
      case DictionaryList.MEMBERSHIP_PACKAGES:
        return this.getMembershipPackages();
      case DictionaryList.TAXES_BY_TAX_RATE_ID:
        return this.getTaxesListByTaxRateId(options);
      case DictionaryList.TAXES:
        return this.getTaxesList(options);
      case DictionaryList.VENDORS:
        return this.getVendorsList(options);
      case DictionaryList.STATES:
        return this.getStatesList(options);
      case DictionaryList.COUNTRIES:
        return this.getCountriesList(options);
      case DictionaryList.FALLTHROUGH_REASONS:
        return this.getFallthroughReasonsList(options);
      case DictionaryList.PARTICIPANTS:
        return this.getParticipantsList(options.module, options.types, options.personId);
      case DictionaryList.DEPARTMENTS:
        return this.getDepartmentsList(options);
      case DictionaryList.TIMECLOCK_DEPARTMENTS:
        return this.getTimeclockDepartments(options);
      case DictionaryList.TIMECLOCK_EMPLOYEES:
        return this.getTimeclockEmployees(options);
      case DictionaryList.RESOURCES:
        return this.getResourcesList(options.module, options.clubId, options.personId);
      case DictionaryList.INSURANCE_COMPANY:
        return this.getInsuranceCompanies(options.module);
      case DictionaryList.TIME_ACCESS:
        return this.getTimeAccess(options);
      case DictionaryList.ADDITIONAL_FIELDS:
        return this.getAdditionalFields(options.module);
      case DictionaryList.END_REASON:
        return this.getEndReason(options);
      default:
        throw new Error(`Unsupported dictionary list: ${dictionary}`);
    }
  };

  // TODO: It is not a dictionary. Move it to another file.
  public getCampaignList = (): Promise<IPaginatedData<TCampaignsDictionaryImt>> =>
    axios.get('api/v1/tasks/campaigns');

  // TODO: It is not a dictionary. Move it to another file.
  public getPtCampaignList = (): Promise<IPaginatedData<TCampaignsDictionaryImt>> =>
    axios.get('api/v1/pt-tasks/campaigns');

  // TODO: It is not a dictionary. Move it to another file.
  public getLeadList = (options: IDictionaryOptions): Promise<IPaginatedData<INamedEntity>> => {
    const { search, page, perPage, module } = options || {};

    const params = {
      perPage,
      page,
      search,
    };

    if (module === PeakModules.Crm) {
      return axios.get('api/v1/tasks/leads', { params });
    }

    return axios.get('api/v1/pt-tasks/leads', { params });
  };

  public getReportsMembersList = (
    options: IDictionaryOptions,
  ): Promise<IPaginatedData<INamedEntity>> => {
    return axios.get('api/v1/reports/dictionary/members', {
      params: options,
    });
  };

  public getReportsInventoriesList = (
    options: IDictionaryOptions,
  ): Promise<IPaginatedData<INamedEntity>> => {
    const { search, page, perPage } = options || {};

    const params = {
      perPage,
      page,
      search,
    };
    return axios.get('api/v1/reports/dictionary/inventories', { params });
  };

  public getReportsServicesList = (
    options: IDictionaryOptions,
  ): Promise<IPaginatedData<INamedEntity>> => {
    const { search, page, perPage } = options || {};

    const params = {
      perPage,
      page,
      search,
    };
    return axios.get('api/v1/reports/dictionary/services', { params });
  };

  public getReportsEmployeeList = (
    options: IDictionaryOptions,
  ): Promise<IPaginatedData<INamedEntity>> => {
    const { search, page, perPage } = options || {};

    const params = {
      perPage,
      page,
      search,
    };
    return axios.get('api/v1/reports/dictionary/employees', { params });
  };

  public getReceiptsList = (
    options?: IDictionaryOptions,
  ): Promise<IPaginatedData<IRecieptsListItem>> =>
    axios.get('/api/v1/receipt/dictionaries', {
      params: options,
    });

  public getMessagingCampaignEmail = (
    options?: IDictionaryOptions,
  ): Promise<IPaginatedData<INamedEntity>> => {
    const { personId, module } = options || {};
    if (module === PeakModules.Crm) {
      return axios.get(`/api/v1/leads/${personId}/messaging/campaign-dictionary/email`, {
        params: options,
      });
    }

    return axios.get(`/api/v1/pt-leads/${personId}/messaging/campaign-dictionary/email`, {
      params: options,
    });
  };

  public getMessagingCampaignSMS = (
    options?: IDictionaryOptions,
  ): Promise<IPaginatedData<INamedEntity>> => {
    const { personId, module } = options || {};
    if (module === PeakModules.Crm) {
      return axios.get(`/api/v1/leads/${personId}/messaging/campaign-dictionary/sms`, {
        params: options,
      });
    }

    return axios.get(`/api/v1/pt-leads/${personId}/messaging/campaign-dictionary/sms`, {
      params: options,
    });
  };

  public getMessagingCampaignPhone = (
    options?: IDictionaryOptions,
  ): Promise<IPaginatedData<INamedEntity>> => {
    const { module, personId } = options || {};

    if (module === PeakModules.Crm) {
      return axios.get(`/api/v1/leads/${personId}/messaging/campaign-dictionary/phone`, {
        params: options,
      });
    }

    return axios.get(`/api/v1/pt-leads/${personId}/messaging/campaign-dictionary/phone`, {
      params: options,
    });
  };

  public getRegistersList = (
    options?: IDictionaryOptions,
  ): Promise<IPaginatedData<IRegisterDictionary>> => {
    const { module, ...otherOptions } = options || {};

    switch (module) {
      case PeakModules.Members:
        return axios.get('/api/v1/members/pos/register/dictionary', { params: otherOptions });
      case PeakModules.Crm:
        return axios.get('/api/v1/leads/pos/register/dictionary', { params: otherOptions });
      case PeakModules.PersonalTrainingCrm:
        return axios.get('/api/v1/pt-leads/pos/register/dictionary', { params: otherOptions });
      case PeakModules.PosKiosk:
        return axios.get('/api/v1/pos/register/dictionary', { params: otherOptions });
      case PeakModules.FrontDesk:
        return axios.get(
          `/api/v1/front-desk/${options.isCustomer ? 'customer-' : ''}pos/register/dictionary`,
          { params: otherOptions },
        );
      default:
        return axios.get('/api/v1/reports/dictionary/registers', { params: otherOptions });
    }
  };

  public getRevenueCodesList = (
    options?: IDictionaryOptions,
  ): Promise<IPaginatedData<IRevenueCodesListItem>> =>
    axios.get('/api/v1/revenue-code/dictionary', {
      params: options,
    });

  // TODO: It is not a dictionary. Move it to another file.
  public getClubsList = async (
    module: PeakModules | ServicesSubModules,
  ): Promise<IResult<INamedEntity[]>> => {
    switch (module) {
      case ServicesSubModules.ServicePackages:
        return axios.get('/api/v1/packages/clubs');
      default:
        return Promise.resolve({ data: [], meta: null });
    }
  };

  // TODO: It is not a dictionary. Move it to another file.
  public getEmployeesList = (
    options: IDictionaryOptions,
  ): Promise<IPaginatedData<INamedEntity>> => {
    const { search, page, perPage, module, corporationId } = options || {};

    const params = {
      perPage,
      page,
      search,
    };
    switch (module) {
      case PeakModules.FrontDesk:
        return axios.get('/api/v1/front-desk/employees');
      case PeakModules.Crm:
        return axios.get('/api/v1/leads/employees', { params });
      case PeakModules.PersonalTrainingCrm:
        return axios.get('/api/v1/pt-leads/employees', { params });
      case PeakModules.Members:
        return axios.get('/api/v1/members/employees');
      case PeakModules.Booking:
        return axios.get('/api/v1/booking-resources/employees', { params });
      case PeakModules.Reports:
        return axios.get('/api/v1/reports/dictionary/employees');
      case PeakModules.KidZone:
        return axios.get('/api/v1/kid-zone/employees');
      case ServicesSubModules.ServicePackages:
        return axios.get('/api/v1/packages/employees');
      case PeakModules.PosKiosk:
        return axios.get('/api/v1/pos/employees');
      case PeakModules.Corporations:
        return axios.get(`/api/v1/corporations/${corporationId}/dictionary/employees`, { params });
      default:
        return axios.get('/api/v1/employees');
    }
  };

  // TODO: It is not a dictionary. Move it to another file.
  public getTasksEmployeesList = (
    options: IDictionaryOptions,
  ): Promise<IResult<INamedEntity[]>> => {
    const { search, page, perPage, module } = options || {};

    const params = {
      perPage,
      page,
      search,
    };
    switch (module) {
      case PeakModules.Crm:
        return axios.get('api/v1/tasks/employees', { params });
      case PeakModules.PersonalTrainingCrm:
        return axios.get('api/v1/pt-tasks/employees', { params });
      default:
        return Promise.resolve({ data: [], meta: null });
    }
  };

  // TODO: It is not a dictionary. Move it to another file.
  public getCrmTemplateItemList = (
    options?: IDictionaryOptions,
  ): Promise<IPaginatedData<IEmployeeListItem>> => {
    const { commandType, ...restOpt } = options;
    const crmName =
      window.location.pathname.indexOf('/personal-training-crm') === -1 ? 'crm' : 'pt-crm';
    switch (commandType as GrapesCustomCommands) {
      case GrapesCustomCommands.SearchEmployees: {
        return axios.get(`/api/v1/${crmName}/document-template/employees`, {
          params: {
            ...restOpt,
            ...getObjectRequestParams(restOpt?.page, restOpt?.perPage),
          },
        });
      }
      case GrapesCustomCommands.SearchServices: {
        return axios.get(`/api/v1/${crmName}/document-template/packages`, {
          params: {
            ...restOpt,
            ...getObjectRequestParams(restOpt?.page, restOpt?.perPage),
          },
        });
      }
      case GrapesCustomCommands.SearchProducts: {
        return axios.get(`/api/v1/${crmName}/document-template/inventories`, {
          params: {
            ...restOpt,
            ...getObjectRequestParams(restOpt?.page, restOpt?.perPage),
          },
        });
      }
      default:
        return Promise.resolve({
          data: [],
          meta: {
            page: 0,
            perPage: 0,
            total: 0,
          },
        });
    }
  };

  public getCameFromIndicatorsDictionary = (
    module: PeakModules | CrmSubModules,
  ): Promise<IResult<ICameFromIndicatorDictionaryItem[]>> => {
    switch (module) {
      case PeakModules.FrontDesk:
        return axios.get('/api/v1/front-desk/came-from/dictionary');
      case PeakModules.Crm:
        return axios.get('/api/v1/leads/came-from/dictionary');
      case PeakModules.PersonalTrainingCrm:
        return axios.get('/api/v1/pt-leads/came-from/dictionary');
      case CrmSubModules.Campaigns:
        return axios.get('/api/v1/campaign/came-from/dictionary');
      case PeakModules.Members:
        return axios.get('/api/v1/members/came-from/dictionary');
      case PeakModules.Reports:
        return axios.get('/api/v1/reports/dictionary/came-from');
      case PeakModules.PosKiosk:
        return axios.get('/api/v1/pos/came-from/dictionary');
      case PeakModules.KidZone:
        return axios.get('/api/v1/kid-zone/came-from/dictionary');
      default:
        return Promise.resolve({ data: [], meta: null });
    }
  };

  public getNoteCodesDictionary = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedData<INoteCode>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/note-code/dictionary', { params: searchParams });
  };

  public searchNoteCodesDictionary = (
    search: string,
  ): Promise<IPaginatedData<INoteCodeDictionaryItem>> =>
    axios.get('/api/v1/note-code/dictionary', { params: { search } });

  // TODO: It is not a dictionary. Move it to another file.
  public getSalesPersons = (options?: {
    corporationId: string;
  }): Promise<IPaginatedData<ISalesPersonListItem>> =>
    axios.get('/api/v1/employees', {
      params: { status: 'ACTIVE', role: 'SALESPERSON', ...options },
    });

  // TODO: It is not a dictionary. Move it to another file.
  public getMembersList = (): Promise<IPaginatedData<IMemberListItem>> => {
    return axios.get('/api/v1/members');
  };

  public getServiceTagsList = (): Promise<IResult<IServiceTagListItem[]>> =>
    new Promise(resolve => setTimeout(() => resolve({ data: services, meta: null }), 500));

  public getDocumentTemplatesList = (
    type: TemplateTypes,
    purpose: TemplatePurpose,
    module: PeakModules,
  ): Promise<IResult<TDocumentTemplateListItem[]>> => {
    const params = { documentTypeList: type, purposeTypeList: purpose };

    switch (module) {
      case PeakModules.Crm:
        return axios.get('/api/v1/crm/document-template/dictionaries', { params });
      case PeakModules.PersonalTrainingCrm:
        return axios.get('/api/v1/pt-crm/document-template/dictionaries', { params });
      case PeakModules.Services:
        return axios.get('/api/v1/services/document-template/dictionaries', { params });
      default:
        return Promise.resolve({ data: [], meta: null });
    }
  };

  // TODO: It is not a dictionary. Move it to another file.
  public getTags = (options?: IDictionaryOptions): Promise<IPaginatedData<ITagListItem>> =>
    axios.get('/api/v1/tag', {
      params: { tagType: options?.tagType },
    });

  // TODO: It is not a dictionary. Move it to another file.
  public getAllergies = (options?: IDictionaryOptions): Promise<IPaginatedData<TAllergyListItem>> =>
    axios.get(`/api/v1/allergies`, { params: options });

  // TODO: It is not a dictionary. Move it to another file. Request for PeakModules.Reports type leave here
  public getEventTags = (
    module?: PeakModules,
    usedId?: string,
  ): Promise<IPaginatedData<IDictionaryCommonItem>> => {
    switch (module) {
      case PeakModules.FrontDesk:
        return axios.get(`/api/v1/front-desk/${usedId}/tour/event-tags`);
      case PeakModules.Crm:
        return axios.get(`/api/v1/leads/${usedId}/appointment/event-tags`);
      case PeakModules.PersonalTrainingCrm:
        return axios.get(`/api/v1/pt-leads/${usedId}/appointment/event-tags`);
      case PeakModules.Members:
        return axios.get(`/api/v1/members/${usedId}/appointment/event-tags`);
      case PeakModules.Booking:
        return axios.get(`/api/v1/booking-resources/event-tags`);
      case PeakModules.Reports:
        return axios.get(`/api/v1/reports/event-tag/dictionary`);
      default:
        return axios.get('/api/v1/events/event-tags');
    }
  };

  public getResourceTags = (
    options?: IDictionaryOptions,
  ): Promise<IPaginatedData<ITagListItem>> => {
    const { module, ...restOpt } = options;
    switch (module) {
      case PeakModules.Booking:
        return axios.get('/api/v1/booking-resources/resource-tags', { params: restOpt });
      case PeakModules.Reports:
        return axios.get('/api/v1/reports/resource-tag/dictionary');
      default:
        return axios.get('/api/v1/resource-tag/dictionary', { params: restOpt });
    }
  };

  public getVendorsList = (
    options?: IDictionaryOptions,
  ): Promise<IPaginatedData<IVendorsListItem>> =>
    axios.get('/api/v1/vendor/dictionary', {
      params: {
        ...options,
        ...getObjectRequestParams(options?.page, options?.perPage),
      },
    });

  public getTaxesListByTaxRateId = (
    options?: IDictionaryOptions,
  ): Promise<IPaginatedData<ITaxesListItem>> => {
    const ops = options || {};
    const { taxRateId = '', ...restOptions } = ops;

    return axios.get(`/api/v1/taxes/${taxRateId}/dictionaries`, {
      params: {
        ...restOptions,
        ...getObjectRequestParams(restOptions.page, restOptions.perPage),
      },
    });
  };

  public getTaxesList = (options?: IDictionaryOptions): Promise<IPaginatedData<ITaxesListItem>> => {
    return axios.get(`/api/v1/taxes/dictionaries`, {
      params: {
        ...options,
        ...getObjectRequestParams(options?.page, options?.perPage),
      },
    });
  };

  public getMembershipPackages = (
    clubId?: string,
  ): Promise<{ data: IShortPackage[]; meta?: IPageMeta }> =>
    axios
      .get(`/api/v1/packages/dictionaries/membership`, { params: { clubId } })
      .then((data: any) => ({ data }));

  // TODO: It is not a dictionary. Move it to another file
  public getStatesList = (options?: IDictionaryOptions): Promise<IPaginatedData<IStateItem>> =>
    axios.get('/api/v1/addresses/states', {
      params: getObjectRequestParams(options?.page, options?.perPage),
    });

  // TODO: It is not a dictionary. Move it to another file
  public getCountriesList = (options?: IDictionaryOptions): Promise<IPaginatedData<IStateItem>> =>
    axios.get('/api/v1/addresses/countries', {
      params: getObjectRequestParams(options?.page, options?.perPage),
    });

  public getFallthroughReasonsList = (
    options?: IDictionaryOptions,
  ): Promise<IPaginatedData<IStateItem>> =>
    axios.get('/api/v1/fallthrough-reason/dictionary', {
      params: options,
    });

  // TODO: It is not a dictionary. Move it to another file
  public getParticipantsList = (
    module: PeakModules,
    types: Array<EventAttendeeType>,
    personId?: string,
  ): Promise<IResult<IParticipantListItem[]>> => {
    const participantsTypesParams = types.join(',');

    switch (module) {
      case PeakModules.FrontDesk:
        return axios.get(`/api/v1/front-desk/${personId}/appointment/participants`, {
          params: { types: participantsTypesParams },
        });
      case PeakModules.Crm:
        return axios.get(
          window.location.pathname.indexOf(PtCrmRoutes.PT_CRM) === -1
            ? `/api/v1/leads/${personId}/appointment/participants`
            : `/api/v1/pt-leads/${personId}/appointment/participants`,
          {
            params: { types: participantsTypesParams },
          },
        );
      case PeakModules.PersonalTrainingCrm:
        return axios.get(`/api/v1/pt-leads/${personId}/appointment/participants`, {
          params: { types: participantsTypesParams },
        });
      case PeakModules.Members:
        return axios.get(`/api/v1/members/${personId}/appointment/participants`, {
          params: { types: participantsTypesParams },
        });
      case PeakModules.Booking:
        return axios.get('/api/v1/events/participants', {
          params: { types: participantsTypesParams },
        });
      default:
        return Promise.resolve({ data: [], meta: null });
    }
  };

  public getDepartmentsList = (
    options?: IDictionaryOptions,
  ): Promise<IPaginatedData<IDictionaryCommonItem>> =>
    axios.get('/api/v1/departments/dictionary', {
      params: options,
    });

  // TODO: It is not a dictionary. Move it to another file
  public getTimeclockDepartments = (
    options?: IDictionaryOptions,
  ): Promise<IPaginatedData<IDictionaryCommonItem>> =>
    axios.get('/api/v1/time-clock/payroll-report/departments', { params: options });

  // TODO: It is not a dictionary. Move it to another file
  public getTimeclockEmployees = (
    options?: IDictionaryOptions,
  ): Promise<IPaginatedData<IDictionaryCommonItem>> =>
    axios.get('/api/v1/time-clock/payroll-report/employees', { params: options });

  // TODO: It is not a dictionary. Move it to another file
  public getResourcesList = (
    module: PeakModules,
    clubId?: string,
    personId?: string,
  ): Promise<IResult<IShortResource[]>> => {
    switch (module) {
      case PeakModules.FrontDesk:
        return axios.get(`/api/v1/front-desk/${personId}/appointment/resources`, {
          params: { clubId },
        });
      case PeakModules.Crm:
        return axios.get(`/api/v1/leads/${personId}/appointment/resources`, { params: { clubId } });
      case PeakModules.Members:
        return axios.get(`/api/v1/members/${personId}/appointment/resources`, {
          params: { clubId },
        });
      case PeakModules.Booking:
        return axios.get('/api/v1/events/resources', { params: { clubId } });
      default:
        return Promise.resolve({ data: [], meta: null });
    }
  };

  public getInsuranceCompanies = (
    module: PeakModules,
  ): Promise<IResult<IDictionaryCommonItem[]>> => {
    switch (module) {
      case PeakModules.FrontDesk:
        return axios.get('/api/v1/front-desk/insurance-company/dictionary');
      case PeakModules.Crm:
        return axios.get('/api/v1/leads/insurance-company/dictionary');
      case PeakModules.PersonalTrainingCrm:
        return axios.get('/api/v1/pt-leads/insurance-company/dictionary');
      case PeakModules.Members:
        return axios.get('/api/v1/members/insurance-company/dictionary');
      default:
        return Promise.resolve({ data: [], meta: null });
    }
  };

  // TODO: It is not a dictionary. Move it to another file
  public getTimeAccess = (
    options?: IDictionaryOptions,
  ): Promise<IPaginatedData<IAccessHoursItem>> =>
    axios.get('/api/v1/clubs/time-access', {
      params: options,
    });

  public getAdditionalFields = (module: PeakModules): Promise<IResult<IDictionaryCommonItem[]>> => {
    switch (module) {
      case PeakModules.FrontDesk:
        return axios.get('/api/v1/front-desk/additional-fields/dictionary');
      case PeakModules.Members:
        return axios.get('/api/v1/members/additional-fields/dictionary');
      case PeakModules.Crm:
        return axios.get('/api/v1/leads/additional-fields/dictionary');
      case PeakModules.PersonalTrainingCrm:
        return axios.get('/api/v1/pt-leads/additional-fields/dictionary');
      default:
        return Promise.resolve({ data: [], meta: null });
    }
  };

  public getEndReason = (options?: IDictionaryOptions): Promise<IPaginatedData<IEndReasonItem>> =>
    axios.get('/api/v1/end-reason/dictionary', {
      params: options,
    });
}
