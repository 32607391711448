import axios from 'axios';

import ApiService from './ApiService';

// interfaces
import {
  EventUpdateType,
  IBookingEvent,
  IEventAction,
  IFetchSenderAvailabilityData,
  IListSenderAvailability,
  IRemindAppointmentDto,
  IUpdatedEventDto,
} from 'modules/booking/interfaces';
import { IPaginatedCommon } from 'modules/pos-settings/interfaces/common';
import { INamedEntity } from 'common/interfaces/common';
import { ITableParams } from 'common/interfaces/table';
import { ICalendarSharingParams } from 'modules/booking/containers/BookingShared/useCalendarSharingParams';

export default class BookingService extends ApiService {
  public getBookingEvents = (
    tableParams?: ITableParams,
  ): Promise<IPaginatedCommon<IBookingEvent>> => {
    const searchParams = this.getSearchFromTableParams(tableParams, true);

    return axios.get('/api/v1/events', { params: searchParams });
  };

  public getBookingEvent = (eventId: string, eventDate: string): Promise<IBookingEvent> => {
    return axios.get(`/api/v1/events/${eventId}`, {
      params: { eventDate },
    });
  };

  public createEvent = (event: IBookingEvent): Promise<IBookingEvent> => {
    return axios.post('/api/v1/events', JSON.stringify(event));
  };

  public updateEvent = (
    eventId: string,
    eventDate: string,
    updatedEvent: IUpdatedEventDto,
  ): Promise<IBookingEvent> => {
    return axios.put(`/api/v1/events/${eventId}`, updatedEvent, {
      params: { eventStartDate: eventDate },
    });
  };

  public deleteEvent = (
    eventId: string,
    eventDate: string,
    data: IEventAction,
  ): Promise<IBookingEvent> => {
    return axios.delete(`/api/v1/events/${eventId}`, {
      data,
      params: { eventStartDate: eventDate },
    });
  };

  public cancelEvent = (
    eventId: string,
    eventDate: string,
    data: IEventAction,
  ): Promise<IBookingEvent> => {
    return axios.post(`/api/v1/events/${eventId}/cancel`, data, {
      params: { eventStartDate: eventDate },
    });
  };

  public restoreEvent = (eventId: string, eventDate: string, data = {}): Promise<IBookingEvent> => {
    return axios.post(`/api/v1/events/${eventId}/restore`, data, {
      params: { eventStartDate: eventDate },
    });
  };

  public getEventServices = (search: string): Promise<INamedEntity[]> => {
    return axios.get('/api/v1/events/services', { params: { title: search } });
  };

  public getSharingEvents = (
    corporationId: string,
    search: ICalendarSharingParams,
  ): Promise<IPaginatedCommon<IBookingEvent>> => {
    return axios.get(`/api/v1/corporations/${corporationId}/calendar-sharing/events`, {
      params: search,
    });
  };

  public getSharingEventByIdAndTime = (
    corporationId: string,
    eventId: string,
    eventTime: string,
  ): Promise<IBookingEvent> =>
    axios.get(`/api/v1/corporations/${corporationId}/calendar-sharing/events/${eventId}`, {
      params: { eventStartDate: eventTime },
    });

  public duplicateEvent = (
    eventId: string,
    eventDate: string,
    isDuplicateParticipants: boolean,
  ): Promise<IBookingEvent> => {
    return axios.post(
      `/api/v1/events/${eventId}/duplicate`,
      { duplicateParticipants: isDuplicateParticipants },
      {
        params: { eventStartDate: eventDate },
      },
    );
  };

  public remindEvent = (eventId: string, payload: IRemindAppointmentDto): Promise<IBookingEvent> =>
    axios.post(`/api/v1/events/${eventId}/remind`, payload);

  public getSenderAvailability = async ({
    events = [],
  }: IFetchSenderAvailabilityData): Promise<IListSenderAvailability> => {
    return axios.get('/api/v1/sender-availability', {
      params: { types: events.join(',') },
    });
  };

  public joinBookingEventClass = (
    eventId: string,
    date: string,
    updateType?: EventUpdateType,
  ): Promise<IBookingEvent> => {
    return axios.put(`/api/v1/events/${eventId}/join`, null, {
      params: { updateType, date },
    });
  };
}
