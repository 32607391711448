import React, { useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Box, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { PeakModules } from 'common/constants/peakModules';
import { PaymentField } from 'common/components/index';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import { getOnAccountValidationSchema } from './onAccountValidationSchema';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { selectCurrentInvoice, selectUpdatedInvoiceLoading } from 'common/state/invoice/selectors';
import Button from 'common/components/Button/Button';
import { formatPrice } from 'common/utils';
import { IInvoicePaymentSplit, PaymentsType } from 'common/interfaces/invoices';
import { updateInvoiceWithSync } from 'common/state/invoice/actions';
import { useAppDispatch } from 'store/hooks';
import { StepContext } from 'common/createContext/stepContext';
import { useUpdatePaymentData } from '../useUpdatePaymentData';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import { fetchProfileInfoView } from 'common/components/PersonProfile/state/actions';

interface IOnAccountStepProps {
  module: PeakModules;
  isPaymentStep: boolean;
  paymentTypeId: string;
  leftToPay: number;
  balance: number;
  memberId: string;
  clubId: string;
  onClose: () => void;
}

const OnAccountStep = (props: IOnAccountStepProps): JSX.Element => {
  const {
    module,
    memberId,
    isPaymentStep,
    paymentTypeId,
    onClose,
    balance,
    leftToPay,
    clubId,
  } = props;

  const dispatch = useAppDispatch();

  const currentInvoice = useSelector(selectCurrentInvoice);
  const isUpdatingInvoiceLoading = useSelector(selectUpdatedInvoiceLoading);

  const renderIntlMessage = useRenderIntlMessage();

  const { helperData } = useContext(StepContext);
  const { profileId } = helperData || {};

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      amount: balance > leftToPay ? leftToPay : balance,
    },
    resolver: yupResolver(getOnAccountValidationSchema(leftToPay, balance)),
  });

  const updatedInvoiceResult = useUpdatePaymentData(module, profileId, onClose);

  useComponentDidUpdate(() => {
    if (updatedInvoiceResult && profileId === memberId && module === PeakModules.FrontDesk) {
      dispatch(fetchProfileInfoView(profileId, module));
    }
  }, [updatedInvoiceResult, profileId, memberId, module, dispatch]);

  const handleProceed = data => {
    const invoiceData = currentInvoice.toJS();
    const invoicePaymentSplit: IInvoicePaymentSplit = {
      paymentMethodId: paymentTypeId,
      type: PaymentsType.ON_ACCOUNT,
      paymentAmount: data.amount,
    };

    dispatch(
      updateInvoiceWithSync(
        module,
        clubId,
        invoiceData,
        invoicePaymentSplit,
        profileId,
        isPaymentStep,
      ),
    );
  };

  return (
    <Box>
      <Box pb={2} display="flex" justifyContent="space-between">
        <Typography color="primary">
          <FormattedMessage {...commonMessages.onAccountLabel} />
        </Typography>
        <Typography color="primary">{formatPrice(balance)}</Typography>
      </Box>
      <Box pt={1} pb={3}>
        <Controller
          control={control}
          name="amount"
          render={({ field }) => {
            return (
              <PaymentField
                fullWidth
                variant="outlined"
                name={field.name}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
                defaultValue={0}
                label={<FormattedMessage {...inputLabels.amountToPay} />}
                error={!!errors.amount}
                helperText={renderIntlMessage(errors.amount?.message)}
              />
            );
          }}
        />
      </Box>
      <Box pb={2}>
        <Button
          disabled={isUpdatingInvoiceLoading}
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleSubmit(handleProceed)}
        >
          <FormattedMessage {...commonMessages.proceedBtn} />
        </Button>
      </Box>
    </Box>
  );
};

export default OnAccountStep;
