import React from 'react';
import classnames from 'classnames';
import { List as ImmutableList } from 'immutable';
import { Controller, FieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { Card, CardContent, Grid, makeStyles, SvgIcon, Theme } from '@material-ui/core';

import { INamedEntity, INamedEntityImt, IObject } from 'common/interfaces/common';

import { ReactComponent as DeleteIcon } from 'img/times.svg';
import { AdaptiveDatePicker, MultipleSelect } from 'common/components';
import ResourceButton from 'modules/booking/components/ResourceForm/ResourceButton/ResourceButton';

import inputLabels from 'common/messages/inputLabels';
import messages from 'modules/booking/messages';
import commonMessages from 'common/messages/messages';
import DayTimeAvailabilitySelector from 'common/components/DayTimeAvailabilitySelector/DayTimeAvailabilitySelector';
import { getAccessByPropPath } from 'common/utils/errorObject';

const useStyles = makeStyles((theme: Theme) => ({
  rootTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  availabilityCard: {
    marginBottom: theme.spacing(1.5),
  },
  availabilityCardContent: {
    backgroundColor: theme.palette.background.default,
    '& .MuiOutlinedInput-root, & .MuiCheckbox-root .MuiIconButton-label>span': {
      backgroundColor: '#fff',
    },
  },
  dayBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    border: '1px solid  #f3f3f3',
    backgroundColor: 'white',
  },
  dayBoxSelected: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  dayBoxLabel: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  deleteIcon: {
    fontSize: '1.125rem',
    color: theme.palette.text.primary,
    opacity: 0.5,
  },
  deleteIconDisabled: {
    opacity: 0.2,
  },
}));

interface IProps {
  disableRemoveCard: boolean;
  availabilityIndex: number;
  clubs: ImmutableList<INamedEntityImt>;
  occupiedClubs: INamedEntity[];
  setOccupiedClubs: (clubs) => void;
  availability: FieldArray<{ availability: IObject[] }, 'availability'>;
  remove: (value) => void;
}

const AvailabilityCard = ({
  availabilityIndex,
  clubs,
  occupiedClubs,
  setOccupiedClubs,
  availability,
  disableRemoveCard,
  remove,
}: IProps): JSX.Element => {
  const { formState, control, getValues } = useFormContext();
  const { errors } = formState;
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();

  // clubs

  const availableClubs = () =>
    clubs
      .filter(club => !occupiedClubs.map(oclub => oclub.id).includes(club.get('id')))
      .map(club => club.set('title', club.get('title')));

  // handlers

  const handleOnClubsChange = (e, onChange, value) => {
    onChange(e);
    const occupiedClubsExceptCurrent = occupiedClubs.filter(
      oclub => !value.map(item => item.id).includes(oclub.id),
    );
    setOccupiedClubs([...occupiedClubsExceptCurrent, ...e]);
  };

  const handleOnRemoveAvailability = () => {
    const occupiedClubsExceptCurrent = occupiedClubs.filter(
      oclub =>
        !getValues(`availability.${availabilityIndex}.clubs`)
          .map(item => item.id)
          .includes(oclub.id),
    );

    setOccupiedClubs([...occupiedClubsExceptCurrent]);

    remove(availabilityIndex);
  };

  // render

  return (
    <Card key={availabilityIndex} className={classes.availabilityCard}>
      <CardContent className={classes.availabilityCardContent}>
        <Grid container justifyContent="flex-end" style={{ marginBottom: 8 }}>
          <ResourceButton
            iconButton
            icon={
              <SvgIcon
                fontSize="small"
                className={classnames(classes.deleteIcon, {
                  [classes.deleteIconDisabled]: disableRemoveCard,
                })}
              >
                <DeleteIcon />
              </SvgIcon>
            }
            iconButtonTooltip={<FormattedMessage {...commonMessages.removeBtn} />}
            IconButtonProps={{
              size: 'small',
              disabled: disableRemoveCard,
            }}
            onClick={handleOnRemoveAvailability}
          />
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`availability.${availabilityIndex}.clubs`}
                  control={control}
                  defaultValue={availability.clubs}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <MultipleSelect
                      multiple
                      fullWidth
                      value={value}
                      options={availableClubs().toJS()}
                      label={<FormattedMessage {...commonMessages.clubsLabel} />}
                      onChange={event => handleOnClubsChange(event, onChange, value)}
                      onBlur={onBlur}
                      error={
                        !!getAccessByPropPath(errors, `availability.${availabilityIndex}.clubs`)
                      }
                      helperText={renderIntlMessage(
                        getAccessByPropPath(errors, `availability.${availabilityIndex}.clubs`)
                          ?.message || {
                          ...messages.selectClubHelperLabel,
                        },
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item sm={3} xs={12}>
                <Controller
                  name={`availability.${availabilityIndex}.startDate`}
                  control={control}
                  defaultValue={availability.startDate}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <AdaptiveDatePicker
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      label={<FormattedMessage {...inputLabels.startDate} />}
                      disablePast
                      error={
                        !!getAccessByPropPath(errors, `availability.${availabilityIndex}.startDate`)
                      }
                      helperText={renderIntlMessage(
                        getAccessByPropPath(errors, `availability.${availabilityIndex}.startDate`)
                          ?.message,
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item sm={3} xs={12}>
                <Controller
                  name={`availability.${availabilityIndex}.endDate`}
                  control={control}
                  defaultValue={availability.endDate}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <AdaptiveDatePicker
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      label={<FormattedMessage {...inputLabels.endDate} />}
                      disablePast
                      error={
                        !!getAccessByPropPath(errors, `availability.${availabilityIndex}.endDate`)
                      }
                      helperText={renderIntlMessage(
                        getAccessByPropPath(errors, `availability.${availabilityIndex}.endDate`)
                          ?.message,
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <DayTimeAvailabilitySelector
              rangesFieldName={`availability.${availabilityIndex}.dayTimeAvails`}
              weekdaysFieldName={`availability.${availabilityIndex}`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default React.memo(AvailabilityCard);
