// libraries
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FormControlLabel, Grid, makeStyles, TextField } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

// components
import { Checkbox, Select, NotifyControlFields } from 'common/components';
// messages
import messages from 'modules/booking/messages';
import inputLabels from 'common/messages/inputLabels';
// constants
import { ReminderPeriodTypes } from 'common/interfaces/common';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
// hooks
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { TSenderAvailabilityImt } from 'modules/booking/interfaces';
// state
import { selectSenderAvailability } from 'modules/booking/state/senderAvailability/selectors';

const useStyles = makeStyles((theme: Theme) => ({
  notifyGroupLabel: {
    marginBottom: theme.spacing(1),
  },
}));

const ReminderSection: React.FC = (): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();

  const senderAvailability: TSenderAvailabilityImt = useSelector(
    selectSenderAvailability(SenderAvailabilityTypeList.REMIND),
  );

  // form
  const { control, setValue, formState, watch } = useFormContext();
  const { errors } = formState;
  const isReminderEnabled = watch('reminder');

  // renders

  return (
    <Grid container spacing={2}>
      <Grid item xs={isReminderEnabled ? 4 : 12}>
        <Controller
          name="reminder"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.value}
                  onBlur={field.onBlur}
                  onChange={val => {
                    field.onChange(val.target.checked);

                    if (!val.target.checked) {
                      setValue('reminderPeriodType', null);
                    }
                  }}
                />
              }
              label={<FormattedMessage {...inputLabels.reminderBefore} />}
            />
          )}
        />
      </Grid>

      {isReminderEnabled && (
        <>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="reminderPeriodNumber"
              defaultValue={1}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  label={<FormattedMessage {...inputLabels.amount} />}
                  error={!!errors.reminderPeriodNumber}
                  helperText={renderIntlMessage(errors.reminderPeriodNumber?.message)}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="reminderPeriodType"
              render={({ field }) => (
                <Select
                  fullWidth
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  label={<FormattedMessage {...inputLabels.unit} />}
                  error={!!errors.reminderPeriodType}
                  helperText={renderIntlMessage(errors.reminderPeriodType?.message)}
                >
                  {ReminderPeriodTypes.getSelectOptions()}
                </Select>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <NotifyControlFields
              label={<FormattedMessage {...messages.remindParticipantsLabel} />}
              labelClassName={classes.notifyGroupLabel}
              emailFieldName="remindViaEmail"
              smsFieldName="remindViaSms"
              notificationName="remindViaNotification"
              senderAvailability={senderAvailability}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ReminderSection;
