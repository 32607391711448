import React, { useEffect } from 'react';
import { useForm, Controller, useFieldArray, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Grid, makeStyles, Theme, IconButton } from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';

import { getRequiredMessage } from 'common/constants/globalConstants';
import { phoneSchema } from 'common/validationSchemas/phoneSchema';
import { PhoneTypes, PhoneTypesOptions } from 'common/components/PersonProfile/constants';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { IProfileInfo } from 'common/components/PersonProfile/interfaces';
import { AlertCondition } from 'common/interfaces/alerts';

import { Button, Checkbox, DialogComponent, PhoneNumberInput, Select } from 'common/components';

import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import { useAppDispatch } from 'store/hooks';
import { useSelector } from 'react-redux';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';

const useStyles = makeStyles((theme: Theme) => ({
  phoneWrapper: {
    '&:not(:first-child)': {
      marginTop: theme.spacing(1),
    },
  },
  checkboxSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(1),
    paddingLeft: `${theme.spacing(1)}px !important`,
  },
}));

interface IFormPhone {
  phoneType: PhoneTypes;
  phone: string;
  allowAutomatedCalls: boolean;
  canReceiveSms: boolean;
}

interface IFormValues {
  phones: IFormPhone[];
}

const initialPhoneValues: IFormPhone = {
  phoneType: PhoneTypes.Mobile,
  phone: '',
  allowAutomatedCalls: true,
  canReceiveSms: true,
};

interface IProps {
  alert: AlertCondition | null;
  isOpen: boolean;
  onSubmit: (info: Partial<IProfileInfo>, alertId: string) => void;
  switchingModal: (alertCondition: AlertCondition) => void;
}

const PhoneModal = ({ isOpen, switchingModal, onSubmit, alert }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const profileRequiredFields = useSelector(selectProfileRequiredFields);
  const isSubmitted = useSelector(selectIsSubmittedRecommendedFieldsModal);

  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();

  const formMethods = useForm<IFormValues>({
    defaultValues: {
      phones: [initialPhoneValues],
    },
    resolver: yupResolver(
      yup.object().shape({
        phones: yup
          .array(
            yup.object().shape({
              phone: phoneSchema.when(RequiredProfileInfo.PhoneNumber, {
                is: () => {
                  return (
                    profileRequiredFields.get(RequiredProfileInfo.PhoneNumber) ===
                    FieldNecessity.Required
                  );
                },
                then: schema => schema.required(getRequiredMessage),
                otherwise: schema => schema,
              }),
            }),
          )
          .required(getRequiredMessage),
      }),
    ) as any, // TODO - PRM-1810 need resolver type
    mode: 'onBlur',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = formMethods;

  const { fields: phones, append, remove } = useFieldArray({
    control,
    name: 'phones',
    keyName: 'key',
  });

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  useEffect(() => {
    if (isSubmitted) {
      const values = getValues();

      onSubmit(values as Partial<IProfileInfo>, alert);
      switchingModal(alert);

      dispatch(showRecommendedFieldsModal(false));
    }
  }, [alert, dispatch, getValues, isSubmitted, onSubmit, switchingModal]);

  const onAddPhone = () => append(initialPhoneValues);

  const onSubmitForm = (values: Partial<IProfileInfo>): void => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty(values);

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit(values, alert);
      switchingModal(alert);
    }
  };

  return (
    <DialogComponent
      size="sm"
      title={<FormattedMessage {...inputLabels.phone} />}
      isOpen={isOpen}
      onClose={() => switchingModal(alert)}
      onSubmit={handleSubmit(onSubmitForm as any)} // TODO - PRM-1810 need type
    >
      <FormProvider {...formMethods}>
        {phones.map((phone, index) => (
          <Grid container spacing={1} className={classes.phoneWrapper}>
            <Grid item xs={6}>
              <Controller
                name={`phones.${index}.phone`}
                control={control}
                render={({ field: { value, onBlur, onChange } }) => (
                  <PhoneNumberInput
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    error={!!errors.phones?.[index]}
                    helperText={renderIntlMessage(errors.phones?.[index]?.phone?.message)}
                    recommended={Boolean(
                      profileRecommendedFields?.get(RequiredProfileInfo.PhoneNumber),
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={!index ? 6 : 5} className={classes.checkboxSection}>
              <Controller
                control={control}
                name={`phones.${index}.allowAutomatedCalls`}
                defaultValue
                render={({ field: { value, onBlur, onChange } }) => (
                  <FormControlLabel
                    label={<FormattedMessage {...inputLabels.allowAutomatedCalls} />}
                    control={
                      <Checkbox
                        size="small"
                        checked={value}
                        onChange={e => onChange(e.target.checked)}
                        onBlur={onBlur}
                      />
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name={`phones.${index}.canReceiveSms`}
                defaultValue
                render={({ field: { value, onBlur, onChange } }) => (
                  <FormControlLabel
                    label={<FormattedMessage {...inputLabels.allowTextMessages} />}
                    control={
                      <Checkbox
                        size="small"
                        checked={value}
                        onChange={e => onChange(e.target.checked)}
                        onBlur={onBlur}
                      />
                    }
                  />
                )}
              />
            </Grid>

            {!!index && (
              <Grid item xs={1}>
                <IconButton onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}

            <Grid item xs={6}>
              <Controller
                name={`phones.${index}.phoneType`}
                control={control}
                defaultValue={PhoneTypesOptions.values[0].value as any} // TODO - PRM-1810 need type
                render={({ field: { value, onBlur, onChange } }) => (
                  <Select
                    fullWidth
                    label={<FormattedMessage {...inputLabels.type} />}
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  >
                    {PhoneTypesOptions.getSelectOptions()}
                  </Select>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Button color="primary" startIcon={<AddIcon />} onClick={onAddPhone}>
                <FormattedMessage {...commonMessages.addPhoneBtn} />
              </Button>
            </Grid>
          </Grid>
        ))}
      </FormProvider>
    </DialogComponent>
  );
};

export default PhoneModal;
