import React from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Button, makeStyles, TextField, Theme, Box } from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getRequiredMessage } from 'common/constants/globalConstants';

import { AlertTypes } from 'common/interfaces/alerts';
import { IUnlockCredentials } from 'modules/authentication/interfaces';
import { clockInTimeclockSignInError } from 'modules/timeclock/state/actions';
import { IServerError } from 'common/interfaces/http';

import { Alert, ButtonWithCircularProgress, PasswordField } from 'common/components';

import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import messages from 'modules/authentication/messages';
import errorMessages from 'common/errors/messages';
import inputErrors from 'common/messages/inputErrors';

const useStyles = makeStyles((theme: Theme) => ({
  continue: {
    marginBottom: theme.spacing(1),
  },
}));

interface IProps {
  onSubmit: (data: IUnlockCredentials) => void;
  isLoading?: boolean;
  serverError?: IServerError;
  showCancelBtn?: boolean;
  onClose?: () => void;
}

const getValidationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email(() => inputErrors.emailInvalidError)
    .required(getRequiredMessage),
  password: yup
    .string()
    .trim()
    .required(getRequiredMessage),
});

const UnlockKioskModeForm = ({
  onSubmit,
  isLoading = false,
  serverError,
  onClose,
  showCancelBtn = false,
}: IProps): JSX.Element => {
  const dispatch = useDispatch();

  const formMethods = useForm<any>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(getValidationSchema),
    mode: 'onBlur',
  });

  const classes = useStyles();
  const resetError = () => serverError && dispatch(clockInTimeclockSignInError(null));

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const renderErrorMessage = useRenderIntlMessage();

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box overflow="hidden">
          <Box mb={1}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  onChange={v => {
                    resetError();
                    field.onChange(v);
                  }}
                  onBlur={field.onBlur}
                  label={<FormattedMessage {...inputLabels.username} />}
                  fullWidth
                  variant="outlined"
                  error={!serverError ? !!errors.email : false}
                  helperText={!serverError ? renderErrorMessage(errors?.email?.message) : ''}
                />
              )}
            />
          </Box>

          <Box>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <PasswordField
                  fullWidth
                  variant="outlined"
                  value={field.value}
                  onChange={v => {
                    resetError();
                    field.onChange(v);
                  }}
                  onBlur={field.onBlur}
                  label={<FormattedMessage {...inputLabels.password} />}
                  error={!serverError ? !!errors.password : false}
                  helperText={!serverError ? renderErrorMessage(errors?.password?.message) : ''}
                />
              )}
            />
          </Box>
          <Box mt={1}>
            {serverError && !isLoading && (
              <Alert
                title={<FormattedMessage {...errorMessages.wrongUsernameOrPasswordError} />}
                severity={AlertTypes.Danger}
                resolveLabel={<FormattedMessage {...commonMessages.configureBtn} />}
              />
            )}
          </Box>
        </Box>

        <Box mt={1}>
          <ButtonWithCircularProgress
            type="submit"
            variant="contained"
            fullWidth
            isSubmitting={false}
            className={classes.continue}
          >
            <FormattedMessage {...messages.continue} />
          </ButtonWithCircularProgress>

          {showCancelBtn && (
            <Button color="primary" fullWidth onClick={onClose}>
              <FormattedMessage {...commonMessages.cancelBtn} />
            </Button>
          )}
        </Box>
      </form>
    </FormProvider>
  );
};

export default UnlockKioskModeForm;
