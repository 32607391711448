// libraries
import React, { useEffect } from 'react';
import { useParams, useNavigate, useNavigationType } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { List as ImmutableList } from 'immutable';
import { FormattedMessage } from 'react-intl';

// interfaces
import { IServiceItemImt } from '../../interfaces/services';

// components
import {
  Box,
  createStyles,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Checkbox,
  FormContainer,
  LoadingBackdrop,
  MultipleSelect,
  Select,
} from 'common/components';
// messages
import messages from 'modules/services/messages/services';

// selectors
import * as dictionarySelectors from 'common/state/dictionary/selectors';
import * as selectors from '../../state/services/selectors';

// actions
import * as dictionaryActions from 'common/state/dictionary/actions';
import * as actions from '../../state/services/actions';
// hooks
import { useAppDispatch } from 'store/hooks';
// constants
import { ActionResult, ActiveInactiveStatus, DictionaryList } from 'common/constants';
import { TDocumentTemplateListItemImt } from 'common/interfaces/dictionary';
import { DurationUnit, DurationUnits, INamedEntityImt } from 'common/interfaces/common';
import { TemplatePurpose, TemplateTypes } from 'common/constants/documentTemplate';
import { PeakModules } from 'common/constants/peakModules';

import { RouteBackground } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import commonMessages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import { yupResolver } from '@hookform/resolvers/yup';
import { getRequiredMessage, requiredMessage } from 'common/constants/globalConstants';
import { RedeemType, ServiceType } from 'common/constants/service';
import { RedeemTypeOptions, ServiceTypeOptions } from 'modules/services/constants/services';
import { IConstOption } from 'common/constants/classes';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getString } from 'common/utils/typeUtils';

// TODO - PRM-1810 tmp form interface
interface IFormValues {
  [field: string]: any;
}

type TRouteParams = { serviceId: string };

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

const getOption = <T extends string>(v: IConstOption<T>) => (
  <MenuItem key={v.value} value={v.value}>
    <FormattedMessage {...v.message} />
  </MenuItem>
);

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .nullable()
    .max(120, () => messages.titleMaxLengthError)
    .required(requiredMessage),
  active: yup.boolean(),
  type: yup
    .string()
    .oneOf(Object.values(ServiceType))
    .required(getRequiredMessage),
  redeemType: yup
    .string()
    .oneOf(Object.values(RedeemType))
    .required(getRequiredMessage),
  redeemDurationUnit: yup
    .string()
    .nullable()
    .when('redeemType', {
      is: RedeemType.Duration,
      then: yup
        .string()
        .nullable()
        .required(requiredMessage),
    }),
  waiverTemplate: yup
    .object()
    .nullable()
    .when('signWaiver', {
      is: true,
      then: yup
        .object()
        .nullable()
        .required(requiredMessage),
    }),
});

const EditService: React.FC = (): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();

  const dispatch = useAppDispatch();

  const { serviceId } = useParams<TRouteParams>();
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const isEditForm = !!serviceId;

  // selectors
  const documentsList: ImmutableList<TDocumentTemplateListItemImt> = useSelector(
    dictionarySelectors.selectDictionaryList(DictionaryList.DOCUMENT_TEMPLATES),
  );
  // const documentsListLoading: boolean = useSelector(
  //   dictionarySelectors.selectIsDictionaryListLoading(DictionaryList.DOCUMENT_TEMPLATES),
  // );
  const serviceLoadingSelector: boolean = useSelector(selectors.selectCreateUpdateServiceLoading());
  const serviceActionResultSelector: ActionResult = useSelector(
    selectors.selectCreateUpdateServiceActionResult(),
  );
  const serviceSelector: IServiceItemImt = useSelector(selectors.selectServiceItem());
  const resourceTags: ImmutableList<INamedEntityImt> = useSelector(
    dictionarySelectors.selectDictionaryList(DictionaryList.RESOURCE_TAGS),
  );
  // select service
  // select document templates

  const classes = useStyles();

  useEffect(() => {
    if (serviceId) {
      dispatch(actions.fetchService(serviceId));
    }
  }, [dispatch, serviceId]);

  const formMethods = useForm<IFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      title: '',
      active: true,
      type: ServiceType.Custom,
      redeemType: RedeemType.Duration,
      redeemDurationUnit: DurationUnit.Mins,
      scheduled: 'false',
      resourceTags: [],
      signWaiver: false,
      waiverTemplate: null,
      clubs: [],
    },
    resolver: yupResolver<IFormValues>(validationSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = formMethods;

  const type = useWatch({ name: 'type', control });
  const redeemTypeWatch = useWatch({ name: 'redeemType', control });
  const signWaiverWatch = useWatch({ name: 'signWaiver', control });
  const scheduled = useWatch({ name: 'scheduled', control });

  useEffect(() => {
    if (redeemTypeWatch === RedeemType.Amount && type !== ServiceType.FamilyMember) {
      setValue('redeemType', RedeemType.Duration);
    }

    if (redeemTypeWatch !== RedeemType.Amount && type === ServiceType.FamilyMember) {
      setValue('redeemType', RedeemType.Amount);
    }
  }, [redeemTypeWatch, type, setValue]);

  useEffect(() => {
    if (serviceId && serviceSelector?.size) {
      reset(serviceSelector.toJS());
    }
  }, [serviceId, serviceSelector, reset]);

  useEffect(() => {
    dispatch(
      dictionaryActions.fetchDictionaryList(DictionaryList.DOCUMENT_TEMPLATES, {
        type: TemplateTypes.Document,
        purpose: TemplatePurpose.ServiceWaiverTemplate,
        module: PeakModules.Services,
      }),
    );
    dispatch(dictionaryActions.fetchDictionaryList(DictionaryList.RESOURCE_TAGS));
  }, [dispatch]);

  useEffect(() => {
    if (ActionResult.SUCCESS_ACTION === serviceActionResultSelector) {
      dispatch(actions.createUpdateResultAction(null));
      if (navigationType === 'PUSH') navigate(-1);
      else navigate('/services/services-list');
    }
  }, [serviceActionResultSelector, dispatch, navigate, navigationType]);

  // handlers
  const onSubmit = (event): void => {
    if (isEditForm) {
      dispatch(actions.updateService(serviceId, event));
    } else {
      dispatch(actions.createService(event));
    }
  };

  const onCancel = () => {
    if (navigationType === 'PUSH') navigate(-1);
    else navigate('/services/services-list');
  };

  // renders

  const renderFormTitle = (): JSX.Element => (
    <FormattedMessage
      {...(isEditForm ? messages.editServicePageTitle : messages.newServicePageTitle)}
    />
  );

  return (
    <RouteBackground>
      <Box className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
          <FormContainer title={renderFormTitle()} onCancel={onCancel} maxWidth={625}>
            <Grid container spacing={1}>
              <Grid item xs={9} sm={9}>
                <Controller
                  name="title"
                  control={control}
                  render={({ field: { name, value, onChange, onBlur, ref } }) => (
                    <TextField
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      fullWidth
                      variant="outlined"
                      label={<FormattedMessage {...messages.editServiceFormTitleLabel} />}
                      error={Boolean(errors.title)}
                      helperText={renderIntlMessage(errors.title?.message)}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3} sm={3}>
                <Controller
                  name="active"
                  control={control}
                  render={({ field: { name, value, onChange, onBlur } }) => (
                    <Select
                      fullWidth
                      label={<FormattedMessage {...commonMessages.statusTitle} />}
                      variant="outlined"
                      name={name}
                      value={value}
                      onChange={e => {
                        if (e === 'true') onChange(true);
                        else onChange(false);
                      }}
                      onBlur={onBlur}
                    >
                      {ActiveInactiveStatus.map(item => (
                        <MenuItem key={item.key} value={`${item.value}`}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field: { name, value, onChange, onBlur } }) => (
                    <Select
                      fullWidth
                      label={<FormattedMessage {...inputLabels.type} />}
                      variant="outlined"
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    >
                      {ServiceTypeOptions.getSelectOptions<ServiceType>()}
                    </Select>
                  )}
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <Controller
                  name="redeemType"
                  control={control}
                  render={({ field: { name, value, onChange, onBlur } }) => (
                    <Select
                      fullWidth
                      label={<FormattedMessage {...messages.editServiceFormRedeemTypeLabel} />}
                      variant="outlined"
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    >
                      {RedeemTypeOptions.values.reduce(
                        (acc: JSX.Element[], item: IConstOption<RedeemType>) => {
                          if (type === ServiceType.FamilyMember) {
                            return item.value === RedeemType.Amount
                              ? [getOption<RedeemType>(item)]
                              : acc;
                          }

                          return item.value === RedeemType.Amount
                            ? acc
                            : [...acc, getOption<RedeemType>(item)];
                        },
                        [],
                      )}
                    </Select>
                  )}
                />
              </Grid>

              {redeemTypeWatch === RedeemType.Duration && (
                <Grid item xs={6} sm={4}>
                  <Controller
                    name="redeemDurationUnit"
                    control={control}
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <Select
                        fullWidth
                        label={
                          <FormattedMessage {...messages.editServiceFormRedeemDurationUnitLabel} />
                        }
                        variant="outlined"
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.redeemDurationUnit}
                        helperText={getString(errors.redeemDurationUnit?.message)}
                      >
                        {DurationUnits.getSelectOptions<DurationUnit>()}
                      </Select>
                    )}
                  />
                </Grid>
              )}

              {(type === ServiceType.Custom || type === ServiceType.PersonalTraining) && (
                <>
                  <Grid item xs={6} sm={4}>
                    <Controller
                      name="scheduled"
                      control={control}
                      render={({ field: { name, value, onChange, onBlur } }) => (
                        <Select
                          fullWidth
                          label={
                            <FormattedMessage {...messages.editServiceFormSchedulingTypeLabel} />
                          }
                          variant="outlined"
                          name={name}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        >
                          <MenuItem key="schedulingOpt" value="true">
                            <FormattedMessage
                              {...messages.editServiceFormSchedulingTrueOptionLabel}
                            />
                          </MenuItem>
                          <MenuItem key="notSchedulingOpt" value="false">
                            <FormattedMessage
                              {...messages.editServiceFormSchedulingFalseOptionLabel}
                            />
                          </MenuItem>
                        </Select>
                      )}
                    />
                  </Grid>

                  {(scheduled === 'false' || !scheduled || type) && (
                    <Grid item xs={12}>
                      <Controller
                        name="resourceTags"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <MultipleSelect
                            multiple
                            options={resourceTags.toJS()}
                            value={value || []}
                            label={<FormattedMessage {...inputLabels.resources} />}
                            fullWidth
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </>
              )}

              <Grid item xs={12} sm={12}>
                <Controller
                  name="signWaiver"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={value}
                          onChange={e => onChange(e.target.checked)}
                        />
                      }
                      label={
                        <Typography style={{ fontWeight: 'bold' }}>
                          <FormattedMessage {...messages.editServiceFormSignWaiverLabel} />
                        </Typography>
                      }
                    />
                  )}
                />
              </Grid>

              {signWaiverWatch && (
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="waiverTemplate"
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <MultipleSelect
                        options={documentsList.toJS()}
                        fullWidth
                        label={
                          <FormattedMessage {...messages.editServiceFormWaiverTemplateLabel} />
                        }
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={Boolean(errors.waiverTemplate)}
                        helperText={getString(errors.waiverTemplate?.message)}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <LoadingBackdrop isLoading={serviceLoadingSelector} />
          </FormContainer>
        </form>
      </Box>
    </RouteBackground>
  );
};

export default EditService;
