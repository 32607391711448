import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, SvgIcon, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as BoxIcon } from 'img/box.svg';
import messages from 'modules/booking/messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';

interface IBookingMenu {
  onShare: () => void;
  showResourcesButton: boolean;
  showShareButton: boolean;
  className?: string;
}

export default function BookingMenu({
  onShare,
  showResourcesButton,
  showShareButton,
  className,
}: IBookingMenu): JSX.Element {
  const navigate = useNavigate();

  const onResourcesClick = () => {
    navigate('/booking/resources');
  };

  const onShareClick = () => {
    onShare();
  };

  return (
    <Grid container spacing={1} className={className}>
      <Grid item>
        {showResourcesButton && (
          <Button
            onClick={onResourcesClick}
            variant="contained"
            startIcon={
              <SvgIcon>
                <BoxIcon />
              </SvgIcon>
            }
          >
            <FormattedMessage {...messages.resources} />
          </Button>
        )}
      </Grid>

      <Grid item>
        {showShareButton && (
          <Button
            onClick={onShareClick}
            variant="contained"
            startIcon={
              <SvgIcon>
                <FontAwesomeIcon icon={faShare} />
              </SvgIcon>
            }
          >
            <FormattedMessage {...messages.share} />
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
