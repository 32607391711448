import { IRoutes } from 'common/interfaces/routes';
import { PermissionLevels } from 'common/constants/permissions';
import messages from 'modules/dictionaries/messages/messages';
import notificationMessages from 'modules/dictionaries/messages/notifications';
import commonMessages from 'common/messages/messages';
import { EndReasonActionTypes } from '../interfaces/interfaces';
import IntlService from 'services/intl/IntlService';

export enum DictionaryPath {
  ROOT = 'dictionaries',

  CAME_FROM_INDICATION = 'came-from-indication',
  ALERT_SOUND_UPLOAD = 'alert-sound-upload',
  NOTE_CODE = 'note-code',
  FALLTHROUGH_REASON = 'fallthrough-reason',
  DEPARTMENTS = 'departments',
  EVENT_TAGS = 'event-tags',
  RESOURCE_TAG = 'resource-tag',
  ADDITIONAL_GUEST_FIELDS = 'additional-guest-fields',
  CLUB_GROUPS = 'club-groups',
  INSURANCE_COMPANIES = 'insurance-companies',
  ACCESS_HOURS = 'access-hours',
  HELP_INFORMATION = 'help-information',
  END_REASON = 'end-reason',

  // notifications
  NOTIFICATIONS = 'notifications',
  EDIT_NOTIFICATION = 'notifications/:id/edit',
  VIEW_NOTIFICATION = 'notifications/:id',
}

export enum DictionariesTypes {
  CAME_FROM_INDICATION = 'CAME_FROM_INDICATION',
  ALERT_SOUND_UPLOAD = 'ALERT_SOUND_UPLOAD',
  NOTE_CODE = 'NOTE_CODE',
  FALLTHROUGH_REASON = 'FALLTHROUGH_REASON',
  DEPARTMENTS = 'DEPARTMENTS',
  EVENT_TAGS = 'EVENT_TAGS',
  RESOURCE_TAG = 'RESOURCE_TAG',
  ADDITIONAL_GUEST_FIELDS = 'ADDITIONAL_GUEST_FIELDS',
  END_REASON = 'END_REASON',
}

export enum TagType {
  FRONTDESK_PROFILE = 'FRONTDESK_PROFILE',
  INVENTORY_CATEGORY = 'INVENTORY_CATEGORY',
}

export const routes: IRoutes[] = [
  {
    type: 'bookmark',
    message: commonMessages.frontDesk,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'link',
    path: `/dictionaries/${DictionaryPath.CAME_FROM_INDICATION}`,
    message: messages.cameFromIndication,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'link',
    path: `/dictionaries/${DictionaryPath.ADDITIONAL_GUEST_FIELDS}`,
    message: messages.additionalGuestFields,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'link',
    path: `/dictionaries/${DictionaryPath.ALERT_SOUND_UPLOAD}`,
    message: messages.alertSoundUpload,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'link',
    path: `/dictionaries/${DictionaryPath.NOTE_CODE}`,
    message: messages.noteCode,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'bookmark',
    message: commonMessages.crm,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'link',
    path: `/dictionaries/${DictionaryPath.FALLTHROUGH_REASON}`,
    message: messages.fallthroughReason,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'bookmark',
    message: messages.clubSetup,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'link',
    path: `/dictionaries/${DictionaryPath.DEPARTMENTS}`,
    message: messages.departments,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'link',
    path: `/dictionaries/${DictionaryPath.CLUB_GROUPS}`,
    message: messages.clubGroups,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'link',
    path: `/dictionaries/${DictionaryPath.INSURANCE_COMPANIES}`,
    message: messages.insuranceCompanies,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'link',
    path: `/dictionaries/${DictionaryPath.ACCESS_HOURS}`,
    message: messages.accessHours,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'bookmark',
    message: commonMessages.booking,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'link',
    path: `/dictionaries/${DictionaryPath.EVENT_TAGS}`,
    message: messages.eventsTags,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'link',
    path: `/dictionaries/${DictionaryPath.RESOURCE_TAG}`,
    message: messages.resourcesTags,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'bookmark',
    message: commonMessages.automation,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'link',
    path: `/dictionaries/${DictionaryPath.NOTIFICATIONS}`,
    message: notificationMessages.notificationsTitle,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
  {
    type: 'bookmark',
    message: commonMessages.generalLabel,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT, PermissionLevels.PEAK],
  },
  {
    type: 'link',
    path: `/dictionaries/${DictionaryPath.HELP_INFORMATION}`,
    message: messages.helpInformation,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT, PermissionLevels.PEAK],
  },
  {
    type: 'link',
    path: `/dictionaries/${DictionaryPath.END_REASON}`,
    message: messages.endReason,
    levels: [PermissionLevels.CORPORATE, PermissionLevels.CLIENT],
  },
];

export const endReasonActionTypesLabels = {
  [EndReasonActionTypes.ZIP_CODE]: 'zipCodeOption',
  [EndReasonActionTypes.MD_LICENSE]: 'mdLicenseOption',
  [EndReasonActionTypes.NOTE_CODE]: 'noteCodeOption',
};

export const getEndReasonActionTypeOptions = () => [
  {
    key: EndReasonActionTypes.ZIP_CODE,
    label: IntlService.formatMessage(
      messages[endReasonActionTypesLabels[EndReasonActionTypes.ZIP_CODE]],
    ),
    value: EndReasonActionTypes.ZIP_CODE,
  },
  {
    key: EndReasonActionTypes.MD_LICENSE,
    label: IntlService.formatMessage(
      messages[endReasonActionTypesLabels[EndReasonActionTypes.MD_LICENSE]],
    ),
    value: EndReasonActionTypes.MD_LICENSE,
  },
  {
    key: EndReasonActionTypes.NOTE_CODE,
    label: IntlService.formatMessage(
      messages[endReasonActionTypesLabels[EndReasonActionTypes.NOTE_CODE]],
    ),
    value: EndReasonActionTypes.NOTE_CODE,
  },
];
