import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';

import { textareaSettings } from 'modules/reports/constants/invoiceHistory';

import { DialogComponent, TextArea } from 'common/components/index';

import messages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import { getString } from 'common/utils/typeUtils';

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (reason: string) => void;
}

const ValidationSchema = yup.object().shape({
  reason: yup
    .string()
    .trim()
    .nullable(),
});

const VoidInvoiceModal = ({ isOpen, isLoading, onClose, onSubmit }: IProps): JSX.Element => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues: { reason: '' },
    resolver: yupResolver(ValidationSchema),
    mode: 'onBlur',
  });

  const handleFormSubmit = formValue => {
    onSubmit(formValue.reason);
  };

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...messages.voidInvoice} />}
      submitBtnTitle={<FormattedMessage {...messages.voidInvoice} />}
      cancelBtnTitle={<FormattedMessage {...messages.notNowBtn} />}
      isOpen={isOpen}
      onSubmit={handleSubmit(handleFormSubmit)}
      onClose={onClose}
      disableFullScreenBehavior
      loading={isLoading}
    >
      <form>
        <Typography variant="body1" gutterBottom>
          <FormattedMessage {...messages.voidInvoiceBody} />
        </Typography>

        <Controller
          name="reason"
          control={control}
          render={({ field }) => (
            <TextArea
              variant="outlined"
              label={<FormattedMessage {...inputLabels.reason} />}
              maxSymbols={textareaSettings.maxSymbols}
              rows={textareaSettings.rows}
              fullWidth
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!errors.reason}
              helperText={getString(errors.reason?.message)}
            />
          )}
        />
      </form>
    </DialogComponent>
  );
};

export default VoidInvoiceModal;
