import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  IconButton,
  makeStyles,
  Paper,
  Popover,
  SvgIcon,
  Theme,
  Typography,
} from '@material-ui/core';

import { ReactComponent as XDeleteIcon } from 'img/icons/x-bold_deprecated.svg';

import commonMessages from 'common/messages/messages';
import { LoadingBackdrop, ScrollBox } from 'common/components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > .MuiPaper-root': {
      top: '72px !important',
      right: '8px !important',
      height: 'calc(100% - 80px)',
      width: '440px',

      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  closeBtnIcon: {
    color: theme.palette.darkBackground?.light,
  },
  timesIcon: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
  },
  chip: {
    background: theme.palette.secondary.light,
    border: 'none',
    padding: theme.spacing(0.25, 1),
    height: 'initial',
    marginBottom: theme.spacing(0.5),

    '& .MuiChip-label': {
      padding: 0,
      fontWeight: 700,
      lineHeight: '1rem',
      color: theme.palette.secondary.main,
    },

    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },

    '&:not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
  },
  selectedChip: {
    backgroundColor: theme.palette.primary.main,

    '& .MuiChip-label': {
      color: theme.palette.primary.contrastText,
    },
  },
  searchResultsWrapper: {
    position: 'relative',
    padding: theme.spacing(0, 3),
  },
  noItemsFoundText: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

interface IProps {
  anchorEl: null | Element;
  onClose: () => void;
  children: JSX.Element;
  title: string | JSX.Element;
  isLoading: boolean;
}

const TimeClockSideMenu = ({
  anchorEl,
  onClose,
  children,
  title,
  isLoading,
}: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Popover
      anchorEl={anchorEl}
      open={!!anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
      className={classes.root}
    >
      <Paper className={classes.paper}>
        <Box p={3}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h3">{title}</Typography>

            <IconButton size="small" onClick={onClose}>
              <SvgIcon className={classes.closeBtnIcon} component={XDeleteIcon} />
            </IconButton>
          </Box>
        </Box>

        <ScrollBox hasShadowsOnScroll className={classes.searchResultsWrapper}>
          {children}

          {!children && (
            <Box
              display="flex"
              height="100%"
              justifyContent="center"
              alignItems="center"
              className={classes.noItemsFoundText}
            >
              <Typography color="textSecondary">
                <FormattedMessage {...commonMessages.noItemsFound} />
              </Typography>
            </Box>
          )}

          <LoadingBackdrop isLoading={isLoading} />
        </ScrollBox>
      </Paper>
    </Popover>
  );
};

export default TimeClockSideMenu;
