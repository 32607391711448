import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import cx from 'classnames';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getAccessByPropPath } from 'common/utils/errorObject';

import { ReactComponent as CrossIcon } from 'img/icons/times.svg';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';

import servicesMessages from 'modules/services/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  membershipStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    width: '1rem',
    height: '1rem',
    marginRight: theme.spacing(0.5),

    '&.primary': {
      color: theme.palette.primary.main,
    },
    '&.error': {
      color: theme.palette.error.main,
    },
  },
}));

const FreezeSection = ({ isOutOfTerm }: { isOutOfTerm: boolean }): JSX.Element => {
  const { control, formState, watch } = useFormContext();
  const { errors } = formState;
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();
  const valuePath = isOutOfTerm ? 'outOfTermBillingOption.' : '';

  const freezeAmountError = getAccessByPropPath(
    errors,
    `${valuePath}freeze.editableDaysNumber.value`,
  );

  const isBillingFrozenMembership = watch(`${valuePath}freeze.billingFrozenMembership`);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle2" gutterBottom>
          <FormattedMessage {...servicesMessages.allowMembershipFreezeLabel} />
        </Typography>
      </Grid>

      <Grid item xs={6} sm={4}>
        <Controller
          name={`${valuePath}freeze.editableDaysNumber`}
          control={control}
          render={({ field }) => {
            const { name, value: editableDaysNumber, onChange, onBlur } = field;

            return (
              <TextField
                fullWidth
                variant="outlined"
                disabled={!editableDaysNumber?.editable}
                label={<FormattedMessage {...servicesMessages.freezeDuration} />}
                name={name}
                value={editableDaysNumber?.value || ''}
                onChange={event => onChange({ ...editableDaysNumber, value: event.target.value })}
                onBlur={onBlur}
                error={!!freezeAmountError}
                helperText={renderIntlMessage(freezeAmountError?.message, {
                  value:
                    freezeAmountError?.type === 'max'
                      ? editableDaysNumber?.maxValue
                      : editableDaysNumber?.minValue,
                })}
              />
            );
          }}
        />
      </Grid>

      <Grid item xs={6} sm={8} className={classes.membershipStatus}>
        {isBillingFrozenMembership ? (
          <CheckIcon className={cx(classes.statusIcon, 'primary')} />
        ) : (
          <CrossIcon className={cx(classes.statusIcon, 'error')} />
        )}

        <Typography>
          <FormattedMessage {...servicesMessages.billingFrozenMembershipLabel} />
        </Typography>
      </Grid>
    </>
  );
};

export default React.memo(FreezeSection);
