import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

import TaggedAutocomplete from 'common/components/TaggedAutocomplete/TaggedAutocomplete';
import { TAllergyImtList } from 'common/interfaces/additionalInfo';
import inputLabels from 'common/messages/inputLabels';
import { changeAllergies } from 'common/utils/http';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { LoadingBackdrop } from 'common/components/index';
import { DictionaryList } from 'common/constants';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { useAppDispatch } from 'store/hooks';
import SectionTitle from '../SectionTitle/SectionTitle';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import { RequiredProfileInfo } from 'modules/corporate-settings/constants/requiredFields';

interface IProps {
  title?: JSX.Element;
  recommendedFields: TShortProfileFieldInfoImt;
}

const AllergiesSection = ({ title, recommendedFields }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { control, formState } = useFormContext();
  const { errors } = formState;

  const allergiesList: TAllergyImtList = useSelector(
    selectDictionaryList(DictionaryList.ALLERGIES),
  );

  const renderIntlMessage = useRenderIntlMessage();

  return (
    <Grid container spacing={1}>
      {title && (
        <Grid item xs={12}>
          <SectionTitle title={title} />
        </Grid>
      )}

      <Grid item xs={12}>
        <Controller
          control={control}
          name="allergies"
          render={({ field: { onChange, value } }) => (
            <TaggedAutocomplete
              expandable
              label={renderIntlMessage(inputLabels.allergies)}
              autocompleteProps={{
                multiple: true,
                freeSolo: true,
                filterSelectedOptions: true,
                clearOnBlur: true,
                selectOnFocus: true,
                options: allergiesList?.toJS() || [],
                value,
              }}
              onChange={values => {
                changeAllergies(values, onChange, dispatch, setIsLoading);
              }}
              fullWidth
              error={!!errors.allergies}
              helperText={renderIntlMessage(errors.allergies?.message)}
              recommended={Boolean(recommendedFields?.get(RequiredProfileInfo.Allergies))}
            />
          )}
        />
      </Grid>
      <LoadingBackdrop isLoading={isLoading} />
    </Grid>
  );
};

export default React.memo(AllergiesSection);
