import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useNavigationType, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { Box, Button, Card, CardContent, Grid, SvgIcon, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import {
  AvailabilityType,
  EditPackageStep,
  EditPackageSteps,
  PackageCostType,
  PackageType,
  StepToggleAction,
} from 'modules/services/constants/packages';
import { ActionResult } from 'common/constants/globalConstants';
import { DictionaryList } from 'common/constants';
import { TemplatePurpose, TemplateTypes } from 'common/constants/documentTemplate';
import { PeakModules } from 'common/constants/peakModules';

import { CustomTheme } from 'common/ui/interfaces';
import { IEditPackageDetails, IErrorsSteps } from 'modules/services/interfaces/packages';

import * as actions from 'modules/services/state/packages/actions';
import * as dictionaryActions from 'common/state/dictionary/actions';

import * as selectors from 'modules/services/state/packages/selectors';

import { useAppDispatch } from 'store/hooks';

import { ArrowCircleIcon, ArrowCircleLeftIcon } from 'img/icons';
import { RouteBackground } from 'components';
import { EditPackageForm } from 'modules/services/components';

import messages from 'modules/services/messages/messages';
import commonMessages from 'common/messages/messages';
import { snackbar } from 'common/utils/snackbarUtils';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      flex: 1,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 625,
    },
    pageHeader: {
      padding: theme.spacing(2, 2, 0, 2),
    },
    footer: {
      padding: theme.spacing(2),
      flexWrap: 'nowrap',
    },
    btnIcon: {
      width: '16px',
      height: '16px',
    },
    stepBtn: {
      width: '100%',
      textTransform: 'initial',
      padding: theme.spacing(1, 1.25),
      backgroundColor: theme.palette.secondary.light,
      '&.MuiButton-root:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
      borderBottom: `2px solid white`,
      borderTop: `2px solid white`,
    },
    selectedStepBtn: {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
      '&.MuiButton-root:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    validationErrorBtn: {
      borderBottomColor: theme.palette.error.main,
      borderTopColor: theme.palette.error.main,
    },
    cardContent: {
      padding: theme.spacing(2),
      '&:last-child': {
        padding: theme.spacing(2),
      },
    },
  }),
);

const steps: EditPackageStep[] = [
  EditPackageStep.General,
  EditPackageStep.Billing,
  EditPackageStep.Membership,
  EditPackageStep.Inventory,
  EditPackageStep.Services,
  EditPackageStep.Fees,
];

const stepNameRefs = {
  [EditPackageStep.General]: 'generalSection',
  [EditPackageStep.Billing]: 'billingSection',
  [EditPackageStep.Membership]: 'membershipSection',
  [EditPackageStep.Inventory]: 'inventorySection',
  [EditPackageStep.Services]: 'serviceSection',
  [EditPackageStep.Fees]: 'feesSection',
};

type TRouteParams = { packageId: string };

const EditPackage: React.FC = () => {
  const classes = useStyles();
  const { packageId } = useParams<TRouteParams>();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const stepFormComponentRef = useRef(null);
  const isEditForm = !!packageId;

  // component state

  const [currentStep, setCurrentStep] = useState<EditPackageStep>(EditPackageStep.General);
  const [disabledSteps, setDisabledSteps] = useState<EditPackageStep[]>([]);
  const [formData, setFormData] = useState<Partial<IEditPackageDetails>>({});
  const [errors, setErrors] = useState<IErrorsSteps>({
    generalSection: !packageId,
    billingSection: !packageId,
    membershipSection: !packageId,
  });
  const [packageLoading, setPackageLoading] = useState<boolean>(!!packageId);
  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);

  // redux state

  const dispatch = useAppDispatch();

  const packageLoadingSelector: boolean = useSelector(selectors.selectEditPackageLoading());
  const packageSelector: IEditPackageDetails = useSelector(selectors.selectEditPackage());
  const createUpdatePackageResultSelector = useSelector(
    selectors.selectCreateUpdatePackageActionResult(),
  );

  // handlers

  const onPackageSubmit = useCallback(
    (data, submitErrors) => {
      setSubmitAttempted(true);
      const tempFormErrors = { ...errors, ...submitErrors };

      if (Object.values(tempFormErrors).filter(val => !!val).length !== 0) {
        return;
      }

      const editPackageFormData: IEditPackageDetails = { ...formData, ...data };

      if (!editPackageFormData.generalSection.membership) {
        delete editPackageFormData.membershipSection;
      }

      if (editPackageFormData.generalSection.availabilityType === AvailabilityType.FromNow) {
        delete editPackageFormData.generalSection.startDate;
        delete editPackageFormData.generalSection.endDate;
      }

      if (editPackageFormData.generalSection.availabilityType === AvailabilityType.StartingOnDate) {
        delete editPackageFormData.generalSection.endDate;
      }

      if (
        PackageType.Trial === editPackageFormData.generalSection.type &&
        PackageCostType.Free === editPackageFormData.generalSection.costType
      ) {
        delete editPackageFormData.billingSection;
      } else {
        if (editPackageFormData.billingSection?.outOfTermBillingOption) {
          editPackageFormData.billingSection.billingOptions = [
            ...editPackageFormData.billingSection?.billingOptions,
            editPackageFormData.billingSection.outOfTermBillingOption,
          ];
          delete editPackageFormData.billingSection.outOfTermBillingOption;
        } else {
          snackbar.error(<FormattedMessage {...messages.setOutOfTermBillingOptionLabel} />);

          setErrors(prevState => ({ ...prevState, [EditPackageStep.Billing]: true }));
          return;
        }

        delete editPackageFormData.billingSection.outOfTermBillingOptionPresent;

        editPackageFormData.billingSection.billingOptions = editPackageFormData.billingSection?.billingOptions.map(
          el => {
            const billingOption = { ...el };
            if (!billingOption.paymentSplitting?.allow) {
              delete billingOption.paymentSplitting;
            }
            if (!billingOption.downPayment?.allow) {
              delete billingOption.downPayment;
            }
            if (!billingOption.paymentGrace?.allow) {
              delete billingOption.paymentGrace;
            }
            return billingOption;
          },
        );
      }

      // TODO remove when employees tab will be integrated
      delete editPackageFormData.employees;

      if (isEditForm) {
        dispatch(actions.updateServicePackage(packageId, editPackageFormData));
      } else {
        dispatch(actions.createServicePackage(editPackageFormData));
      }
    },
    [dispatch, errors, formData, isEditForm, packageId],
  );

  const handleChange = (data, errorsChange = {}) => {
    setFormData(prevState => ({ ...prevState, ...data }));

    const resultError = {};
    Object.keys(errorsChange).forEach(key => {
      if (errors[key] !== errorsChange[key]) {
        resultError[key] = errorsChange[key];
      }
    });
    if (Object.keys(resultError).length > 0) {
      setErrors(prevState => ({ ...prevState, ...errorsChange }));
    }
  };

  const handleSelectStep = async (value: EditPackageStep) => {
    const stepFormData = await stepFormComponentRef?.current?.getFormData();
    if (stepFormData) {
      handleChange(stepFormData.formData, stepFormData.errors);
      const isAnyValidationErrors = Object.values(stepFormData.errors).reduce(
        (total, current) => total || current,
      );
      if (!isAnyValidationErrors) {
        setCurrentStep(value);
      }
    }
  };

  const goBack = useCallback(() => {
    if (navigationType === 'PUSH') {
      navigate(-1);
    } else {
      navigate('/services/service-packages');
    }
  }, [navigate, navigationType]);

  const getNextStep = (index: number) => {
    const nextStepIndex = index + 1;
    if (nextStepIndex > steps.length) {
      return null;
    }
    if (disabledSteps.includes(steps[nextStepIndex])) {
      return getNextStep(nextStepIndex);
    }
    return steps[nextStepIndex];
  };

  const onNextStep = () => {
    const index = steps.indexOf(currentStep);
    setCurrentStep(getNextStep(index));
  };

  const getPrevStep = (index: number) => {
    const prevStepIndex = index - 1;
    if (prevStepIndex > steps.length) {
      return null;
    }
    if (disabledSteps.includes(steps[prevStepIndex])) {
      return getPrevStep(prevStepIndex);
    }
    return steps[prevStepIndex];
  };

  const onBackStep = () => {
    const index = steps.indexOf(currentStep);
    setCurrentStep(getPrevStep(index));
  };

  const toggleStep = (stepToDisable: EditPackageStep, action: StepToggleAction) => {
    const stepNameRef = stepNameRefs[stepToDisable];
    setDisabledSteps(prevState => {
      const isStepDisabled = prevState.indexOf(stepToDisable) !== -1;
      if (StepToggleAction.ENABLE === action && isStepDisabled) {
        setErrors(errorsPrevState => ({ ...errorsPrevState, [stepNameRef]: true }));
        return prevState.filter(el => el !== stepToDisable);
      }
      if (StepToggleAction.DISABLE === action && !isStepDisabled) {
        setErrors(errorsPrevState => ({ ...errorsPrevState, [stepNameRef]: false }));
        return prevState.concat(stepToDisable);
      }
      return prevState;
    });
  };

  // effects

  useEffect(() => {
    if (isEditForm && packageSelector) {
      const modifiedData = packageSelector;
      setFormData(prevState => ({ ...prevState, ...modifiedData }));
    }
  }, [isEditForm, packageSelector]);

  useEffect(() => {
    if (packageId) {
      dispatch(actions.fetchPackage(packageId));
    }

    return () => {
      dispatch(actions.resetPackageState());
    };
  }, [dispatch, packageId]);

  useEffect(() => {
    if (packageSelector !== null) {
      setPackageLoading(packageLoadingSelector);
    }
  }, [packageLoadingSelector, packageSelector]);

  useEffect(() => {
    if (ActionResult.SUCCESS_ACTION === createUpdatePackageResultSelector) {
      goBack();
      dispatch(actions.createUpdatePackageResult(null));
    }
  }, [createUpdatePackageResultSelector, dispatch, goBack]);

  useEffect(() => {
    dispatch(
      dictionaryActions.fetchDictionaryList(DictionaryList.DOCUMENT_TEMPLATES, {
        type: TemplateTypes.Document,
        purpose: TemplatePurpose.ServiceContractTemplate,
        module: PeakModules.Services,
      }),
    );
    dispatch(actions.fetchPackageRCodes());
    dispatch(actions.fetchPackageInventory());

    return () => {
      dictionaryActions.resetDictionaryListAction({
        dictionary: DictionaryList.DOCUMENT_TEMPLATES,
      });
      dispatch(actions.resetPackageRCodes());
      dispatch(actions.resetPackageInventory());
    };
  }, [dispatch]);

  // TODO
  const loading = false;

  // renders

  const renderFooter = (onSubmit, onNext, onBack, disabled): JSX.Element => {
    return (
      <Grid container className={classes.footer} justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button color="primary" type="button" onClick={goBack}>
            <FormattedMessage {...commonMessages.cancelBtn} />
          </Button>
        </Grid>

        {currentStep !== steps[0] && (
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              disabled={disabled || loading}
              startIcon={
                <SvgIcon className={classes.btnIcon}>
                  <ArrowCircleLeftIcon />
                </SvgIcon>
              }
              onClick={async () => {
                if (onBack) {
                  if (await onBack()) {
                    onBackStep();
                  }
                }
              }}
            >
              <FormattedMessage {...commonMessages.backBtn} />
            </Button>
          </Grid>
        )}

        {currentStep !== steps[steps.length - 1] && (
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              endIcon={
                <SvgIcon className={classes.btnIcon}>
                  <ArrowCircleIcon />
                </SvgIcon>
              }
              onClick={async () => {
                if (onNext) {
                  if (await onNext()) {
                    onNextStep();
                  }
                }
              }}
              disabled={disabled || loading}
            >
              <FormattedMessage {...commonMessages.nextBtn} />
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={async () => onSubmit()}
            disabled={loading}
          >
            <FormattedMessage {...commonMessages.saveBtn} />
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <RouteBackground>
      <Card className={classes.root}>
        <Grid container className={classes.pageHeader} justifyContent="space-between">
          <Grid item>
            <Typography component="h1" variant="h3">
              <FormattedMessage
                {...(isEditForm
                  ? messages.editServicePackageTitle
                  : messages.newServicePackageTitle)}
              />
            </Typography>
          </Grid>
        </Grid>
        <Box>
          <CardContent className={classes.cardContent}>
            <Box display="flex">
              {steps.map(value => (
                <Button
                  key={value}
                  className={cx(
                    currentStep === value && classes.selectedStepBtn,
                    classes.stepBtn,
                    submitAttempted &&
                      currentStep !== value &&
                      errors[stepNameRefs[value]] &&
                      classes.validationErrorBtn,
                  )}
                  disabled={disabledSteps.includes(value)}
                  onClick={() => {
                    handleSelectStep(value);
                  }}
                >
                  <Typography variant="h5">{EditPackageSteps.translate(value)}</Typography>
                </Button>
              ))}
            </Box>
          </CardContent>
        </Box>
        {!packageLoading && (
          <EditPackageForm
            refToStepForm={stepFormComponentRef}
            currentStep={currentStep}
            renderFooter={renderFooter}
            toggleStep={toggleStep}
            disabledSteps={disabledSteps.slice(0)}
            editPackageFormData={formData}
            handleChange={handleChange}
            errorsStep={errors}
            onPackageSubmit={onPackageSubmit}
            submitAttempted={submitAttempted}
          />
        )}
      </Card>
    </RouteBackground>
  );
};

export default EditPackage;
