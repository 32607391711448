import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { getRequiredMessage } from 'common/constants/globalConstants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { StepContext } from 'common/createContext/stepContext';
import { defaultPriceNumberProps } from 'common/components/NumberController/NumberController';

import { NumberTextField } from 'common/components';

import inputErrors from 'common/messages/inputErrors';
import messages from 'modules/pos-kiosk/messages/messages';
import inputLabels from 'common/messages/inputLabels';

interface IFormReconcileChecksStep {
  checkAmount: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  formWrapper: {
    marginTop: theme.spacing(1.5),
  },
}));

const ValidationSchema = yup.object().shape({
  checkAmount: yup
    .string()
    .min(0, () => inputErrors.checksAmountCountError)
    .required(getRequiredMessage),
});

const ReconcileChecksStep = (): JSX.Element => {
  const formMethods = useForm<IFormReconcileChecksStep>({
    defaultValues: { checkAmount: 0 },
    resolver: yupResolver(ValidationSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'onBlur',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const { onBack, renderFooter, onNext } = useContext(StepContext);
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();

  const onSubmit = values => {
    onNext({ cashDesk: { checkAmount: Number(values.checkAmount) } });
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" color="textSecondary">
            <FormattedMessage {...messages.checksTotalTitle} />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <form className={classes.formWrapper}>
            <Controller
              name="checkAmount"
              control={control}
              render={({ field: { value, onBlur, onChange } }) => (
                <NumberTextField
                  variant="outlined"
                  value={value}
                  fullWidth
                  label={<FormattedMessage {...inputLabels.checksAmount} />}
                  onChange={onChange}
                  onBlur={onBlur}
                  numberFormatProps={defaultPriceNumberProps}
                  error={!!errors.checkAmount}
                  helperText={renderIntlMessage(errors.checkAmount?.message)}
                />
              )}
            />
          </form>
        </Grid>
      </Grid>

      {renderFooter(onBack, handleSubmit(onSubmit))}
    </>
  );
};

export default ReconcileChecksStep;
