import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';

import { Alert, DialogComponent } from 'common/components';
import bookingMessages from 'modules/booking/messages';
import { Controller, useForm } from 'react-hook-form';
import { EventUpdateType } from 'modules/booking/interfaces';
import { AlertTypes } from 'common/interfaces/alerts';

export interface ICancelParticipationFormValues {
  updateType: EventUpdateType;
}

interface ICancelParticipationModalProps {
  isOpen: boolean;
  isUpdateEventLoading: boolean;
  isRepeatedEvent: boolean;
  dialogTitle: JSX.Element | string;
  onClose: () => void;
  onSubmit: (data: ICancelParticipationFormValues) => void;
  isWaitlist?: boolean;
}

const ParticipationModal = ({
  isOpen,
  onClose,
  onSubmit,
  isRepeatedEvent,
  dialogTitle,
  isUpdateEventLoading,
  isWaitlist,
}: ICancelParticipationModalProps): JSX.Element => {
  const formMethods = useForm<ICancelParticipationFormValues>({
    defaultValues: { updateType: EventUpdateType.OnlyCurrentInstance },
    mode: 'onBlur',
    shouldUnregister: false,
  });

  const { control, handleSubmit } = formMethods;

  const formId = 'participation-form';

  return (
    <DialogComponent
      formId={formId}
      size="xs"
      title={dialogTitle}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      loading={isUpdateEventLoading}
    >
      <form id={formId}>
        <Grid item xs={12}>
          {isRepeatedEvent && (
            <Controller
              name="updateType"
              control={control}
              render={({ field }) => (
                <FormControl component="fieldset">
                  {isWaitlist && (
                    <Alert
                      title={<FormattedMessage {...bookingMessages.youCanJoinTheWaitlist} />}
                      severity={AlertTypes.Warning}
                    />
                  )}
                  <RadioGroup
                    aria-label="recurringEventUpdateType"
                    name="eventUpdateType"
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                  >
                    <>
                      <FormControlLabel
                        value={EventUpdateType.OnlyCurrentInstance}
                        control={<Radio />}
                        label={
                          <FormattedMessage {...bookingMessages.editRecurringThisEventOption} />
                        }
                      />
                      <FormControlLabel
                        value={EventUpdateType.CurrentAndAllNext}
                        control={<Radio />}
                        label={
                          <FormattedMessage {...bookingMessages.editRecurringFutureEventOption} />
                        }
                      />
                      <FormControlLabel
                        value={EventUpdateType.All}
                        control={<Radio />}
                        label={
                          <FormattedMessage {...bookingMessages.editRecurringAllEventOption} />
                        }
                      />
                    </>
                  </RadioGroup>
                </FormControl>
              )}
            />
          )}
        </Grid>
      </form>
    </DialogComponent>
  );
};

export default ParticipationModal;
