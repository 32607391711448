import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

import { HOME_PATH } from 'common/constants/globalConstants';

import { ReactComponent as ArrowLeftIcon } from 'img/icons/arrow-left.svg';
import { Button } from 'common/components/index';

import messages from 'modules/kid-zone/messages';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '520px',
    textAlign: 'center',
  },
  lockIcon: {
    padding: theme.spacing(2),

    '& svg': {
      width: '150px',
      height: 'auto',
      color: theme.palette.darkBackground?.light,
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '46px',
  },
  description: {
    marginTop: theme.spacing(3),
  },
  toDashboardBtn: {
    color: theme.palette.primary.main,
    marginRight: ({ additionalButton }: Partial<IProps>) => theme.spacing(additionalButton ? 2 : 0),
  },
}));

interface IProps {
  className?: string;
  lockIcon: JSX.Element;
  title?: JSX.Element;
  description?: JSX.Element;
  additionalButton?: JSX.Element;
  hasVisibleBackToBtn?: boolean;
}

const UnavailableModule: FC<IProps> = ({
  className,
  lockIcon,
  title,
  description,
  additionalButton,
  hasVisibleBackToBtn = true,
}) => {
  const navigate = useNavigate();

  const classes = useStyles({ additionalButton });

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%" minWidth="100%">
      <Box className={cx(classes.wrapper, className)}>
        <Box display="flex" justifyContent="center" className={classes.lockIcon} mb={1}>
          {lockIcon}
        </Box>

        {title && <Typography className={classes.title}>{title}</Typography>}
        {description && <Box mt={3}>{description}</Box>}

        <Box display="flex" justifyContent="center" mt={3}>
          {hasVisibleBackToBtn && (
            <Button
              variant="contained"
              className={classes.toDashboardBtn}
              startIcon={<ArrowLeftIcon />}
              onClick={() => navigate(HOME_PATH)}
            >
              <FormattedMessage {...messages.backToDashboardBtn} />
            </Button>
          )}
          {additionalButton}
        </Box>
      </Box>
    </Box>
  );
};

export default UnavailableModule;
