import React, { CSSProperties, useEffect, useState } from 'react';
import {
  CartesianGrid,
  AreaChart,
  Area,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { List as ImmutableList } from 'immutable';
import moment from 'moment-timezone';
import { IChartLineSettings } from '../../../interfaces/common';
import { IOverTimeActivityItem, IStatisticsImt } from 'modules/reports/interfaces/salesOverTime';

import { getQueueColor } from 'common/utils';
import { prepareChartData } from '../utils';
import { CustomAxisTick } from 'modules/reports/components/index';
import { colors } from 'common/ui/theme/default';

interface IOverviewChartProps {
  chartData: ImmutableList<IOverTimeActivityItem>;
  lineSettings: IChartLineSettings[];
  yAxisTransform?: (value: string | number) => string;
  xAxisTransform?: (value: string | number) => string;
  dataStatistics: ImmutableList<IStatisticsImt>;
  tooltipWrapperStyle?: CSSProperties;
}

const SalesChart: React.FC<IOverviewChartProps> = (props: IOverviewChartProps): JSX.Element => {
  const {
    chartData,
    lineSettings,
    yAxisTransform,
    xAxisTransform,
    dataStatistics,
    tooltipWrapperStyle,
  } = props;
  const [updatedData, setUpdatedData] = useState([]);

  useEffect(() => {
    if ('activity' in chartData.toJS()) {
      setUpdatedData(prepareChartData(chartData?.toJS(), dataStatistics));
    }
  }, [chartData, dataStatistics]);

  const updatedChartData = updatedData.map(data => ({ ...data, date: moment(data.date).toDate() }));

  return (
    <ResponsiveContainer width="100%" minHeight="300px">
      <AreaChart
        data={updatedChartData}
        width={500}
        height={300}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
        style={{ fontSize: 12 }}
      >
        <defs>
          {dataStatistics.toJS()?.map((item: any) => (
            <linearGradient id={item.register.id} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={item.color} stopOpacity={0.4} />
              <stop offset="96%" stopColor={item.color} stopOpacity={0.1} />
            </linearGradient>
          ))}
        </defs>
        <CartesianGrid fill={colors.input.disabled.backgroundColor} strokeDasharray="3 3" />
        <XAxis axisLine={false} dataKey="date" interval={20} tickFormatter={xAxisTransform} />

        <YAxis axisLine={false} tick={<CustomAxisTick formatter={yAxisTransform} />} />
        <Tooltip
          labelFormatter={label => moment(label).format('MMM DD')}
          formatter={yAxisTransform}
          wrapperStyle={{
            position: 'absolute',
            outline: 'none',
            zIndex: 100,
            ...tooltipWrapperStyle,
          }}
        />
        {!!lineSettings.length &&
          lineSettings.map((setting, index) => {
            return (
              <Area
                key={setting.key}
                type="linear"
                strokeWidth={2}
                activeDot={{ r: 5 }}
                stroke={getQueueColor(index)}
                name={setting.name}
                fillOpacity={1}
                fill={`url(#${setting.key})`}
                dataKey={`registers.${index}.${setting.dataKey}`}
                hide={setting.isHidden}
              />
            );
          })}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default SalesChart;
