import axios from 'axios';
import ApiService from 'services/network/ApiService';

import {
  IBankRemittanceInfo,
  IBankViewInfoDto,
  IClubBankInfo,
  IClubBillingInfo,
  IClubEntrances,
  IClubFeeLate,
  IClubFeeMonthly,
  IClubFeeReturn,
  IClubFeeSingle,
  IClubFeeTransaction,
  IClubGeneralInfo,
  IClubGeneralInfoView,
  IClubKidZoneInfo,
  IClubOtherInfo,
  IClubProcessingFeeRates,
  IClubProcessorConfigInfo,
  IClubTaxpayerInfo,
  IEntranceItem,
} from 'modules/clubs/interfaces';
import { IBankData } from '../../modules/corporations/interfaces';
import { ITableParams } from 'common/interfaces/table';
import { IPaginatedData } from 'common/interfaces/pagination';
import { IShortPerson } from 'common/interfaces/common';

export default class ClubSettingsService extends ApiService {
  public getClubSettingsGeneralInfo = (clubId: string): Promise<IClubGeneralInfo> =>
    axios.get(`/api/v1/club-settings/${clubId}`);

  public getClubSettingsGeneralInfoView = (clubId: string): Promise<IClubGeneralInfoView> =>
    axios.get(`/api/v1/club-settings/${clubId}/view`);

  public getClubEntrances = (clubId: string): Promise<IClubEntrances> =>
    axios.get(`/api/v1/club-settings/${clubId}/club-entrance`);

  public getClubSettingsBankInfo = (clubId: string): Promise<IClubBankInfo> =>
    axios.get(`/api/v1/club-settings/${clubId}/bank-info`);

  public getClubSettingsBankInfoView = (clubId: string): Promise<IBankViewInfoDto> =>
    axios.get(`/api/v1/club-settings/${clubId}/bank-info/view`);

  public getClubBillingInfo = (clubId: string): Promise<IClubBillingInfo> =>
    axios.get(`/api/v1/club-settings/${clubId}/billing-info`);

  public getClubProcessorConfigInfo = (clubId: string): Promise<IClubProcessorConfigInfo> =>
    axios.get(`/api/v1/club-settings/${clubId}/processor-config`);

  public getClubTaxpayerInfo = (clubId: string): Promise<IClubTaxpayerInfo> =>
    axios.get(`/api/v1/club-settings/${clubId}/taxpayer-info`);

  public getClubOtherInfo = (clubId: string): Promise<IClubOtherInfo> =>
    axios.get(`/api/v1/club-settings/${clubId}/other-info`);

  public getClubKidZoneSettingsInfo = (clubId: string): Promise<IClubKidZoneInfo> =>
    axios.get(`/api/v1/club-settings/${clubId}/kid-zone`);

  public updateClubGeneralInfo = (
    clubId: string,
    requestData: IClubGeneralInfo,
  ): Promise<IClubGeneralInfo> => axios.put(`/api/v1/club-settings/${clubId}`, requestData);

  public updateClubEntrances = (
    clubId: string,
    requestData: IClubEntrances[],
  ): Promise<IClubEntrances[]> =>
    axios.put(`/api/v1/club-settings/${clubId}/club-entrance`, requestData);

  public updateClubBankInfo = (
    clubId: string,
    requestData: IClubBankInfo,
  ): Promise<IClubBankInfo> => axios.put(`/api/v1/club-settings/${clubId}/bank-info`, requestData);

  public updateClubBillingInfo = (
    clubId: string,
    requestData: IClubBillingInfo,
  ): Promise<IClubBillingInfo> =>
    axios.put(`/api/v1/club-settings/${clubId}/billing-info`, requestData);

  public updateClubProcessorConfigInfo = (
    clubId: string,
    requestData: IClubProcessorConfigInfo,
  ): Promise<IClubProcessorConfigInfo> =>
    axios.put(`/api/v1/club-settings/${clubId}/processor-config`, requestData);

  public updateClubTaxpayerInfo = (
    clubId: string,
    requestData: IClubTaxpayerInfo,
  ): Promise<IClubTaxpayerInfo> =>
    axios.put(`/api/v1/club-settings/${clubId}/taxpayer-info`, requestData);

  public updateClubOtherInfo = (
    clubId: string,
    requestData: IClubOtherInfo,
  ): Promise<IClubOtherInfo> =>
    axios.put(`/api/v1/club-settings/${clubId}/other-info`, requestData);

  public updateClubBankRemittanceInfo = (
    clubId: string,
    requestData: IBankRemittanceInfo,
  ): Promise<IBankRemittanceInfo> =>
    axios.put(`/api/v1/club-settings/${clubId}/bank-info/remittance-info`, requestData);

  public updateClubKidZoneSettingsInfo = (
    clubId: string,
    requestData: IEntranceItem,
  ): Promise<IClubKidZoneInfo> =>
    axios.put(`/api/v1/club-settings/${clubId}/kid-zone`, requestData);

  public changeClubKidZoneSettingsStatus = (
    clubId: string,
    isActive: boolean,
  ): Promise<IClubKidZoneInfo> =>
    axios.patch(`/api/v1/club-settings/${clubId}/kid-zone/change-status`, { active: isActive });

  public changeClubStatus = (clubId: string, isActive: boolean): Promise<IClubGeneralInfo> =>
    axios.patch(`/api/v1/club-settings/${clubId}/change-status`, { active: isActive });

  public getClubFeeProcessingRates = (clubId: string): Promise<IClubProcessingFeeRates> =>
    axios.get(`/api/v1/club-settings/${clubId}/fees/fee-processing-info`);

  public updateClubProcessingFeeRates = (
    clubId: string,
    requestData: IClubProcessingFeeRates,
  ): Promise<IClubProcessingFeeRates> =>
    axios.put(`/api/v1/club-settings/${clubId}/fees/fee-processing-info`, requestData);

  public getCorporationProcessingFeeRates = (clubId: string): Promise<IClubProcessingFeeRates> =>
    axios.get(`/api/v1/club-settings/${clubId}/fees/fee-processing-info/corporation`);

  public getClubFeeSingleRates = (clubId: string): Promise<IClubFeeSingle> =>
    axios.get(`/api/v1/club-settings/${clubId}/fees/fee-single-info`);

  public updateClubFeeSingleRates = (
    clubId: string,
    requestData: IClubFeeSingle,
  ): Promise<IClubFeeSingle> =>
    axios.put(`/api/v1/club-settings/${clubId}/fees/fee-single-info`, requestData);

  public getCorporationFeeSingleRates = (clubId: string): Promise<IClubFeeSingle> =>
    axios.get(`/api/v1/club-settings/${clubId}/fees/fee-single-info/corporation`);

  public getClubFeeLateRates = (clubId: string): Promise<IClubFeeLate> =>
    axios.get(`/api/v1/club-settings/${clubId}/fees/fee-late-info`);

  public updateClubFeeLateRates = (
    clubId: string,
    requestData: IClubFeeLate,
  ): Promise<IClubFeeLate> =>
    axios.put(`/api/v1/club-settings/${clubId}/fees/fee-late-info`, requestData);

  public getCorporationFeeLateRates = (clubId: string): Promise<IClubFeeLate> =>
    axios.get(`/api/v1/club-settings/${clubId}/fees/fee-late-info/corporation`);

  public getClubFeeReturnRates = (clubId: string): Promise<IClubFeeReturn> =>
    axios.get(`/api/v1/club-settings/${clubId}/fees/fee-return-info`);

  public updateClubFeeReturnRates = (
    clubId: string,
    requestData: IClubFeeReturn,
  ): Promise<IClubFeeReturn> =>
    axios.put(`/api/v1/club-settings/${clubId}/fees/fee-return-info`, requestData);

  public getCorporationFeeReturnRates = (clubId: string): Promise<IClubFeeReturn> =>
    axios.get(`/api/v1/club-settings/${clubId}/fees/fee-return-info/corporation`);

  public getClubFeeMonthlyRates = (clubId: string): Promise<IClubFeeMonthly> =>
    axios.get(`/api/v1/club-settings/${clubId}/fees/fee-monthly-info`);

  public updateClubFeeMonthlyRates = (
    clubId: string,
    requestData: IClubFeeMonthly,
  ): Promise<IClubFeeMonthly> =>
    axios.put(`/api/v1/club-settings/${clubId}/fees/fee-monthly-info`, requestData);

  public getCorporationFeeMonthlyRates = (clubId: string): Promise<IClubFeeMonthly> =>
    axios.get(`/api/v1/club-settings/${clubId}/fees/fee-monthly-info/corporation`);

  public getClubFeeTransactionRates = (corporationId: string): Promise<IClubFeeTransaction> =>
    axios.get(`/api/v1/club-settings/${corporationId}/fees/fee-transaction-info`);

  public updateClubFeeTransactionRates = (
    corporationId: string,
    requestData: IClubFeeTransaction,
  ): Promise<IClubFeeTransaction> =>
    axios.put(`/api/v1/club-settings/${corporationId}/fees/fee-transaction-info`, requestData);

  public getCorporationFeeTransactionRates = (
    corporationId: string,
  ): Promise<IClubFeeTransaction> =>
    axios.get(`/api/v1/club-settings/${corporationId}/fees/fee-transaction-info/corporation`);

  public fetchBankList = (clubId: string): Promise<IBankData[]> =>
    axios.get(`/api/v1/club-settings/${clubId}/banks`);

  public getEmployees = (
    clubId: string,
    { searchStr, ...params }: Omit<ITableParams, 'order' | 'orderBy' | 'filters' | 'calendarView'>,
  ): Promise<IPaginatedData<IShortPerson & { email: string }>> => {
    return axios.get(`/api/v1/club-settings/${clubId}/dictionary/employees`, {
      params: {
        ...params,
        search: searchStr,
      },
    });
  };
}
