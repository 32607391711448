import moment from 'moment-timezone';

import {
  IMultipleSelectData,
  ITableMultiSelectParams,
  ITableParams,
} from 'common/interfaces/table';
import {
  FilterTypes,
  IDateRangeFilterValue,
  IFilter,
  IFilterOption,
  IMultipleFilterValue,
  ISingleFilterValue,
} from 'common/interfaces/filter';
import { IObject } from 'common/interfaces/common';

export default class ApiService {
  private makeArrayFilters = (filters: IFilter[]) => {
    const searchParams: Record<string, unknown> = {};

    filters.forEach(filter => {
      if (
        filter.type === FilterTypes.MULTIPLE ||
        filter.type === FilterTypes.MULTIPLE_WITH_PAGINATE
      ) {
        searchParams[filter.name] = (filter.value as IMultipleFilterValue)
          .map(option => option.value)
          .join(`,`);
      } else if (filter.type === FilterTypes.SINGLE) {
        if (filter.value && 'value' in filter.value) {
          searchParams[filter.name] = filter.value.value;
        }
      } else if (filter.type === FilterTypes.DATE_RANGE) {
        const value: IDateRangeFilterValue = filter.value as IDateRangeFilterValue;

        if (value.startDate) {
          searchParams[`${filter.name}StartDate`] = moment(value.startDate)
            .startOf('day')
            .utc(true)
            .format();
        }

        if (value.endDate) {
          searchParams[`${filter.name}EndDate`] = moment(value.endDate)
            .endOf('day')
            .utc(true)
            .format();
        }
      }
    });

    return searchParams;
  };

  protected transformArrayToObjParams = <T>(params: T[]) => {
    return params.reduce((acc: { [key: number]: T }, item, i) => ({ ...acc, [i]: item }), {});
  };

  protected getSearchFromTableParams = (
    tableParams?: ITableParams,
    disablePaginationAndSorting?: boolean,
  ): Record<string, unknown> => {
    if (!tableParams) {
      return {};
    }

    let searchParams: Record<string, unknown> = disablePaginationAndSorting
      ? {}
      : {
          page: tableParams.page,
          perPage: tableParams.perPage,
          sortBy: (tableParams.orderBy as string).toUpperCase(),
          sortDirection: (tableParams.order as string).toUpperCase(),
        };

    if (tableParams.searchStr) {
      searchParams.search = tableParams.searchStr;
    }

    if (tableParams.filters?.length) {
      const transformedFilters = this.makeArrayFilters(tableParams.filters);

      searchParams = { ...searchParams, ...transformedFilters };
    }

    return searchParams;
  };

  protected getSearchFromTableMultiSelectParams = (
    selected: Array<string | number>,
    isAllSelected: boolean,
    tableParamsSelected: ITableMultiSelectParams,
  ): IObject => {
    let searchParams: IObject = isAllSelected
      ? {
          excludedIds: selected.join(','),
          allSelected: isAllSelected,
        }
      : {
          includedIds: selected.join(','),
          allSelected: isAllSelected,
        };

    if (tableParamsSelected.tableFilters.length) {
      const transformedFilters = this.makeArrayFilters(tableParamsSelected.tableFilters);

      searchParams = { ...searchParams, ...transformedFilters };
    }

    return searchParams;
  };

  protected getSearchFromFilters = (filters: IFilter[]) => {
    if (!filters) {
      return {};
    }

    let searchParams = {};

    if (filters.length) {
      const transformedFilters = this.makeArrayFilters(filters);

      searchParams = { ...searchParams, ...transformedFilters };
    }

    return searchParams;
  };

  private dateToISOString = (dateObj: Date, endDate?: boolean): string => {
    const preparedDate = endDate
      ? dateObj.setUTCHours(23, 59, 59, 0)
      : dateObj.setUTCHours(0, 0, 0, 0);
    return moment(preparedDate).toISOString();
  };

  protected makeRequestUrl = (url: string, requestOptions?: ITableParams): string => {
    let requestParams = '';

    if (requestOptions) {
      const { page, perPage } = requestOptions;
      requestParams += `?page=${page}&perPage=${perPage}`;
    }

    return `${url}${requestParams}`;
  };

  protected makeRequestUrlFromFilter = (url: string, filters: IFilter[]): string => {
    const queryParamsString = filters
      .map(filter => {
        let queryString;

        if (filter.type === FilterTypes.SINGLE) {
          const selectedOption = filter.value as ISingleFilterValue;

          if (selectedOption) {
            queryString = `${filter.name}=${selectedOption.value}`;
          }
        }

        if (
          filter.type === FilterTypes.MULTIPLE ||
          filter.type === FilterTypes.MULTIPLE_WITH_PAGINATE
        ) {
          const selectedOptions = filter.value as IMultipleFilterValue;
          queryString = `${filter.name}=${selectedOptions.map(option => option.value).join('&')}`;
        }

        if (filter.type === FilterTypes.DATE_RANGE) {
          const dateRangeValue: IDateRangeFilterValue = filter.value as IDateRangeFilterValue;
          const startDate: string = this.dateToISOString(dateRangeValue.startDate as Date);
          const endDate: string = this.dateToISOString(dateRangeValue.endDate as Date);
          queryString = `${filter.name}StartDate=${startDate}&${filter.name}EndDate=${endDate}`;
        }

        return queryString;
      })
      .join('&');

    return `${url}${queryParamsString ? `?${queryParamsString}` : ''}`;
  };

  protected makeRequestUrlFromIdsSelectedFilter = (
    url: string,
    selected: Array<string | number>,
    isAllSelected: boolean,
    tableParamsSelected: ITableMultiSelectParams,
  ): string => {
    return `${this.makeRequestUrlFromFilter(url, tableParamsSelected.tableFilters)}${
      tableParamsSelected.tableFilters.length > 0 ? '&' : '?'
    }ids=${selected.join(',')}${isAllSelected ? '&allSelected=true' : ''}${
      tableParamsSelected.searchStr ? `&searchStr=${tableParamsSelected.searchStr}` : ''
    }`;
  };

  protected transformMultiselectDataToBulkDTO = (multipleSelectData: IMultipleSelectData) => {
    const { tableParams } = multipleSelectData;
    return {
      ...multipleSelectData,
      tableFilter: {
        search: tableParams?.searchStr,
        ...tableParams?.tableFilters?.reduce(
          (obj, filter: any) => ({
            ...obj,
            [filter.name]:
              filter.type === FilterTypes.SINGLE || filter.type === FilterTypes.DATE_RANGE
                ? filter.value.value
                : filter.value.map((value: IFilterOption) => value.value),
          }),
          {},
        ),
      },
    };
  };
}
