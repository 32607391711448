import React, { useEffect, useMemo } from 'react';
import { batch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { yupResolver } from '@hookform/resolvers/yup';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { useAppDispatch } from 'store/hooks';

import { IServerError } from 'common/interfaces/http';
import { ServerError } from 'common/errors/serverErrors';

import * as actions from 'memberPortalModules/authentication/state/actions';

import { ReactComponent as DefaultIcon } from 'img/icons/default-icon.svg';
import { ReactComponent as PeakLogo } from 'img/peak-logo-dark.svg';
import { ReactComponent as PeakLogoSmall } from 'img/icons/peak-logo-small.svg';
import { ReactComponent as ArrowLeftIcon } from 'img/icons/arrow-left.svg';
import { PasswordField, Alert, ButtonWithCircularProgress } from 'common/components';
import { RouteBackground } from 'components';
import { AlertTypes } from 'common/interfaces/alerts';
import { ICorporationItem, ICorporationItemImt } from 'modules/authentication/interfaces';
import { getMemberPortalLoginValidationSchema } from './ValidationSchema';

import inputLabels from 'common/messages/inputLabels';
import messages from 'modules/authentication/messages';
import commonMessages from 'common/messages/messages';
import errorMessages from 'common/errors/messages';
import * as selectors from 'modules/peak-settings/state/selectors';
import * as peakActions from 'modules/peak-settings/state/actions';
import { snackbar } from 'common/utils/snackbarUtils';
import { PRIVACY_POLICY_PATH } from 'common/constants/globalConstants';

const useStyles = makeStyles((theme: Theme) => ({
  optionLogo: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
  },
  selectedOptionLogo: {
    width: '16px',
    height: '16px',
    margin: theme.spacing(0, 0.5),
  },
  backIcon: {
    width: '16px',
    height: '16px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    width: '280px',
  },
  privacyPolicyTitle: {
    lineHeight: '1rem',
  },
  disabled: {
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: theme.palette.darkBackground.light,
    },
  },
}));

const peakOptionId = 'PEAK';

const initialValues = {
  username: '',
  password: '',
  corporation: null,
};

interface ILogin {
  username: string;
  password: string;
  corporation: ICorporationItem;
}

interface ILoginPageProps {
  signInError: IServerError;
  isLoading: boolean;
  corporations: ImmutableList<ICorporationItemImt>;
}

const MainLogin = ({ signInError, isLoading, corporations }: ILoginPageProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const primaryColor: string = useSelector(selectors.selectPeakPrimaryColor);
  const primaryColorLoading: boolean = useSelector(selectors.selectPeakPrimaryColorLoading);

  const isAvailableCorporationsExists = !!corporations?.size;

  const formMethods = useForm<any>({
    defaultValues: initialValues,
    resolver: yupResolver(getMemberPortalLoginValidationSchema(isAvailableCorporationsExists)),
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    getValues,
    watch,
    setValue,
    clearErrors,
  } = formMethods;

  const corporation = watch('corporation');
  // TODO: Need to move to container
  useEffect(() => {
    // TODO: Need to add the thunk for member portal
    dispatch(peakActions.fetchPeakPrimaryColor());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetCorporationsForSignInAction());
    };
  }, [dispatch]);

  useEffect(() => {
    // if the only corporation set by default
    if (corporations?.size === 1) {
      setValue('corporation', corporations.get(0)?.toJS());
      clearErrors();
      dispatch(actions.setOrganizationPrimaryColorAction(corporations.getIn([0, 'color'])));
    }
  }, [clearErrors, corporations, dispatch, setValue]);

  const renderErrorMessage = useRenderIntlMessage();

  const theme = useTheme();

  const classes = useStyles();

  const showSnackBar = () => {
    snackbar.warning(<FormattedMessage {...commonMessages.notImplementedYet} />);
  };

  const onSubmit = (formValues: ILogin) => {
    const { username, password } = formValues;

    dispatch(
      actions.signIn(username, password, corporation.id === peakOptionId ? null : corporation.id),
    );
  };

  const handleSubmitFirstStep = async event => {
    event.preventDefault();

    const isFormValid = await trigger();
    const formValues: ILogin = getValues();

    if (isFormValid && !isAvailableCorporationsExists) {
      dispatch(actions.fetchCorporationsForSignIn(formValues.username));
    }
  };

  const resetServerError = () => signInError && dispatch(actions.signInErrorAction(null));

  const handleBackBtnClick = () => {
    resetServerError();

    batch(() => {
      dispatch(actions.resetCorporationsForSignInAction());
      dispatch(actions.resetOrganizationPrimaryColorAction());
    });
  };

  const onUserNameChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    resetServerError();

    setValue('username', e.target.value.trim());
  };

  const transformedCorporationsOptions = useMemo(
    () =>
      corporations
        ?.map(corporationItem =>
          corporationItem.get('id') ? corporationItem : corporationItem.set('id', 'PEAK'),
        )
        ?.toJS(),
    [corporations],
  );

  const permissionsErrorCode = ServerError.EMPLOYEE_HAVE_NOT_ACTIVE_CLUB_OR_CORPORATE_PERMISSIONS;
  const isExistCodes = Array.isArray(signInError?.codes);
  const hasNotPermissionsError = isExistCodes && signInError.codes[0] === permissionsErrorCode;

  const errorMessage = (
    <FormattedMessage
      {...(hasNotPermissionsError
        ? errorMessages.haveNotActiveClubOrCorporatePermissionsError
        : errorMessages.wrongUsernameOrPasswordError)}
    />
  );

  return (
    <RouteBackground fullScreenView>
      <Box height="100%" overflow="hidden">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          height="calc(100% - 1rem)"
        >
          <Box pb={3}>
            <PeakLogo />
          </Box>

          <form
            id="login-form"
            onSubmit={corporation ? handleSubmit(onSubmit) : handleSubmitFirstStep}
            className={classes.formContainer}
          >
            <Controller
              name="username"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    className={classes.disabled}
                    fullWidth
                    name={field.name}
                    variant="outlined"
                    value={field.value}
                    onChange={onUserNameChange}
                    disabled={!!corporations?.size}
                    onBlur={field.onBlur}
                    label={<FormattedMessage {...inputLabels.email} />}
                    error={!signInError ? !!errors.username : false}
                    helperText={!signInError ? renderErrorMessage(errors.username?.message) : ''}
                  />
                );
              }}
            />

            {isAvailableCorporationsExists && (
              <Controller
                name="corporation"
                control={control}
                render={({ field }) => (
                  <MuiAutocomplete
                    value={field.value}
                    onChange={(_, option) => {
                      field.onChange(option);
                      dispatch(actions.setOrganizationPrimaryColorAction(option.color));
                    }}
                    onBlur={field.onBlur}
                    multiple={false}
                    disableClearable
                    getOptionLabel={option => option.title || ''}
                    options={transformedCorporationsOptions || []}
                    renderOption={option => (
                      <Box display="flex" alignItems="center">
                        {option.id === peakOptionId ? (
                          <PeakLogoSmall className={classes.optionLogo} />
                        ) : (
                          <Avatar
                            src={option.imageUrl}
                            alt="corporation-logo"
                            className={classes.optionLogo}
                          >
                            <DefaultIcon />
                          </Avatar>
                        )}

                        <Typography>{option.title}</Typography>
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={<FormattedMessage {...inputLabels.corporation} />}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: field.value && (
                            <>
                              {field.value.title.toLowerCase() === peakOptionId.toLowerCase() ? (
                                <PeakLogoSmall className={classes.selectedOptionLogo} />
                              ) : (
                                <Avatar
                                  src={field.value.imageUrl}
                                  alt="corporation-logo"
                                  className={classes.selectedOptionLogo}
                                >
                                  <DefaultIcon />
                                </Avatar>
                              )}
                            </>
                          ),
                        }}
                        error={!!errors.corporation}
                        helperText={renderErrorMessage(errors.corporation?.message)}
                      />
                    )}
                  />
                )}
              />
            )}

            {corporation && (
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <PasswordField
                    fullWidth
                    variant="outlined"
                    value={field.value}
                    name={field.name}
                    onChange={v => {
                      resetServerError();
                      field.onChange(v);
                    }}
                    onBlur={field.onBlur}
                    label={<FormattedMessage {...inputLabels.password} />}
                    error={!signInError ? !!errors.password : false}
                    helperText={!signInError ? renderErrorMessage(errors.password?.message) : ''}
                  />
                )}
              />
            )}

            <Box display="flex" flexDirection="column" gridGap={16}>
              {signInError && !isLoading && (
                <Alert
                  title={errorMessage}
                  severity={AlertTypes.Danger}
                  resolveLabel={<FormattedMessage {...commonMessages.configureBtn} />}
                />
              )}

              <ButtonWithCircularProgress
                isSubmitting={isLoading || primaryColorLoading}
                fullWidth
                variant="contained"
                type={corporation ? 'submit' : 'button'}
                onClick={corporation ? null : handleSubmitFirstStep}
                style={{
                  backgroundColor: corporation ? theme.palette.primary.main : primaryColor,
                }}
              >
                <FormattedMessage {...(corporation ? messages.signIn : messages.continue)} />
              </ButtonWithCircularProgress>

              {!!corporations?.size && (
                <Button
                  startIcon={<ArrowLeftIcon className={classes.backIcon} />}
                  fullWidth
                  color="primary"
                  onClick={handleBackBtnClick}
                >
                  <FormattedMessage {...commonMessages.backBtn} />
                </Button>
              )}

              <Button fullWidth color="primary" onClick={showSnackBar}>
                <FormattedMessage {...messages.forgotPassword} />
              </Button>

              <Button fullWidth color="primary" onClick={showSnackBar}>
                <FormattedMessage {...messages.needAccount} />
              </Button>
            </Box>
          </form>
        </Box>
        <Box display="flex" justifyContent="center">
          <Link to={PRIVACY_POLICY_PATH}>
            <Typography color="secondary" className={classes.privacyPolicyTitle}>
              <FormattedMessage {...commonMessages.privacyPolicy} />
            </Typography>
          </Link>
        </Box>
      </Box>
    </RouteBackground>
  );
};

export default MainLogin;
