import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Autocomplete as MuiAutocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { Box, FormHelperText, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Search as SearchIcon, Cancel as CancelIcon } from '@material-ui/icons';
import { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete/Autocomplete';

import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';

interface UseStylesProps {
  padding?: string;
  height?: number;
  width?: number;
  blackStyle?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapAutocomplete: {
    padding: 0,
  },
  wrapInput: {
    '&>div': {
      padding: '0 5px!important',
      backgroundColor: ({ blackStyle }: UseStylesProps) =>
        blackStyle && theme.palette.secondary.light,
      '& .MuiOutlinedInput-notchedOutline': {
        border: ({ blackStyle }: UseStylesProps) => blackStyle && 'unset',
      },
    },
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 200,
    position: 'relative',
    boxShadow: theme.shadows[3],
  },
  wrapIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  wrapSearchIcon: {
    pointerEvents: 'none',
  },
  wrapClearIcon: {
    right: 0,
    cursor: 'pointer',
    transitionDuration: '0.3s',
    opacity: 0.7,
  },
  hiddenClearIcon: {
    pointerEvents: 'none',
    opacity: 0,
  },
  searchIconIcon: {
    width: '20px',
    height: '20px',
    color: theme.palette.text.secondary,
    opacity: 0.7,
  },
  inputClassName: {
    padding: ({ padding }: UseStylesProps) => padding || theme.spacing(0, 3.75, 0, 3.25),
    height: ({ height }: UseStylesProps) => height || theme.spacing(4),
    width: ({ width }: UseStylesProps) => (width ? width - 65 : '100%'),
  },
}));

interface ISearchInputWithOptionsAutocompleteProps<OptionsType> extends UseStylesProps {
  placeholder?: string;
  loading?: boolean;
  isAutoDeselect?: boolean;
  disabled?: boolean;
  onFocus?: () => void;
  onChange?: (event, value: string | NonNullable<OptionsType>) => void;
  getOptionsByValue: (event) => void;
  renderOption: (option: OptionsType) => React.ReactNode;
  getOptionLabel: (option: OptionsType) => string;
  getOptionDisabled?: (option: OptionsType) => boolean;
  options: Array<OptionsType>;
  fullWidth?: boolean;
  classes?: Record<string, string>;
  PaperComponent?: React.ComponentType<React.HTMLAttributes<HTMLElement>>;
  ListboxComponent?: React.ComponentType<React.HTMLAttributes<HTMLElement>>;
  ListboxProps?: object;
  onBlur?: (event: React.BaseSyntheticEvent) => void;
  showPopperComponent?: boolean;
  className?: string;

  groupBy?: (option: OptionsType) => string;
  renderGroup?: (params: AutocompleteRenderGroupParams) => JSX.Element;
  helperText?: React.ReactNode;
  error?: boolean;
  multiple?: boolean;
}

const SearchInputWithOptions = <OptionType,>(
  props: ISearchInputWithOptionsAutocompleteProps<OptionType>,
): JSX.Element => {
  const {
    getOptionLabel,
    getOptionDisabled,
    options = [],
    renderOption,
    placeholder,
    fullWidth,
    getOptionsByValue,
    loading,
    disabled,
    onChange,
    onFocus,
    isAutoDeselect,
    groupBy,
    renderGroup,
    helperText,
    error,
    classes,
    PaperComponent,
    ListboxComponent,
    ListboxProps,
    showPopperComponent,
    className,
    onBlur,
    multiple = false,
  } = props;
  const searchInputClasses = useStyles(props);

  const [open, setOpen] = useState(false);
  const [valueState, setValueState] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const timeoutIdRef = useRef(null);
  const hasGetOptionsByValue = !!getOptionsByValue;

  useComponentDidUpdate(() => {
    if (!loading) {
      setIsLoading(false);
    }
  }, [setIsLoading, loading]);

  const loadOptions = useCallback(
    value => {
      if (getOptionsByValue) {
        return getOptionsByValue(value);
      }
      return () => {};
    },
    [getOptionsByValue],
  );

  const onChangeSearch = e => {
    const { value } = e.target;

    if (getOptionsByValue) {
      setIsLoading(true);
    }
    setValueState(value);

    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }

    timeoutIdRef.current = setTimeout(() => loadOptions(value.trim()), 500);
  };

  useEffect(() => {
    if (open && options.length === 0) {
      loadOptions('');

      if (hasGetOptionsByValue) {
        setIsLoading(true);
      }
    }
  }, [setIsLoading, open, hasGetOptionsByValue, loadOptions, options.length]);

  const valueProps = isAutoDeselect ? { value: null } : {};

  const handleBlur = (event: React.BaseSyntheticEvent) => {
    setOpen(false);

    if (onBlur) {
      onBlur(event);
    }
  };

  // renders

  const renderInput = (params: AutocompleteRenderInputParams): JSX.Element => {
    const inputParams = { ...params };

    if (inputParams.InputProps) {
      inputParams.InputProps.startAdornment = undefined;
    }

    return (
      <Box className={searchInputClasses.search}>
        <Box className={`${searchInputClasses.wrapIcon} ${searchInputClasses.wrapSearchIcon}`}>
          <SearchIcon className={searchInputClasses.searchIconIcon} />
        </Box>
        <TextField
          {...inputParams}
          variant="outlined"
          margin="none"
          fullWidth
          className={searchInputClasses.wrapInput}
          placeholder={placeholder}
          onChange={onChangeSearch}
          onBlur={handleBlur}
          error={error}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
            className: searchInputClasses.inputClassName,
            onKeyDown: event => {
              switch (event.key) {
                case 'Enter':
                  event.preventDefault();
                  event.stopPropagation();
                  break;
                default:
              }
            },
          }}
        />
        <Box
          className={`${searchInputClasses.wrapIcon} ${searchInputClasses.wrapClearIcon} ${
            valueState ? '' : searchInputClasses.hiddenClearIcon
          }`}
        >
          <CancelIcon
            className={searchInputClasses.searchIconIcon}
            onClick={() => onChangeSearch({ target: { value: '' } })}
          />
        </Box>
      </Box>
    );
  };

  return (
    <>
      <MuiAutocomplete<OptionType, boolean, true, true>
        {...valueProps}
        className={cx(searchInputClasses.wrapAutocomplete, className)}
        classes={classes}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        freeSolo
        debug
        fullWidth={fullWidth}
        options={options}
        inputValue={valueState}
        onChange={onChange}
        loading={isLoading}
        disabled={disabled}
        clearOnEscape
        getOptionLabel={getOptionLabel}
        getOptionDisabled={getOptionDisabled}
        disableClearable
        renderOption={renderOption}
        onFocus={onFocus}
        groupBy={groupBy}
        renderGroup={renderGroup}
        renderInput={renderInput}
        PopperComponent={options?.length || showPopperComponent ? undefined : () => null}
        PaperComponent={PaperComponent}
        ListboxComponent={ListboxComponent}
        ListboxProps={ListboxProps}
        multiple={multiple}
      />
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  );
};

export default SearchInputWithOptions;
