import React from 'react';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';

import { DialogComponent, FormFilesUploader, Select, TextArea } from 'common/components';
import CustomersAutocompleteField from './CustomersAutocompleteField';
import CurrentUserInfo from './CurrentUserInfo';
import { TicketTopic, topicTypeLabels } from 'common/components/PersonProfile/constants';
import inputLabels from 'common/messages/inputLabels';
import inputErrors from 'common/messages/inputErrors';
import messages from 'common/components/PersonProfile/messages';
import {
  selectCurrentUserClubs,
  selectUserSelectedClubId,
} from 'modules/authentication/state/selectors';
import commonMessages from 'common/messages/messages';
import { getRequiredMessage } from 'common/constants/globalConstants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import {
  ICustomerSimpleDto,
  IProfileInfoImt,
  ITicketCreateDto,
} from 'common/components/PersonProfile/interfaces';
import { PeakModules } from 'common/constants/peakModules';
import { colors } from 'common/ui/theme/default';

interface ITicketCreateModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onCreateTicket: (attachments: File[], dto: ITicketCreateDto) => void;
  onClose: () => void;
  profile?: IProfileInfoImt;
  module?: PeakModules;
}

type TicketCreateFormData = ITicketCreateDto<ICustomerSimpleDto> & { attachments: File[] };

const validationSchema = yup.object().shape({
  topic: yup.string().required(getRequiredMessage),
  subject: yup.string().max(255, () => inputErrors.subjectLengthError),
  clubId: yup
    .string()
    .nullable()
    .required(getRequiredMessage),
  memberIds: yup.array().min(1, getRequiredMessage),
  description: yup.string().required(getRequiredMessage),
});

const useStyles = makeStyles({
  filesUploader: {
    height: 97,
  },
  noteText: {
    color: colors.warning,
  },
});

const TicketCreateModal = (props: ITicketCreateModalProps): JSX.Element => {
  const { isOpen, onClose, profile, isLoading, onCreateTicket, module } = props;
  const clubs = useSelector(selectCurrentUserClubs);
  const currentClubId: string = useSelector(selectUserSelectedClubId);
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();
  const initialClub = clubs.find(clubItem => clubItem.get('id') === currentClubId)?.toJS() ?? null;

  const formMethods = useForm<TicketCreateFormData>({
    defaultValues: {
      topic: TicketTopic.MemberChange,
      subject: '',
      clubId: initialClub?.id,
      memberIds: profile
        ? [
            {
              id: profile.get('id'),
              imageUrl: profile.getIn(['image', 'filePath']),
              personalCode: null,
              firstName: profile.get('firstName'),
              lastName: profile.get('lastName'),
            },
          ]
        : [],
      description: '',
      attachments: [],
    },
    resolver: yupResolver(validationSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'onSubmit',
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const submitForm = (values: TicketCreateFormData) => {
    const { attachments, memberIds, ...data } = values;

    const ids = memberIds.map(item => item.id);

    const addTicketDto = { ...data, memberIds: ids };

    onCreateTicket(attachments, addTicketDto);
  };

  return (
    <DialogComponent
      title={<FormattedMessage {...messages.newTicketTitle} />}
      size="sm"
      isOpen={isOpen}
      submitBtnTitle={<FormattedMessage {...commonMessages.sendBtn} />}
      loading={isLoading}
      onClose={onClose}
      onSubmit={handleSubmit(submitForm)}
    >
      <form>
        <FormProvider {...formMethods}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CurrentUserInfo />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="topic"
                render={({ field: { onChange, value, onBlur, name } }) => (
                  <Select
                    label={<FormattedMessage {...inputLabels.topic} />}
                    value={value}
                    name={name}
                    fullWidth
                    onBlur={onBlur}
                    onChange={onChange}
                    error={!!errors.topic}
                    helperText={renderIntlMessage(errors.topic?.message)}
                    variant="outlined"
                  >
                    {topicTypeLabels.getSelectOptions()}
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="subject"
                control={control}
                render={({ field: { name, value, onBlur, onChange } }) => (
                  <TextField
                    fullWidth
                    name={name}
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={<FormattedMessage {...inputLabels.subjectOptional} />}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    error={!!errors.subject}
                    helperText={renderIntlMessage(errors?.subject?.message)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="clubId"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <Select
                    label={<FormattedMessage {...inputLabels.club} />}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth
                    error={!!errors.clubId}
                    helperText={renderIntlMessage(errors?.clubId?.message)}
                  >
                    {clubs?.map(clubItem => (
                      <MenuItem key={clubItem.get('id')} value={clubItem.get('id')}>
                        {clubItem.get('title')}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="memberIds"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CustomersAutocompleteField
                    module={module}
                    value={value}
                    onChange={(e, v) => {
                      onChange(v);
                    }}
                    personId={profile?.get('id')}
                    label={<FormattedMessage {...inputLabels.member} />}
                    fullWidth
                    error={renderIntlMessage((errors.memberIds as any)?.message)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextArea
                    value={value}
                    label={<FormattedMessage {...inputLabels.description} />}
                    variant="outlined"
                    autoComplete="none"
                    fullWidth
                    maxSymbols={1000}
                    rows={4}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.description}
                    helperText={renderIntlMessage(errors.description?.message)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormFilesUploader dropzoneClassName={classes.filesUploader} />
            </Grid>
            <Grid item xs={12}>
              <Typography component="span" className={classes.noteText}>
                <Typography display="inline" color="inherit" variant="h5">
                  <FormattedMessage {...inputLabels.note} />
                  :&nbsp;
                </Typography>
                <FormattedMessage {...messages.newTicketNote} />
              </Typography>
            </Grid>
          </Grid>
        </FormProvider>
      </form>
    </DialogComponent>
  );
};

export default TicketCreateModal;
