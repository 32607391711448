import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';

import { getQueueColor } from 'common/utils';
import { ReactComponent as ZoomMinusIcon } from 'img/icons/zoom-minus.svg';
import { ReactComponent as ZoomPlusIcon } from 'img/icons/zoom-plus.svg';
import { CustomTheme } from 'common/ui/interfaces';
import { TooltipTypography } from 'common/components/index';
import { IBarChartData } from 'common/interfaces/barChart';

interface IBarChartProps {
  data: IBarChartData[];
  className?: string;
  title: JSX.Element | string;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  barChartContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.borderColor?.main}`,
    height: 104,
    width: '100%',
    padding: theme.spacing(2),
  },
  barContainer: {
    height: 32,
    width: '100%',
    backgroundColor: theme.palette.borderColor?.main,
    overflowX: 'scroll',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  bar: {
    height: '100%',
    display: 'flex',
  },
  title: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
  zoomInBtn: {
    marginRight: `${theme.spacing(1.5)}px !important`,
  },
}));

const STEP = 150;
const WHEEL_STEP = 10;
const DEFAULT_SIZE = 100;

const BarChart = (props: IBarChartProps): JSX.Element => {
  const { data, className, title } = props;
  const isPressedCtrlBtn = useRef(false);
  const ref = useRef<HTMLDivElement>(null);
  const [barSize, setBarSize] = useState(DEFAULT_SIZE);
  const theme = useTheme();
  const classes = useStyles();
  const isDefaultBarSize = barSize === DEFAULT_SIZE;

  const [dragging, setDragging] = useState<boolean>(false);
  const startX = useRef<number>(0);
  const scrollLeft = useRef<number>(0);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Control') {
        isPressedCtrlBtn.current = true;
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Control') {
        isPressedCtrlBtn.current = false;
      }
    };

    const handleOnWheel = (event: WheelEvent) => {
      if (isPressedCtrlBtn.current) {
        event.preventDefault();
        if (event.deltaY < 0) {
          setBarSize(size => size + WHEEL_STEP);
        } else {
          setBarSize(size => {
            const currentSize = size - WHEEL_STEP;
            if (currentSize < DEFAULT_SIZE) {
              return DEFAULT_SIZE;
            }
            return currentSize;
          });
        }
      }
    };

    const el = ref.current;

    el?.addEventListener('wheel', handleOnWheel, { passive: false });
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      el?.removeEventListener('wheel', handleOnWheel);
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleZoomIn = () => {
    setBarSize(size => size + STEP);
  };

  const handleZoomOut = () => {
    if (barSize > DEFAULT_SIZE) {
      setBarSize(size => {
        const currentSize = size - STEP;
        if (currentSize < DEFAULT_SIZE) {
          return DEFAULT_SIZE;
        }
        return currentSize;
      });
    }
  };

  const startDragging = useCallback(event => {
    if (ref.current) {
      setDragging(true);
      startX.current = event.pageX - ref.current.offsetLeft;
      scrollLeft.current = ref.current.scrollLeft;
    }
  }, []);

  const stopDragging = useCallback(() => {
    setDragging(false);
  }, []);

  const move = useCallback(
    event => {
      event.preventDefault();

      if (dragging && ref.current) {
        const x = event.pageX - ref.current.offsetLeft;
        const scroll = x - startX.current;
        ref.current.scrollLeft = scrollLeft.current - scroll;
      }
    },
    [dragging],
  );

  useEffect(() => {
    const container = ref.current;

    if (container) {
      container.addEventListener('mousemove', move, false);
      container.addEventListener('mousedown', startDragging, false);
      container.addEventListener('mouseup', stopDragging, false);
      container.addEventListener('mouseleave', stopDragging, false);
    }

    return () => {
      if (container) {
        container.removeEventListener('mousemove', move, false);
        container.removeEventListener('mousedown', startDragging, false);
        container.removeEventListener('mouseup', stopDragging, false);
        container.removeEventListener('mouseleave', stopDragging, false);
      }
    };
  }, [startDragging, stopDragging, move]);

  return (
    <Box className={cn(classes.barChartContainer, className)}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <Box display="flex">
          <Button
            variant="text"
            color="secondary"
            type="button"
            className={`btn-text ${classes.zoomInBtn}`}
            onClick={handleZoomIn}
          >
            <ZoomPlusIcon />
          </Button>
          <Button
            variant="text"
            color="secondary"
            type="button"
            className="btn-text"
            onClick={handleZoomOut}
            disabled={isDefaultBarSize}
          >
            <ZoomMinusIcon />
          </Button>
        </Box>
      </Box>

      <Box
        {...{ ref }}
        className={cn(classes.barContainer, {
          'cursor-grab': !isDefaultBarSize && !dragging,
          'cursor-grabbing': !isDefaultBarSize && dragging,
        })}
      >
        <Box className={classes.bar} width={`${barSize}%`}>
          {data.map((item, index) => {
            const bgColor = getQueueColor(index);
            const { percentage, title: text } = item;
            const values = percentage.toString().split('.');
            const decimalValue = values[1];
            const condition = decimalValue?.length > 2;
            const percentageTitle = condition
              ? `${values[0]}.${decimalValue.slice(0, 2)}`
              : percentage;
            return (
              <Box
                height="100%"
                bgcolor={bgColor}
                width={`${percentage}%`}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <TooltipTypography
                  style={{
                    paddingLeft: percentage ? 2 : 0,
                    paddingRight: percentage ? 2 : 0,
                    color: theme.palette.getContrastText(bgColor),
                  }}
                  ellipsized
                  variant="h6"
                >
                  {`${text} ${percentageTitle}%`}
                </TooltipTypography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default BarChart;
