import React, { useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import isEqual from 'lodash/isEqual';
import { Box, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormContainer } from 'common/components';
import { EmailEditor } from 'modules/crm/components';
import EmailFormFields from './EmailFormFields/EmailFormFields';
import messages from 'modules/crm/messages/templates';
import { EmailTemplateSchema } from 'modules/crm/constants/shemas';
import {
  ICreateDocumentTemplateDto,
  IDocumentTemplateDtoImt,
} from 'common/interfaces/documentTemplate';
import { TemplatePurpose, TemplateTypes } from 'common/constants/documentTemplate';
import { IObject } from 'common/interfaces/common';
import { getArray } from 'common/utils/typeUtils';
import isFormDirty from 'common/hooks/isFormDirty';
import DiscardChangesModalProvider from 'common/modals/DiscardChangesModal/DiscardChangesModalProvider';

const useStyles = makeStyles((theme: Theme) => ({
  editor: {
    '& .gjs-editor': {
      '& .gjs-pn-panels': {
        marginRight: theme.spacing(3),
      },
      '& .gjs-cv-canvas': {
        flex: 2,
        height: 'auto',
      },
      '& .gjs-block': {
        flexBasis: '25%',
        maxHeight: '80px',
        minHeight: 'auto',
      },
      '& .gjs-comp-selected': {
        outline: '2px solid red !important',
      },
    },
  },
}));

interface IEmailFormValues {
  from: string;
  fromName: string;
  name: string;
  subject: string;
  content: string;
  status: boolean;
}

interface IProps {
  template: IDocumentTemplateDtoImt;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (doc: ICreateDocumentTemplateDto) => void;
}

const EmailForm = (props: IProps): JSX.Element => {
  const { isLoading, onCancel, onSubmit, template } = props;
  const [hasEditorChanges, setHasEditorChanges] = useState<boolean>(false);
  const editor = useRef(null);
  const initialEditorComponents = useRef<IObject[]>([]);
  const classes = useStyles();

  const formMethods = useForm<IEmailFormValues>({
    defaultValues: {
      name: template.get('title') || '',
      subject: template.get('emailSubject') || '',
      from: template.get('senderEmail') || '',
      fromName: template.get('emailSenderName') || '',
      status: Boolean(template.get('active')),
      content: template.get('content'),
    },
    resolver: yupResolver(EmailTemplateSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'onBlur',
  });

  const { handleSubmit, formState } = formMethods;
  const isDirty = isFormDirty(formState);

  const formSubmit = (values: IEmailFormValues) => {
    const style = editor.current.getCss();
    const content = `<div><style>${style}</style>${editor.current.getHtml()}</div>`;

    DOMPurify.addHook('uponSanitizeElement', node => {
      if ('className' in node && node.className === 'email-template-tag') {
        const text = document.createElement(undefined);

        if ('innerHTML' in node) {
          text.innerHTML = node.innerHTML;
        }

        node.parentNode.replaceChild(text, node);
      }
    });

    const sanitizedContent: string = DOMPurify.sanitize(content);
    DOMPurify.removeHook('uponSanitizeElement');

    onSubmit({
      title: values.name,
      content: sanitizedContent,
      active: values.status,
      emailSenderName: values.fromName,
      emailSubject: values.subject,
      senderEmail: values.from,
      purposeType: TemplatePurpose.CampaignTemplate,
      attachments: [],
      type: TemplateTypes.Email,
      components: JSON.stringify(editor.current.getComponents()),
      style: JSON.stringify(style),
    });
  };

  const onEditorInit = editorInstance => {
    if (template.size) {
      editorInstance.setComponents(
        JSON.parse(template.get('components')) || template.get('content'),
      );
      editorInstance.addStyle(JSON.parse(template.get('style')));
      initialEditorComponents.current = getEditorComponents(editorInstance);
    }
  };

  const isEditorDirty = (editorComponents: IObject[]): boolean => {
    const initialEditorComponentsLen = initialEditorComponents.current.length;

    if (initialEditorComponentsLen !== editorComponents.length) {
      return false;
    }

    let hasContentChanges = false;

    for (let i = 0; i < initialEditorComponentsLen; i += 1) {
      if (!isEqual(initialEditorComponents.current[i], editorComponents[i])) {
        hasContentChanges = true;
        break;
      }
    }

    return !hasContentChanges;
  };

  const getEditorComponents = (editorInstance): IObject[] => {
    return getArray<IObject>(JSON.parse(JSON.stringify(editorInstance.getComponents())));
  };

  const onEditorContentChanged = (): void => {
    if (editor.current) {
      const newEditorComponents = getEditorComponents(editor.current);

      if (isEditorDirty(newEditorComponents)) {
        setHasEditorChanges(false);
      } else {
        setHasEditorChanges(true);
      }
    }
  };

  return (
    <>
      <FormContainer
        title={
          <FormattedMessage
            {...(template?.size ? messages.editEmailTitle : messages.newEmailTitle)}
          />
        }
        onSubmit={handleSubmit(formSubmit)}
        onCancel={onCancel}
        isSubmitting={isLoading}
      >
        <form id="email-form">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={4}>
              <FormProvider {...formMethods}>
                <EmailFormFields />
              </FormProvider>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.editor}>
                <EmailEditor
                  id="gjs"
                  ref={editor}
                  width="100%"
                  onInit={onEditorInit}
                  onEditorContentChanged={onEditorContentChanged}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormContainer>

      {isDirty || hasEditorChanges ? <DiscardChangesModalProvider headerIds sidebarIds /> : null}
    </>
  );
};

export default EmailForm;
