import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IRoutes } from 'common/interfaces/routes';
// eslint-disable-next-line import/no-cycle
import { IPermissionsArray } from './interfaces/permissions';
import messages from './messages/messages';
import commonMessages from 'common/messages/messages';
import { PoliciesPermissionSetItems, PermissionLevels } from 'common/constants/permissions';

export const actionTypes = {
  // policies
  FETCH_POLICIES_LIST_LOADING: 'FETCH_POLICIES_LIST_LOADING',
  FETCH_POLICIES_DICTIONARY_LIST: 'FETCH_POLICIES_DICTIONARY_LIST',
  FETCH_POLICIES_LIST: 'FETCH_POLICIES_LIST',
  RESET_POLICIES_LIST: 'RESET_POLICIES_LIST',

  CREATE_POLICY_ITEM_LOADING: 'CREATE_POLICY_ITEM_LOADING',
  CREATE_POLICY_ITEM_SUCCESS: 'CREATE_POLICY_ITEM_SUCCESS',
  RESET_POLICY_ITEM_DATA: 'RESET_POLICY_ITEM_DATA',

  EDIT_POLICY_ITEM_LOADING: 'EDIT_POLICY_ITEM_LOADING',
  EDIT_POLICY_ITEM_SUCCESS: 'EDIT_POLICY_ITEM_SUCCESS',
  RESET_POLICY_ITEM_ACTION_RESULT: 'RESET_POLICY_ITEM_ACTION_RESULT',

  FETCH_POLICY_ITEM_LOADING: 'FETCH_POLICY_ITEM_LOADING',
  FETCH_POLICY_ITEM: 'FETCH_POLICY_ITEM',

  // roles
  FETCH_ROLES_LIST_LOADING: 'FETCH_ROLES_LIST_LOADING',
  FETCH_ROLES_LIST: 'FETCH_ROLES_LIST',
  RESET_ROLES_LIST: 'RESET_ROLES_LIST',

  CREATE_ROLE_ITEM_LOADING: 'CREATE_ROLE_ITEM_LOADING',
  CREATE_ROLE_ITEM_SUCCESS: 'CREATE_ROLE_ITEM_SUCCESS',
  RESET_ROLE_ITEM_DATA: 'RESET_ROLE_ITEM_DATA',

  EDIT_ROLE_ITEM_LOADING: 'EDIT_ROLE_ITEM_LOADING',
  EDIT_ROLE_ITEM_SUCCESS: 'EDIT_ROLE_ITEM_SUCCESS',
  RESET_ROLE_ITEM_ACTION_RESULT: 'RESET_ROLE_ITEM_ACTION_RESULT',

  FETCH_ROLE_ITEM_LOADING: 'FETCH_ROLE_ITEM_LOADING',
  FETCH_ROLE_ITEM: 'FETCH_ROLE_ITEM',
};

export enum PermissionsPaths {
  PERMISSIONS = '/',
  POLICIES = 'policies',
  ROLES = 'roles',
}

export const routes: IRoutes[] = [
  {
    type: 'link',
    path: `/permissions/${PermissionsPaths.ROLES}`,
    message: messages.roles,
  },
  {
    type: 'link',
    path: `/permissions/${PermissionsPaths.POLICIES}`,
    message: messages.policies,
  },
];

export enum PermissionGroupsClubLevel {
  FINANCIAL = 'FINANCIAL',
  POINT_OF_SALE_POS = 'POINT_OF_SALE_POS',
  COLLECTIONS = 'COLLECTIONS',
}

export enum PermissionGroupsCorporateOrClubLevel {
  // could be on corporate and clubs levels
  SCHEDULING = 'SCHEDULING',
  CRM = 'CRM',
  PT_CRM = 'PT_CRM',
  POS_CONTENT = 'POS_CONTENT',
  MEMBER_MANAGEMENT = 'MEMBER_MANAGEMENT',
  EMPLOYEE_MANAGEMENT = 'EMPLOYEE_MANAGEMENT',
  TIME_CLOCK = 'TIME_CLOCK',
  REPORTING = 'REPORTING',
  SALES_MANAGEMENT = 'SALES_MANAGEMENT',
}

export enum PermissionGroupsCorporateLevel {
  CORPORATE_LEVEL = 'CORPORATE_LEVEL',
  SERVICE_MANAGEMENT = 'SERVICE_MANAGEMENT',
  DICTIONARIES = 'DICTIONARIES',
  PERMISSIONS = 'PERMISSIONS',
  CLIENT_MANAGEMENT = 'CLIENT_MANAGEMENT',
  CORPORATE_MANAGEMENT = 'CORPORATE_MANAGEMENT',
  COMMUNICATION = 'COMMUNICATION',
}

export enum PermissionGroupsPeakLevel {
  PEAK_LEVEL = 'PEAK_LEVEL',
}

export const permissionLevelsArray = {
  [PermissionLevels.PEAK]: PermissionGroupsPeakLevel,
  [PermissionLevels.CLIENT]: {
    ...PermissionGroupsClubLevel,
    ...PermissionGroupsCorporateOrClubLevel,
  },
  [PermissionLevels.CORPORATE]: {
    ...PermissionGroupsCorporateLevel,
    ...PermissionGroupsCorporateOrClubLevel,
  },
};

export const PermissionsGroupsLabels = {
  // club level
  [PermissionGroupsClubLevel.FINANCIAL]: <FormattedMessage {...messages.financial} />,
  [PermissionGroupsClubLevel.POINT_OF_SALE_POS]: <FormattedMessage {...messages.pos} />,
  [PermissionGroupsClubLevel.COLLECTIONS]: <FormattedMessage {...messages.collections} />,
  // corporate or club level
  [PermissionGroupsCorporateOrClubLevel.SCHEDULING]: <FormattedMessage {...messages.scheduling} />,
  [PermissionGroupsCorporateOrClubLevel.CRM]: <FormattedMessage {...messages.crm} />,
  [PermissionGroupsCorporateOrClubLevel.PT_CRM]: <FormattedMessage {...commonMessages.ptcrm} />,
  [PermissionGroupsCorporateOrClubLevel.POS_CONTENT]: (
    <FormattedMessage {...commonMessages.posContent} />
  ),
  [PermissionGroupsCorporateOrClubLevel.MEMBER_MANAGEMENT]: (
    <FormattedMessage {...messages.memberManagement} />
  ),
  [PermissionGroupsCorporateOrClubLevel.EMPLOYEE_MANAGEMENT]: (
    <FormattedMessage {...messages.employeeManagement} />
  ),
  [PermissionGroupsCorporateOrClubLevel.TIME_CLOCK]: (
    <FormattedMessage {...commonMessages.timeclock} />
  ),
  [PermissionGroupsCorporateOrClubLevel.REPORTING]: <FormattedMessage {...messages.reporting} />,
  [PermissionGroupsCorporateOrClubLevel.SALES_MANAGEMENT]: (
    <FormattedMessage {...messages.salesManagement} />
  ),
  // corporate level
  [PermissionGroupsCorporateLevel.CORPORATE_LEVEL]: (
    <FormattedMessage {...commonMessages.corporateTitle} />
  ),
  [PermissionGroupsCorporateLevel.SERVICE_MANAGEMENT]: (
    <FormattedMessage {...messages.corporateServiceManagement} />
  ),
  [PermissionGroupsCorporateLevel.DICTIONARIES]: (
    <FormattedMessage {...commonMessages.dictionaries} />
  ),
  [PermissionGroupsCorporateLevel.PERMISSIONS]: (
    <FormattedMessage {...commonMessages.permissions} />
  ),
  [PermissionGroupsCorporateLevel.CLIENT_MANAGEMENT]: (
    <FormattedMessage {...messages.corporateClientManagement} />
  ),
  [PermissionGroupsCorporateLevel.CORPORATE_MANAGEMENT]: (
    <FormattedMessage {...messages.corporateManagement} />
  ),
  [PermissionGroupsCorporateLevel.COMMUNICATION]: <FormattedMessage {...messages.communication} />,
  // pe level
  [PermissionGroupsPeakLevel.PEAK_LEVEL]: <FormattedMessage {...commonMessages.peakTitle} />,
};

export const PermissionsArrayPeakLevel: IPermissionsArray<PermissionGroupsPeakLevel> = {
  [PermissionGroupsPeakLevel.PEAK_LEVEL]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsPeakLevel.PEAK_LEVEL],
      key: PermissionGroupsPeakLevel.PEAK_LEVEL,
      children: [
        {
          title: <FormattedMessage {...messages.peakSuperUser} />,
          key: PoliciesPermissionSetItems.PEAK_SU,
        },
        {
          title: <FormattedMessage {...messages.peakHelpInformationView} />,
          key: PoliciesPermissionSetItems.PEAK_HELP_INFORMATION_VIEW,
        },
        {
          title: <FormattedMessage {...messages.peakHelpInformationCreate} />,
          key: PoliciesPermissionSetItems.PEAK_HELP_INFORMATION_CREATE,
        },
        {
          title: <FormattedMessage {...messages.peakHelpInformationEdit} />,
          key: PoliciesPermissionSetItems.PEAK_HELP_INFORMATION_EDIT,
        },
        {
          title: <FormattedMessage {...messages.peakHelpInformationChangeStatus} />,
          key: PoliciesPermissionSetItems.PEAK_HELP_INFORMATION_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.peakEmployeeView} />,
          key: PoliciesPermissionSetItems.PEAK_EMPLOYEE_VIEW,
        },
        {
          title: <FormattedMessage {...messages.peakEmployeeCreate} />,
          key: PoliciesPermissionSetItems.PEAK_EMPLOYEE_CREATE,
        },
        {
          title: <FormattedMessage {...messages.peakEmployeeEdit} />,
          key: PoliciesPermissionSetItems.PEAK_EMPLOYEE_EDIT,
        },
        {
          title: <FormattedMessage {...messages.peakEmployeeChangeStatus} />,
          key: PoliciesPermissionSetItems.PEAK_EMPLOYEE_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.peakOrganizationCreate} />,
          key: PoliciesPermissionSetItems.PEAK_ORGANIZATION_CREATE,
        },
        {
          title: <FormattedMessage {...messages.peakOrganizationView} />,
          key: PoliciesPermissionSetItems.PEAK_ORGANIZATION_VIEW,
        },
        {
          title: <FormattedMessage {...messages.peakOrganizationEdit} />,
          key: PoliciesPermissionSetItems.PEAK_ORGANIZATION_EDIT,
        },
        {
          title: <FormattedMessage {...messages.peakOrganizationChangeStatus} />,
          key: PoliciesPermissionSetItems.PEAK_ORGANIZATION_CHANGE_STATUS,
        },

        {
          title: <FormattedMessage {...messages.peakIntegrationView} />,
          key: PoliciesPermissionSetItems.PEAK_INTEGRATION_VIEW,
        },
        {
          title: <FormattedMessage {...messages.peakIntegrationEdit} />,
          key: PoliciesPermissionSetItems.PEAK_INTEGRATION_EDIT,
        },
        {
          title: <FormattedMessage {...messages.peakIntegrationChangeStatus} />,
          key: PoliciesPermissionSetItems.PEAK_INTEGRATION_CHANGE_STATUS,
        },

        {
          title: <FormattedMessage {...messages.peakSecurityRoleView} />,
          key: PoliciesPermissionSetItems.PEAK_SECURITY_ROLE_VIEW,
        },
        {
          title: <FormattedMessage {...messages.peakSecurityRoleCreate} />,
          key: PoliciesPermissionSetItems.PEAK_SECURITY_ROLE_CREATE,
        },
        {
          title: <FormattedMessage {...messages.peakSecurityRoleEdit} />,
          key: PoliciesPermissionSetItems.PEAK_SECURITY_ROLE_EDIT,
        },
        {
          title: <FormattedMessage {...messages.peakSecurityRoleChangeStatus} />,
          key: PoliciesPermissionSetItems.PEAK_SECURITY_ROLE_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.peakSecurityPolicyView} />,
          key: PoliciesPermissionSetItems.PEAK_SECURITY_POLICY_VIEW,
        },
        {
          title: <FormattedMessage {...messages.peakSecurityPolicyCreate} />,
          key: PoliciesPermissionSetItems.PEAK_SECURITY_POLICY_CREATE,
        },
        {
          title: <FormattedMessage {...messages.peakSecurityPolicyEdit} />,
          key: PoliciesPermissionSetItems.PEAK_SECURITY_POLICY_EDIT,
        },
        {
          title: <FormattedMessage {...messages.peakSecurityPolicyChangeStatus} />,
          key: PoliciesPermissionSetItems.PEAK_SECURITY_POLICY_CHANGE_STATUS,
        },
      ],
    },
  ],
};

export const PermissionsArrayCorporateOrClubLevel: IPermissionsArray<PermissionGroupsCorporateOrClubLevel> = {
  [PermissionGroupsCorporateOrClubLevel.SCHEDULING]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsCorporateOrClubLevel.SCHEDULING],
      key: PermissionGroupsCorporateOrClubLevel.SCHEDULING,
      children: [
        {
          title: <FormattedMessage {...messages.bookingEventsView} />,
          key: PoliciesPermissionSetItems.BOOKING_EVENTS_VIEW,
        },
        {
          title: <FormattedMessage {...messages.bookingEventsViewOne} />,
          key: PoliciesPermissionSetItems.BOOKING_EVENTS_VIEW_ONE,
        },
        {
          title: <FormattedMessage {...messages.bookingEventsCreate} />,
          key: PoliciesPermissionSetItems.BOOKING_EVENTS_CREATE,
        },
        {
          title: <FormattedMessage {...messages.bookingEventsEdit} />,
          key: PoliciesPermissionSetItems.BOOKING_EVENTS_EDIT,
        },
        {
          title: <FormattedMessage {...messages.bookingEventsCancel} />,
          key: PoliciesPermissionSetItems.BOOKING_EVENTS_CANCEL,
        },
        {
          title: <FormattedMessage {...messages.bookingEventsDelete} />,
          key: PoliciesPermissionSetItems.BOOKING_EVENTS_DELETE,
        },
        {
          title: <FormattedMessage {...messages.bookingEventsDuplicate} />,
          key: PoliciesPermissionSetItems.BOOKING_EVENTS_DUPLICATE,
        },
        {
          title: <FormattedMessage {...messages.bookingEventsRemindParticipants} />,
          key: PoliciesPermissionSetItems.BOOKING_EVENTS_REMIND_PARTICIPANTS,
        },
        {
          title: <FormattedMessage {...messages.bookingEventsNotSelfEdit} />,
          key: PoliciesPermissionSetItems.BOOKING_EVENTS_NOT_SELF_EDIT,
        },
        {
          title: <FormattedMessage {...messages.bookingResourcesView} />,
          key: PoliciesPermissionSetItems.BOOKING_RESOURCES_VIEW,
        },
        {
          title: <FormattedMessage {...messages.bookingResourcesCreate} />,
          key: PoliciesPermissionSetItems.BOOKING_RESOURCES_CREATE,
        },
        {
          title: <FormattedMessage {...messages.bookingResourcesEdit} />,
          key: PoliciesPermissionSetItems.BOOKING_RESOURCES_EDIT,
        },
        {
          title: <FormattedMessage {...messages.bookingResourcesChangeStatus} />,
          key: PoliciesPermissionSetItems.BOOKING_RESOURCES_CHANGE_STATUS,
        },
      ],
    },
  ],
  [PermissionGroupsCorporateOrClubLevel.CRM]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsCorporateOrClubLevel.CRM],
      key: PermissionGroupsCorporateOrClubLevel.CRM,
      children: [
        {
          title: <FormattedMessage {...messages.crmTaskView} />,
          key: PoliciesPermissionSetItems.CRM_TASK_VIEW,
        },
        {
          title: <FormattedMessage {...messages.crmTaskEditStatus} />,
          key: PoliciesPermissionSetItems.CRM_TASK_EDIT_STATUS,
        },
        {
          title: <FormattedMessage {...messages.crmTaskEditSalesperson} />,
          key: PoliciesPermissionSetItems.CRM_TASK_EDIT_SALESPERSON,
        },
        {
          title: <FormattedMessage {...messages.crmLeadView} />,
          key: PoliciesPermissionSetItems.CRM_LEAD_VIEW,
        },
        {
          title: <FormattedMessage {...messages.crmLeadCreate} />,
          key: PoliciesPermissionSetItems.CRM_LEAD_CREATE,
        },
        {
          title: <FormattedMessage {...messages.crmLeadEdit} />,
          key: PoliciesPermissionSetItems.CRM_LEAD_EDIT,
        },
        {
          title: <FormattedMessage {...messages.crmLeadEditStatus} />,
          key: PoliciesPermissionSetItems.CRM_LEAD_EDIT_STATUS,
        },
        {
          title: <FormattedMessage {...messages.crmLeadEditSalesperson} />,
          key: PoliciesPermissionSetItems.CRM_LEAD_EDIT_SALESPERSON,
        },
        {
          title: <FormattedMessage {...messages.crmScriptView} />,
          key: PoliciesPermissionSetItems.CRM_SCRIPT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.crmScriptCreate} />,
          key: PoliciesPermissionSetItems.CRM_SCRIPT_CREATE,
        },
        {
          title: <FormattedMessage {...messages.crmScriptEdit} />,
          key: PoliciesPermissionSetItems.CRM_SCRIPT_EDIT,
        },
        {
          title: <FormattedMessage {...messages.crmScriptChangeStatus} />,
          key: PoliciesPermissionSetItems.CRM_SCRIPT_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.crmCampaignView} />,
          key: PoliciesPermissionSetItems.CRM_CAMPAIGN_VIEW,
        },
        {
          title: <FormattedMessage {...messages.crmCampaignCreate} />,
          key: PoliciesPermissionSetItems.CRM_CAMPAIGN_CREATE,
        },
        {
          title: <FormattedMessage {...messages.crmCampaignEdit} />,
          key: PoliciesPermissionSetItems.CRM_CAMPAIGN_EDIT,
        },
      ],
    },
  ],
  [PermissionGroupsCorporateOrClubLevel.PT_CRM]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsCorporateOrClubLevel.PT_CRM],
      key: PermissionGroupsCorporateOrClubLevel.PT_CRM,
      children: [
        {
          title: <FormattedMessage {...messages.ptCrmTaskView} />,
          key: PoliciesPermissionSetItems.PT_CRM_TASK_VIEW,
        },
        {
          title: <FormattedMessage {...messages.ptCrmTaskEditStatus} />,
          key: PoliciesPermissionSetItems.PT_CRM_TASK_EDIT_STATUS,
        },
        {
          title: <FormattedMessage {...messages.ptCrmTaskEditSalesperson} />,
          key: PoliciesPermissionSetItems.PT_CRM_TASK_EDIT_SALESPERSON,
        },
        {
          title: <FormattedMessage {...messages.ptCrmLeadView} />,
          key: PoliciesPermissionSetItems.PT_CRM_LEAD_VIEW,
        },
        {
          title: <FormattedMessage {...messages.ptCrmLeadCreate} />,
          key: PoliciesPermissionSetItems.PT_CRM_LEAD_CREATE,
        },
        {
          title: <FormattedMessage {...messages.ptCrmLeadEdit} />,
          key: PoliciesPermissionSetItems.PT_CRM_LEAD_EDIT,
        },
        {
          title: <FormattedMessage {...messages.ptCrmLeadEditStatus} />,
          key: PoliciesPermissionSetItems.PT_CRM_LEAD_EDIT_STATUS,
        },
        {
          title: <FormattedMessage {...messages.ptCrmLeadEditSalesperson} />,
          key: PoliciesPermissionSetItems.PT_CRM_LEAD_EDIT_SALESPERSON,
        },
        {
          title: <FormattedMessage {...messages.ptCrmScriptView} />,
          key: PoliciesPermissionSetItems.PT_CRM_SCRIPT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.ptCrmScriptCreate} />,
          key: PoliciesPermissionSetItems.PT_CRM_SCRIPT_CREATE,
        },
        {
          title: <FormattedMessage {...messages.ptCrmScriptEdit} />,
          key: PoliciesPermissionSetItems.PT_CRM_SCRIPT_EDIT,
        },
        {
          title: <FormattedMessage {...messages.ptCrmScriptChangeStatus} />,
          key: PoliciesPermissionSetItems.PT_CRM_SCRIPT_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.ptCrmCampaignView} />,
          key: PoliciesPermissionSetItems.PT_CRM_CAMPAIGN_VIEW,
        },
        {
          title: <FormattedMessage {...messages.ptCrmCampaignCreate} />,
          key: PoliciesPermissionSetItems.PT_CRM_CAMPAIGN_CREATE,
        },
        {
          title: <FormattedMessage {...messages.ptCrmCampaignEdit} />,
          key: PoliciesPermissionSetItems.PT_CRM_CAMPAIGN_EDIT,
        },
      ],
    },
  ],
  [PermissionGroupsCorporateOrClubLevel.POS_CONTENT]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsCorporateOrClubLevel.POS_CONTENT],
      key: PermissionGroupsCorporateOrClubLevel.POS_CONTENT,
      children: [
        {
          title: <FormattedMessage {...messages.corporateReceiptTemplatesView} />,
          key: PoliciesPermissionSetItems.RECEIPT_TEMPLATES_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateReceiptTemplatesCreate} />,
          key: PoliciesPermissionSetItems.RECEIPT_TEMPLATES_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateReceiptTemplatesEdit} />,
          key: PoliciesPermissionSetItems.RECEIPT_TEMPLATES_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateReceiptTemplatesChangeStatus} />,
          key: PoliciesPermissionSetItems.RECEIPT_TEMPLATES_CHANGE_STATUS,
        },

        {
          title: <FormattedMessage {...messages.corporateTaxRateView} />,
          key: PoliciesPermissionSetItems.TAX_RATE_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateTaxRateCreate} />,
          key: PoliciesPermissionSetItems.TAX_RATE_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateTaxRateEdit} />,
          key: PoliciesPermissionSetItems.TAX_RATE_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateTaxRateChangeStatus} />,
          key: PoliciesPermissionSetItems.TAX_RATE_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateRevenueCodeView} />,
          key: PoliciesPermissionSetItems.REVENUE_CODE_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateRevenueCodeCreate} />,
          key: PoliciesPermissionSetItems.REVENUE_CODE_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateRevenueCodeEdit} />,
          key: PoliciesPermissionSetItems.REVENUE_CODE_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateRevenueCodeChangeStatus} />,
          key: PoliciesPermissionSetItems.REVENUE_CODE_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateVendorView} />,
          key: PoliciesPermissionSetItems.VENDOR_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateVendorCreate} />,
          key: PoliciesPermissionSetItems.VENDOR_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateVendorEdit} />,
          key: PoliciesPermissionSetItems.VENDOR_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateVendorChangeStatus} />,
          key: PoliciesPermissionSetItems.VENDOR_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateInventoryView} />,
          key: PoliciesPermissionSetItems.INVENTORY_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateInventoryCreate} />,
          key: PoliciesPermissionSetItems.INVENTORY_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateInventoryEdit} />,
          key: PoliciesPermissionSetItems.INVENTORY_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateInventoryChangeStatus} />,
          key: PoliciesPermissionSetItems.INVENTORY_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateRegisterView} />,
          key: PoliciesPermissionSetItems.REGISTER_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateRegisterViewOne} />,
          key: PoliciesPermissionSetItems.REGISTER_VIEW_ONE,
        },
        {
          title: <FormattedMessage {...messages.corporateRegisterCreate} />,
          key: PoliciesPermissionSetItems.REGISTER_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateRegisterEdit} />,
          key: PoliciesPermissionSetItems.REGISTER_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateRegisterChangeStatus} />,
          key: PoliciesPermissionSetItems.REGISTER_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateDefaultPaymentMethodView} />,
          key: PoliciesPermissionSetItems.DEFAULT_PAYMENT_METHOD_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateDefaultPaymentMethodEdit} />,
          key: PoliciesPermissionSetItems.DEFAULT_PAYMENT_METHOD_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateDefaultPaymentMethodChangeStatus} />,
          key: PoliciesPermissionSetItems.DEFAULT_PAYMENT_METHOD_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateCustomPaymentMethodView} />,
          key: PoliciesPermissionSetItems.CUSTOM_PAYMENT_METHOD_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateCustomPaymentMethodCreate} />,
          key: PoliciesPermissionSetItems.CUSTOM_PAYMENT_METHOD_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateCustomPaymentMethodEdit} />,
          key: PoliciesPermissionSetItems.CUSTOM_PAYMENT_METHOD_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateCustomPaymentMethodChangeStatus} />,
          key: PoliciesPermissionSetItems.CUSTOM_PAYMENT_METHOD_CHANGE_STATUS,
        },
      ],
    },
  ],
  [PermissionGroupsCorporateOrClubLevel.MEMBER_MANAGEMENT]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsCorporateOrClubLevel.MEMBER_MANAGEMENT],
      key: PermissionGroupsCorporateOrClubLevel.MEMBER_MANAGEMENT,
      children: [
        {
          title: <FormattedMessage {...messages.memberView} />,
          key: PoliciesPermissionSetItems.MEMBER_VIEW,
        },
        {
          title: <FormattedMessage {...messages.memberCreate} />,
          key: PoliciesPermissionSetItems.MEMBER_CREATE,
        },
        {
          title: <FormattedMessage {...messages.memberEdit} />,
          key: PoliciesPermissionSetItems.MEMBER_EDIT,
        },
      ],
    },
  ],
  [PermissionGroupsCorporateOrClubLevel.EMPLOYEE_MANAGEMENT]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsCorporateOrClubLevel.EMPLOYEE_MANAGEMENT],
      key: PermissionGroupsCorporateOrClubLevel.EMPLOYEE_MANAGEMENT,
      children: [
        {
          title: <FormattedMessage {...messages.employeeView} />,
          key: PoliciesPermissionSetItems.EMPLOYEE_VIEW,
        },
        {
          title: <FormattedMessage {...messages.employeeCreate} />,
          key: PoliciesPermissionSetItems.EMPLOYEE_CREATE,
        },
        {
          title: <FormattedMessage {...messages.employeeEdit} />,
          key: PoliciesPermissionSetItems.EMPLOYEE_EDIT,
        },
        {
          title: <FormattedMessage {...messages.employeeChangeStatus} />,
          key: PoliciesPermissionSetItems.EMPLOYEE_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.employeeUpdatePermissions} />,
          key: PoliciesPermissionSetItems.EMPLOYEE_UPDATE_PERMISSIONS,
        },
        {
          title: <FormattedMessage {...messages.employeeTakePhoto} />,
          key: PoliciesPermissionSetItems.EMPLOYEE_TAKE_PHOTO,
        },
        {
          title: <FormattedMessage {...messages.employeeViewDocuments} />,
          key: PoliciesPermissionSetItems.EMPLOYEE_VIEW_DOCUMENTS,
        },
        {
          title: <FormattedMessage {...messages.employeeUploadDocuments} />,
          key: PoliciesPermissionSetItems.EMPLOYEE_UPLOAD_DOCUMENTS,
        },
      ],
    },
  ],
  [PermissionGroupsCorporateOrClubLevel.TIME_CLOCK]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsCorporateOrClubLevel.TIME_CLOCK],
      key: PermissionGroupsCorporateOrClubLevel.TIME_CLOCK,
      children: [
        {
          title: <FormattedMessage {...messages.timeClockView} />,
          key: PoliciesPermissionSetItems.TIME_CLOCK_VIEW,
        },
        {
          title: <FormattedMessage {...messages.timeClockDelete} />,
          key: PoliciesPermissionSetItems.TIME_CLOCK_DELETE,
        },
        {
          title: <FormattedMessage {...messages.timeClockIn} />,
          key: PoliciesPermissionSetItems.TIME_CLOCK_IN,
        },
        {
          title: <FormattedMessage {...messages.timeClockOut} />,
          key: PoliciesPermissionSetItems.TIME_CLOCK_OUT,
        },
        {
          title: <FormattedMessage {...messages.timeClockPayrollReportView} />,
          key: PoliciesPermissionSetItems.TIME_CLOCK_PAYROLL_REPORT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.timeClockPayrollReportExport} />,
          key: PoliciesPermissionSetItems.TIME_CLOCK_PAYROLL_REPORT_EXPORT,
        },
      ],
    },
  ],
  [PermissionGroupsCorporateOrClubLevel.REPORTING]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsCorporateOrClubLevel.REPORTING],
      key: PermissionGroupsCorporateOrClubLevel.REPORTING,
      children: [
        {
          title: <FormattedMessage {...messages.reportsView} />,
          key: PoliciesPermissionSetItems.REPORTS_VIEW,
        },
        {
          title: <FormattedMessage {...messages.reportsExport} />,
          key: PoliciesPermissionSetItems.REPORTS_EXPORT,
        },
        {
          title: <FormattedMessage {...messages.overviewReportView} />,
          key: PoliciesPermissionSetItems.OVERVIEW_REPORT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.overviewReportExport} />,
          key: PoliciesPermissionSetItems.OVERVIEW_REPORT_EXPORT,
        },
        {
          title: <FormattedMessage {...messages.salesReportView} />,
          key: PoliciesPermissionSetItems.SALES_REPORT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.salesReportExport} />,
          key: PoliciesPermissionSetItems.SALES_REPORT_EXPORT,
        },
        {
          title: <FormattedMessage {...messages.invoiceReportView} />,
          key: PoliciesPermissionSetItems.INVOICE_REPORT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.invoiceReportDetailedView} />,
          key: PoliciesPermissionSetItems.INVOICE_REPORT_DETAILED_VIEW,
        },
        {
          title: <FormattedMessage {...messages.invoiceReportExport} />,
          key: PoliciesPermissionSetItems.INVOICE_REPORT_EXPORT,
        },
        {
          title: <FormattedMessage {...messages.invoiceReportViewSnapshots} />,
          key: PoliciesPermissionSetItems.INVOICE_REPORT_VIEW_SNAPSHOTS,
        },
        {
          title: <FormattedMessage {...messages.invoiceReportMakeSnapshots} />,
          key: PoliciesPermissionSetItems.INVOICE_REPORT_MAKE_SNAPSHOT,
        },
        {
          title: <FormattedMessage {...messages.invoiceReportAssignMember} />,
          key: PoliciesPermissionSetItems.INVOICE_REPORT_ASSIGN_MEMBER,
        },
        {
          title: <FormattedMessage {...messages.invoiceReportVoid} />,
          key: PoliciesPermissionSetItems.INVOICE_REPORT_VOID,
        },
        {
          title: <FormattedMessage {...messages.zOutReportView} />,
          key: PoliciesPermissionSetItems.Z_REPORT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.zOutReportDetailedView} />,
          key: PoliciesPermissionSetItems.Z_REPORT_DETAILED_VIEW,
        },
        {
          title: <FormattedMessage {...messages.zOutReportExport} />,
          key: PoliciesPermissionSetItems.Z_REPORT_EXPORT,
        },
        {
          title: <FormattedMessage {...messages.overTimeReportView} />,
          key: PoliciesPermissionSetItems.OVER_TIME_REPORT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.overTimeReportExport} />,
          key: PoliciesPermissionSetItems.OVER_TIME_REPORT_EXPORT,
        },
        {
          title: <FormattedMessage {...messages.registerStatusReportView} />,
          key: PoliciesPermissionSetItems.REGISTER_STATUS_REPORT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.registerStatusReportExport} />,
          key: PoliciesPermissionSetItems.REGISTER_STATUS_REPORT_EXPORT,
        },
        {
          title: <FormattedMessage {...messages.registerStatusReportClose} />,
          key: PoliciesPermissionSetItems.REGISTER_STATUS_REPORT_CLOSE,
        },
        {
          title: <FormattedMessage {...messages.transactionReportView} />,
          key: PoliciesPermissionSetItems.TRANSACTION_REPORT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.transactionReportExport} />,
          key: PoliciesPermissionSetItems.TRANSACTION_REPORT_EXPORT,
        },
        {
          title: <FormattedMessage {...messages.redemptionReportView} />,
          key: PoliciesPermissionSetItems.REDEMPTION_REPORT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.redemptionReportExport} />,
          key: PoliciesPermissionSetItems.REDEMPTION_REPORT_EXPORT,
        },
        {
          title: <FormattedMessage {...messages.redemptionReportUnredeem} />,
          key: PoliciesPermissionSetItems.REDEMPTION_REPORT_UNREDEEM,
        },
        {
          title: <FormattedMessage {...messages.dashboardReportView} />,
          key: PoliciesPermissionSetItems.DASHBOARD_REPORT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.dashboardReportExport} />,
          key: PoliciesPermissionSetItems.DASHBOARD_REPORT_EXPORT,
        },
        {
          title: <FormattedMessage {...messages.campaignReportView} />,
          key: PoliciesPermissionSetItems.CAMPAIGN_REPORT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.campaignReportDetailedView} />,
          key: PoliciesPermissionSetItems.CAMPAIGN_REPORT_DETAILED_VIEW,
        },
        {
          title: <FormattedMessage {...messages.campaignReportExport} />,
          key: PoliciesPermissionSetItems.CAMPAIGN_REPORT_EXPORT,
        },
        {
          title: <FormattedMessage {...messages.appointmentReportView} />,
          key: PoliciesPermissionSetItems.APPOINTMENT_REPORT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.appointmentReportDetailedView} />,
          key: PoliciesPermissionSetItems.APPOINTMENT_REPORT_DETAILED_VIEW,
        },
        {
          title: <FormattedMessage {...messages.appointmentReportExport} />,
          key: PoliciesPermissionSetItems.APPOINTMENT_REPORT_EXPORT,
        },
      ],
    },
  ],
  [PermissionGroupsCorporateOrClubLevel.SALES_MANAGEMENT]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsCorporateOrClubLevel.SALES_MANAGEMENT],
      key: PermissionGroupsCorporateOrClubLevel.SALES_MANAGEMENT,
      children: [
        {
          title: <FormattedMessage {...messages.viewLead} />,
          key: PoliciesPermissionSetItems.SALES_VIEW_LEADS,
        },
        {
          title: <FormattedMessage {...messages.editLead} />,
          key: PoliciesPermissionSetItems.SALES_EDIT_LEADS,
        },
        {
          title: <FormattedMessage {...messages.commissionRuleView} />,
          key: PoliciesPermissionSetItems.COMMISSION_RULE_VIEW,
        },
        {
          title: <FormattedMessage {...messages.commissionRuleCreate} />,
          key: PoliciesPermissionSetItems.COMMISSION_RULE_CREATE,
        },
        {
          title: <FormattedMessage {...messages.commissionRuleEdit} />,
          key: PoliciesPermissionSetItems.COMMISSION_RULE_EDIT,
        },
        {
          title: <FormattedMessage {...messages.commissionRuleDelete} />,
          key: PoliciesPermissionSetItems.COMMISSION_RULE_DELETE,
        },
        {
          title: <FormattedMessage {...messages.daysheetView} />,
          key: PoliciesPermissionSetItems.DAYSHEET_VIEW,
        },
      ],
    },
  ],
};

export const PermissionsArrayCorporateLevel: IPermissionsArray<PermissionGroupsCorporateLevel &
  PermissionGroupsCorporateOrClubLevel> = {
  [PermissionGroupsCorporateLevel.CORPORATE_LEVEL]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsCorporateLevel.CORPORATE_LEVEL],
      key: PermissionGroupsCorporateLevel.CORPORATE_LEVEL,
      children: [
        {
          title: <FormattedMessage {...messages.corporateSuperUser} />,
          key: PoliciesPermissionSetItems.CORPORATE_SU,
        },
      ],
    },
  ],
  [PermissionGroupsCorporateLevel.SERVICE_MANAGEMENT]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsCorporateLevel.SERVICE_MANAGEMENT],
      key: PermissionGroupsCorporateLevel.SERVICE_MANAGEMENT,
      children: [
        {
          title: <FormattedMessage {...messages.corporateServiceView} />,
          key: PoliciesPermissionSetItems.SERVICE_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateServiceCreate} />,
          key: PoliciesPermissionSetItems.SERVICE_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateServiceEdit} />,
          key: PoliciesPermissionSetItems.SERVICE_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateServiceChangeStatus} />,
          key: PoliciesPermissionSetItems.SERVICE_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateFeeView} />,
          key: PoliciesPermissionSetItems.FEE_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateFeeCreate} />,
          key: PoliciesPermissionSetItems.FEE_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateFeeEdit} />,
          key: PoliciesPermissionSetItems.FEE_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateFeeChangeStatus} />,
          key: PoliciesPermissionSetItems.FEE_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateServiceBundleView} />,
          key: PoliciesPermissionSetItems.SERVICE_BUNDLE_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateServiceBundleCreate} />,
          key: PoliciesPermissionSetItems.SERVICE_BUNDLE_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateServiceBundleEdit} />,
          key: PoliciesPermissionSetItems.SERVICE_BUNDLE_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateServiceBundleChangeStatus} />,
          key: PoliciesPermissionSetItems.SERVICE_BUNDLE_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateServiceBundleDuplicate} />,
          key: PoliciesPermissionSetItems.SERVICE_BUNDLE_DUPLICATE,
        },
        {
          title: <FormattedMessage {...messages.corporateContractView} />,
          key: PoliciesPermissionSetItems.CONTRACT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateContractCreate} />,
          key: PoliciesPermissionSetItems.CONTRACT_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateContractEdit} />,
          key: PoliciesPermissionSetItems.CONTRACT_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateContractChangeStatus} />,
          key: PoliciesPermissionSetItems.CONTRACT_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateWaiverView} />,
          key: PoliciesPermissionSetItems.WAIVER_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateWaiverCreate} />,
          key: PoliciesPermissionSetItems.WAIVER_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateWaiverEdit} />,
          key: PoliciesPermissionSetItems.WAIVER_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateWaiverChangeStatus} />,
          key: PoliciesPermissionSetItems.WAIVER_CHANGE_STATUS,
        },
      ],
    },
  ],
  [PermissionGroupsCorporateLevel.DICTIONARIES]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsCorporateLevel.DICTIONARIES],
      key: PermissionGroupsCorporateLevel.DICTIONARIES,
      children: [
        {
          title: <FormattedMessage {...messages.corporateDictionariesView} />,
          key: PoliciesPermissionSetItems.DICTIONARIES_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateDictionariesCreate} />,
          key: PoliciesPermissionSetItems.DICTIONARIES_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateDictionariesEdit} />,
          key: PoliciesPermissionSetItems.DICTIONARIES_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateDictionariesChangeStatus} />,
          key: PoliciesPermissionSetItems.DICTIONARIES_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateCameFromIndicationView} />,
          key: PoliciesPermissionSetItems.CAME_FROM_INDICATION_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateCameFromIndicationCreate} />,
          key: PoliciesPermissionSetItems.CAME_FROM_INDICATION_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateCameFromIndicationEdit} />,
          key: PoliciesPermissionSetItems.CAME_FROM_INDICATION_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateCameFromIndicationChangeStatus} />,
          key: PoliciesPermissionSetItems.CAME_FROM_INDICATION_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateAdditionalGuestFieldView} />,
          key: PoliciesPermissionSetItems.ADDITIONAL_GUEST_FIELD_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateAdditionalGuestFieldCreate} />,
          key: PoliciesPermissionSetItems.ADDITIONAL_GUEST_FIELD_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateAdditionalGuestFieldEdit} />,
          key: PoliciesPermissionSetItems.ADDITIONAL_GUEST_FIELD_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateAdditionalGuestFieldChangeStatus} />,
          key: PoliciesPermissionSetItems.ADDITIONAL_GUEST_FIELD_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateAlertSoundView} />,
          key: PoliciesPermissionSetItems.ALERT_SOUND_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateAlertSoundEdit} />,
          key: PoliciesPermissionSetItems.ALERT_SOUND_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateNoteCodeView} />,
          key: PoliciesPermissionSetItems.NOTE_CODE_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateNoteCodeCreate} />,
          key: PoliciesPermissionSetItems.NOTE_CODE_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateNoteCodeEdit} />,
          key: PoliciesPermissionSetItems.NOTE_CODE_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateNoteCodeChangeStatus} />,
          key: PoliciesPermissionSetItems.NOTE_CODE_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateFallthroughReasonView} />,
          key: PoliciesPermissionSetItems.FALLTHROUGH_REASON_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateFallthroughReasonCreate} />,
          key: PoliciesPermissionSetItems.FALLTHROUGH_REASON_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateFallthroughReasonEdit} />,
          key: PoliciesPermissionSetItems.FALLTHROUGH_REASON_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateFallthroughReasonChangeStatus} />,
          key: PoliciesPermissionSetItems.FALLTHROUGH_REASON_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateDepartmentView} />,
          key: PoliciesPermissionSetItems.DEPARTMENT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateDepartmentCreate} />,
          key: PoliciesPermissionSetItems.DEPARTMENT_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateDepartmentEdit} />,
          key: PoliciesPermissionSetItems.DEPARTMENT_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateDepartmentChangeStatus} />,
          key: PoliciesPermissionSetItems.DEPARTMENT_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateClubGroupView} />,
          key: PoliciesPermissionSetItems.CLIENT_GROUP_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateClubGroupCreate} />,
          key: PoliciesPermissionSetItems.CLIENT_GROUP_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateClubGroupEdit} />,
          key: PoliciesPermissionSetItems.CLIENT_GROUP_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateClubGroupChangeStatus} />,
          key: PoliciesPermissionSetItems.CLIENT_GROUP_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateInsuranceCompanyView} />,
          key: PoliciesPermissionSetItems.INSURANCE_COMPANY_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateInsuranceCompanyCreate} />,
          key: PoliciesPermissionSetItems.INSURANCE_COMPANY_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateInsuranceCompanyEdit} />,
          key: PoliciesPermissionSetItems.INSURANCE_COMPANY_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateInsuranceCompanyChangeStatus} />,
          key: PoliciesPermissionSetItems.INSURANCE_COMPANY_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateAccessHourView} />,
          key: PoliciesPermissionSetItems.ACCESS_HOUR_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateAccessHourCreate} />,
          key: PoliciesPermissionSetItems.ACCESS_HOUR_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateAccessHourEdit} />,
          key: PoliciesPermissionSetItems.ACCESS_HOUR_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateAccessHourChangeStatus} />,
          key: PoliciesPermissionSetItems.ACCESS_HOUR_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateEventTagView} />,
          key: PoliciesPermissionSetItems.EVENT_TAG_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateEventTagCreate} />,
          key: PoliciesPermissionSetItems.EVENT_TAG_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateEventTagEdit} />,
          key: PoliciesPermissionSetItems.EVENT_TAG_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateEventTagChangeStatus} />,
          key: PoliciesPermissionSetItems.EVENT_TAG_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateResourceTagView} />,
          key: PoliciesPermissionSetItems.RESOURCE_TAG_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateResourceTagCreate} />,
          key: PoliciesPermissionSetItems.RESOURCE_TAG_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateResourceTagEdit} />,
          key: PoliciesPermissionSetItems.RESOURCE_TAG_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateResourceTagChangeStatus} />,
          key: PoliciesPermissionSetItems.RESOURCE_TAG_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateNotificationView} />,
          key: PoliciesPermissionSetItems.NOTIFICATION_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateNotificationEdit} />,
          key: PoliciesPermissionSetItems.NOTIFICATION_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateHelpInformationView} />,
          key: PoliciesPermissionSetItems.HELP_INFORMATION_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateHelpInformationCreate} />,
          key: PoliciesPermissionSetItems.HELP_INFORMATION_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateHelpInformationEdit} />,
          key: PoliciesPermissionSetItems.HELP_INFORMATION_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateHelpInformationChangeStatus} />,
          key: PoliciesPermissionSetItems.HELP_INFORMATION_CHANGE_STATUS,
        },
      ],
    },
  ],
  [PermissionGroupsCorporateLevel.PERMISSIONS]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsCorporateLevel.PERMISSIONS],
      key: PermissionGroupsCorporateLevel.PERMISSIONS,
      children: [
        {
          title: <FormattedMessage {...messages.corporateSecurityRoleView} />,
          key: PoliciesPermissionSetItems.SECURITY_ROLE_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateSecurityRoleCreate} />,
          key: PoliciesPermissionSetItems.SECURITY_ROLE_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateSecurityRoleEdit} />,
          key: PoliciesPermissionSetItems.SECURITY_ROLE_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateSecurityRoleChangeStatus} />,
          key: PoliciesPermissionSetItems.SECURITY_ROLE_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateSecurityPolicyView} />,
          key: PoliciesPermissionSetItems.SECURITY_POLICY_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateSecurityPolicyCreate} />,
          key: PoliciesPermissionSetItems.SECURITY_POLICY_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateSecurityPolicyEdit} />,
          key: PoliciesPermissionSetItems.SECURITY_POLICY_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateSecurityPolicyChangeStatus} />,
          key: PoliciesPermissionSetItems.SECURITY_POLICY_CHANGE_STATUS,
        },
      ],
    },
  ],
  [PermissionGroupsCorporateLevel.CLIENT_MANAGEMENT]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsCorporateLevel.CLIENT_MANAGEMENT],
      key: PermissionGroupsCorporateLevel.CLIENT_MANAGEMENT,
      children: [
        {
          title: <FormattedMessage {...messages.corporateClientView} />,
          key: PoliciesPermissionSetItems.CLIENT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateClientCreate} />,
          key: PoliciesPermissionSetItems.CLIENT_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateClientChangeStatus} />,
          key: PoliciesPermissionSetItems.CLIENT_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateClientGeneralInfoView} />,
          key: PoliciesPermissionSetItems.CLIENT_GENERAL_INFO_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateClientGeneralInfoEdit} />,
          key: PoliciesPermissionSetItems.CLIENT_GENERAL_INFO_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateClientBankInfoView} />,
          key: PoliciesPermissionSetItems.CLIENT_BANK_INFO_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateClientBankInfoEdit} />,
          key: PoliciesPermissionSetItems.CLIENT_BANK_INFO_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateClientBillingInfoView} />,
          key: PoliciesPermissionSetItems.CLIENT_BILLING_INFO_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateClientBillingInfoEdit} />,
          key: PoliciesPermissionSetItems.CLIENT_BILLING_INFO_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateClientTaxesInfoView} />,
          key: PoliciesPermissionSetItems.CLIENT_TAXES_INFO_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateClientTaxesInfoEdit} />,
          key: PoliciesPermissionSetItems.CLIENT_TAXES_INFO_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateClientOtherInfoView} />,
          key: PoliciesPermissionSetItems.CLIENT_OTHER_INFO_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateClientOtherInfoEdit} />,
          key: PoliciesPermissionSetItems.CLIENT_OTHER_INFO_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateClientWorkingHoursView} />,
          key: PoliciesPermissionSetItems.CLIENT_WORKING_HOURS_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateClientWorkingHoursEdit} />,
          key: PoliciesPermissionSetItems.CLIENT_WORKING_HOURS_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateClientKidzoneSettingsView} />,
          key: PoliciesPermissionSetItems.CLIENT_KIDZONE_SETTINGS_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateClientKidzoneSettingsEdit} />,
          key: PoliciesPermissionSetItems.CLIENT_KIDZONE_SETTINGS_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateClientKidzoneSettingsChangeStatus} />,
          key: PoliciesPermissionSetItems.CLIENT_KIDZONE_SETTINGS_CHANGE_STATUS,
        },
      ],
    },
  ],
  [PermissionGroupsCorporateLevel.CORPORATE_MANAGEMENT]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsCorporateLevel.CORPORATE_MANAGEMENT],
      key: PermissionGroupsCorporateLevel.CORPORATE_MANAGEMENT,
      children: [
        {
          title: <FormattedMessage {...messages.corporateView} />,
          key: PoliciesPermissionSetItems.CORPORATE_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateCreate} />,
          key: PoliciesPermissionSetItems.CORPORATE_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporateChangeStatus} />,
          key: PoliciesPermissionSetItems.CORPORATE_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateGeneralInfoView} />,
          key: PoliciesPermissionSetItems.CORPORATE_GENERAL_INFO_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateGeneralInfoEdit} />,
          key: PoliciesPermissionSetItems.CORPORATE_GENERAL_INFO_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateTransferOwnership} />,
          key: PoliciesPermissionSetItems.CORPORATE_TRANSFER_OWNERSHIP,
        },
        {
          title: <FormattedMessage {...messages.corporateBankInfoView} />,
          key: PoliciesPermissionSetItems.CORPORATE_BANK_INFO_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateBankInfoEdit} />,
          key: PoliciesPermissionSetItems.CORPORATE_BANK_INFO_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateBillingInfoView} />,
          key: PoliciesPermissionSetItems.CORPORATE_BILLING_INFO_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateBillingInfoEdit} />,
          key: PoliciesPermissionSetItems.CORPORATE_BILLING_INFO_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateTaxesInfoView} />,
          key: PoliciesPermissionSetItems.CORPORATE_TAXES_INFO_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateTaxesInfoEdit} />,
          key: PoliciesPermissionSetItems.CORPORATE_TAXES_INFO_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateOtherInfoView} />,
          key: PoliciesPermissionSetItems.CORPORATE_OTHER_INFO_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateOtherInfoEdit} />,
          key: PoliciesPermissionSetItems.CORPORATE_OTHER_INFO_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateIntegrationView} />,
          key: PoliciesPermissionSetItems.CORPORATE_INTEGRATION_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateIntegrationEdit} />,
          key: PoliciesPermissionSetItems.CORPORATE_INTEGRATION_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporateIntegrationChangeStatus} />,
          key: PoliciesPermissionSetItems.CORPORATE_INTEGRATION_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.corporateRequiredFieldsView} />,
          key: PoliciesPermissionSetItems.CORPORATE_REQUIRED_FIELDS_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporateRequiredFieldsEdit} />,
          key: PoliciesPermissionSetItems.CORPORATE_REQUIRED_FIELDS_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporatePayPeriodSettingsView} />,
          key: PoliciesPermissionSetItems.CORPORATE_PAY_PERIOD_SETTINGS_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporatePayPeriodSettingsCreate} />,
          key: PoliciesPermissionSetItems.CORPORATE_PAY_PERIOD_SETTINGS_CREATE,
        },
        {
          title: <FormattedMessage {...messages.corporatePayPeriodSettingsEdit} />,
          key: PoliciesPermissionSetItems.CORPORATE_PAY_PERIOD_SETTINGS_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporatePayPeriodView} />,
          key: PoliciesPermissionSetItems.CORPORATE_PAY_PERIOD_VIEW,
        },
        {
          title: <FormattedMessage {...messages.corporatePayPeriodEdit} />,
          key: PoliciesPermissionSetItems.CORPORATE_PAY_PERIOD_EDIT,
        },
        {
          title: <FormattedMessage {...messages.corporatePayPeriodChangeStatus} />,
          key: PoliciesPermissionSetItems.CORPORATE_PAY_PERIOD_CHANGE_STATUS,
        },
      ],
    },
  ],
  [PermissionGroupsCorporateLevel.COMMUNICATION]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsCorporateLevel.COMMUNICATION],
      key: PermissionGroupsCorporateLevel.COMMUNICATION,
      children: [
        {
          title: <FormattedMessage {...messages.bannerAdvertisementView} />,
          key: PoliciesPermissionSetItems.BANNER_ADVERTISEMENT_VIEW,
        },
        {
          title: <FormattedMessage {...messages.bannerAdvertisementCreate} />,
          key: PoliciesPermissionSetItems.BANNER_ADVERTISEMENT_CREATE,
        },
        {
          title: <FormattedMessage {...messages.bannerAdvertisementEdit} />,
          key: PoliciesPermissionSetItems.BANNER_ADVERTISEMENT_EDIT,
        },
        {
          title: <FormattedMessage {...messages.bannerAdvertisementChangeStatus} />,
          key: PoliciesPermissionSetItems.BANNER_ADVERTISEMENT_CHANGE_STATUS,
        },
        {
          title: <FormattedMessage {...messages.pushNotificationView} />,
          key: PoliciesPermissionSetItems.PUSH_NOTIFICATION_VIEW,
        },
        {
          title: <FormattedMessage {...messages.pushNotificationCreate} />,
          key: PoliciesPermissionSetItems.PUSH_NOTIFICATION_CREATE,
        },
        {
          title: <FormattedMessage {...messages.pushNotificationEdit} />,
          key: PoliciesPermissionSetItems.PUSH_NOTIFICATION_EDIT,
        },
        {
          title: <FormattedMessage {...messages.pushNotificationChangeStatus} />,
          key: PoliciesPermissionSetItems.PUSH_NOTIFICATION_CHANGE_STATUS,
        },
      ],
    },
  ],
  ...PermissionsArrayCorporateOrClubLevel,
};

export const PermissionsArrayClubLevel: IPermissionsArray<PermissionGroupsClubLevel &
  PermissionGroupsCorporateOrClubLevel> = {
  [PermissionGroupsClubLevel.FINANCIAL]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsClubLevel.FINANCIAL],
      key: PermissionGroupsClubLevel.FINANCIAL,
      children: [
        {
          title: <FormattedMessage {...messages.setClientFeeSchedule} />,
          key: PoliciesPermissionSetItems.SET_CLIENT_FEE_SCHEDULE,
        },
      ],
    },
  ],
  [PermissionGroupsClubLevel.POINT_OF_SALE_POS]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsClubLevel.POINT_OF_SALE_POS],
      key: PermissionGroupsClubLevel.POINT_OF_SALE_POS,
      children: [
        {
          title: <FormattedMessage {...messages.openRegister} />,
          key: PoliciesPermissionSetItems.OPEN_REGISTER,
        },
      ],
    },
  ],
  [PermissionGroupsClubLevel.COLLECTIONS]: [
    {
      title: PermissionsGroupsLabels[PermissionGroupsClubLevel.COLLECTIONS],
      key: PermissionGroupsClubLevel.COLLECTIONS,
      children: [
        {
          title: <FormattedMessage {...messages.sendLetter} />,
          key: PoliciesPermissionSetItems.SEND_A_LETTER,
        },
      ],
    },
  ],
  ...PermissionsArrayCorporateOrClubLevel,
};

export function getPolicyLevel(permissionGroup): PermissionLevels {
  if (
    // TODO: PermissionGroupsCorporateOrClubLevel permission groups  included to CORPORATE and CLUB levels. Temporary method until the permissionLevel field is added to the server to the policy DTO.
    Object.values({
      ...PermissionGroupsCorporateLevel,
      ...PermissionGroupsCorporateOrClubLevel,
    }).includes(permissionGroup)
  )
    return PermissionLevels.CORPORATE;
  if (Object.values(PermissionGroupsClubLevel).includes(permissionGroup))
    return PermissionLevels.CLIENT;
  return PermissionLevels.PEAK;
}

export const permissionsArray = {
  [PermissionLevels.PEAK]: PermissionsArrayPeakLevel,
  [PermissionLevels.CLIENT]: PermissionsArrayClubLevel,
  [PermissionLevels.CORPORATE]: PermissionsArrayCorporateLevel,
};
