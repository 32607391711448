import axios from 'axios';
import ApiService from 'services/network/ApiService';

import { ITableParams } from 'common/interfaces/table';
import {
  IBankData,
  ICorporationBankInfo,
  ICorporationBillingInfo,
  ICorporationGeneralInfo,
  ICorporationGeneralInfoView,
  ICorporationListItem,
  ICorporationOtherInfo,
  ICorporationProcessorConfigInfo,
  ICorporationTaxpayerInfo,
} from 'modules/corporations/interfaces';
import { IPaginatedData } from 'common/interfaces/pagination';
import {
  IBankProcessorConfiguration,
  IBankRemittanceInfo,
  IBankViewInfoDto,
  IClubFeeLate,
  IClubFeeMonthly,
  IClubFeeRatesData,
  IClubFeeReturn,
  IClubFeeSingle,
  IClubFeeTransaction,
  IClubProcessingFeeRates,
} from 'modules/clubs/interfaces';
import { IPeakMessagingSettings } from 'modules/peak-settings/interfaces';
import { INamedEntity } from 'common/interfaces/common';
import { IRequiredFieldInfo } from 'modules/corporate-settings/interfaces';

export type IPaginatedCorporations = IPaginatedData<ICorporationListItem>;

export default class CorporationsService extends ApiService {
  public getCorporationsList = (requestOptions?: ITableParams): Promise<IPaginatedCorporations> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get('/api/v1/corporations', { params: searchParams });
  };

  public getPeakSystemSettings = (): Promise<IPeakMessagingSettings> =>
    axios.get('/api/v1/corporations/zendesk-settings');

  public createCorporation = (
    requestData: ICorporationGeneralInfo,
  ): Promise<ICorporationGeneralInfo> => axios.post('/api/v1/corporations', requestData);

  public getCorporationGeneralInfo = (corporationId: string): Promise<ICorporationGeneralInfo> =>
    axios.get(`/api/v1/corporations/${corporationId}`);

  public getCorporationGeneralInfoView = (
    corporationId: string,
  ): Promise<ICorporationGeneralInfoView> =>
    axios.get(`/api/v1/corporations/${corporationId}/view`);

  public getCorporationBankInfo = (corporationId: string): Promise<ICorporationBankInfo> =>
    axios.get(`/api/v1/corporations/${corporationId}/bank-info`);

  public getCorporationBankInfoView = (corporationId: string): Promise<IBankViewInfoDto> =>
    axios.get(`/api/v1/corporations/${corporationId}/bank-info/view`);

  public getCorporationBillingInfo = (corporationId: string): Promise<ICorporationBillingInfo> =>
    axios.get(`/api/v1/corporations/${corporationId}/billing-info`);

  public getCorporationProcessorConfigInfo = (
    corporationId: string,
  ): Promise<ICorporationProcessorConfigInfo> =>
    axios.get(`/api/v1/corporations/${corporationId}/processor-config`);

  public getCorporationTaxpayerInfo = (corporationId: string): Promise<ICorporationTaxpayerInfo> =>
    axios.get(`/api/v1/corporations/${corporationId}/taxpayer-info`);

  // TODO add api for integrations step
  /* public getCorporationIntegrationsInfo = (corporationId: string): Promise<ICorporationTaxesInfo> =>
axios.get(`/api/v1/corporations/${corporationId}/tax-info`); */

  public getCorporationOtherInfo = (corporationId: string): Promise<ICorporationOtherInfo> =>
    axios.get(`/api/v1/corporations/${corporationId}/other-info`);

  public updateCorporationGeneralInfo = (
    corporationId: string,
    requestData: ICorporationGeneralInfo,
  ): Promise<ICorporationGeneralInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}`, requestData);

  public updateCorporationBankInfo = (
    corporationId: string,
    requestData: ICorporationBankInfo,
  ): Promise<ICorporationBankInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}/bank-info`, requestData);

  public updateCorporationBankRemittanceInfo = (
    corporationId: string,
    requestData: IBankRemittanceInfo,
  ): Promise<IBankRemittanceInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}/bank-info/remittance-info`, requestData);

  public updateCorporationBankProcessorConfigInfo = (
    corporationId: string,
    requestData: IBankProcessorConfiguration,
  ): Promise<IBankProcessorConfiguration> =>
    axios.put(`/api/v1/corporations/${corporationId}/bank-info/processor-config`, requestData);

  public updateCorporationBillingInfo = (
    corporationId: string,
    requestData: ICorporationBillingInfo,
  ): Promise<ICorporationBillingInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}/billing-info`, requestData);

  public updateCorporationProcessorConfigInfo = (
    corporationId: string,
    requestData: ICorporationProcessorConfigInfo,
  ): Promise<ICorporationProcessorConfigInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}/processor-config`, requestData);

  public updateCorporationTaxpayerInfo = (
    corporationId: string,
    requestData: ICorporationTaxpayerInfo,
  ): Promise<ICorporationTaxpayerInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}/taxpayer-info`, requestData);

  public updateCorporationOtherInfo = (
    corporationId: string,
    requestData: ICorporationOtherInfo,
  ): Promise<ICorporationOtherInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}/other-info`, requestData);

  public changeCorporationStatus = (
    corporationId: string,
    isActive: boolean,
  ): Promise<ICorporationGeneralInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}/change-status`, {
      active: isActive,
    });

  public updateCorporation = (
    corporationId: string,
    requestData: ICorporationGeneralInfo,
  ): Promise<ICorporationGeneralInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}`, requestData);

  public transferOwnership = (
    corporationId: string,
    employeeId: string,
  ): Promise<IPaginatedData<INamedEntity>> =>
    axios.put(
      `/api/v1/corporations/${corporationId}/transfer-ownership
`,
      null,
      { params: { employeeId } },
    );

  public getProfileFieldsInfo = (corporationId: string): Promise<Array<IRequiredFieldInfo>> =>
    axios.get(`/api/v1/corporations/${corporationId}/profile-required-info`);

  public getCorporationProcessingFeeRates = (
    corporationId: string,
  ): Promise<IClubProcessingFeeRates> =>
    axios.get(`/api/v1/corporations/${corporationId}/fees/fee-processing-info`);

  public updateCorporationProcessingFeeRates = (
    corporationId: string,
    requestData: IClubProcessingFeeRates,
  ): Promise<IClubProcessingFeeRates> =>
    axios.put(`/api/v1/corporations/${corporationId}/fees/fee-processing-info`, requestData);

  public getPeakProcessingFeeRates = (corporationId: string): Promise<IClubProcessingFeeRates> =>
    axios.get(`/api/v1/corporations/${corporationId}/fees/fee-processing-info/peak`);

  public getCorporationFeeSingleRates = (corporationId: string): Promise<IClubFeeSingle> =>
    axios.get(`/api/v1/corporations/${corporationId}/fees/fee-single-info`);

  public updateCorporationFeeSingleRates = (
    corporationId: string,
    requestData: IClubFeeSingle,
  ): Promise<IClubFeeSingle> =>
    axios.put(`/api/v1/corporations/${corporationId}/fees/fee-single-info`, requestData);

  public getPeakFeeSingleRates = (corporationId: string): Promise<IClubFeeSingle> =>
    axios.get(`/api/v1/corporations/${corporationId}/fees/fee-single-info/peak`);

  public getCorporationFeeLateRates = (corporationId: string): Promise<IClubFeeLate> =>
    axios.get(`/api/v1/corporations/${corporationId}/fees/fee-late-info`);

  public updateCorporationFeeLateRates = (
    corporationId: string,
    requestData: IClubFeeLate,
  ): Promise<IClubFeeLate> =>
    axios.put(`/api/v1/corporations/${corporationId}/fees/fee-late-info`, requestData);

  public getPeakFeeLateRates = (corporationId: string): Promise<IClubFeeLate> =>
    axios.get(`/api/v1/corporations/${corporationId}/fees/fee-late-info/peak`);

  public getCorporationFeeReturnRates = (corporationId: string): Promise<IClubFeeReturn> =>
    axios.get(`/api/v1/corporations/${corporationId}/fees/fee-return-info`);

  public updateCorporationFeeReturnRates = (
    corporationId: string,
    requestData: IClubFeeReturn,
  ): Promise<IClubFeeReturn> =>
    axios.put(`/api/v1/corporations/${corporationId}/fees/fee-return-info`, requestData);

  public getPeakFeeReturnRates = (corporationId: string): Promise<IClubFeeReturn> =>
    axios.get(`/api/v1/corporations/${corporationId}/fees/fee-return-info/peak`);

  public getCorporationFeeMonthlyRates = (corporationId: string): Promise<IClubFeeMonthly> =>
    axios.get(`/api/v1/corporations/${corporationId}/fees/fee-monthly-info`);

  public updateCorporationFeeMonthlyRates = (
    corporationId: string,
    requestData: IClubFeeMonthly,
  ): Promise<IClubFeeMonthly> =>
    axios.put(`/api/v1/corporations/${corporationId}/fees/fee-monthly-info`, requestData);

  public getPeakFeeMonthlyRates = (corporationId: string): Promise<IClubFeeMonthly> =>
    axios.get(`/api/v1/corporations/${corporationId}/fees/fee-monthly-info/peak`);

  public getCorporationFeeTransactionRates = (
    corporationId: string,
  ): Promise<IClubFeeTransaction> =>
    axios.get(`/api/v1/corporations/${corporationId}/fees/fee-transaction-info`);

  public updateCorporationFeeTransactionRates = (
    corporationId: string,
    requestData: IClubFeeTransaction,
  ): Promise<IClubFeeTransaction> =>
    axios.put(`/api/v1/corporations/${corporationId}/fees/fee-transaction-info`, requestData);

  public getPeakFeeTransactionRates = (corporationId: string): Promise<IClubFeeTransaction> =>
    axios.get(`/api/v1/corporations/${corporationId}/fees/fee-transaction-info/peak`);

  public updateCorporationFeeRates = (
    corporationId: string,
    requestData: IClubFeeRatesData,
  ): Promise<IClubFeeRatesData> =>
    axios.put(`/api/v1/corporations/${corporationId}/fees`, requestData);

  public fetchBankList = (corporationId: string): Promise<IBankData[]> =>
    axios.get(`/api/v1/corporations/${corporationId}/processor-config/banks`);
}
