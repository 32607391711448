import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import * as yup from 'yup';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';

import {
  AvatarWithInitials,
  DialogComponent,
  PhoneNumberInputWithReset,
  Select,
  TextArea,
} from 'common/components';
import { PhoneTypes } from 'common/components/PersonProfile/constants';
import inputLabels from 'common/messages/inputLabels';
import inputErrors from 'common/messages/inputErrors';
import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';
import { getRequiredMessage } from 'common/constants/globalConstants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import {
  IReferralCreateDto,
  IReferralFormFields,
} from 'common/components/PersonProfile/interfaces';
import { PeakModules } from 'common/constants/peakModules';
import { phoneSchema } from 'common/validationSchemas/phoneSchema';
import Services from 'services/network';
import { getInitials } from 'helpers/common';

interface IFormValues {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  salespersonId: string;
  note: string;
  smsEnabled: boolean;
}

interface IReferralCreateModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onCreateReferral: (dto: IReferralCreateDto) => void;
  onClose: () => void;
  module?: PeakModules;
}

const useStyles = makeStyles(() => ({
  checkbox: {
    alignItems: 'start',
    '& .MuiCheckbox-root': {
      width: 20,
      height: 20,
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#66CD00',
    },
    '& .MuiFormControlLabel-label': {
      fontWeight: 500,
    },
  },
}));

const validationSchema = yup.object().shape({
  firstName: yup.string().required(getRequiredMessage),
  lastName: yup.string().required(getRequiredMessage),
  email: yup
    .string()
    .trim()
    .email(() => inputErrors.emailInvalidError)
    .required(getRequiredMessage),
  phone: yup
    .string()
    .nullable()
    .when('smsEnabled', {
      is: value => value,
      then: phoneSchema.nullable().required(getRequiredMessage),
    })
    .required(getRequiredMessage),
  salespersonId: yup.string().required(getRequiredMessage),
});

const ReferralCreateModal = (props: IReferralCreateModalProps): JSX.Element => {
  const { isOpen, onClose, isLoading, onCreateReferral, module } = props;

  const [salespersonList, setSalesPersonList] = useState([]);

  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();

  const formMethods = useForm<IFormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      salespersonId: '',
      note: '',
      smsEnabled: false,
    },
    resolver: yupResolver(validationSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'onSubmit',
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const loadEmployees = useCallback(async () => {
    const employees = await Services.DictionaryLists.getEmployeesList({
      module,
    });

    setSalesPersonList(employees.data);
  }, [module]);

  useEffect(() => {
    void loadEmployees();
  }, [module, loadEmployees]);

  const submitForm = (values: IReferralFormFields) => {
    const { firstName, lastName, email, salespersonId, phone, note } = values;

    const addReferralDto = {
      firstName,
      lastName,
      email,
      salespersonId,
      note,
      phone: {
        phoneType: PhoneTypes.Mobile,
        phone,
        allowAutomatedCalls: true,
        canReceiveSms: values.smsEnabled,
        useAsDefault: true,
      },
    };

    onCreateReferral(addReferralDto);
  };

  return (
    <DialogComponent
      title={<FormattedMessage {...messages.newReferralTitle} />}
      size="sm"
      isOpen={isOpen}
      submitBtnTitle={<FormattedMessage {...commonMessages.sendBtn} />}
      loading={isLoading}
      onClose={onClose}
      onSubmit={handleSubmit(submitForm)}
    >
      <form>
        <FormProvider {...formMethods}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    name={field.name}
                    variant="outlined"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    label={<FormattedMessage {...inputLabels.firstName} />}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    error={!!errors.firstName}
                    helperText={renderIntlMessage(errors?.firstName?.message)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    name={field.name}
                    variant="outlined"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    label={<FormattedMessage {...inputLabels.lastName} />}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    error={!!errors.lastName}
                    helperText={renderIntlMessage(errors?.lastName?.message)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label={<FormattedMessage {...inputLabels.email} />}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    fullWidth
                    error={!!errors.email}
                    helperText={renderIntlMessage(errors.email?.message)}
                    value={field.value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <PhoneNumberInputWithReset
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    label={<FormattedMessage {...inputLabels.phone} />}
                    error={!!errors.phone}
                    helperText={renderIntlMessage(errors.phone?.message)}
                  />
                )}
              />
            </Grid>
            <Box display="flex" alignItems="center">
              <Controller
                control={control}
                name="smsEnabled"
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="medium"
                        checked={field.value}
                        onChange={async e => {
                          const isChecked = e.target.checked;
                          field.onChange(isChecked);
                        }}
                        onBlur={field.onBlur}
                      />
                    }
                    label={<FormattedMessage {...messages.newReferralNote} />}
                    className={classes.checkbox}
                  />
                )}
              />
            </Box>
            <Grid item xs={12}>
              <Controller
                name="salespersonId"
                control={control}
                render={({ field }) => (
                  <Select
                    name={field.name}
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={optionId => {
                      field.onChange(optionId);
                    }}
                    fullWidth
                    label={<FormattedMessage {...inputLabels.salesperson} />}
                    error={!!errors?.salespersonId}
                    helperText={renderIntlMessage(errors.salespersonId?.message)}
                  >
                    {salespersonList.map(el => {
                      const [firstName, lastName] = el.title.split(/\s(.*)/);

                      const initials = getInitials(firstName, lastName);
                      return (
                        <MenuItem key={el.id} value={el.id}>
                          <Box display="flex" key={el.id}>
                            <AvatarWithInitials
                              imageUrl={el.imageUrl}
                              width="36px"
                              height="36px"
                              fontSize="1rem"
                              initials={initials}
                            />

                            <Box display="flex" alignItems="center">
                              <Typography>{el.title}</Typography>
                            </Box>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextArea
                    value={field.value}
                    label={<FormattedMessage {...inputLabels.note} />}
                    variant="outlined"
                    autoComplete="none"
                    fullWidth
                    maxSymbols={1000}
                    rows={4}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!errors.note}
                    helperText={renderIntlMessage(errors.note?.message)}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </form>
    </DialogComponent>
  );
};

export default ReferralCreateModal;
