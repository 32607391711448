import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, makeStyles, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';

// components
import { Select } from 'common/components';
import { signingTypeOptions } from 'common/components/Steps/SignDocumentStep/constants';
import { ReactComponent as InfoIcon } from 'img/icons/info.svg';
// constants
import { SignType } from 'common/constants/contract';
import { QuestionnaireModal } from 'common/modals';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
// messages
import messages from 'common/components/Steps/SignDocumentStep/messages';
import commonMessages from 'common/messages/messages';
// interfaces
import { ISenderAvailability, TSenderAvailabilityImt } from 'modules/booking/interfaces';
// state
import { selectSenderAvailability } from 'modules/booking/state/senderAvailability/selectors';

interface ISigningTypeSelectorProps {
  fieldName: string;
}

const useStyles = makeStyles({
  infoIconWrapper: {
    lineHeight: 0,
  },
});

const SigningTypeSelector = (props: ISigningTypeSelectorProps): JSX.Element => {
  const { fieldName } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOk, setIsOk] = useState(false);
  const [signType, setSignType] = useState<SignType>();
  const senderAvailability: TSenderAvailabilityImt = useSelector(
    selectSenderAvailability(SenderAvailabilityTypeList.DOCUMENTS),
  );

  const { control, setValue, formState, clearErrors } = useFormContext();
  const { errors } = formState;
  const classes = useStyles();

  useEffect(() => {
    if (isOk && signType) {
      setValue(fieldName, signType);
      setIsOpenModal(false);
      setIsOk(false);
    }
  }, [isOk, signType, setIsOpenModal, setIsOk, setValue, fieldName]);

  const transformedSenderAvailability = useMemo<Partial<ISenderAvailability>>(
    () => senderAvailability?.toJS() || {},
    [senderAvailability],
  );

  const onClose = () => {
    setIsOpenModal(false);
  };

  const onSubmit = () => {
    if (Object.keys(errors).length) {
      clearErrors();
    }
    setIsOk(true);
  };

  return (
    <Grid container alignItems="center" item xs={6}>
      <Controller
        name={fieldName}
        defaultValue={SignType.ViaTopaz}
        control={control}
        render={({ field }) => (
          <Select
            onChange={(val: SignType) => {
              if (val) {
                setIsOpenModal(true);
                setSignType(val);
              }
            }}
            value={field.value}
            fullWidth
            variant="outlined"
            size="small"
          >
            {signingTypeOptions.map(({ value: item, messageDescriptor, name }) => {
              let tooltipMessage = null;

              const menuItem = (
                <MenuItem
                  key={item}
                  value={item}
                  disabled={name ? !transformedSenderAvailability[name] : false}
                >
                  <FormattedMessage {...messageDescriptor} />
                </MenuItem>
              );

              if (name === 'emailAvailable' && transformedSenderAvailability.emailMessage) {
                tooltipMessage = transformedSenderAvailability.emailMessage;
              }

              if (name === 'smsAvailable' && transformedSenderAvailability.smsMessage) {
                tooltipMessage = transformedSenderAvailability.smsMessage;
              }

              return tooltipMessage ? (
                <Box display="flex" alignItems="center" justifyContent="space-between" pr={2}>
                  {menuItem}
                  <Tooltip title={tooltipMessage}>
                    <Typography className={classes.infoIconWrapper} color="textSecondary">
                      <InfoIcon width={16} height={16} />
                    </Typography>
                  </Tooltip>
                </Box>
              ) : (
                menuItem
              );
            })}
          </Select>
        )}
      />
      {isOpenModal && (
        <QuestionnaireModal
          onClose={onClose}
          onSubmit={onSubmit}
          submitBtnTitle={<FormattedMessage {...commonMessages.okBtn} />}
          title={<FormattedMessage {...messages.changeTypeQuestionnaireModalTitle} />}
          isOpen={isOpenModal}
          body={<FormattedMessage {...messages.changeTypeQuestion} />}
        />
      )}
    </Grid>
  );
};

export default SigningTypeSelector;
