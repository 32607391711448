import React from 'react';
import { Box } from '@material-ui/core';

import { IBundleView } from 'modules/services/interfaces/packages';

import GeneralInfoView from './GeneralInfoView/GeneralInfoView';
import IncludedServicesView from './IncludedServicesView/IncludedServicesView';
import IncludedInventoryView from './IncludedInventoryView/IncludedInventoryView';
import FeesView from './FeesView/FeesView';
import BillingInfoView from './BillingInfoView/BillingInfoView';
import { PackageCostType } from 'modules/services/constants/packages';

interface IProps {
  bundle: IBundleView;
  onViewInventoryInfo?: (inventoryId: string, packageId: string) => void;
}

const BundleView = ({ bundle, onViewInventoryInfo }: IProps): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      <GeneralInfoView bundle={bundle} />
      {bundle.costType !== PackageCostType.Free && (
        <BillingInfoView billingOption={bundle.billingOption} />
      )}
      {bundle.costType !== PackageCostType.Free && (
        <BillingInfoView billingOption={bundle.outOfTermBillingOption} isOutOfTerm />
      )}
      <IncludedServicesView bundle={bundle} onViewInventoryInfo={onViewInventoryInfo} />
      <IncludedInventoryView bundle={bundle} onViewInventoryInfo={onViewInventoryInfo} />
      <FeesView bundle={bundle} />
    </Box>
  );
};

export default BundleView;
