import moment from 'moment-timezone';

import Services from 'services/network';
import { FilterTypes, IFilter, IPaginateFilterReturnType } from 'common/interfaces/filter';
import { IPeriodItem } from 'modules/corporations/interfaces';
import { ITableParams } from 'common/interfaces/table';
import { makeFilterChangeHandler } from 'common/utils/filter';
import { getArray } from 'common/utils/typeUtils';

export const employeeTimeclockCommonFilter = {
  perPage: 20,
  fetchItemTitle: async (id: string): Promise<string | null> => {
    try {
      const employee = await Services.Employees.getEmployeeById(id);
      return `${employee.firstName} ${employee.lastName}`;
    } catch (e) {
      return null;
    }
  },
  fetchList: async (search: string, page: number): Promise<IPaginateFilterReturnType> => {
    try {
      const { meta, data } = await Services.DictionaryLists.getTimeclockEmployees({
        search,
        page,
        perPage: 20,
      });

      return {
        pageElements: data.map(item => ({
          key: String(item.id), // TODO - PRM-3575
          label: item.title,
          value: item.id,
        })),
        itemCount: meta.total,
      };
    } catch (e) {
      return { pageElements: [], itemCount: 0 };
    }
  },
};

export const getCurrentPayPeriod = (data: IPeriodItem[]) => {
  let currentPayPeriod: IPeriodItem | null = null;

  for (let i = 0, ln = data.length; i < ln; i += 1) {
    const { startDate, endDate } = data[i];

    const currentDate = moment();
    const startMomentDate = moment(startDate, 'YYYY-MM-DD');
    const endMomentDate = moment(endDate, 'YYYY-MM-DD');

    if (
      currentDate.isSameOrAfter(startMomentDate, 'date') &&
      currentDate.isSameOrBefore(endMomentDate, 'date')
    ) {
      currentPayPeriod = data[i];
    }

    if (currentPayPeriod) {
      break;
    }
  }

  return currentPayPeriod;
};

export const getNewTableParams = (
  requestOptions?: ITableParams,
  currentPayPeriod?: IPeriodItem,
): ITableParams => {
  const dateRangeFilterValue: IFilter = {
    name: 'range',
    type: FilterTypes.DATE_RANGE,
    value: {
      startDate: currentPayPeriod
        ? moment(currentPayPeriod.startDate, 'YYYY-MM-DD').toDate()
        : null,
      endDate: currentPayPeriod ? moment(currentPayPeriod.endDate, 'YYYY-MM-DD').toDate() : null,
    },
  };

  const getFilters = makeFilterChangeHandler(requestOptions?.filters || [], () => {});
  const filters = dateRangeFilterValue ? getArray(getFilters(dateRangeFilterValue)) : [];

  if (requestOptions) {
    return {
      ...requestOptions,
      filters,
    };
  }

  return {
    order: 'asc',
    page: 0,
    perPage: 0,
    filters,
  };
};
