import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from 'common/components/index';
import { Control, Controller } from 'react-hook-form';
import React from 'react';

interface ICheckboxLabeled {
  control: Control<any>;
  defaultValue: boolean;
  name: string;
  label: JSX.Element;
}

const CheckboxLabeled = ({ control, defaultValue, name, label }: ICheckboxLabeled): JSX.Element => {
  return (
    <Controller
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={field.value}
              onChange={e => field.onChange(e.target.checked)}
            />
          }
          label={label}
        />
      )}
      defaultValue={defaultValue}
      control={control}
      name={name}
    />
  );
};

export default CheckboxLabeled;
