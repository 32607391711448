import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// constants
import { getPermissionsFilteredArray } from 'modules/permissions/utils/permission';
import { PoliciesPermissionSetItems } from 'common/constants/permissions';
// interfaces
import { IEmployeeRole, IEmployeeRoleImt } from 'common/interfaces/employee';
// messages
import commonMessages from 'common/messages/messages';
// state
import * as selectors from '../../state/roles/selectors';
import * as actions from '../../state/roles/actions';
// hooks
import { useAppDispatch } from 'store/hooks';
// components
import { DialogComponent, LoadingBackdrop } from 'common/components';
import { CollapsedComponent } from '..';
import { ISecurityPolicyDtoImt } from 'common/interfaces/permissions';

interface ViewRoleModalProps {
  selectedRow: Partial<IEmployeeRole>;
  isOpen: boolean;
  onClose: () => void;
}

export default function ViewRoleModal({
  selectedRow,
  isOpen,
  onClose,
}: ViewRoleModalProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [hideUnchecked, setHideUnchecked] = useState(true);

  const roleItem: IEmployeeRoleImt = useSelector(selectors.selectRoleItemData);
  const isLoading = useSelector(selectors.selectRoleItemLoading);

  useEffect(() => {
    if (selectedRow?.id && isOpen) dispatch(actions.fetchRoleItem(selectedRow.id));
  }, [dispatch, selectedRow, isOpen]);

  const handleDialogClose = () => {
    onClose();
    dispatch(actions.resetRoleItemData());
  };

  const rolePermissionsSet: Array<PoliciesPermissionSetItems> =
    roleItem
      .get('securityPolicyDtoSet')
      ?.map((securityPolicy: ISecurityPolicyDtoImt) => securityPolicy.get('permissionSet'))
      .toJS()
      .flat() || [];

  const currentRolePermissionsGroupsArray: Array<string> =
    roleItem
      .get('securityPolicyDtoSet')
      ?.map((securityPolicy: ISecurityPolicyDtoImt) => securityPolicy.get('permissionGroup'))
      .toJS() || [];

  const permissionsArray = getPermissionsFilteredArray(
    roleItem.get('level'),
    hideUnchecked,
    currentRolePermissionsGroupsArray,
  );

  return (
    <DialogComponent
      title={selectedRow?.title || roleItem?.get('title')}
      submitBtnTitle={<FormattedMessage {...commonMessages.closeBtn} />}
      size="sm"
      isOpen={isOpen}
      onClose={handleDialogClose}
      disableFullScreenBehavior
      submitBtn={false}
      cancelBtnTitle={<FormattedMessage {...commonMessages.closeBtn} />}
    >
      <LoadingBackdrop isLoading={isLoading} />
      {!isLoading && (
        <CollapsedComponent
          permissions={permissionsArray}
          permissionSet={rolePermissionsSet || []}
          onHideUnchecked={setHideUnchecked}
          hideUnchecked={hideUnchecked}
        />
      )}
    </DialogComponent>
  );
}
