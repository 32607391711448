import isArray from 'lodash/isArray';
import isString from 'lodash/isString';

export function getArray<T = any>(val: any): T[] {
  return isArray(val) ? val : [];
}

export function getString(val: any): string {
  return isString(val) ? val : '';
}
