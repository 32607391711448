import React from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Grid, TextField } from '@material-ui/core';

import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { UserAutocomplete } from 'common/components';
import inputLabels from 'common/messages/inputLabels';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { PeakModules } from 'common/constants/peakModules';

interface IProps {
  formMethods: UseFormReturn<any>;
}

const ResourceInfoSection = ({ formMethods }: IProps): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();

  const {
    control,
    formState: { errors },
  } = formMethods;

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="label"
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={onChange}
                label={<FormattedMessage {...inputLabels.resourceName} />}
                variant="outlined"
                autoComplete="off"
                fullWidth
                error={!!errors?.label?.message}
                helperText={renderIntlMessage(errors?.label?.message)}
              />
            )}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="employee"
            render={({ field: { value, onChange } }) => (
              <UserAutocomplete
                module={PeakModules.Booking}
                label={renderIntlMessage({ ...inputLabels.linkedEmployee })}
                fullWidth
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(ResourceInfoSection);
