// libraries
import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
// components
import { DialogComponent } from 'common/components';
import DayTimeAvailabilitySelector from 'common/components/DayTimeAvailabilitySelector/DayTimeAvailabilitySelector';
import { IDayTimeAvailabilityDto } from 'common/interfaces/common';
import { generateAvailabilityScheduleSchema } from '../../validationSchemas/dayTimeAvailabilitySchema';
import { sortAvailabilities } from '../../../modules/services/utils/servicePackage';
// messages
import messages from './messages';
import commonMessages from 'common/messages/messages';

interface IDayTimeAvailabilityModalProps {
  open: boolean;
  availabilitySettings?: IDayTimeAvailabilityDto[];
  validAvailabilityRange?: IDayTimeAvailabilityDto[];

  onSubmit: (availabilitySettings: IDayTimeAvailabilityDto[]) => void;
  onCancel: () => void;
}

interface IDayTimeAvailabilityForm {
  availabilitySchedule: IDayTimeAvailabilityDto[];
  weekdays: IDayTimeAvailabilityDto[];
}

const DayTimeAvailabilityModal: React.FC<IDayTimeAvailabilityModalProps> = ({
  open,
  availabilitySettings = [],
  validAvailabilityRange = [],
  onSubmit,
  onCancel,
}) => {
  const formMethods = useForm<IDayTimeAvailabilityForm>({
    defaultValues: {
      weekdays: [],
      availabilitySchedule: [],
    },
    mode: 'all',
    resolver: yupResolver(generateAvailabilityScheduleSchema(validAvailabilityRange)) as any, // TODO - PRM-1810 need resolver type
    shouldUnregister: false,
  });

  const { handleSubmit, reset } = formMethods;

  // effects

  useEffect(() => {
    if (availabilitySettings?.length) {
      const transformedSettings = availabilitySettings.map(availabilityItem => ({
        ...availabilityItem,
        editableRangeIndex: null,
      }));

      const sortedAvailabilities = sortAvailabilities(transformedSettings);

      reset({
        availabilitySchedule: sortedAvailabilities,
        weekdays: sortedAvailabilities,
      });
    }
  }, [availabilitySettings, open, reset]);

  const handleDialogSubmitAction = handleSubmit(data => onSubmit(data.availabilitySchedule));

  return (
    <DialogComponent
      isOpen={open}
      size="md"
      title={<FormattedMessage {...messages.modalDayTimeAvailabilityTitle} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.doneBtn} />}
      onSubmit={handleDialogSubmitAction}
      onClose={onCancel}
    >
      <FormProvider {...formMethods}>
        <form>
          <DayTimeAvailabilitySelector
            rangesFieldName="availabilitySchedule"
            validAvailabilityRange={validAvailabilityRange}
          />
        </form>
      </FormProvider>
    </DialogComponent>
  );
};

export default React.memo(DayTimeAvailabilityModal);
