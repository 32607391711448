import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { IBalanceHistoryItem } from 'common/components/PersonProfile/interfaces';
import { IHeadCell, ITableRow } from 'common/interfaces/table';

import { DialogComponent, Table } from 'common/components/index';

import commonMessages from 'common/messages/messages';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import tableHeaders from 'common/messages/tableHeaders';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import { Typography, useTheme } from '@material-ui/core';
import { BalanceType } from '../../constants';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  balanceHistory: IBalanceHistoryItem[];
  balanceHistoryLoading: boolean;
  setSelectedInvoiceId: (id: string) => void;
}

const BalanceHistoryModal = ({
  isOpen,
  onClose,
  balanceHistory,
  balanceHistoryLoading,
  setSelectedInvoiceId,
}: IProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();
  const theme = useTheme();

  const headerOptions = useMemo(
    (): IHeadCell[] => [
      {
        id: 'invoiceNumber',
        label: <FormattedMessage {...tableHeaders.invoiceNumber} />,
        sort: false,
      },
      {
        id: 'amount',
        label: <FormattedMessage {...tableHeaders.amount} />,
        sort: false,
      },
      {
        id: 'type',
        label: <FormattedMessage {...tableHeaders.type} />,
        sort: false,
      },
      {
        id: 'date',
        label: <FormattedMessage {...tableHeaders.date} />,
        sort: false,
      },
    ],
    [],
  );

  const rows = useMemo(() => {
    return balanceHistory?.map(
      (item: IBalanceHistoryItem): ITableRow => {
        const isDebitType = item.type === BalanceType.DEBIT;

        return {
          id: item.id.toString(),
          cells: [
            {
              label: '',
              cellComponent: (
                <Typography
                  variant="h6"
                  color="primary"
                  onClick={() => setSelectedInvoiceId(item.invoiceId)}
                  style={{ cursor: 'pointer' }}
                >
                  {item.invoiceNumber}
                </Typography>
              ),
            },
            {
              label: `$${item.amount.toString()}`,
            },
            {
              label: '',
              cellComponent: (
                <Typography
                  variant="h6"
                  color="primary"
                  style={{
                    color: isDebitType ? theme.palette.error.main : theme.palette.primary.main,
                  }}
                >
                  <FormattedMessage
                    {...(isDebitType ? commonMessages.debitLabel : commonMessages.creditLabel)}
                  />
                </Typography>
              ),
            },
            {
              label: `${timezoneMoment(item.createdAt).format(DEFAULT_DATE_TIME_FORMAT)}`,
            },
          ],
        };
      },
    );
  }, [balanceHistory, timezoneMoment, setSelectedInvoiceId, theme.palette]);

  return (
    <DialogComponent
      title={<FormattedMessage {...commonMessages.accountCreditHistoryTitle} />}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      hideFooterButtons
    >
      <Table
        rows={rows}
        headerOptions={headerOptions}
        hideSearchInput
        hidePagination
        isLoading={balanceHistoryLoading}
      />
    </DialogComponent>
  );
};

export default BalanceHistoryModal;
