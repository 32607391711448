import React, { useCallback, useMemo, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { List as ImmutableList } from 'immutable';

import { IPayrollActivityReportImt } from 'modules/timeclock/interfaces/timeclock';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { colors } from 'common/ui/theme/default';
import { ChartPeriodFilter, LoadingBackdrop } from 'common/components';
import { getQueueColor } from 'common/utils';
import {
  PayrollActivityChartLegendLabels,
  PayrollReportActivityChartFilters,
} from 'modules/timeclock/constants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { IChartLineSettings, PeriodFilterType } from 'modules/reports/interfaces/common';
import PayrollActivityChartLegend from './PayrollActivityChartLegend';

import messages from 'modules/timeclock/messages';
import { FormattedMessage } from 'react-intl';
import commonMessages from 'common/messages/messages';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';
import { transformMinutesToTimeFormat } from 'common/utils/time';
import IntlService from 'services/intl/IntlService';

interface IProps {
  chartData: ImmutableList<IPayrollActivityReportImt>;
  isChartDataLoading: boolean;
  onPeriodChange: (period: PeriodFilterType) => void;
  selectedPeriod: PeriodFilterType;
}

const PayrollActivityChart = ({
  chartData,
  isChartDataLoading,
  selectedPeriod,
  onPeriodChange,
}: IProps): JSX.Element => {
  const [chartFilters, setChartFilters] = useState<string[]>(
    Object.values(PayrollReportActivityChartFilters),
  );

  const transformedChartData = useMemo(() => chartData?.toJS() || [], [chartData]);
  const renderIntlMessage = useRenderIntlMessage();
  const [timezoneMoment] = useTimezoneMoment();

  const handleChangeReportLegendFilters = useCallback(
    (legendFilters: string[]): void => setChartFilters(legendFilters),
    [],
  );

  const chartLineSettings = useMemo(
    (): IChartLineSettings[] => [
      {
        key: PayrollReportActivityChartFilters.Regular,
        name: IntlService.formatMessage(
          PayrollActivityChartLegendLabels[PayrollReportActivityChartFilters.Regular],
        ),
        dataKey: PayrollReportActivityChartFilters.Regular,
        isHidden: !chartFilters.includes(PayrollReportActivityChartFilters.Regular),
        fillColor: getQueueColor(0),
      },
      {
        key: PayrollReportActivityChartFilters.Overtime,
        name: IntlService.formatMessage(
          PayrollActivityChartLegendLabels[PayrollReportActivityChartFilters.Overtime],
        ),
        dataKey: PayrollReportActivityChartFilters.Overtime,
        isHidden: !chartFilters.includes(PayrollReportActivityChartFilters.Overtime),
        fillColor: getQueueColor(2),
      },
      {
        key: PayrollReportActivityChartFilters.Scheduled,
        name: IntlService.formatMessage(
          PayrollActivityChartLegendLabels[PayrollReportActivityChartFilters.Scheduled],
        ),
        dataKey: PayrollReportActivityChartFilters.Scheduled,
        isHidden: !chartFilters.includes(PayrollReportActivityChartFilters.Scheduled),
        fillColor: getQueueColor(4),
      },
    ],
    [chartFilters],
  );

  const getTickFormatter = () => {
    switch (selectedPeriod) {
      case PeriodFilterType.DAY:
        return (date: string) => timezoneMoment(date, 'YYYY-MM-DD').format('MMM D');
      case PeriodFilterType.WEEK:
        return (date: string) =>
          `${renderIntlMessage(commonMessages.weekLabel)} ${Number(date.slice(4))}`;
      case PeriodFilterType.MONTH:
        return (date: string) => timezoneMoment(`${date.slice(4)}`, 'MM').format('MMM');
      default:
        return (date: string) => date;
    }
  };

  const getTooltipFormatter = (date: string) => {
    switch (selectedPeriod) {
      case PeriodFilterType.DAY:
        return timezoneMoment(date, 'YYYY-MM-DD').format(DEFAULT_DATE_FORMAT);
      case PeriodFilterType.WEEK:
        return `${renderIntlMessage(commonMessages.weekLabel)} ${Number(date.slice(4))}`;
      case PeriodFilterType.MONTH:
        return timezoneMoment(`${date.slice(4)}`, 'MM').format('MMM YYYY');
      default:
        return date;
    }
  };

  return (
    <Box p={2} position="relative">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="h4" noWrap>
            <FormattedMessage {...messages.payrollLabel} />
          </Typography>

          <ChartPeriodFilter onPeriodChange={onPeriodChange} period={selectedPeriod} />
        </Box>

        <PayrollActivityChartLegend onChange={handleChangeReportLegendFilters} />
      </Box>

      <ResponsiveContainer width="100%" minHeight="300px">
        <BarChart
          width={500}
          height={368}
          data={transformedChartData}
          margin={{
            top: 24,
            right: 8,
            left: 0,
            bottom: 6,
          }}
          style={{ fontSize: 12 }}
        >
          <CartesianGrid fill={colors.input.disabled.backgroundColor} strokeDasharray="3 3" />

          <XAxis dataKey="date" axisLine={false} tickFormatter={getTickFormatter()} />
          <YAxis axisLine={false} />
          <Tooltip
            labelFormatter={getTooltipFormatter}
            formatter={value => transformMinutesToTimeFormat(Math.trunc(Number(value)))}
          />

          {chartLineSettings.map(({ dataKey, isHidden, fillColor, name }) => (
            <Bar dataKey={dataKey} fill={fillColor} name={name} hide={isHidden} key={dataKey} />
          ))}
        </BarChart>
      </ResponsiveContainer>

      <LoadingBackdrop isLoading={isChartDataLoading} />
    </Box>
  );
};

export default PayrollActivityChart;
