export enum DictionaryList {
  REGISTERS = 'registers',
  CLUBS = 'clubs',
  MEMBERS = 'members',
  CAMPAIGNS = 'campaigns',
  PT_CAMPAIGNS = 'pt-campaigns',
  LEADS = 'leads',
  SERVICES = 'services',
  EMPLOYEES = 'employees',
  CRM_TEMPLATE_ITEMS = 'crmTemplateItems',
  REPORTS_MEMBERS = 'reportsMembers',
  REPORTS_INVENTORIES = 'reportsInventories',
  REPORTS_SERVICES = 'reportsServices',
  REPORTS_EMPLOYEES = 'reportsEmployees',
  SALESPERSONS = 'salesPersons',
  RECEIPTS = 'receipts',
  RESOURCE_TAGS = 'resourceTags',
  EVENT_TAGS = 'eventTags',
  REVENUE_CODES = 'revenueCodes',
  TAGS = 'tags',
  ALLERGIES = 'allergies',
  DOCUMENT_TEMPLATES = 'documentTemplates',
  TAXES_BY_TAX_RATE_ID = 'taxesByTaxRateId',
  TAXES = 'taxes',
  INVENTORY_CATEGORIES = 'inventoryCategories',
  FRONTDESK_PROFILES = 'frontdeskProfiles',
  MEMBERSHIP_PACKAGES = 'membershipPackages',
  VENDORS = 'vendors',
  STATES = 'states',
  COUNTRIES = 'countries',
  FALLTHROUGH_REASONS = 'fallthroughReasons',
  PARTICIPANTS = 'participants',
  CAME_FROM = 'cameFrom',
  NOTE_CODES = 'noteCodes',
  DEPARTMENTS = 'deparrtments',
  MESSAGING_CAMPAIGN_EMAIL = 'messagingCampaignEmail',
  MESSAGING_CAMPAIGN_SMS = 'messagingCampaignSMS',
  MESSAGING_CAMPAIGN_PHONE = 'messagingCampaignPhone',
  RESOURCES = 'resources',
  INSURANCE_COMPANY = 'insuranceCompany',
  TIME_ACCESS = 'timeAccess',
  ADDITIONAL_FIELDS = 'additionalFields',
  TIMECLOCK_DEPARTMENTS = 'timeclockDepartments',
  TIMECLOCK_EMPLOYEES = 'timeclockEmployees',
  END_REASON = 'endReason',
}

export enum DictionaryItem {
  CAMPAIGN_PHONE = 'campaignPhone',
  CAMPAIGN_EMAIL = 'campaignEmail',
  CAMPAIGN_SMS = 'campaignSms',
}
