import React from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Box, Grid, makeStyles, MenuItem, Typography } from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import MomentAdapter from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getRequiredMessage } from 'common/constants/globalConstants';
import { selectPayrollJobPositions } from 'modules/timeclock/state/selectors';
import { payTypeLabels } from 'modules/employees/constants';

import { AdaptiveDatePicker, Select, TooltipTypography } from 'common/components';
import ClockInClockOutField, {
  CLOCK_IN_CLOCK_OUT_TIME,
} from '../ClockinClockOutField/ClockInClockOutField';

import inputLabels from 'common/messages/inputLabels';
import tableHeaders from 'common/messages/tableHeaders';
import { IAddClockInManuallyData } from 'modules/timeclock/interfaces/timeclock';
import { minutesOfDay } from 'common/utils/time';
import { CustomTheme } from 'common/ui/interfaces';

// TODO - PRM-1810 tmp form interface
interface IFormValues {
  jobPositionId: any;
  date: any;
  clockIn: any;
  clockOut: any;
}

interface IProps {
  onSubmit: (data: IAddClockInManuallyData) => void;
  formId: string;
}

const validationSchema = yup.object().shape({
  jobPositionId: yup
    .string()
    .nullable()
    .required(getRequiredMessage),
  date: yup
    .string()
    .nullable()
    .required(getRequiredMessage),
  clockIn: yup
    .string()
    .nullable()
    .test('clockIn', (clockInTime, context) => {
      const clockOutTime = context.parent.clockOut;

      if (
        clockInTime &&
        clockOutTime &&
        minutesOfDay(moment(clockInTime)) >= minutesOfDay(moment(clockOutTime))
      ) {
        return context.createError({ type: CLOCK_IN_CLOCK_OUT_TIME });
      }

      return true;
    })
    .required(getRequiredMessage),
  clockOut: yup
    .string()
    .nullable()
    .test('clockOut', (clockOutTime, context) => {
      const clockInTime = context.parent.clockIn;

      if (
        clockOutTime &&
        clockInTime &&
        minutesOfDay(moment(clockInTime)) >= minutesOfDay(moment(clockOutTime))
      ) {
        return context.createError({ type: CLOCK_IN_CLOCK_OUT_TIME });
      }

      return true;
    })
    .required(getRequiredMessage),
});

const useStyles = makeStyles((theme: CustomTheme) => ({
  optionTitle: {
    marginRight: theme.spacing(1),
  },
}));

const AddClockInDataForm = ({ onSubmit, formId }: IProps): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();
  const [timezoneMoment] = useTimezoneMoment();

  const jobPositionsOptions = useSelector(selectPayrollJobPositions);

  const classes = useStyles();

  const formMethods = useForm<IFormValues>({
    defaultValues: {
      jobPositionId: null,
      date: null,
      clockIn: null,
      clockOut: null,
    },
    resolver: yupResolver<IFormValues>(validationSchema),
    mode: 'onTouched',
  });

  const { control, formState, handleSubmit } = formMethods;
  const { errors } = formState;

  const handleSubmitData = data => {
    const newData = {
      ...data,
      date: timezoneMoment(data.date, 'YYYY-MM-DD')
        .utc()
        .format(),
      clockIn: timezoneMoment(data.clockIn).format('HH:mm:ss'),
      clockOut: timezoneMoment(data.clockOut).format('HH:mm:ss'),
      eventId: null,
    };
    onSubmit(newData);
  };

  return (
    <FormProvider {...formMethods}>
      <form id={formId} onSubmit={handleSubmit(handleSubmitData)}>
        <Box overflow="hidden">
          <Box mb={1}>
            <Controller
              control={control}
              name="jobPositionId"
              render={({ field }) => (
                <Select
                  label={<FormattedMessage {...tableHeaders.jobPosition} />}
                  variant="outlined"
                  value={field.value}
                  fullWidth
                  onChange={field.onChange}
                  error={!!errors.jobPositionId}
                  helperText={renderIntlMessage(errors.jobPositionId?.message)}
                >
                  {jobPositionsOptions?.map(position => (
                    <MenuItem key={position.id} value={position.id}>
                      <TooltipTypography className={classes.optionTitle} ellipsized>
                        {`${position.jobTitle} at ${position.club.title} (${position.department.title})`}
                      </TooltipTypography>
                      <Typography variant="body1" color="primary">
                        {payTypeLabels.translate(position.payType)}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={4}>
              <MuiPickersUtilsProvider utils={MomentAdapter}>
                <Controller
                  name="date"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <AdaptiveDatePicker
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      disableFuture
                      label={<FormattedMessage {...inputLabels.date} />}
                      error={!!errors.date}
                      helperText={renderIntlMessage(errors.date?.message)}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <ClockInClockOutField />
          </Grid>
        </Box>
      </form>
    </FormProvider>
  );
};

export default AddClockInDataForm;
