// libraries
import React from 'react';
import cx from 'classnames';
import { Control, Controller } from 'react-hook-form';
import { Cancel } from '@material-ui/icons';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core/styles';
// custom components
import { AvailabilityScheduleSelector, NumberTextField, Select } from 'common/components';
import StatusBlock from 'common/components/StatusBlock/StatusBlock';
// interfaces
import CheckBoxWithReset from 'common/components/CheckBoxWithReset/CheckBoxWithReset';
import { FieldArray } from 'react-hook-form/dist/types/fieldArray';
import { IIncludedService } from 'modules/services/interfaces/packages';
import { IDayTimeAvailabilityDto } from 'common/interfaces/common';
// constants
import { LimitationTypes } from 'modules/services/constants/packages';
// messages
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import messages from 'modules/services/messages/messages';
import packageMessages from 'modules/services/messages/packages';
import inputLabels from 'common/messages/inputLabels';
import { getLimitedQuantityLabel } from 'common/utils/displayServiceQuantity';

interface IIncludedServiceItemProps {
  control: Control<any>;
  errors: any;
  watch: any;

  index: number;
  field: FieldArray;
  onRemove: (index: number) => void;

  validAvailabilityRange?: IDayTimeAvailabilityDto[];
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapFlexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    width: '100%',
    paddingRight: theme.spacing(2.5),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
  },
  serviceStatus: {
    marginLeft: theme.spacing(1),
  },
  relative: {
    position: 'relative',
  },
  removeButton: {
    color: theme.palette.text.primary,
    opacity: 0.4,
    position: 'absolute',
    right: theme.spacing(1.5),
  },
  excludableCheckbox: {
    justifyContent: 'flex-end',
    marginRight: theme.spacing(4),
  },
}));

const IncludedServiceItem: React.FC<IIncludedServiceItemProps> = ({
  control,
  errors,
  index,
  field: formField,
  onRemove,
  validAvailabilityRange,
  watch,
}: IIncludedServiceItemProps): JSX.Element => {
  const classes = useStyles();
  const renderIntlMessage = useRenderIntlMessage();
  const field = formField as IIncludedService; // TODO - PRM-1810 need types

  // form

  const isLimited = watch(`includedServices.${index}.limited`);

  // render

  return (
    <Grid container spacing={2} key={field.id}>
      {/* title */}

      <Grid item xs={12} className={cx(classes.wrapFlexCenter, classes.relative)}>
        <Grid item xs={6}>
          <Box display="flex" alignItems="center" className={classes.header}>
            <Typography variant="h5">{field.service.title}</Typography>
            {!field.service.active && (
              <StatusBlock isActive={false} className={classes.serviceStatus} />
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name={`includedServices.${index}.excludable`}
            defaultValue={field.excludable || false}
            render={({ field: { onChange, value } }) => (
              <CheckBoxWithReset
                value={value}
                onChange={onChange}
                label={<FormattedMessage {...messages.canBeRemovedUponSaleLabel} />}
                wrapperClassName={classes.excludableCheckbox}
              />
            )}
          />
        </Grid>
        <IconButton
          size="small"
          type="button"
          className={classes.removeButton}
          onClick={() => onRemove(index)}
        >
          <Cancel fontSize="inherit" />
        </IconButton>
      </Grid>

      {/* configuration */}

      <Grid item xs={6}>
        <Controller
          control={control}
          name={`includedServices.${index}.dayTimeAvailDtoList`}
          defaultValue={field.dayTimeAvailDtoList}
          render={({ field: { onChange } }) => (
            <AvailabilityScheduleSelector
              defaultValue={field.dayTimeAvailDtoList}
              onChange={onChange}
              label={<FormattedMessage {...inputLabels.daysAndHoursForAccess} />}
              defaultOptionLabel={<FormattedMessage {...inputLabels.accessAsPackage} />}
              validAvailabilityRange={validAvailabilityRange}
            />
          )}
        />
      </Grid>

      <Grid item xs={3}>
        <Controller
          control={control}
          name={`includedServices.${index}.limited`}
          defaultValue={field.limited}
          render={({ field: { name, value, onChange, onBlur } }) => (
            <Select
              fullWidth
              label={<FormattedMessage {...packageMessages.serviceLimitationLabel} />}
              variant="outlined"
              name={name}
              value={value}
              onChange={e => onChange(e === 'true')}
              onBlur={onBlur}
              error={!!errors?.limited?.message}
              helperText={renderIntlMessage(errors?.limited?.message)}
            >
              {LimitationTypes.getSelectOptions()}
            </Select>
          )}
        />
      </Grid>

      {isLimited && (
        <Grid item xs={3}>
          <Controller
            control={control}
            name={`includedServices.${index}.limitedRedeemNumber`}
            defaultValue={field.limitedRedeemNumber || 0}
            render={({ field: { value, onChange, onBlur } }) => (
              <NumberTextField
                numberFormatProps={{
                  decimalScale: 0,
                  fixedDecimalScale: true,
                  allowLeadingZeros: false,
                  allowNegative: false,
                  isNumericString: true,
                  type: 'text',
                }}
                variant="outlined"
                label={getLimitedQuantityLabel(
                  field.service.type,
                  field.service.redeemType,
                  field.service.redeemDurationUnit,
                )}
                value={value}
                onChange={v => {
                  if (v !== null && v !== '') onChange(Number(v));
                  else onChange(v);
                }}
                onBlur={onBlur}
                fullWidth
                error={!!errors?.limitedRedeemNumber?.message}
                helperText={renderIntlMessage(errors?.limitedRedeemNumber?.message)}
              />
            )}
          />
        </Grid>
      )}

      <Controller
        name={`includedServices.${index}.service`}
        control={control}
        defaultValue={field.service}
        render={() => null}
      />
    </Grid>
  );
};

export default IncludedServiceItem;
