import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box } from '@material-ui/core';

import { ITask } from 'modules/crm/interfaces/tasks';
import { AlertTypes } from 'common/interfaces/alerts';

import { PeakModules } from 'common/constants/peakModules';

import { getRequiredMessage } from 'common/constants/globalConstants';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import useCheckEmployeeAccess from 'modules/crm/hooks/useCheckEmployeeAccess';

import { Alert, DialogComponent, UserAutocomplete } from 'common/components';

import messages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import tasksMessages from 'modules/crm/messages/tasks';
import leadsMessages from 'modules/crm/messages/leads';

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (salesperson: ITask) => void;
  module: PeakModules.Crm | PeakModules.PersonalTrainingCrm;
}

const initFormValues = {
  salesperson: null,
};

export const ValidationSchema = yup.object().shape({
  salesperson: yup
    .object()
    .nullable()
    .required(getRequiredMessage),
});

const ReassignSalespersonModal = ({
  isOpen,
  isLoading,
  onClose,
  onSubmit,
  module,
}: IProps): JSX.Element => {
  const formMethods = useForm<any>({
    defaultValues: initFormValues,
    resolver: yupResolver(ValidationSchema),
    mode: 'onBlur',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const renderIntlMessage = useRenderIntlMessage();

  const {
    handleChangeSalesperson,
    isFetchEmployeeAccessInfoLoading,
    isEmployeeHasNoPermissions,
  } = useCheckEmployeeAccess(module);

  return (
    <DialogComponent
      title={<FormattedMessage {...tasksMessages.assignSalesPersonTitle} />}
      submitBtnTitle={<FormattedMessage {...messages.assignBtn} />}
      size="xs"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading || isFetchEmployeeAccessInfoLoading}
    >
      <form>
        <Controller
          control={control}
          name="salesperson"
          render={({ field: { value, onChange } }) => (
            <UserAutocomplete
              module={module}
              label={<FormattedMessage {...inputLabels.salespersonAssigned} />}
              fullWidth
              value={value}
              onChange={option => {
                onChange(option);

                if (option) {
                  handleChangeSalesperson(option);
                }
              }}
              error={!!errors.salesperson}
              helperText={renderIntlMessage(errors.salesperson?.message)}
            />
          )}
        />

        {isEmployeeHasNoPermissions && (
          <Box mt={2}>
            <Alert
              severity={AlertTypes.Warning}
              title={<FormattedMessage {...leadsMessages.employeeHasNoPermissions} />}
            />
          </Box>
        )}
      </form>
    </DialogComponent>
  );
};

export default React.memo(ReassignSalespersonModal);
