// Libraries
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';

import {
  Box,
  Button,
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';

// Icons
import { Add as AddIcon } from '@material-ui/icons';
import HeightIcon from '@material-ui/icons/Height';
import { ReactComponent as Delete } from 'img/icons/x-bold_deprecated.svg';

// Custom components
import { FormContainer } from 'common/components';

// Interfaces
import { IScriptDetailed, IScriptDetailedImt } from 'modules/crm/interfaces/scripts';

// Selectors
import { selectQueryPageList } from 'common/state/queryPage-lists/selectors';
import { QueryPageList, ActiveInactiveStatus } from 'common/constants';
// messages
import inputLabels from 'common/messages/inputLabels';
import messages from 'common/messages/messages';
import isFormDirty from 'common/hooks/isFormDirty';
import DiscardChangesModalProvider from 'common/modals/DiscardChangesModal/DiscardChangesModalProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepWrapper: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 2, 2, 1.5),
      marginBottom: theme.spacing(1.5),
      borderRadius: 4,
    },
    selectWrapper: {
      width: 102,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    inputContainer: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    inputWrapper: {
      flex: 1,
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        marginBottom: theme.spacing(1.5),
      },
    },
    moveStepWrapper: {
      //
    },
    stepButton: {
      width: 18,
      height: 18,
      color: theme.palette.text.primary,
      opacity: 0.4,
      padding: 0,
      minWidth: 0,
      '& svg': {
        width: 20,
      },
    },
    newStepButton: {
      backgroundColor: theme.palette.background.default,
      width: '100%',
    },
  }),
);

interface IProps {
  title: JSX.Element;
  script?: IScriptDetailedImt | null;
  isLoading: boolean;
  onSubmit: (formData: IScriptDetailed) => void;
}

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .matches(/^.{0,100}$/, 'Script name must be 100 characters or less')
    .required('Required field'),
  scriptStepDtoList: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .trim()
        .matches(/^.{0,100}$/, 'Step name must be 100 characters or less')
        .required('Required field'),
      instruction: yup
        .string()
        .matches(/^(?=[\S\s]{0,5000}$)[\S\s]*/, 'Instructions must be 5000 characters or less')
        .required('Required field'),
    }),
  ),
});

const defaultFormValues = {
  title: '',
  active: true,
  scriptStepDtoList: [{ title: '', allowInput: true, instruction: '' }],
};

export default function ScriptForm({ title, script, isLoading, onSubmit }: IProps): JSX.Element {
  const classes = useStyles();
  const queryScripts: string = useSelector(selectQueryPageList(QueryPageList.SCRIPTS));

  const initFormValues = () => {
    if (!script) {
      return defaultFormValues;
    }

    const scriptData = script.toJS();

    const sortedScriptSteps = scriptData?.scriptStepDtoList.sort(
      (firstStep, secondStep) => firstStep.index - secondStep.index,
    );

    return {
      ...defaultFormValues,
      ...script.toJS(),
      scriptStepDtoList: sortedScriptSteps,
    };
  };

  const { register, handleSubmit, control, formState } = useForm<IScriptDetailed>({
    defaultValues: {
      ...initFormValues(),
    },
    resolver: yupResolver(validationSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'onBlur',
  });

  const { errors } = formState;
  const regTitle = register('title');

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'scriptStepDtoList',
  });

  const handleRemove = index => {
    if (fields.length > 1) remove(index);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const isDirty = isFormDirty(formState);

  const handleFormSubmit = values => {
    onSubmit({
      ...values,
      scriptStepDtoList: values.scriptStepDtoList.map((item, index) => ({
        ...item,
        index,
      })),
    });
  };

  const handleCancelButton = useCallback(() => {
    navigate(`/${location.pathname.split('/')[1]}/scripts${queryScripts}`);
  }, [location.pathname, navigate, queryScripts]);

  const handleDrag = ({ source, destination }) => {
    if (destination) {
      move(source.index, destination.index);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} style={{ height: '100%' }}>
      <FormContainer
        title={title}
        maxWidth={625}
        isSubmitting={isLoading}
        onCancel={handleCancelButton}
      >
        <Box display="flex" mb={1.5} className={classes.inputContainer}>
          <Box mr={1.5} className={classes.inputWrapper}>
            <TextField
              defaultValue={defaultFormValues.title}
              inputRef={regTitle.ref}
              variant="outlined"
              label={<FormattedMessage {...inputLabels.name} />}
              fullWidth
              name={regTitle.name}
              error={!!errors.title}
              helperText={errors?.title?.message}
            />
          </Box>
          <Box className={classes.selectWrapper}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="status">
                <FormattedMessage {...inputLabels.status} />
              </InputLabel>

              <Controller
                control={control}
                name="active"
                render={({ field }) => (
                  <Select
                    value={field.value ? 1 : 0}
                    name={field.name}
                    defaultValue={defaultFormValues.active}
                    onChange={e => {
                      field.onChange(!!e.target.value);
                    }}
                    labelId="status"
                    variant="outlined"
                    fullWidth
                  >
                    {ActiveInactiveStatus.map(status => (
                      <MenuItem value={Number(status.value)} key={status.key}>
                        {status.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Box>
        </Box>
        <DragDropContext onDragEnd={handleDrag}>
          <Box>
            <Droppable droppableId="test-items">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((item, index) => {
                    const regStepTitle = register(`scriptStepDtoList.${index}.title`);
                    const regStepInstruction = register(`scriptStepDtoList.${index}.instruction`);

                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Draggable key={`test.${index}`} draggableId={`item-${index}`} index={index}>
                        {draggableProvided => (
                          <Box
                            key={item.id}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                          >
                            <Box className={classes.stepWrapper} display="flex" key={item.id}>
                              <Box className={classes.moveStepWrapper} pt={1.5} mr={1.5}>
                                <div {...draggableProvided.dragHandleProps}>
                                  <Button className={classes.stepButton}>
                                    <HeightIcon />
                                  </Button>
                                </div>
                              </Box>
                              <Box flex={1}>
                                <Box display="flex" flexDirection="column">
                                  <Box display="flex" mb={1.5} className={classes.inputContainer}>
                                    <Box mr={1.5} className={classes.inputWrapper}>
                                      <TextField
                                        defaultValue={item.title}
                                        variant="outlined"
                                        label={<FormattedMessage {...inputLabels.stepName} />}
                                        name={regStepTitle.name}
                                        error={!!errors?.scriptStepDtoList?.[index]?.title}
                                        helperText={
                                          errors.scriptStepDtoList?.[index]?.title?.message
                                        }
                                        fullWidth
                                        inputRef={regStepTitle.ref}
                                      />
                                    </Box>
                                    <Box className={classes.selectWrapper}>
                                      <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                          id={`scriptStepDtoList.${index}.allowsLabel`}
                                          style={{ zIndex: 1 }}
                                        >
                                          <FormattedMessage {...inputLabels.allowsInput} />
                                        </InputLabel>

                                        <Controller
                                          control={control}
                                          name={`scriptStepDtoList.${index}.allowInput`}
                                          defaultValue={item.allowInput}
                                          render={({ field }) => (
                                            <Select
                                              defaultValue={item.allowInput}
                                              value={field.value ? 1 : 0}
                                              name={field.name}
                                              onChange={e => {
                                                field.onChange(!!e.target.value);
                                              }}
                                              labelId={`scriptStepDtoList.${index}.allowsLabel`}
                                              variant="outlined"
                                              fullWidth
                                            >
                                              <MenuItem value={1}>Yes</MenuItem>
                                              <MenuItem value={0}>No</MenuItem>
                                            </Select>
                                          )}
                                        />
                                      </FormControl>
                                    </Box>
                                  </Box>
                                  <Box>
                                    <TextField
                                      variant="outlined"
                                      label={<FormattedMessage {...inputLabels.instructions} />}
                                      inputProps={{ maxLength: 5001 }}
                                      fullWidth
                                      multiline
                                      rows={2}
                                      name={regStepInstruction.name}
                                      defaultValue={item.instruction}
                                      inputRef={regStepInstruction.ref}
                                      error={!!errors?.scriptStepDtoList?.[index]?.instruction}
                                      helperText={
                                        errors?.scriptStepDtoList?.[index]?.instruction?.message
                                      }
                                    />
                                  </Box>
                                </Box>
                              </Box>
                              <Box ml={1.75} pt={1.5}>
                                <Button
                                  className={classes.stepButton}
                                  onClick={() => handleRemove(index)}
                                >
                                  <Delete />
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    );
                  })}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Box>
        </DragDropContext>
        <Box>
          <Button
            color="primary"
            onClick={() => {
              append({
                title: '',
                allowInput: true,
                instruction: '',
              } as any); // TODO - PRM-1810 need type
            }}
            startIcon={<AddIcon />}
            className={classes.newStepButton}
          >
            <FormattedMessage {...messages.newStepBtn} />
          </Button>
        </Box>
      </FormContainer>

      {isDirty ? <DiscardChangesModalProvider headerIds sidebarIds /> : null}
    </form>
  );
}
